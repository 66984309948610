import {states} from '../states.js';
import {delay} from './util.js';

const panelMessage = (function(){

	//options
	const fadeDuration = 700;
	const showPanelsMsg = '<span class="key">H</span> to show panels, <span class="key">F</span> for fullscreen';
	const hidePanelsMsg = '<span class="key">H</span> to hide panels, <span class="key">F</span> for fullscreen';

	//cache DOM
	const $el = $("#panel-message");
	const $msg = $el.find('p');
	// Note: caching parent element
	const $messages = $("#messages");

	function init(){
		updateUI();
		if (!states.screenMode) show();
	}

	async function updateUI(){
		if (states.hidePanels) await delay(400);
		states.hidePanels ? setMessage(showPanelsMsg) : setMessage(hidePanelsMsg);
	}

	function setMessage(msg){
		$msg.html(msg);
	}

	function show(){
		$el.addClass('show');
	}

	function fadeOutAllMessages() {
		$messages.fadeOut(fadeDuration);
	}

	return {
		init: init,
		updateUI: updateUI,
		fadeOutAllMessages: fadeOutAllMessages
	}

})();

export {panelMessage};