import {states} from '../states.js';
import {sharePanel} from './sharePanel.js';
import {exportPanel} from './exportPanel.js';
import {keyframes} from './keyframes.js';

const topPanel = (function(){

	//cache DOM
	const $el = $("#top-panel");
  const $share = $el.find('#share');
  const $export = $el.find('#export');
  const $save = $el.find('#save');

  // Note: cached outside of component
	const $resetSavedKeyframes = $('#reset-saved-keyframes');

  //bind events
  $save.on('click', () => keyframes.updatePost());
	$share.on('click', function(){
		if ($(this).hasClass('disableUI')) return;
		sharePanel.updateLink();
		sharePanel.show();
	});
  $export.on('click', function(){
		if ($(this).hasClass('disableUI')) return;
		exportPanel.show()
	});

  // Init
	/*-------------------------------*/

	function init() {
		if (!states.userOwnsPost) disableButton({$elm: $save, UI: true});
    if (!states.screenMode) initShow();
  }

  function updateUI(){
    states.playing ? hide() : show()
  }

  function updateExportButtonUI({enable}){
		if (!states.auth) enable = false;
		enable ? enableButton({$elm: $export, UI: true}) : disableButton({$elm: $export, UI: true});
		exportPanel.updateExportButton({enable: enable});
	}

  function updateSaveButtonUI({enable, highlight}){
		if (highlight) return $save.addClass('highlight');
		enable ? enableButton({$elm: $save, UI: true}) : disableButton({$elm: $save, UI: true});
		enable ? enableButton({$elm: $resetSavedKeyframes}) : disableButton({$elm: $resetSavedKeyframes});
		$save.removeClass('highlight');
	}
  
  function enableButton({$elm, UI}){
		UI ? $elm.closest('div').removeClass('disableUI') : $elm.closest('div').removeClass('disable');
	}

	function disableButton({$elm, UI}){
		UI ? $elm.closest('div').addClass('disableUI') : $elm.closest('div').addClass('disable');
	}

  function initShow(){
    $el.addClass('show');
		$el.css('visibility', 'visible');
  }

	function show(){
		$el.removeClass('hide')
	}

	function hide(){
    $el.addClass('hide')
	}

	return{
    init: init,
    updateUI: updateUI,
    updateExportButtonUI: updateExportButtonUI,
    updateSaveButtonUI: updateSaveButtonUI,
		show: show,
		hide: hide
	}

})();

export {topPanel};