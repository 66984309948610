import {states} from '../states.js';
import {centerModal} from './centerModal.js';
import {sidePanel} from './sidePanel.js';

// Remote status
const remoteStatus = (function(){

	function updateUI() {
		// Not paired
		if (!states.remotePaired){
			sidePanel.updatePhoneConnectionUI();
			centerModal.showQrCode();
			return;
		}
		// Paired
		sidePanel.updatePhoneConnectionUI();
		centerModal.updateUI();
	}
	
	return {
		updateUI: updateUI
	};

})(); //remoteStatus

export {remoteStatus};