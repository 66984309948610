import Sortable from 'sortablejs';
import {states} from '../states.js';
import {options} from '../options.js';
import {postRequests} from '../shared/postRequests.js';
import {delay, shallowObjEqual} from '../shared/util.js';
import {map, getRandomInteger} from '../shared/math.js';
import {filterObject, convertObjValuesToFloat} from '../shared/objectMutations.js';
import {convertObjArrayToParamStringArray} from '../shared/urlParams.js';
import {menu} from '../shared/menu.js';
import {images} from './images.js';
import {controls} from './controls.js';
import {hoverOverlay} from './hoverOverlay.js';
import {projectorSpeed} from './images_projectorSpeed.js';
import {sidePanel} from './sidePanel.js';
import {socketEmit} from './socketEmit.js';
import {sharePanel} from './sharePanel.js';
import {paramOptions} from './paramOptions.js';
import {topPanel} from './topPanel.js';

const keyframes = (function(){

	// States
	let loopingKeyframes = false;
	let sortDisabled = false;
	let held = false;
	let adding = false;
	let removing = false;
	let shuffle = false;
	let preventTogglePlayAll = false;
	let preventTogglePlayAllResetDuration = 500;

	// Data
	let sortable;
	let sortingTimer;
	let progressRingCircumference;
	let resetParams;
	let keyframeList = [];
	let listArray = [];
	let holdTimer;
	const rampUpValues = {
		0: 'slow',
		1: 'medium',
		2: 'fast',
		3: 'instant'
	}

	// Options
	const maxKeyframes = 5;
	const progressRingRadius = 18;
	const delayDuration = 0;
	const emptyBubbleWidth = 54;
	const emptyParamWidthOffset = 2; // Note: not sure why this offset is needed
	const paramIconWidth = 40;
	const deleteButtonWidth = 32;
	const extraWidth = 20;
	const gapWidth = 1;
	const holdDuration = 500;
	const defaultRampUp = 1;
	const defaultDuration = 1;
	const defaultAutoFrame = 0; // 0 is false
	const bubbleExpandMinWidth = 250

	// Cache DOM
	/*-------------------------------*/

	const $el = $('#keyframes');
	const $items = $el.find('#items');
	const items = $items[0];
	const $sort = $el.find('#sort');
	const sort = $sort[0];
	let bubbleClickTarget;
	let $lis;
	let $bubbles;
	let $openBtns;
	let $undoBtns;
	let $redoBtns;
	let $deleteBtns;
	let $addButton;
	let $rampUpRangeInputs;
	let $durationRangeInputs;
	let $autoFrameInputs;

	function cacheDOM(){
		$lis = $sort.find('li');
		$bubbles = $lis.find('.bubble');
		$openBtns = $items.find('.open-button');
		$undoBtns = $items.find('.undo-button');
		$redoBtns = $items.find('.redo-button');
		$deleteBtns = $items.find('.delete-button');
		$addButton = $items.find('.add-button .bubble');
		$rampUpRangeInputs = $items.find('.range-slider.ramp-up');
		$durationRangeInputs = $items.find('.range-slider.duration');
		$autoFrameInputs = $items.find('.checkbox.auto-frame');
	}

	// Bind events
	/*-------------------------------*/

	function bindEvents(){
		$undoBtns.off().on('click', undoPhase);
		$redoBtns.off().on('click', redoPhase);
		$deleteBtns.off().on('click', remove);
		$addButton.off().on('click', function(){addCurrent({fromClick: true, elm: this})});
		$bubbles
		.off('mousedown').on('mousedown', function () {
			bubbleClickTarget = this			
			holdTimer = setTimeout(() => {
				holdEvent(this);
			}, holdDuration);
		})
		.off('mouseup').on('mouseup', function () {
			// Return if bubble element on mousedown doesn't match bubble element on mouseup
			if (this != bubbleClickTarget) return;
			clearInterval(holdTimer);
			clickEvent(this);
		});
		$rampUpRangeInputs.off().on('input', async function (){
			const value = rampUpValues[this.value]
			$(this).closest('li').find('.ramp-up-value').text(value)
			await updateArray();
			states.saved = false;
			console.log("states.saved", states.saved);
			updateButtons();
		})
		$durationRangeInputs.off().on('input', async function (){
			$(this).closest('li').find('.duration-value').text(this.value)
			await updateArray();
			states.saved = false;
			console.log("states.saved", states.saved);
			updateButtons();
		})
		$autoFrameInputs.off().on('input', async function (){
			$(this).closest('li').find('.auto-frame-value').text(this.checked ? 'true' : 'false')
			await updateArray();
			states.saved = false;
			console.log("states.saved", states.saved);
			updateButtons();
		})
	}

	function holdEvent(elm){
		//resetParam(elm);
		held = true;
		console.log("held", held)
	}

	function clickEvent(elm){
		const $target = $(event.target);
		console.log("$target", $target);
		if (states.playing) return;
		if (!held){
			// Return if user does not click on top section of keyframe
			const toggleExpandElm = $target.closest( ".toggle-expand" )
			if (toggleExpandElm.length < 1) return
			automateParams({fromClick: true, elm: elm});
			return;
		}
		held = false
		console.log("held", held);
	}

	// Confirm user decision to leave page if project is not saved and user changed keyframes
	window.addEventListener("beforeunload", (e) => {
		if (states.saved || !states.userOwnsPost || menu.getLeavePageConfimed() || !states.userChangedKeyframes || sidePanel.getPostDeleted()) return;
		// Highlight save button
		topPanel.updateSaveButtonUI({highlight: true});
		e.returnValue = true;
		return true
	});

	// Init
	/*-------------------------------*/

	async function init(){
		initSortable();
		// Calc progress ring circumference
		progressRingCircumference = progressRingRadius * 2 * Math.PI;
		// Clone reset params
		resetParams = images.getResetParams();
		// Set reset params frame rate
		resetParams.frameRate = states.mediaData.fps;
		//console.log("resetParams", resetParams);
		// If post has saved keyframes and no url params are detected, populate post keyframes
		if (states.mediaData.keyframes && states.mediaData.keyframes.length > 0 && !images.getHasUrlParams()){
			// In export audio mode, strip params from keyframes that are not related to audio playback rate in order to make audio stream recording as smooth as possible
			if (states.exportAudioMode){
				const paramsToKeep = new Set(['speed', 'frameRate', 'order']);
				// Iterate through keyframe objects in array and filter params in keyframe object
				for (const keyframeObj of states.mediaData.keyframes) filterObject(keyframeObj, paramsToKeep);
			}
			await populateKeyframes(states.mediaData.keyframes);
			states.saved = true;
			console.log("states.saved", states.saved);
		}
		// If url params are detected and contain items, populate url keyframes
		if (images.getHasUrlParams()){
			const urlParamsArray = images.getUrlParamsArray();
			if (urlParamsArray && urlParamsArray.length > 0) await populateKeyframes(urlParamsArray);
			states.saved = false;
			console.log("states.saved", states.saved);
		}
		// If no url params are detected, disable reset url keyframes button
		if (!images.getHasUrlParams()) sidePanel.updateResetUrlKeyframesButtonUI({enable: false});
		// Create add button
		if (listArray.length < maxKeyframes) createAddButton();
		console.log("listArray.length", listArray.length);
		// Update buttons
		updateButtons();
		// Update enable
		updateEnable();
		// Show
		if (!states.screenMode) show();
	}

	function initSortable(){
		sortable = new Sortable(sort, {
			animation: 200,
			chosenClass: 'chosen',
			handle: '.sort-handle',
			dataIdAttr: 'data-id',
			disabled: false,
			onChoose: function (evt) {
				//console.log("onChoose");
				sortingTimer = setInterval(() => {
					hoverOverlay.resetTimer();
				}, 200)
			},
			onUnchoose: function (evt) {
				//console.log("onUnchoose");
				clearInterval(sortingTimer);
				clearInterval(holdTimer);
				held = false;
				console.log("held", held)
			},
			onMove: function (evt) {
				//console.log("onMove");
				hoverOverlay.resetTimer();
			},
			onEnd: async function (evt) {
				//console.log("onEnd");
				await updateArray();
				states.saved = false;
				console.log("states.saved", states.saved);
				updateButtons();
			}
		});
	}

	// Phase history
	/*-------------------------------*/

	function populateInitialPhaseHistory(){
		states.history = []
		listArray.forEach((item, index) => {
			addToPhaseHistory(item)
		});
	}

	function addToPhaseHistory(elm){
		const listItemUniqueId = $(elm).data('unique-id')
		const params = $(elm).attr('data-params');
		const searchParams = new URLSearchParams(params);
		const paramsObj = Object.fromEntries(searchParams);
		const { order, ...paramsObjStripped } = paramsObj;
		convertObjValuesToFloat(paramsObjStripped);
		const newData = {
			id: listItemUniqueId,
			activeIndex: 0,
			params: [paramsObjStripped]
		};
		states.history.push(newData)
		//console.log("states.history", states.history)
	}

	// Calc
	/*-------------------------------*/

	function calcBubbleWidth({numberOfParams}){
		if (numberOfParams < 1) return emptyBubbleWidth+paramIconWidth-emptyParamWidthOffset;
		return paramIconWidth*numberOfParams + gapWidth*(numberOfParams-1) + extraWidth + deleteButtonWidth;
	}

	function calcBubbleWidthContract({numberOfParams}){
		if (numberOfParams < 1) return emptyBubbleWidth+paramIconWidth-emptyParamWidthOffset;
		return paramIconWidth*numberOfParams + gapWidth*(numberOfParams-1) + extraWidth;
	}

	// Create
	/*-------------------------------*/

	function createAddButton({slide} = {}){
		const str = 
			`<div class="tooltip">
				<li class="add-button ${slide ? 'slide' : ''}">
					${bubbleMarkup({init: false, rampUp: defaultRampUp, duration: defaultDuration, autoFrame: defaultAutoFrame})}
				</li>
				<span class="tooltip-text"><p>Create phase <span class="key">P</span></p></span>
			</div>`
		$items.append(str);
		cacheDOM();
		bindEvents();
	}

	async function addCurrent({fromClick, elm} = {}){
		// Transform add button into a sortable list item
		if (states.playing) return;
		const $parent = fromClick ? $(elm).closest('li') : $addButton.closest('li');
		if (!$parent.hasClass('add-button')) return;
		if (adding || removing) return;
		adding = true;
		console.log("adding", adding);
		const $tooltip = $parent.closest('.tooltip');
		const playbackParamsRound = await images.getRoundedPlaybackParams();
		const response = await add({obj: playbackParamsRound});
		const width = calcBubbleWidth({numberOfParams: response.numberOfParams});
		console.log("width", width);
		const $childBubble = $parent.find('.bubble');
		const $childIcons = $parent.find('.icons');
		$childBubble.css('width', `${width}px`);
		$childBubble.attr('data-width', width);
		$parent.removeClass('add-button');
		toggleBubbleExpand({open:true, elm:$childBubble})
		$lis.removeClass('active');
		$parent.addClass('active');
		// Add unique ID to list item
		const randomNumber = getRandomInteger(0, 1000000);
		$parent.attr('data-unique-id', randomNumber);
		// Create new add button
		// Note: Current keyframe won't be represented in listArray because it has not been appended to sort list yet. Add plus one to list array.
		console.log("listArray.length", listArray.length);
		if (listArray.length+1 < maxKeyframes) createAddButton({slide: true});
		await delay(300);
		$childIcons.addClass('show');
		await delay(300);
		images.resetKeyframeStatusChecked();
		// Move keyframe element to sortable list
		$sort.append($parent[0]);
		if ($tooltip) $tooltip.remove();
		await updateArray();
		// Add to phase history
		addToPhaseHistory($parent[0])
		states.saved = false;
		console.log("states.saved", states.saved);
		if (!states.userChangedKeyframes) states.userChangedKeyframes = true;
		console.log("states.userChangedKeyframes", states.userChangedKeyframes);
		updateButtons();
		bindEvents();
		adding = false;
		console.log("adding", adding);
	}

	async function updateCurrent({elm=false, params=false} = {}){
		const $parent = elm ? $(elm) : $sort.find('li.active');
		if ($parent.length < 1) return console.log("No active phase")
		const $childBubble = $parent.find('.bubble');
		const $childIcons = $parent.find('.icons');
		if (adding || removing) return;
		adding = true;
		console.log("adding", adding);
		$childIcons.removeClass('show');
		$childIcons.empty()
		const playbackParamsRound = params || await images.getRoundedPlaybackParams();
		const { order, ...playbackParamsRoundStripped } = playbackParamsRound;
		const listItemUniqueId = $parent.data('unique-id')
		const phaseIndex = states.history.findIndex(obj => obj.id === listItemUniqueId);
		// If no params are passed, add to phase history
		if (!params){
			// If object is found 
			if (phaseIndex !== -1) {
				// If current params are not equal to active history params
				if (!shallowObjEqual(states.history[phaseIndex].params[states.history[phaseIndex].activeIndex], playbackParamsRoundStripped)){
					// Remove all phase objects from array after active index
					states.history[phaseIndex].params.splice(states.history[phaseIndex].activeIndex+1);
					// Add to phase history
					const newData = {
						id: listItemUniqueId,
						activeIndex: states.history[phaseIndex].params.length,
						params: [...states.history[phaseIndex].params, ...[playbackParamsRoundStripped]]
					};
					states.history[phaseIndex] = { ...states.history[phaseIndex], ...newData };
				}
			} else {
				const newData = {
					id: listItemUniqueId,
					activeIndex: 0,
					params: [playbackParamsRoundStripped]
				};
				states.history.push(newData)
			}
		}
		updateUndoRedoButtons($parent, phaseIndex)
		const response = await add({elm: $parent, obj: playbackParamsRound});
		const width = calcBubbleWidth({numberOfParams: response.numberOfParams});
		console.log("width", width);
		$childBubble.attr('data-width', width);
		// Animate bubble
		const widthUse = width < bubbleExpandMinWidth ? `${bubbleExpandMinWidth}px` : width
		$childBubble.animate({ width: widthUse }, 100, async function() {
			$childIcons.addClass('show');
		});
		$parent.addClass('active');
		images.resetKeyframeStatusChecked();
		await updateArray();
		states.saved = false;
		console.log("states.saved", states.saved);
		if (!states.userChangedKeyframes) states.userChangedKeyframes = true;
		console.log("states.userChangedKeyframes", states.userChangedKeyframes);
		updateButtons();
		bindEvents();
		adding = false;
		console.log("adding", adding);
	}

	async function undoPhase(){
		const $parent = $(this).closest('li')
		const listItemUniqueId = $parent.data('unique-id')
		const phaseIndex = states.history.findIndex(obj => obj.id === listItemUniqueId);
		if (states.history[phaseIndex]?.activeIndex == null || states.history[phaseIndex].activeIndex == 0) return
		const newActiveIndex = states.history[phaseIndex].activeIndex-1
		const paramsObj = states.history[phaseIndex].params[newActiveIndex]
		states.history[phaseIndex].activeIndex = newActiveIndex
		await updateCurrent({elm:$parent[0], params:paramsObj})
		// Set playback
		const paramsUse = formatParams(paramsObj)
		paramsUse.speed = constrainSpeed(paramsUse.speed)
		await images.initAutoParams({
			params: paramsUse,
			automateInstantly: true
		});
	}

	async function redoPhase(){
		const $parent = $(this).closest('li')
		const listItemUniqueId = $parent.data('unique-id')
		const phaseIndex = states.history.findIndex(obj => obj.id === listItemUniqueId);
		if (states.history[phaseIndex]?.activeIndex == null || states.history[phaseIndex].activeIndex == states.history[phaseIndex].params.length-1) return
		const newActiveIndex = states.history[phaseIndex].activeIndex+1
		const paramsObj = states.history[phaseIndex].params[newActiveIndex]
		states.history[phaseIndex].activeIndex = newActiveIndex
		await updateCurrent({elm:$parent[0], params:paramsObj})
		// Set playback
		const paramsUse = formatParams(paramsObj)
		paramsUse.speed = constrainSpeed(paramsUse.speed)
		await images.initAutoParams({
			params: paramsUse,
			automateInstantly: true
		});
	}

	function updateUndoRedoButtons($parent, phaseIndex){
		const $undoButton = $parent.find('.undo-button')
		states.history[phaseIndex].activeIndex == null || states.history[phaseIndex].activeIndex == 0 ? $undoButton.addClass('disable') : $undoButton.removeClass('disable')
		const $redoButton = $parent.find('.redo-button')
		states.history[phaseIndex].activeIndex == states.history[phaseIndex].params.length-1 ? $redoButton.addClass('disable') : $redoButton.removeClass('disable')
	}

	function populateKeyframes(array){
		return new Promise(async resolve => {
			// Sort array by order value
			array.sort(function (a, b) {
				if (array.order) return a.order - b.order;
				if (array.o) return a.order - b.order;
			});
			for (const obj of array) {
				await add({obj: {...obj}, populate: true});
			}
			resolve();
		});
	}

	function add({elm = false, obj, populate = false} = {}){
		return new Promise(async resolve => {
			// Note: generate ID with random number because timestamp is duplicating
			const randomNumber = getRandomInteger(0, 1000000);
			const id = `${randomNumber}`;
			obj.order = id;
			keyframeList.push(obj);
			//console.log("keyframeList", keyframeList);
			await initElements({elm: elm, obj: obj, id: id, populate: populate});
			cacheDOM();
			bindEvents();
			const circleArray = $(`#${id} circle`).toArray();
			console.log("circleArray", circleArray);
			circleArray.forEach(circle => {
				const value = $(circle).closest('.circle').attr('data-value');
				console.log("value", value);
				setProgress(circle, value, progressRingCircumference);
			})
			if (populate){
				await updateArray();
				populateInitialPhaseHistory()
				states.saved = false;
				console.log("states.saved", states.saved);
				updateButtons();
			}
			resolve({numberOfParams: circleArray.length});
		});
	}

	function bubbleMarkup({init, rampUp, duration, autoFrame}){
		return `
			<div class="bubble ${!states.fantascope ? 'increase-height' : ''}">
				<div class="flex-col-container">
					<div class="sort-handle toggle-expand flex-row-container">
						<div class="icons ${init ? 'show' : ''}"></div>
						<span class="open-button"></span>
					</div>
					<div class="wrap-expand-controls">
						<div class="expand-controls">
							<div class="line"></div>
							<div class="wrap-input">
								<div class="range-slider-container h-[20px]">
									<input type="range" min="0" max="3" value="${rampUp}" class="ramp-up range-slider black-circle" name="duration">
								</div>
								<p>Ramp up: <span class="ramp-up-value">${rampUpValues[rampUp]}</span></p>
							</div>
							<div class="wrap-input">
								<div class="range-slider-container h-[20px]">
									<input type="range" min="0" max="10" step=".1" value="${duration}" class="duration range-slider black-circle" name="duration">
								</div>
								<p>Hold: <span class="duration-value fixed-font">${duration}</span> sec</p>
							</div>
							${!states.fantascope ? 
							`<div class="wrap-input switches small h-[20px]"">
								<label class="switch">
									<input type="checkbox" class="checkbox auto-frame" ${autoFrame == 1 ? 'checked' : ''} />
									<div class="slider round no-border"></div>
								</label>
								<p>Auto-frame: <span class="auto-frame-value">${autoFrame == 1 ? 'true' : 'false'}</span></p>
							</div>`
							: '' }
							<div class="line ${!states.fantascope ? 'mt-[4px]' : ''}"></div>
							<div class="bottom-icons flex flex-row justify-between mt-[-4px]">
								<div class="flex flex-row gap-2">
									<span class="undo-button icon undo disable"></span>
									<span class="redo-button icon redo disable"></span>
								</div>
								<span class="delete-button icon trashcan"></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		`
	}

	function initElements({elm, obj, id, populate}){
		return new Promise(resolve => {
			const {order, rampUp = defaultRampUp, duration = defaultDuration, autoFrame = defaultAutoFrame, ...keyframeItems} = obj;
			console.log("keyframeItems", keyframeItems);
			const searchParams = new URLSearchParams(keyframeItems);
			const paramsString = searchParams.toString();
			console.log("paramsString", paramsString);
			// Generate unique ID for list item
			const randomNumber = getRandomInteger(0, 1000000);
			// Populate keyframes based on data
			if (populate){
				const str =
					`
					<li id=${id} data-id="${id}" data-unique-id="${randomNumber}" data-ramp-up="${rampUp}" data-duration="${duration}" data-params="${paramsString}">
						${bubbleMarkup({init: true, rampUp: rampUp, duration: duration, autoFrame: autoFrame})}
					</li>
					`
				// Append to DOM
				$sort.append(str);
				const width = calcBubbleWidth({numberOfParams: Object.keys(keyframeItems).length});
				console.log("width", width);
				const childBubble = $(`#${id} .bubble`);
				childBubble.css('width', `${width}px`);
				childBubble.attr('data-width', width);
			} else {
				// Add single current keyframe by transforming current or add button
				const thisElm = elm || $('.add-button');
				thisElm.attr('id', id);
				thisElm.attr('data-id', id);
				thisElm.attr('data-params', paramsString);
			}
			// Get parent container
			const $parentContainer = $(`#${id} .bubble .icons`);
			console.log("$parentContainer", $parentContainer);
			// Iterate through params in keyframe object
			for (const key in keyframeItems){
				// Find current object param in param ranges array
				const paramRange = images.getParamsRanges().find(obj => obj.name === key);
				// Set min percent based on param range balance attribute
				const minPercent = paramRange.rangeBalanced ? -100 : 0;
				// Map param value to circle stroke percent based on param range
				const mappedValue = map(keyframeItems[key], paramRange.min, paramRange.max, minPercent, 100);
				//console.log("mappedValue", mappedValue);
				// Create child elements
				createElement({
					parameter: key,
					value: mappedValue,
					container: $parentContainer
				});
			}
			// Resolve promise
			resolve();
		});
	}

	function createElement({parameter, value, container} = {}){
		const str = 
			`
			<div class="circle" data-value="${value}">
				<div class="circle-wrap">
					<svg
						class="progress-ring"
						width="40"
						height="40">
					<circle
						class="progress-ring-circle"
						stroke="black"
						stroke-width="2.5"
						fill="transparent"
						r="${progressRingRadius}"
						cx="20"
						cy="20"/>
					</svg>
					<div class="image-wrap">
						<span class="${parameter} icon"></span>
					</div>
				</div>
			</div>
			`
		// Append to DOM
		container.append(str);
	}

	// Progress rings
	// Source: https://css-tricks.com/building-progress-ring-quickly/
	function setProgress(circle, percent, circumference) {
		circle.style.strokeDasharray = `${circumference} ${circumference}`;
		const offset = circumference - percent / 100 * circumference;
		circle.style.strokeDashoffset = offset;
	}

	// Remove
	/*-------------------------------*/

	function removeAddButton(){
		$addButton.closest('.tooltip').remove();
	}

	async function undo({resetSavedKeyframes, removeAll} = {}){
		if (states.createMode && !states.createPostCreated) return;
		if (!resetSavedKeyframes && !images.getHasUrlParams() && !removeAll) return;
		if (resetSavedKeyframes){
			// Get post
			const postResponseData = await postRequests.getPost(states.mediaData.mediaId);
			console.log("postResponseData", postResponseData);
			// Error getting post
			if (!postResponseData.success) return console.log("Error getting post");
			// Success getting post
			// Set media
			states.mediaData = postResponseData.data;
		}
		images.resetParamsGradually();
		// Hide keyframes
		$el.removeClass('show');
		await delay(300);
		$el.addClass('hide');
		// Empty arrays
		keyframeList = [];
		listArray = $lis.toArray();
		console.log("listArray", listArray);
		for (const item of listArray){
			$(item).remove();
		}
		// Populate URL keyframes
		if (!removeAll && !resetSavedKeyframes && images.getHasUrlParams()) await populateKeyframes(images.getUrlParamsArray());
		// Populate saved keyframes
		if (!removeAll && resetSavedKeyframes && states.mediaData.keyframes && states.mediaData.keyframes.length > 0) await populateKeyframes(states.mediaData.keyframes);
		cacheDOM();
		listArray = $lis.toArray();
		console.log("listArray", listArray);
		// Create add button
		if (!$addButton.length && listArray.length != maxKeyframes) createAddButton();
		// Remove add button
		if (listArray.length == maxKeyframes) removeAddButton();
		// Update post if all keyframes are being removed from fantascope frame rate update
		if (removeAll) await updatePost();
		// Show keyframes
		await delay(100);
		$el.removeClass('hide');
		$el.addClass('show');
		if (resetSavedKeyframes){
			states.saved = true;
			console.log("states.saved", states.saved);
		}
		updateButtons();
	}

	async function remove(){
		const $parent = $(this).closest('li');
		if ($parent.hasClass('add-button')) return;
		if (adding || removing) return;
		removing = true;
		console.log("removing", removing);
		const elmWasActive = $parent.hasClass("active");
		const childBubble = $parent.find('.bubble');
		// Add remove class
		childBubble.addClass('remove');
		// Delay
		await delay(300);	
		// Remove phase from history
		const listItemUniqueId = $parent.data('unique-id')
		states.history = states.history.filter(obj => obj.id !== listItemUniqueId);
		// Remove from DOM
		$parent.remove();
		await updateArray();
		states.saved = false;
		console.log("states.saved", states.saved);
		if (!states.userChangedKeyframes) states.userChangedKeyframes = true;
		console.log("states.userChangedKeyframes", states.userChangedKeyframes);
		updateButtons();
		console.log("listArray.length", listArray.length);
		if (listArray.length+1 == maxKeyframes) createAddButton({slide: true});
		removing = false;
		console.log("removing", removing);
	}

	// Update array
	/*-------------------------------*/

	function updateArray(){
		return new Promise(resolve => {
			cacheDOM();
			keyframeList = [];
			listArray = $lis.toArray();
			console.log("listArray", listArray);
			listArray.forEach((item, index) => {
				// Order
				$(item).attr('data-id', $(item).index());
				const order = $(item).attr('data-id');
				// RampUp, duration, and autoFrame
				const rampUp = $(item).find('.range-slider.ramp-up').val()
				const duration = $(item).find('.range-slider.duration').val()
				const autoFrame = $(item).find('.checkbox.auto-frame')[0]?.checked ? 1 : 0
				$(item).attr('data-ramp-up', rampUp);
				$(item).attr('data-duration', duration);
				$(item).attr('data-auto-frame', autoFrame);
				// Params
				const params = $(item).attr('data-params');
				const searchParams = new URLSearchParams(params);
				const paramsObj = Object.fromEntries(searchParams);
				paramsObj.order = order;
				paramsObj.rampUp = rampUp;
				paramsObj.duration = duration;
				paramsObj.autoFrame = autoFrame;
				// Convert param obect values to float
				convertObjValuesToFloat(paramsObj);
				//console.log("paramsObj", paramsObj);
				keyframeList.push(paramsObj);
			});
			console.log("keyframeList", keyframeList);
			if (states.remixPanelOpen || states.postExportPanelOpen) updateCopyUI()
			if (states.remixPanelOpen || states.postExportPanelOpen) paramOptions.updateUI()
			resolve();
		});
	}

	// Post
	/*-------------------------------*/

	async function updatePost(){
		if (states.saved) return;
		if (!states.userOwnsPost || (states.createMode && !states.createPostCreated)) return;
		await updateArray();
		const postObj = {
			keyframes: keyframeList
		}
		// Update post
		const post = await postRequests.updatePost(states.mediaData.mediaId, postObj);
		if (!post) return console.log("Post not updated");
		console.log("Update post", post);
		// Set media
		states.mediaData = post;
		states.saved = true;
		console.log("states.saved", states.saved);
		updateButtons();
	}

	// Playback
	/*-------------------------------*/

	async function togglePlayAll(){
		if (preventTogglePlayAll) return;
		setTimeout(()=>{
			preventTogglePlayAll = false
		}, preventTogglePlayAllResetDuration)
		preventTogglePlayAll = true
		// If adding or removing keyframe, return
		if (adding || removing) return;
		// If playing, reset images gradually
		if (states.playing){
			images.resetParamsGradually();
			controls.updatePlaybackControls();
			return;
		}
		// Return if looping keyframes
		if (loopingKeyframes) return
		// If no list items exist return
		if (!$lis) return reset();
		// If list array is empty return
		listArray = $lis.toArray();
		console.log("listArray", listArray);
		if (listArray.length == 0) return reset();
		states.playing = true;
		console.log("states.playing", states.playing);
		$bubbles.removeClass('expand')
		topPanel.updateUI()
		if (states.remotePaired) socketEmit.send('updateTargetState', {targetState: 'playing', value: states.playing});
		const listItemHasActiveClass = $lis.hasClass("active");
		console.log("listItemHasActiveClass", listItemHasActiveClass);
		controls.updatePlaybackControls();
		let index = shuffle ? getRandomInteger(0, listArray.length-1) : listItemHasActiveClass ? $('li.active').index() : 0;
		while (states.playing && listArray.length > 0) {
			loopingKeyframes = true;
			console.log("loopingKeyframes", loopingKeyframes);
			const elm = listArray[index];
			console.log("elm", elm);
			if (!elm) break;
			await automateParams({elm: elm});
			await delay(delayDuration);
			// After initial ramp up bypass, set ramp up to true in export settings
			if (states.exportMode && !states.exportSettings.rampUp){
				await delay(options.initialPauseDurationForExport);
				states.exportSettings.rampUp = true;
				console.log("states.exportSettings.rampUp", states.exportSettings.rampUp);
			}
			index = shuffle ? getRandomInteger(0, listArray.length-1) : $('li.active').index()+1;
			if (!shuffle && index == listArray.length) index = 0;
		}
		loopingKeyframes = false;
		console.log("loopingKeyframes", loopingKeyframes);
	}

	function automateParams({fromClick = false, elm} = {}){
		return new Promise(async resolve => {
			images.resetKeyframeStatusChecked();
			if (states.playing && fromClick) return resolve();
			const $elm = fromClick ? $(elm).closest('li') : $(elm);
			console.log("$elm", $elm);
			if (fromClick && $elm.hasClass("active")) {
				images.resetParamsGradually();
				toggleBubbleExpand({open:false, elm})
				return resolve();
			}
			if (fromClick) toggleBubbleExpand({open:true, elm})
			$lis.removeClass('active');
			$elm.addClass('active');
			const rampUp = $elm.attr('data-ramp-up');
			const duration = $elm.attr('data-duration');
			const autoFrame = $elm.attr('data-auto-frame');
			const params = $elm.attr('data-params');
			console.log("params", params);
			// Convert search params to obj
			const searchParams = new URLSearchParams(params);
			const paramsObj = Object.fromEntries(searchParams);
			console.log("paramsObj", paramsObj);
			const paramsUse = formatParams(paramsObj)
			// Constrain speed
			paramsUse.speed = constrainSpeed(paramsUse.speed)
			console.log("paramsUse", paramsUse);
			if (!fromClick) images.setReset(false);
			await images.initAutoParams({
				rampUp: rampUp,
				duration: duration,
				autoFrame: autoFrame,
				params: paramsUse,
				automateInstantly: states.exportMode && !states.exportSettings.rampUp ? true : false
			});
			resolve();
		});
	}

	function formatParams(paramsObj){
		// Note: keys must match playback param keys
		return {
			speed: parseFloat(paramsObj.sp ?? paramsObj.speed ?? resetParams.speed),
			frameRate: paramsObj.fr ?? paramsObj.frameRate ?? resetParams.frameRate,
			framing: 0,
			focus: paramsObj.f ?? paramsObj.focus ?? resetParams.focus,
			brightness: paramsObj.b ?? paramsObj.brightness ?? resetParams.brightness,
			saturate: paramsObj.s ?? paramsObj.saturate ?? resetParams.saturate,
			contrast: paramsObj.c ?? paramsObj.contrast ?? resetParams.contrast,
			hue: paramsObj.h ?? paramsObj.hue ?? resetParams.hue
		}
	}

	function constrainSpeed(speed){
		if (speed < 0 && speed > -0.01) speed = -0.01
		if (speed > 0 && speed < 0.01) speed = 0.01
		return speed = parseFloat(speed.toFixed(3))
	}

	function toggleShuffle(){
		shuffle = !shuffle;
		console.log("shuffle", shuffle);
		controls.updateUI();
	}

	// Reset
	/*-------------------------------*/

	function reset(){
		states.playing = false;
		console.log("states.playing", states.playing);
		if (states.remotePaired) socketEmit.send('updateTargetState', {targetState: 'playing', value: states.playing});
		updateButtons();
		if ($lis) $lis.removeClass('active');
		topPanel.updateUI()
	}

	// UI
	/*-------------------------------*/

	async function updatePlaybackModeUI(){
		if (!$openBtns || !$bubbles) return;
		states.playing ? $el.addClass('slide-up') : $el.removeClass('slide-up')
		if (states.playing){
			disableSort();
			$openBtns.hide();
			if ($addButton.length) $addButton.closest('.tooltip').addClass('hide');
		}
		$bubbles.toArray().forEach(elm => {
			console.log("elm", elm);
			const currentWidth = $(elm).width();
			const params = $(elm).closest('li').attr('data-params')
			console.log("params", params);
			// Convert search params to obj
			const searchParams = new URLSearchParams(params);
			const paramsObj = Object.fromEntries(searchParams);
			console.log("paramsObj", paramsObj);
			const numberOfParams = Object.keys(paramsObj).length;
			console.log("numberOfParams", numberOfParams);
			// Note: not sure why this offset is needed
			const expandOffset = -14;
			const contractOffset = -20;
			if (numberOfParams < 1){
				const newWidth = states.playing ? `${emptyBubbleWidth+expandOffset}px` : `${emptyBubbleWidth+paramIconWidth-emptyParamWidthOffset+expandOffset}px`;
				console.log("newWidth", newWidth);
				$(elm).width(newWidth);
				return
			}
			const expandWidth = calcBubbleWidth({numberOfParams: numberOfParams});
			const contractWidth = calcBubbleWidthContract({numberOfParams: numberOfParams});
			const newWidth = states.playing ? `${contractWidth+contractOffset}px` : `${expandWidth+expandOffset}px`;
			console.log("newWidth", newWidth);
			$(elm).width(newWidth);
		})
		if (!states.playing){
			enableSort();
			if ($addButton.length) $addButton.closest('.tooltip').removeClass('hide');
			await delay(300);
			$openBtns.fadeIn();
		}
	}

	// Note: copied and modified from updatePlaybackModeUI() for remix panel
	function updateCopyUI(){
		return new Promise(async resolve => {
			await delay(300);
			// Copy markup from keyframes and insert into keyframes-copy
			const keyframesMarkup = $('#keyframes').html()
			$('#keyframes-copy').html(keyframesMarkup)
			// Cache elements within keyframes-copy
			const $el_copy = $('#keyframes-copy');
			const $items_copy = $el_copy.find('#items');
			const $sort_copy = $el_copy.find('#sort');
			const $lis_copy = $sort_copy.find('li');
			const $bubbles_copy = $lis_copy.find('.bubble');
			const $openBtns_copy = $items_copy.find('.open-button');
			const $addButton_copy = $items_copy.find('.add-button .bubble');
			// Hide elements
			$openBtns_copy.hide();
			$addButton_copy.hide()
			$addButton_copy.closest('.tooltip').addClass('hide');			
			$lis_copy.removeClass('active')
			// Close bubbles			
			$bubbles_copy.toArray().forEach(elm => {
				toggleBubbleExpand({bubbles:$bubbles_copy, open:false, elm})
				const params = $(elm).closest('li').attr('data-params')
				// Convert search params to obj
				const searchParams = new URLSearchParams(params);
				const paramsObj = Object.fromEntries(searchParams);
				const numberOfParams = Object.keys(paramsObj).length;
				// Note: not sure why this offset is needed
				const expandOffset = -14;
				const contractOffset = -20;
				if (numberOfParams < 1){
					const newWidth = `${emptyBubbleWidth+expandOffset+14}px`
					elm.style.setProperty('max-width', newWidth, 'important');
					return resolve();
				}
				const contractWidth = calcBubbleWidthContract({numberOfParams: numberOfParams});
				const newWidth = `${contractWidth+contractOffset+14}px`
				elm.style.setProperty('max-width', newWidth, 'important');
			})
			resolve();
		});
	}

	async function updateButtons(){
		// Disable or enable playback control button
		listArray.length < 1 ? controls.enablePlayback({enable: false}) : controls.enablePlayback({enable: true});
		// Update remix and export panel buttons
		const roundedPlaybackParams = await images.getRoundedPlaybackParams();
		if (Object.keys(roundedPlaybackParams).length < 1 && listArray.length < 1){
			sidePanel.updateRemixButtonUI({enable: false}) 
			topPanel.updateExportButtonUI({enable: false})
		} else {
			sidePanel.updateRemixButtonUI({enable: true});
			topPanel.updateExportButtonUI({enable: true})
		}
		if (states.remixPanelOpen || states.postExportPanelOpen) paramOptions.updateUI()
		// Save button
		if (states.userOwnsPost) states.saved ? topPanel.updateSaveButtonUI({enable: false}) : topPanel.updateSaveButtonUI({enable: true});
		// Share panel link
		sharePanel.updateLink();
	}

	function updateEnable(){
		if (states.createMode && !states.initialPhoto) $el.addClass('disable');
		if (states.createMode && states.initialPhoto) $el.removeClass('disable');
	}

	function toggleBubbleExpand({bubbles=$bubbles, open, elm}){
		bubbles.each(function() {
			if (this == elm) return;
			$(this).removeClass('expand')
			const elmWidth = $(this).attr('data-width')
			$(this).animate({ width: elmWidth }, 100);
		});
		open ? $(elm).addClass('expand') : $(elm).removeClass('expand')
		const elmWidth = $(elm).attr('data-width')
		const widthUse = open && elmWidth < bubbleExpandMinWidth ? `${bubbleExpandMinWidth}px` : elmWidth
		$(elm).animate({ width: widthUse }, 100);
	}

	function closeActivePhase(){
		const $parent = $sort.find('li.active');
		if ($parent.length < 1) return console.log("No active phase")
		const $childBubble = $parent.find('.bubble');
		toggleBubbleExpand({open:false, elm:$childBubble[0]})
	}

	function show(){
		$el.addClass('show');
		$el.css('visibility', 'visible');
	}

	// Sort enable
	/*-------------------------------*/

	function enableSort(){
		sortable.option("disabled", false);
		$items.removeClass('disable-sort');
		sortDisabled = false;
		console.log("sortDisabled", sortDisabled);
	}

	function disableSort(){
		sortable.option("disabled", true);
		$items.addClass('disable-sort');
		sortDisabled = true;
		console.log("sortDisabled", sortDisabled);
	}

	// Get
	/*-------------------------------*/

	function getKeyframeList(){
		return keyframeList;
	}

	async function getParamStringArray({keyframes}){
		return convertObjArrayToParamStringArray({inputArray: keyframes});
	}

	function getShuffle(){
		return shuffle;
	}

	// Set
	/*-------------------------------*/

	function setResetParams(data){
		resetParams = data;
		console.log("resetParams", resetParams);
	}

	return {
		init: init,
		togglePlayAll: togglePlayAll,
		toggleShuffle: toggleShuffle,
		reset: reset,
		getShuffle: getShuffle,
		getKeyframeList: getKeyframeList,
		getParamStringArray: getParamStringArray,
		updateButtons: updateButtons,
		updateEnable: updateEnable,
		updatePost: updatePost,
		updatePlaybackModeUI: updatePlaybackModeUI,
		undo: undo,
		setResetParams: setResetParams,
		addCurrent: addCurrent,
		updateCopyUI: updateCopyUI,
		updateCurrent: updateCurrent,
		closeActivePhase: closeActivePhase
	}

})();

export {keyframes};