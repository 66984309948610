import {states} from '../states.js';

function dragElement(elmnt) {
	// Source: https://www.w3schools.com/howto/howto_js_draggable.asp
	// Data
	let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
	// Bind events
	elmnt.onmousedown = dragMouseDown;

	function dragMouseDown(e) {
		if (!states.enableImageMove && elmnt.id == 'images') return;
		e = e || window.event;
		e.preventDefault();
		// get the mouse cursor position at startup:
		pos3 = e.clientX;
		pos4 = e.clientY;
		document.onmouseup = closeDragElement;
		// call function whenever the cursor moves:
		document.onmousemove = elementDrag;
	}

	function elementDrag(e) {
		e = e || window.event;
		e.preventDefault();
		// calculate the new cursor position:
		pos1 = pos3 - e.clientX;
		pos2 = pos4 - e.clientY;
		pos3 = e.clientX;
		pos4 = e.clientY;
		// set the element's new position:
		elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
		elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
	}

	function closeDragElement() {
		// stop moving when mouse button is released:
		document.onmouseup = null;
		document.onmousemove = null;
	}
}

export{dragElement};