import {
	remoteOptions,
	initArray,
	playbackParams,
	setupProjectorVisualInfo,
	setupAudioInfo
} from '../database.js';
import {options} from '../options.js';
import {states} from '../states.js';
import {map} from '../shared/math.js';
import {projectorSpeed} from './images_projectorSpeed.js';
import {socketEmit} from './socketEmit.js';
import {controls} from './controls.js';

// Projector fps
const projectorFps = (function(){

	//data

		// Fps
			let fpsInit,
				fpsUse,
				minFPS,
				maxFPS;

		// Sound rate
			const neutralSoundRate = 1;
			const soundRateInit = neutralSoundRate;
			let soundRateMapped = soundRateInit;

	//options

		// FPS
			const fpsRange = 2; // Determines max FPS
			// Affects amount of mobile remote rotation
			const minDialValueForFPS = 0;
			const maxDialValueForFPS = 200;

		// Sound rate
			let minSoundRate,
				maxSoundRate;

	function init(){
		// Define variables based on media data
		fpsUse = states.mediaData.fps; 
		// Double source media frame rate if shutter image is generated
		if (options.shutterImage) fpsUse = states.mediaData.fps*2;
		// Define range of fps
		minFPS = 1;
		maxFPS = fpsUse*fpsRange;
		// Define range of sound rate
		minSoundRate = 1/fpsUse;
		maxSoundRate = soundRateInit*fpsRange;
		// Map fps limits to dial limits for initial fps value
		fpsInit = map(fpsUse, minFPS, maxFPS, minDialValueForFPS, maxDialValueForFPS);
		console.log("fpsInit", fpsInit);
		// Set default fps
		projectorSpeed.setFps(fpsUse);
		// Set default soundRateMapped
		projectorSpeed.setSoundRateMapped(soundRateInit);
		// Setup dat.GUI
		setupDatGUI();
	}

	function setupDatGUI(){
		// Setup dat.GUI array with initial values
		initArray({
			array: remoteOptions,
			id: "frameRate",
			minValue: minFPS,
			maxValue: maxFPS,
			value: fpsUse
		});
		// Note: set min and initial value to 0 because projector speed starts at 0
		initArray({
			array: setupProjectorVisualInfo,
			id: "actualFrameRate",
			minValue: 0,
			maxValue: maxFPS,
			value: 0
		});
		initArray({
			array: setupAudioInfo,
			id: "audioRate",
			minValue: -maxSoundRate,
			maxValue: maxSoundRate,
			value: minSoundRate
		});
	}

	function changeFps(data){
		// Set fps
		fpsInit += data;
		// Limit fps
		if (fpsInit > maxDialValueForFPS) {
			fpsInit = maxDialValueForFPS;
			socketEmit.sendFlash();
		}
		if (fpsInit < minDialValueForFPS) {
			fpsInit = minDialValueForFPS;
			socketEmit.sendFlash();
		}
		//console.log("fpsInit", fpsInit);
		// Map dial limits to fps limits
		fpsUse = map(fpsInit, minDialValueForFPS, maxDialValueForFPS, minFPS, maxFPS);
		//console.log("fpsUse", fpsUse);
		// Map fps limits to sound rate limits
		soundRateMapped = map(fpsUse, minFPS, maxFPS, minSoundRate, maxSoundRate);
		//console.log("soundRateMapped", soundRateMapped);
		// Set fps
		projectorSpeed.setFps(fpsUse);
		// Set soundRateMapped
		projectorSpeed.setSoundRateMapped(soundRateMapped);
		// Set sound rate
		projectorSpeed.setSoundRate();
		// Update datGUI params
		playbackParams.frameRate = fpsUse;
		// Update param info
		controls.updateParamInfo();
	}
	
	/*function fixSoundDrift(){
		// Set sound rate with browser render FPS
		// Note: call during images.initPlayback
		// Source: https://www.growingwiththeweb.com/2017/12/fast-simple-js-fps-counter.html
		const times = [];
		let fpsRender;
		function refreshLoop() {
			window.requestAnimationFrame(() => {
				const now = performance.now();
				while (times.length > 0 && times[0] <= now - 1000) {
					times.shift();
				}
				times.push(now);
				fpsRender = times.length;
				//console.log("fpsRender", fpsRender);
				const fpsMin = Math.min(fpsUse, fpsRender);
				//console.log("fpsMin", fpsMin);
				soundRateMapped = map(fpsMin, minFPS, maxFPS, minSoundRate, maxSoundRate);
				//console.log("soundRateMapped", soundRateMapped);
				projectorSpeed.setSoundRateMapped(soundRateMapped);
				projectorSpeed.setSoundRate();
				refreshLoop();
			});
		}
		refreshLoop();
	}*/

	function getMinFPS(){
		return minFPS;
	}

	function getMaxFPS(){
		return maxFPS;
	}

	return {
		init: init,
		changeFps: changeFps,
		getMinFPS: getMinFPS,
		getMaxFPS: getMaxFPS
	};

})(); //projectorFps

export {projectorFps};