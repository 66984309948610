import Shepherd from 'shepherd.js';
import {states} from '../states.js';
import {sidePanel} from './sidePanel.js';

const shepherd = (function(){

	//data
	const lineMarkup = '<div class="line"></div>';
	let tour;
	let steps = [
		{
			id: 'overview',
			title: 'Overview',
			text: `Welcome to Spoolyard! Here you can change the playback settings of moving images&mdash;think of it like controlling an analog film projector.<br><br>Some of the settings affect the way images are “projected,” such as the speed of the images in motion, the frames per second, and the focus of the lens. Other settings control aspects of the image itself, such as the brightness, contrast, and saturation.<br><br>Adjust the settings slowly at first to understand the effect each one has. You can save a snapshot of the playback settings by creating a phase. If you are the creator of the project, you can save phases, create a video documenting the projector, or share your remix!${lineMarkup}`,
			attachTo: {
				element: null,
				on: null
			},
			noButtons: false,
			disableNav: false,
			userOwnsPost: false,
			filmOnly: false,
			fantascopeOnly: false,
			nextOnly: true,
			fullWidth: true,
			longText: true,
			skipCreateMode: false
		},
		{
			id: 'playback-settings',
			title: 'Playback Settings',
			text: `There are two ways to adjust playback settings:<br><br>(1) Connect your phone by clicking the<span class="icon size_50 phone-connection"></span>button in the menu to the left and follow instructions from there.<br><br>(2) Use your mouse or trackpad to click an option below to select a parameter. Once selected, you can scroll to change its value. For fine-tuning, hold down the <span class="key small padding height-23">shift</span> key while scrolling. To reset the value, click and hold the parameter.${lineMarkup}`,
			attachTo: {
				element: '#figures',
				on: 'top'
			},
			noButtons: false,
			disableNav: false,
			userOwnsPost: false,
			filmOnly: false,
			fantascopeOnly: false,
			nextOnly: false,
			skipCreateMode: false
		},
		{
			id: 'keyframes',
			title: 'Phases',
			text: `Create snapshots of the playback settings using phases.<br><br>Once phases are created, you can change their order by dragging them vertically. You can also adjust their duration and transition speed.${lineMarkup}`,
			attachTo: {
				element: '#keyframes',
				on: 'left'
			},
			userOwnsPost: false,
			filmOnly: false,
			fantascopeOnly: false,
			skipCreateMode: false,
			width450: true
		},
		{
			id: 'playback',
			title: 'Playback',
			text: `Once you have created phases, click the play button to experience how they transition from one to the next.<br><br>Try shuffling the phases to randomize their order!${lineMarkup}`,
			attachTo: {
				element: '#playback-button',
				on: 'right'
			},
			userOwnsPost: false,
			filmOnly: false,
			fantascopeOnly: false,
			skipCreateMode: false,
			width450: true
		},
		{
			id: 'audio-playback',
			title: 'Audio Playback',
			text: `If the film has audio, you can toggle the audio playback and waveform. You can also toggle projector sound effects.${lineMarkup}`,
			attachTo: {
				element: '#audio-panel',
				on: 'left'
			},
			userOwnsPost: false,
			filmOnly: true,
			fantascopeOnly: false,
			skipCreateMode: false,
			width450: true
		},
		{
			id: 'image-position',
			title: 'Image Position',
			text: `Move the image by clicking and dragging. Zoom in and out of the image by using the slider or scrolling while holding down the <span class="key small">Z</span> key.${lineMarkup}`,
			attachTo: {
				element: '#image-controls .panel',
				on: 'left'
			},
			userOwnsPost: false,
			filmOnly: false,
			fantascopeOnly: true,
			skipCreateMode: false,
			width450: true
		},
		{
			id: 'save-keyframes',
			title: 'Save Keyframes',
			text: `Save the current set of phases to the project.${lineMarkup}`,
			attachTo: {
				element: '#save',
				on: 'right'
			},
			userOwnsPost: true,
			filmOnly: false,
			fantascopeOnly: false,
			skipCreateMode: false,
			width450: true
		},
		{
			id: 'print',
			title: 'Print',
			text: `Export video of the projector in action!${lineMarkup}`,
			attachTo: {
				element: '#export',
				on: 'right'
			},
			userOwnsPost: false,
			filmOnly: false,
			fantascopeOnly: false,
			skipCreateMode: false,
			width450: true
		},
		{
			id: 'project-settings',
			title: 'Project Settings',
			text: `Update the project's info, adjust privacy settings, or delete the project.${lineMarkup}`,
			attachTo: {
				element: '#project-settings',
				on: 'right'
			},
			userOwnsPost: true,
			filmOnly: false,
			fantascopeOnly: false,
			skipCreateMode: false,
			width450: true
		},
		{
			id: 'audio',
			title: 'Audio',
			text: `Upload, replace, or delete audio.${lineMarkup}`,
			attachTo: {
				element: '#audio-settings',
				on: 'right'
			},
			userOwnsPost: true,
			filmOnly: true,
			fantascopeOnly: false,
			skipCreateMode: true,
			width450: true
		},
		{
			id: 'revert-keyframes',
			title: 'Revert Phases',
			text: `Revert the phases to their saved state using the<span class="icon size_40 reset-saved-keyframes"></span>button or to the settings found in the URL using the<span class="icon size_40 reset-url-keyframes"></span>button.${lineMarkup}`,
			attachTo: {
				element: '#reset',
				on: 'right'
			},
			userOwnsPost: false,
			filmOnly: false,
			fantascopeOnly: false,
			skipCreateMode: false,
			width450: true
		},
		{
			id: 'share',
			title: 'Share Link',
			text: `Generate a link to share your remix!${lineMarkup}`,
			attachTo: {
				element: '#share',
				on: 'right'
			},
			userOwnsPost: false,
			filmOnly: false,
			fantascopeOnly: false,
			skipCreateMode: false,
			width450: true
		},
		{
			id: 'connect-phone',
			title: 'Connect Phone',
			text: `Connect your phone to experience gestural control of the playback settings.${lineMarkup}`,
			attachTo: {
				element: '#phone-connection',
				on: 'right'
			},
			userOwnsPost: false,
			filmOnly: false,
			fantascopeOnly: false,
			skipCreateMode: false,
			width450: true
		}
	]

	//options
	const centerModal = false;

	function init(){
		// Note: match modalOverlayOpeningPadding to highlightClass border width
		tour = new Shepherd.Tour({
			useModalOverlay: true,
			defaultStepOptions: {
				canClickTarget: true,
				cancelIcon: {
					enabled: true
				},
				highlightClass: 'highlight-tour',
				scrollTo: false,
				modalOverlayOpeningPadding: 2,
				modalOverlayOpeningRadius: 11,
				popperOptions: {
					modifiers: [{ name: 'offset', options: { offset: [0, 40] } }]
				}
			}
		});
		// Add listeners
		tour.on('start', () => {
			states.tour = true;
			console.log("states.tour", states.tour);
			sidePanel.updateHelpButton();
		});
		tour.on('complete', () => {
			states.tour = false;
			console.log("states.tour", states.tour);
			sidePanel.updateHelpButton();
		});
		tour.on('cancel', () => {
			states.tour = false;
			console.log("states.tour", states.tour);
			sidePanel.updateHelpButton();
		});
		// Remove steps if user does not own post
		if (!states.userOwnsPost) steps = steps.filter(step => step.userOwnsPost == false);
		// Remove film only steps if media is a fantascope
		if (states.fantascope) steps = steps.filter(step => step.filmOnly == false);
		// Remove fantascope only steps if media is a film
		if (!states.fantascope) steps = steps.filter(step => step.fantascopeOnly == false);
		// Remove skipCreateMode steps if create mode
		if (states.createMode) steps = steps.filter(step => step.skipCreateMode == false);
		// Remove print instructions if user does not own post and media is not curated in explore gallery
		if (!states.userOwnsPost && !states.mediaData.exploreGallery) steps = steps.filter(step => step.id != 'print');
		// Add steps
		addSteps();
	}

	function addSteps(){
		// Add buttons
		steps.forEach((obj, index) => {
			obj.classes = `${centerModal ? 'center-offset' : ''} ${obj.nextOnly ? 'flex-end' : ''} ${obj.fullWidth ? 'full-width' : ''} ${obj.longText ? 'long-text' : ''} ${obj.width450 ? 'w-[450px]' : ''}`;
			if (centerModal) obj.attachTo.on = null;
			if (obj.disableNav){
				// Disable keyboard navigation
				// Source: https://github.com/shipshapecode/shepherd/issues/1043
				obj.when = {
					show() {
						return this.getTour().options.keyboardNavigation = false;
					},
					hide() {
						return this.getTour().options.keyboardNavigation = true;
					},
					cancel() {
						return this.getTour().options.keyboardNavigation = true;
					}
				};

			}
			if (obj.noButtons) return;
			const buttons = [];
			if (index > 0){
				buttons.push({
					action() {
						return this.back();
					},
					secondary: true,
					text: 'Back'
				});
			}
			const textUse = index != steps.length-1 ? 'Next' : 'Done';
			buttons.push({
				action() {
					return this.next();
				},
				text: textUse
			});
			obj.buttons = buttons;
		});
		tour.addSteps(steps);
	}

	function start({id} = {}){
		if (isActive()) return;
		tour.start();
		if (id) tour.show(id);
	}

	function complete(){
		tour.complete();
	}

	function cancel(){
		tour.cancel();
	}

	function resumeStep(){
		if (states.playing) return;
		const currentStep = tour.getCurrentStep();
		if (!currentStep) return start();
		// Start over if last step
		if (tour.steps.indexOf(tour.currentStep) == tour.steps.length-1) return start();
		start({id: currentStep.id});
	}

	function advanceStep({id} = {}){
		const currentStep = tour.getCurrentStep();
		console.log("currentStep", currentStep);
		if (!id || !currentStep) return;
		if (currentStep.id == id && currentStep.isOpen() == true) tour.next();
	}

	function isActive(){
		console.log("tour.isActive()", tour.isActive());
		return tour.isActive();
	}

	return {
		init: init,
		isActive: isActive,
		resumeStep: resumeStep,
		start: start,
		cancel: cancel
	}

})();

export {shepherd};