// Alert
/*Source: https://codepen.io/nodws/pen/RMRRYK*/
const alert = (function(){

	//states
	let waiting = false;
	let eventsBound = false;
	let enableKeyboard = false;

	//data
	let promiseResolve;

	//cache DOM
	const $el = $('#alert');
	const $container = $el.find('.alert-container');
	const $confirm = $el.find('.alert-confirm');
	const $cancel = $el.find('.alert-cancel');
	const $close = $el.find('.alert-close');
	const $spinner = $el.find('.spinner');
	const $msg = $el.find('.alert-custom-message');
	const $actionUndoneMessage = $el.find('.action-undone-message');
	const $action = $el.find('.action');

	//bind events
	function bindEvents(){
		if (eventsBound) return;
		eventsBound = true;
		console.log("eventsBound", eventsBound);
		$cancel.on('click', close);
		$close.on('click', close);
		$confirm.on('click', confirm);
		$(document).keyup(event => {
			// ESC key
			if (!enableKeyboard) return;
			if (event.which == "27") close();
		});
	}

	function init(){
		bindEvents();
	}

	function open({msg, hideActionUndone, action} = {}){
		return new Promise(resolve => {
			promiseResolve = resolve;
			// Set local state
			waiting = false;
			console.log("waiting", waiting);
			// Hide action undone message
			if (hideActionUndone) $actionUndoneMessage.hide()
			// Set action text
			$action.html(action)
			// Set message
			setMessage(msg);
			// Update UI
			updateUI();
			// Show
			$el.show();
		})
	}

	function close(){
		if (!promiseResolve) return;
		promiseResolve(false);
		reset();
		// Hide
		$el.hide();
	}

	function confirm(){
		promiseResolve(true);
		// Set local state
		waiting = true;
		console.log("waiting", waiting);
		// Update UI
		updateUI();
	}

	function updateUI(){
		if (waiting){
			$container.hide();
			$spinner.show();
			// Set local state
			enableKeyboard = false;
			console.log("enableKeyboard", enableKeyboard);
			return;
		}
		$container.show();
		$spinner.hide();
		// Set local state
		enableKeyboard = true;
		console.log("enableKeyboard", enableKeyboard);
	}

	function setMessage(msg){
		$msg.html(msg)
	}

	function reset(){
		promiseResolve = null;
		// Set local states
		waiting = false;
		console.log("waiting", waiting);
		enableKeyboard = false;
		console.log("enableKeyboard", enableKeyboard);
	}

	return {
		init: init,
		open: open,
		close: close
	};

})(); //alert

export {alert};