import {Howl, Howler} from 'howler';
import {S3_PATH, S3_PATH_UPLOAD} from '../config.js';
import {states} from '../states.js';
import {options} from '../options.js';
import {audioContextMessage} from '../shared/audioContextMessage.js';
import {toneAudio} from './toneAudio.js';

const howlerAudio = (function(){

	//states
	let loaded = false;
	let eventsBound = false;

	//data
	let mediaAudio;
	let motor;
	let click;

	//options
	const audioFileName = "audio";
	const clickFileName = "click";
	const motorFileName = "motor";
	const motorVolume = 0.5;
	const clickVolume = 0.3;
	const fadeDuration = 1000;

	//bind events
	function bindEvents(){
		if (eventsBound) return;
		eventsBound = true;
		$(document).on('click', checkAudioContext);
	}

	async function init({uploadAudio} = {}){
		return new Promise((resolve, reject) => {
			bindEvents();
			if (states.mediaHasAudio){
				// Set media path
				const mediaPath = states.mediaData.exploreGallery
				? `${S3_PATH}/media/gallery/${states.mediaData.mediaId}/${options.audioFolderName}/`
				: `${S3_PATH_UPLOAD}/${states.mediaData.mediaId}/${options.audioFolderName}/`;
				// Media audio
				mediaAudio = new Howl({
					src: [`${mediaPath}${audioFileName}.mp3`],
					preload: true,
					onload: () => {
						loaded = true;
						console.log("loaded", loaded);
						resolve("Howler audio loaded");
					},
					onloaderror: () => reject("Howler audio load error")
				});
			}
			if (uploadAudio) return;
			// Motor
			motor = new Howl({
				src: [`${S3_PATH}/media/sound/${motorFileName}.mp3`],
				volume: 0,
				loop: true,
				preload: true,
				autoplay: true,
				onload: () => {
					if (states.mediaHasAudio) return;
					loaded = true;
					console.log("loaded", loaded);
					resolve("Sound effects loaded")
				}
			});
			// Click
			click = new Howl({
				src: [`${S3_PATH}/media/sound/${clickFileName}.mp3`],
				volume: clickVolume,
				preload: true
			});
		});
	}

	function play(offset){
		if (mediaAudio.playing()) return;
		mediaAudio.play();
		mediaAudio.seek(offset);
	}

	function stop(){
		mediaAudio.stop();
	}

	function rate(data){
		// Note: Negative playback rate not working. Using Tone.js for reverse playback.
		// https://github.com/goldfire/howler.js/issues/242
		mediaAudio.rate(data);
	}

	function seek(offset){
		mediaAudio.seek(offset);
	}

	function mute(data){
		mediaAudio.mute(data);
	}

	function unload(){
		if (!mediaAudio) return;
		mediaAudio.unload();
	}

	function playMotor(){
		motor.fade(0, motorVolume, fadeDuration);
	}

	function stopMotor(){
		motor.fade(motor.volume(), 0, fadeDuration);
	}

	function playClick(){
		click.play();
	}

	function getLength(){
		return mediaAudio.duration();
	}

	function getCurrentTime(){
		return mediaAudio.seek();
	}

	function audioContextRunning(){
		if (!loaded) return;
		return Howler.ctx.state == 'running' ? true : false;
	}

	function checkAudioContext(){
		if (!loaded) return;
		//console.log("Howler.ctx.state", Howler.ctx.state);
		if (Howler.ctx.state == 'running') return;
		Howler.ctx.resume();
		audioContextMessage.hide();
		console.log('Howler audio ready');
		toneAudio.checkAudioContext();
	}

	return {
		init: init,
		play: play,
		stop: stop,
		rate: rate,
		seek: seek,
		mute: mute,
		unload: unload,
		playClick: playClick,
		playMotor: playMotor,
		stopMotor: stopMotor,
		getLength: getLength,
		getCurrentTime: getCurrentTime,
		audioContextRunning: audioContextRunning,
		checkAudioContext: checkAudioContext
	}

})();

export {howlerAudio};