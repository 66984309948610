import {options} from '../options.js';
import {states} from '../states.js';
import {delay} from '../shared/util.js';
import {images} from './images.js';
import {backgroundColor} from './backgroundColor.js';
import {exportPanel} from './exportPanel.js';
import {projectInfoTitleCard} from './projectInfoTitleCard.js';
import {topModal} from "../shared/topModal.js";
import {modals} from "./modals.js";
// Note: importing from edit route
import {form} from '../edit/form.js';

const editFormCustom = (function(){

	//cache DOM
	const $el = $("#form");
	const $close = $el.find('.close');
	const $orderField = $el.find('#order-field');
	const $bottomButtons = $el.find('.bottom-buttons');
	const $radios = $el.find('#edit-radios');
	const $updateButton = $el.find('#update');

	//bind events
	$close.on('click', hide);
	$updateButton.on('click', updatePost);

	async function init(){
		$orderField.hide();
		$bottomButtons.hide();
	}

	async function show(){
		if (states.postEditFormOpen) return
		await modals.closePanel()
		states.postEditFormOpen = true;
		console.log("states.postEditFormOpen", states.postEditFormOpen);
		topModal.checkOpenPanels()
		$el.show();
		$el.addClass('show');
	}

	async function hide(){
		$el.removeClass('show');
		await delay(500);
		$el.hide();
		states.postEditFormOpen = false;
		console.log("states.postEditFormOpen", states.postEditFormOpen);
	}

	async function updatePost(){
		const previousFrameRate = states.mediaData.fps;
		const previousBackgroundColor = states.mediaData.bgcolor;
		// Note: calling function in edit route
		await form.updatePost();
		// Update export panel form
		exportPanel.updateForm();
		// Update project info title card
		projectInfoTitleCard.updateText();
		if (!states.fantascope) return;
		// Set default fantascope fps if null
		if (!states.mediaData.fps) states.mediaData.fps = options.defaultFantascopeFrameRate;
		// Set background color if updated
		if (previousBackgroundColor != states.mediaData.bgcolor) backgroundColor.changeColor(states.mediaData.bgcolor);
		// Reset frame rate playback if fps is updated
		// Note: when user selects fps unknown, fps is saved as null in database
		if (!previousFrameRate && !states.mediaData.fps) return;
		if (!previousFrameRate && states.mediaData.fps == options.defaultFantascopeFrameRate) return;
		if (previousFrameRate == states.mediaData.fps) return;
		images.frameRateReset();
	}

	return {
		init: init,
		show: show,
		hide: hide
	}

})();

export {editFormCustom};