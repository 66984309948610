const screenfull = require('screenfull');
import {states} from '../states.js';

const fullscreenButton = (function(){

	//data
	const pageId = states.pageId

	//cache DOM
	// Note: cached outside component
	const $mediaPlayer = $("#media-player");

	//bind events
	function bindEvents(){
		if (!screenfull.isEnabled) return;
		screenfull.on('change', updateUI);
	}

	function init(){
		bindEvents();
	}

	function toggle(){
		if (!screenfull.isEnabled) return;
		screenfull.toggle();
	}

	function updateUI(){
		states.fullscreen = screenfull.isFullscreen ? true : false;
		console.log("states.fullscreen", states.fullscreen);
		// If on screen page, toggle fullscreen class on media player
		if (pageId != "screen") return;
		states.fullscreen ? $mediaPlayer.addClass('fullscreen') : $mediaPlayer.removeClass('fullscreen');
	}

	return {
		init: init,
		toggle: toggle
	}

})();

export {fullscreenButton};