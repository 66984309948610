const fullscreenSpinner = (function(){

	//cache DOM
	const $el = $("#fullscreen-spinner");

	function show(){
		$el.show();
	}

	function hide(){
		$el.hide();
	}

	return {
		show: show,
		hide: hide
	}

})();

export {fullscreenSpinner};