import {states} from '../states.js';

const flickerMessage = (function(){

	//options
	const fadeDuration = 700;
	const msg = "WARNING: May contain flickering images";
	

	//cache DOM
	const $el = $("#flicker-message");
	const el = $el[0];

	function init(){
		const p = document.createElement("P"); 
		p.innerText = msg;
		el.appendChild(p);
		if (!states.screenMode) show();
	}

	function show(){
		$el.addClass('show');
	}

	function fadeOut() {
		$el.fadeOut(fadeDuration);
	}

	return {
		init: init,
		show: show,
		fadeOut: fadeOut
	}

})();

export {flickerMessage};