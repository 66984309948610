import {remoteOptions} from '../database.js';
import {states} from '../states.js';
import {delay} from '../shared/util.js';
import {updateOption} from './updateOption.js';
import {images} from './images.js';
import {socketEmit} from './socketEmit.js';

// Menu options
const menuOptions = (function(){

	// Data
	let holdTimer;

	// State
	let held = false;

	// Options
	const holdDuration = 500;
	const fadeDuration = 200;

	//cache DOM
	const $el = $('#menu-options');
	const $panel = $('#menu-options .panel');
	let $figures;
	let $circles;

	function cacheDom(){
		$figures = $el.find('figure');
		$circles = $el.find('.circle');
	}

	//bind events
	function bindEvents(){
		$circles
		.on('mousedown', function () {
			holdTimer = setTimeout(() => {
				holdEvent(this);
			}, holdDuration);
		})
		.on('mouseup', function () {
			clearInterval(holdTimer);
			clickEvent(this);
		});
	}

	function holdEvent(elm){
		resetParam(elm);
		held = true;
		console.log("held", held)
	}

	function clickEvent(elm){		
		if (!held) return selectOption(elm);
		held = false;
		console.log("held", held)
	}

	// Init
	/*-------------------------------*/

	function init(){
		// Create elements
		remoteOptions.forEach(element => {
			//console.log(element)
			createElement(element)
		});
		// Cache DOM
		cacheDom();
		// Bind events
		bindEvents();
	}

	// Create
	/*-------------------------------*/

	function createElement(element){
		const str = `<figure data-option="${element.id}">
						<div class="circle">
							<span class="${element.id}" title="${element.title}"></span>
						</div>
						<figcaption>
							<p>${element.title}</p>
						</figcaption>
					</figure>`
		// Append to DOM
		$panel.append(str);
	}

	// UI
	/*-------------------------------*/

	function show(){
		$el.show();
	}

	function hide(){
		$el.hide();
	}

	function updateUI(index){
		const elm = $figures[index];
		const active = remoteOptions[index].active;
		if (!states.multipleParams) $figures.removeClass( "select" );
		active ? $(elm).addClass( "select" ) : $(elm).removeClass( "select" );
	}

	function updateSmallDataUI(){
		states.smallDataIncrements ? $el.addClass('small-data') : $el.removeClass('small-data');
	}

	async function resetParamUI(index){
		if (!index) index = 0;
		const elm = $figures[index];
		$(elm).addClass('reset-param');
		await delay(200);
		$(elm).removeClass('reset-param');
	}

	function reset(){
		$figures.removeClass( "select" );
	}

	// Options
	/*-------------------------------*/

	function selectOption(elm){
		const $parentFigure = $(elm).closest('figure');
		const index = $figures.index($parentFigure);
		console.log("index", index);
		updateOption.selectOption(index);
		// Send selected option to mobile remote for UI update
		socketEmit.send('selectOption', index);
	}

	function resetParam(elm){
		const $parentFigure = $(elm).closest('figure');
		const index = $figures.index($parentFigure);
		console.log("index", index);
		images.resetParam(index);
		resetParamUI(index);
		// Send reset option to mobile remote for UI update
		socketEmit.send('resetOption', index);
	}

	// Get
	/*-------------------------------*/

	function getActiveOption(callback){
		// Note: callback used to send multiple socket events
		remoteOptions.forEach((element, index) => {
			if (element.active == true) return callback(index);
		});
	}

	return {
		init: init,
		updateUI: updateUI,
		reset: reset,
		resetParamUI: resetParamUI,
		updateSmallDataUI: updateSmallDataUI,
		getActiveOption: getActiveOption,
		show: show,
		hide: hide
	};

})(); //menuOptions

export {menuOptions};