import {deletePropsWithEmptyValues} from './objectMutations.js';

function convertObjArrayToParamStringArray({inputArray} = {}){
	const outputArray = [];
	inputArray.forEach((obj, index) => {
		let playbackParamNamesShort = changeToShortParamNames(obj);
		playbackParamNamesShort = deletePropsWithEmptyValues(playbackParamNamesShort);
		const searchParams = new URLSearchParams(playbackParamNamesShort);
		const paramsString = searchParams.toString();
		outputArray.push(paramsString);
	})
	return outputArray;
}

// Note: Use nullish coalescing operator to avoid mistaking 0 value as falsy
// Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator
function changeToShortParamNames(obj){
	const objShortNames = {
		sp: obj.speed ?? obj.sp,
		fr: obj.frameRate ?? obj.fr,
		f: obj.focus ?? obj.f,
		b: obj.brightness ?? obj.b,
		s: obj.saturate ?? obj.s,
		c: obj.contrast ?? obj.c,
		h: obj.hue ?? obj.h,
		o: obj.order ?? obj.o,
		ru: obj.rampUp ?? obj.ru,
		d: obj.duration ?? obj.d,
		af: obj.autoFrame ?? obj.af
	}
	delete objShortNames.order;
	return objShortNames;
}

export {convertObjArrayToParamStringArray}