const FileType = require('file-type/browser');
import {options} from '../options.js';
import {mediaRequests} from './mediaRequests.js';
import {uploadRequests} from './uploadRequests.js';
import {bytesToMegaBytes} from './util.js';

// Audio upload
const audioUpload = (function(){

	// Options
	const uploadAudioFileName = 'original-audio';
	const uploadFolderName = 'uploads';

	// Source: https://stackoverflow.com/questions/21720390/how-to-change-name-of-file-in-javascript-from-input-file
	function renameFile(originalFile, newName) {
		return new File([originalFile], newName, {
			type: originalFile.type,
			lastModified: originalFile.lastModified,
		});
	}

	function checkFile({uploadElm, media} = {}){
		return new Promise(async (resolve, reject) => {
			const file = $(uploadElm).prop('files')[0];
			console.log("file", file);
			if (!file) return reject('No file detected');
			const parseFile = await FileType.fromBlob(file);
			console.log("parseFile", parseFile);
			const mediaTypeExt = parseFile.ext;
			console.log("mediaTypeExt", mediaTypeExt);
			// Check file format
			if (mediaTypeExt != 'mp3') return reject('File type not supported');
			// Convert file size to MB
			const fileSizeMB = bytesToMegaBytes(file.size);
			console.log("fileSizeMB", fileSizeMB);
			// Check file size
			if (fileSizeMB > options.actualMaxAudioUploadFileSize) return reject('File is too big');
			// Override original file name and extension
			// Note: use extension parsed from file-type in case file extension was changed manually
			const fileName = `${uploadAudioFileName}.${parseFile.ext}`;
			//console.log("fileName", fileName);
			const fileToUpload = renameFile(file, fileName);
			// Add mediaId to file
			fileToUpload.mediaId = media.mediaId;
			console.log("fileToUpload", fileToUpload);
			resolve({
				file: fileToUpload,
				api: 'sign-s3',
				targetFolder: `${uploadFolderName}`
			});
		});
	}

	function uploadFile({file, api, targetFolder, media}){
		return new Promise(async (resolve, reject) => {
			// Upload file
			const fileUpload = await uploadRequests.initUpload({
				file: file,
				api: api,
				targetFolder: targetFolder
			});
			console.log("fileUpload", fileUpload);
			if (!fileUpload) return reject('Error uploading audio');
			// Replace audio and create waveform
			const mediaData = {
				id: fileUpload.mediaId,
				filmDuration: media.lastFrame / media.fps
			}
			const audioReplaced = await mediaRequests.replaceAudio(mediaData);
			console.log("audioReplaced", audioReplaced);
			if (!audioReplaced) return reject('Error uploading audio');
			resolve(audioReplaced);
		});
	}

	return {
		checkFile: checkFile,
		uploadFile: uploadFile
	};

})(); //audioUpload

export {audioUpload};
