import {
	remoteOptions,
	initArray,
	playbackParams,
	visualInfoParams
} from '../database.js';
import {states} from '../states.js';
import {wheelSpeed} from './images_wheelSpeed.js';
import {controls} from './controls.js';

// Wheel framing
const wheelFraming = (function(){

	//data
	let angleUse = 0,
		anglePrevious = 0,
		resetFramingTimer;

	//options
	const framingAmount = 1;
	const smallFramingAmount = 0.1;

	//cache DOM
	const $el = $('#images');
	let $img;

	function cacheElms(){
		$img = $el.find('img');
	}

	function init(){
		cacheElms();
		// Setup dat.GUI
		setupDatGUI();
	}

	function setupDatGUI(){
		// Setup dat.GUI array with initial values
		initArray({
			array: remoteOptions,
			id: "framing",
			minValue: -100,
			maxValue: 100,
			value: 0
		});
	}

	function setAngleUse(data){
		angleUse = data;
	}

	function changeFraming(data){
		const framingAmountUse = states.smallDataIncrements ? smallFramingAmount : framingAmount;
		angleUse -= data*framingAmountUse;
		rotate($img, angleUse);
		wheelSpeed.setAngleUse(angleUse);
		// Calc angle difference
		const angleDifference = angleUse - anglePrevious;
		//console.log("angleDifference", angleDifference);
		// Define previous angle
		anglePrevious = angleUse;
		//console.log("anglePrevious", anglePrevious);
		// Reset after period of inactivity
		// Clear and set timeout
		clearTimeout(resetFramingTimer);
		resetFramingTimer = setTimeout(resetFraming, 200);
		// Update datGUI params
		playbackParams.framing = angleDifference;
		// Update param info
		controls.updateParamInfo();
	}

	function rotate($elm, degree) {
		//console.log("angleuse", degree);
		$elm.css('transform', `rotate(${degree}deg)`);
		// Update datGUI params
		const remainder = degree % 360;
		const remainderUse = remainder < 0 ? 360 - Math.abs(remainder) : remainder;
		visualInfoParams.currentAngle = remainderUse;
	}

	function resetFraming(){
		playbackParams.framing = 0;
		// Update param info
		controls.updateParamInfo();
	}

	function reset(){
		// Reset initial params
		angleUse = 0;
		// Rotate
		rotate($img, angleUse);
		wheelSpeed.setAngleUse(angleUse);
	}

	return {
		init: init,
		cacheElms: cacheElms,
		changeFraming: changeFraming,
		setAngleUse: setAngleUse,
		reset: reset
	};

})(); //wheelFraming

export {wheelFraming};