const audioContextMessage = (function(){

	//states
	let showing = false;

	//options
	const fadeDuration = 700;
	const msg = "Click anywhere to enable audio"

	//cache DOM
	const $el = $("#audio-context-message");
	const el = $el[0];

	function init(){
		const p = document.createElement("P"); 
		p.innerText = msg;
		el.appendChild(p);
	}

	function show(){
		$el.fadeIn(fadeDuration);
		showing = true;
	}

	function hide(){
		if (!showing) return;
		$el.fadeOut(fadeDuration);
		showing = false;
	}

	return {
		init: init,
		show: show,
		hide: hide
	}

})();

export {audioContextMessage};