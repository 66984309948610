import normalizeWheel from 'normalize-wheel';
import {states} from '../states.js';
import {images} from './images.js';

// Mouse scroll
const mouseScroll = (function(){

	//options
	const scrollLimit = 10;
	const amount = 0.3;

	//bind events
	function bindEvents(){
		//document.addEventListener('wheel', scroll);
		// Source: https://stackoverflow.com/questions/42101723/unable-to-preventdefault-inside-passive-event-listener
		document.addEventListener("wheel", scroll, { passive: false });
	}

	function init(){
		bindEvents();
	}

	function scroll(event){
		if (states.createMode && !states.initialPhoto) return;
		if (states.postEditFormOpen || states.postExportPanelOpen || states.reportPanelOpen || states.remixPanelOpen || states.menuOpen) return;
		event.preventDefault();
		// Normalize mouse wheel data
		const normalized = normalizeWheel(event);
		//console.log(normalized.pixelX, normalized.pixelY);
		// Limit scroll
		let scrollSpeed = normalized.pixelY;
		if (scrollSpeed < -scrollLimit) scrollSpeed = -scrollLimit;
		if (scrollSpeed > scrollLimit) scrollSpeed = scrollLimit;
		// Rotate dial
		images.rotateDial(scrollSpeed*amount);
	}

	return {
		init: init
	};

})(); //mouseScroll

export {mouseScroll};