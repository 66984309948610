import {io} from 'socket.io-client';
const socket = io();
import {states} from '../states.js';
import {options} from '../options.js';
import {audioContextMessage} from '../shared/audioContextMessage.js';
import {qrCodeModal} from './qrCodeModal.js';
import {remoteStatus} from './remoteStatus.js';
import {images} from './images.js';
import {updateOption} from './updateOption.js';
import {datGUI} from './datGUI.js';
import {waveform} from './waveform.js';
import {projectorSpeed} from './images_projectorSpeed.js';
import {controls} from './controls.js';
import {userRequests} from '../shared/userRequests.js';
import {createFilm} from './images_createFilm.js';
import {createWheel} from './images_createWheel.js';
import {howlerAudio} from './howlerAudio.js';
import {initMedia} from './initMedia.js';
import {menuOptions} from './menuOptions.js';
import {sidePanel} from './sidePanel.js';

// Send deviceType after server recieves initial connection
socket.on('deviceConnect', async () => {
	// If auth, create or join socket room with user's socket code, which is stored in user's Auth0 id_token. If not auth, create socket room with random code.
	const userSocketCode = states.auth ? await userRequests.getSocketCode() : false;
	if (states.auth && !userSocketCode) return console.error("User socket code not found in id_token");
	if (states.screenMode) return;
	// Init connection
	socket.emit('deviceType', {
		device: 'desktop',
		userSocketCode: userSocketCode
	}, data => console.log(data));
});

// Desktop joins room and displays QR code
socket.on('connectToRoom', ({code, desktopMembers}) => {
	console.log('Socket ID Connected: '+socket.id);
	console.log("Connected to room-"+code);
	states.socketRoomCode = code;
	console.log("states.socketRoomCode", states.socketRoomCode);
	states.numberOfDesktopSocketsInRoom = desktopMembers;
	console.log("states.numberOfDesktopSocketsInRoom", states.numberOfDesktopSocketsInRoom);
	qrCodeModal.initQrCode(states.socketRoomCode);
});

// Mobile joins room
socket.on('connectMobile', ({desktopMembers}) => {
	console.log("Mobile or app connected");
	//console.log("desktopMembers", desktopMembers);
});

// Mobile leaves room
socket.on('disconnectMobile', ({desktopMembers}) => {
	console.log("Lost connection with mobile browser or app");
	//console.log("desktopMembers", desktopMembers);
	if (!initMedia.isMediaReady()) return;
	states.remotePaired = false;
	console.log("states.remotePaired", states.remotePaired);
	remoteStatus.updateUI();
	if (states.createMode && !states.fantascope) createFilm.updatePost();
	if (!options.newSocketRoomOnRemoteDisconnect || states.auth) return;
	// Remove socket from socket array, leave room, generate new room
	console.log("Leave room");
	socket.emit('leaveRoom');
	console.log("Generate new room");
	// Resend deviceType to initiate creation of socket room with random code
	socket.emit('deviceType', {
		device: 'desktop',
		userSocketCode: false
	}, data => console.log(data));
});

// Desktop joins room
socket.on('connectDesktop', ({desktopMembers}) => {
	states.numberOfDesktopSocketsInRoom = desktopMembers;
	console.log("states.numberOfDesktopSocketsInRoom", states.numberOfDesktopSocketsInRoom);
	sidePanel.updatePhoneConnectionUI();
});

// Desktop leaves room
socket.on('disconnectDesktop', ({desktopMembers}) => {
	states.numberOfDesktopSocketsInRoom = desktopMembers;
	console.log("states.numberOfDesktopSocketsInRoom", states.numberOfDesktopSocketsInRoom);
	sidePanel.updatePhoneConnectionUI();
});

// Remote orientation
socket.on('orientation', data => {
	console.log("orientation", data);
	if (data == "phoneHeld") images.setMaxPlaybackSpeed();
});

// Remote angleDifference
socket.on('angleDifference', data => {
	//console.log("angleDifference", data);
	images.rotateDial(data);
});

// Remote option
socket.on('selectOption', data => {
	console.log("selectOption", data);
	updateOption.selectOption(data);
});

// Remote reset option
socket.on('resetOption', data => {
	console.log("resetOption", data);
	images.resetParam(data);
	menuOptions.resetParamUI(data);
});

// Recieve request for active option from remote
socket.on('requestActiveOption', () => {
	if (!initMedia.isMediaReady()) return;
	// Return states to remote
	// Strip mediaData from states
	const { mediaData, ...filteredStates } = states;
	//console.log("filteredStates", filteredStates);
	socket.emit("returnStates", filteredStates);
	// Return active option to remote
	menuOptions.getActiveOption(index => {
		console.log("index", index);
		socket.emit("returnActiveOption", index);
	});
	states.remotePaired = true;
	console.log("states.remotePaired", states.remotePaired);
	remoteStatus.updateUI();
});

// Remote audio toggle
socket.on('audioToggle', data => {
	//console.log("audioToggle", data);
	// Set state
	states.audio = data;
	console.log("states.audio", states.audio);
	if (states.audio){
		// Play sound
		projectorSpeed.playSound();
		// Show message if Audio Context is not running
		if (!howlerAudio.audioContextRunning()) audioContextMessage.show();
	} else {
		// Stop sound
		projectorSpeed.stopSound();
		// Hide Audio Context message
		if (!states.soundFX) audioContextMessage.hide();
	}
	// Update controls
	controls.updateUI();
});

// Remote sound effects toggle
socket.on('soundFxToggle', data => {
	//console.log("soundFxToggle", data);
	// Set state
	states.soundFX = data;
	console.log("states.soundFX", states.soundFX);
	if (states.soundFX){
		projectorSpeed.playSoundEffects();
		// Show message if Audio Context is not running
		if (!howlerAudio.audioContextRunning()) audioContextMessage.show();
	} else {
		projectorSpeed.stopSoundEffects();
		// Hide Audio Context message
		if (!states.audio) audioContextMessage.hide();
	}
	// Update controls
	controls.updateUI();
});

// Remote waveform toggle
socket.on('waveformToggle', data => {
	console.log("waveformToggle", data);
	const waveformToggle = data;
	waveformToggle ? waveform.open() : waveform.close();
});

// Image from mobile camera
socket.on('image', data => {
	//console.log("image", data);
	if (states.createMode) states.fantascope ? createWheel.initCreateImage() : createFilm.initCreateImage({imageNumber: data});
});

// Export
export {socket};
