const footer = (function(){

	//options
	const fadeDuration = 1000;

	//cache DOM
	const $el = $("#footer");

	function show(){
		$el.show();
	}

	function hide(){
		$el.hide();
	}

	function fadeIn() {
		$el.fadeIn(fadeDuration);
	}

	function fadeOut() {
		$el.fadeOut(fadeDuration);
	}

	return{
		show: show,
		hide: hide,
		fadeIn: fadeIn,
		fadeOut: fadeOut
	}

})();

export{footer};