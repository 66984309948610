import {states} from '../states.js';
import {sidePanel} from './sidePanel';

const projectInfoTitleCard = (function(){

	//state
	let loaded = false;

	//options
	const fadeDuration = 700;

	//cache DOM
	const $el = $("#project-info-title-card");
  const $msg = $el.find(".message");
	const $close = $el.find('.close-button');

	//bind events
	$close.on('click', () => {
		fadeOut()
		sidePanel.updateProjectInfoButton({active: false})
	});

	function init(){
		if (loaded) return;
		loaded = true;
		console.log("loaded", loaded);
		updateText();
	}

	function updateText(){
		const title = states.mediaData.title.trim() || "Untitled";
		const year = states.mediaData.year || "";
		const author = states.mediaData.mediaAuthor.trim() || "";
		const description = states.mediaData.description ? states.mediaData.description.trim() : "";
		const descriptionExists = description.length > 0;
		const str = 
			`
				<h1 class="title">${title}</h1>
				<p class="author">${author}</p>
				<p class="year">${year}</p>
				${descriptionExists ? `<p class="description">${description}</p>` : ''}
			`;
		setMsg(str);
	}

	function makeVisible(){
		$el.css("visibility", "visible");
	}

  function setMsg(msg){
		$msg.html(msg)
	}

	/*Note: Fading style same as center-modal. Opacity used for smooth fading*/
	function fadeIn(){
		makeVisible();
		return new Promise(resolve => {
			show();
			$el.fadeTo(fadeDuration, 1, resolve);
		});
	}

	function fadeOut(){
		return new Promise(resolve => {
			$el.fadeTo(fadeDuration, 0, () => {
				hide();
				resolve();
			});
		});
	}

	function show(){
		$el.show();
	}

	function hide(){
		$el.hide();
	}

	return{
		init: init,
    fadeIn: fadeIn,
		updateText: updateText
	}

})();

export {projectInfoTitleCard};