import Stats from 'stats.js';
import {states} from '../states.js';

// Stats
const stats = (function(){

	//data
	let stats;

	//cache DOM
	const $el = $('#stats');
	const el = $el[0];

	function init(){
		// Init stats
		stats = new Stats();
		stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
		// Append to DOM element for custom positioning
		el.appendChild(stats.dom);
		// Override plugin style
		$el.find('div').css("position", "relative");
		// Begin monitoring
		requestAnimationFrame(animate);
	}

	function animate() {
		if (states.info) stats.update();
		requestAnimationFrame(animate);
	}

	function updateUI(){
		states.info ? $el.addClass('open') : $el.removeClass('open');
	}
	
	return {
		init: init,
		updateUI: updateUI
	};

})(); //stats

export {stats};