import {rootURL, localServerURL} from '../config.js';
import {states} from '../states.js';
import {delay} from '../shared/util.js';
import {keyframes} from './keyframes.js';
import {controls} from './controls.js';
import {projectorSpeed} from './images_projectorSpeed.js';
import {images} from './images.js'

const postMessage = (function(){

	//states
	let loaded = false;
	let parentMessageRecieved = false;

	//bind events
	function bindEvents(){
		// Source: https://stackoverflow.com/questions/9153445/how-to-communicate-between-iframe-and-the-parent-site
		// Source: https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
		// Define origin
		const origin = localServerURL || rootURL;
		// Send message to parent window object
		window.top.postMessage('Child connect', origin);
		// Listen for messages from parent window object
		window.onmessage = async event => {
			if (event.origin !== origin) return;
			if (event.data.msg === 'Parent connect') parentConnect();
			if (event.data.msg === 'Toggle play') togglePlay();
			if (event.data.msg === 'Toggle audio') toggleAudio();
		}
	}

	function init(){
		if (loaded) return;
		loaded = true;
		bindEvents();
	}

	function parentConnect(){
		console.log(event.data)
		states.screenMode = true;
		console.log("states.screenMode", states.screenMode);
		states.screenModeSettings = event.data.data;
		console.log("states.screenModeSettings", states.screenModeSettings);
		states.exportMode = states.screenModeSettings.export;
		console.log("states.exportMode", states.exportMode);
		states.exportAudioMode = states.screenModeSettings.exportAudio;
		console.log("states.exportAudioMode", states.exportAudioMode);
		states.exportSettings = states.screenModeSettings.exportSettings;
		console.log("states.exportSettings", states.exportSettings);
		parentMessageRecieved = true;
		console.log("parentMessageRecieved", parentMessageRecieved);
	}

	async function togglePlay(){
		console.log("event.data", event.data);
		if (!images.getImagesLoaded()) return;
		states.screenModeSettings = event.data.data;
		console.log("states.screenModeSettings", states.screenModeSettings);
		if (!states.screenModeSettings.motor && states.playing) return keyframes.togglePlayAll();
		// Repeat playback attempts if parent window object projector motor is running
		while (states.screenModeSettings.motor && !states.playing){
			keyframes.togglePlayAll();
			await delay(500);
		}
	}

	function toggleAudio(){
		console.log(event.data);
		if (!images.getImagesLoaded()) return;
		states.screenModeSettings = event.data.data;
		console.log("states.screenModeSettings", states.screenModeSettings);
		if (states.fantascope) return;
		states.audio = !states.audio;
		console.log("states.audio", states.audio);
		states.audio ? projectorSpeed.playSound() : projectorSpeed.stopSound();
		controls.updateUI();
	}

	function send({msg, data = false} = {}){
		window.top.postMessage({msg: msg, data: data}, origin);
	}

	function getParentMessageRecieved(){
		return parentMessageRecieved;
	}

	return {
		init: init,
		send: send,
		getParentMessageRecieved: getParentMessageRecieved
	}

})();

export {postMessage};