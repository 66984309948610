const options = {
	newSocketRoomOnRemoteDisconnect: false,
	loopImages: true,
	shutterImage: false,
	projectorSoundEffects: true,
	disableAudioForShortFilm: true,
	generateImageCyclesForShortFilm: true,
	shortFilmTotalFramesTarget: 100,
	shortFilmFrameThreshold: 50,
	imageGenerationCylces: 1,
	applyEffectsToProjectorBackgroundColor: false,
	removeMedia: true,
	removeExports: true,
	exportVideoName: 'video-export',
	exportVideoExtension: 'mp4',
	keyboardInput: true,
	flashRemoteOnParamLimit: false, // Should only be true when multipleParams state is false
	exponentialProjectorSpeed: true,
	createFrameRate: 15,
	defaultFantascopeFrameRate: 15,
	defaultBackgroundColor: "#000",
	initAudioOnLoad: true,
	hideCenterModalOnLoad: false,
	blackDurationAfterLoadingForExport: 1000, // ms // Note: should match blackDurationAfterLoadingForExport in encode options on server
	initialPauseDurationForExport: 2000, // ms
	maxAudioUploadFileSize: 5, // MB
	actualMaxAudioUploadFileSize: 6, // MB
	showDatGUI: false,
	maxPosts: 500,
	maxPostsPro: 500,
	maxExports: 200,
	maxExportsPro: 200,
	maxStorage: 200, // MB
	maxStoragePro: 2048, // MB
	originalUploadedImageName: 'original-image',
	originalUploadedVideoName: 'original-video',
	imagesFolderName: 'images',
	imagesHighResFolderName: 'images-high-res',
	audioFolderName: 'audio',
	videoFolderName: 'video',
	thumbsFolderName: 'thumbs'
};

export {
	options
};