import axios from "axios";
import {options} from '../options.js';
import {states} from '../states.js';
import {delay} from '../shared/util.js';
import {menu} from '../shared/menu.js';
import {alert} from '../shared/alert.js';
import {postRequests} from '../shared/postRequests.js';
import {mediaRequests} from '../shared/mediaRequests.js';
import {images} from './images.js';
import {keyframes} from './keyframes.js';
import {editFormCustom} from './editFormCustom.js';
import {exportPanel} from './exportPanel.js';
import {remixPanel} from './remixPanel.js';
import {reportPanel} from '../shared/reportPanel.js';
import {centerModal} from './centerModal.js';
import {hoverOverlay} from './hoverOverlay.js';
import {shepherd} from './shepherd.js';
import {controls} from './controls.js';
import {projectorSpeed} from './images_projectorSpeed.js';
import {howlerAudio} from './howlerAudio.js';
import {toneAudio} from './toneAudio.js';
import {waveform} from './waveform.js';
import {datGUI} from './datGUI.js';
import {stats} from './stats.js';
import {audioUpload} from '../shared/audioUpload.js';
import {fullscreenSpinner} from '../shared/fullscreenSpinner.js';
import {socketEmit} from './socketEmit.js';
import {createFilm} from './images_createFilm.js';
import {projectInfoTitleCard} from './projectInfoTitleCard.js';

// Side panel
const sidePanel = (function(){

	//states
	let animating = false;
	let infoActive = true;
	let loadingAudio = false;
	let postDeleted = false;
	let helpButtonClicked = false;

	//options
	const singleOpen = true;
	const preventCloseOnExtraClick = false;
	const showInfo = false;
	const highlightHelpButtonOnStart = true;
	const alertDeletePostMsg = 'Are you sure you want to delete this project?';
	const alertDeleteAudioMsg = 'Are you sure you want to delete audio?';
	const phoneConnectMsg = "Phone connected";
	const phoneDisconnectMsg = "Connect phone";
	const remixAuthMsg = 'Save remix';
	const remixNotAuthMsg = 'Log in to save remix';
	const likeAuthMsg = 'Like';
	const likeNotAuthMsg = 'Log in to like';
	const reportAuthMsg = 'Report content';
	const reportNotAuthMsg = 'Log in to report content';
	const attachAudioMsg = `Attach Audio <span class="audio-limits">(mp3, < ${options.maxAudioUploadFileSize}MB)</span>`;
	const replaceAudioMsg = `Replace Audio <span class="audio-limits">(mp3, < ${options.maxAudioUploadFileSize}MB)</span>`;
	const errorAudioMsg = `Upload Error <span class="audio-limits">(mp3, < ${options.maxAudioUploadFileSize}MB)</span>`;
	const audioUploadDisabledMsg = `Audio upload disabled (total frame count must be greater than ${options.shortFilmFrameThreshold})`;
	const multipleDesktopBrowsersMsg = 'For the remote to work properly, please use one desktop browser at a time';

	//cache DOM
	const $el = $("#side-panel");
	const $items = $el.find('.item');
	const $menuToggle = $el.find('#menu-toggle');
	const $phoneConnection = $el.find('#phone-connection');
	const $phoneConnectionMsg = $phoneConnection.siblings('.tooltip-text').find('p');
	const $help = $el.find('#help');
	const $remix = $el.find('#remix');
	const $remixtMsg = $remix.siblings('.tooltip-text').find('p');
	const $report = $el.find('#report');
	const $reportMsg = $report.siblings('.tooltip-text').find('p');
	const $reset = $el.find('#reset');
	const $resetImages = $el.find('#reset-images');
	const $resetSavedKeyframes = $el.find('#reset-saved-keyframes');
	const $resetUrlKeyframes = $el.find('#reset-url-keyframes');
	const $projectSettings = $el.find('#project-settings');
	const $edit = $el.find('#edit');
	const $deleteProject = $el.find('#delete-project');
	const $like = $el.find('#like');
	const $likeMsg = $like.siblings('.tooltip-text').find('p');
	const $projectInfo = $el.find('#project-info');
	const $info = $el.find('#info');
	const $audioSettings = $el.find('#audio-settings');
	const $audioSettingsMsg = $audioSettings.siblings('.tooltip-text').find('p');
	const $uploadAudio = $el.find('#upload-audio');
	const $uploadAudioMsg = $el.find('#upload-audio-message');
	const $uploadAudioInput = $el.find('#upload-audio-input');
	const $deleteAudio = $el.find('#delete-audio');
	const $tooltips = $el.find('.tooltip');

	// Note: cached outside of component
	const $images = $('#images');
	const $form = $('#form');

	//bind events
	$items.on('click', expand);
	$images.on('click', () => expand({closeAll: true}));
	$like.on('click', toggleLike);
	$help.on('click', () => {
		helpButtonClicked = true;
		console.log("helpButtonClicked", helpButtonClicked);
		shepherd.resumeStep();
	});
	$phoneConnection.on('click', () => centerModal.toggle());
	$resetImages.on('click', () => images.resetParamsInstantly());
	$resetSavedKeyframes.on('click', () => keyframes.undo({resetSavedKeyframes: true}));
	$resetUrlKeyframes.on('click', () => keyframes.undo({resetSavedKeyframes: false}));
	$edit.on('click', () => editFormCustom.show());
	$deleteProject.on('click', () => showAlert({msg: alertDeletePostMsg}));
	$projectInfo.on('click', () => {
		projectInfoTitleCard.fadeIn()
		updateProjectInfoButton({active: true})
	});
	$info.on('click', toggleInfoActive);
	$remix.on('click', function(){
		if ($(this).hasClass('disableUI')) return;
		remixPanel.show();
	});
	$report.on('click', function(){
		if ($(this).hasClass('disableUI')) return;
		reportPanel.show();
	});
	$menuToggle.on('click', async () => {
		if ($form.hasClass('show')){
			$form.removeClass('show');
			await delay(500);
		}
		menu.toggle();
		hoverOverlay.show();
	});
	$uploadAudio.click(() => {
		$uploadAudioInput.trigger("click");
	});
	$uploadAudioInput.on('input', initAudioUpload);
	$deleteAudio.on('click', () => showAlert({msg: alertDeleteAudioMsg, deleteAudioPrompt: true}));

	// Init
	/*-------------------------------*/

	async function init() {
		if (states.auth && !states.createMode){
			const response = await axios.get(`/likes/user/${states.mediaData._id}`, {}, {validateStatus: () => true});
			console.log("response.data", response.data)
			if (response.status != 200 && response.status != 204) return console.error("Error checking like")
			// Set state
			states.userLikesPost = response.status == 200
			console.log("states.userLikesPost", states.userLikesPost)
		}
		if (!states.userOwnsPost){
			$projectSettings.closest(".tooltip").hide();
			editFormCustom.hide();
		}
		// Note: audio settings are hidden in create mode since audio upload needs a fixed number of images to transcode properly
		if (states.fantascope || !states.userOwnsPost || states.createMode) $audioSettings.closest(".tooltip").hide();
		// Disable audio upload for short film
		if (!states.fantascope && states.mediaData.lastFrame <= options.shortFilmFrameThreshold && options.disableAudioForShortFilm){
			setMessage({$elm: $audioSettingsMsg, msg: audioUploadDisabledMsg});
			disableButton({$elm: $audioSettings, UI: true});
		}
		if (!showInfo) $info.closest('.tooltip').hide();
		if (!states.screenMode) show();
		updateEnable();
		updateInfoButton();
		updateAudioUI();
		updateReportButtonUI();
		updateLikeButton({init:true});
		// Check sidepanel height and nudge left controls
		controls.nudgeLeftControls()
	}

	// Info
	/*-------------------------------*/

	function toggleInfoActive(){
		infoActive = !infoActive;
		console.log("infoActive", infoActive);
		updateInfoButton();
		controls.updateInfoActive();
	}

	// Like
	/*-------------------------------*/

	async function toggleLike(){
		if (!states.auth) return
		const response = await axios.post(`/likes/toggle/${states.mediaData._id}`, {}, {validateStatus: () => true});
		console.log("response.data", response.data)
		if (response.status != 200 && response.status != 201) return console.error("Error toggling like")
		// Set state
		states.userLikesPost = response.status == 201
		console.log("states.userLikesPost", states.userLikesPost)
		updateLikeButton({init:false});
	}

	// Audio upload
	/*-------------------------------*/

	async function initAudioUpload(){
		if (states.fantascope || !states.userOwnsPost || states.createMode) return;
		try {
			loadingAudio = true;
			console.log("loadingAudio", loadingAudio);
			updateAudioUI();
			const uploadData = await audioUpload.checkFile({
				uploadElm: this,
				media: states.mediaData
			});
			const audioReplaced = await audioUpload.uploadFile({
				...uploadData,
				media: states.mediaData
			});
			// Update media
			// Note: post audio status updated on server
			states.mediaData = audioReplaced.post;
			// Disable audio for short film
			states.mediaHasAudio = !states.fantascope && states.mediaData.lastFrame <= options.shortFilmFrameThreshold && options.disableAudioForShortFilm ? false : states.mediaData.audio;
			console.log("states.mediaHasAudio", states.mediaHasAudio);
			// Destroy waveform
			waveform.destroy();
			// Unload audio
			howlerAudio.unload();
			toneAudio.unload();
			// Reload audio
			const p1 = howlerAudio.init({uploadAudio: true});
			const p2 = toneAudio.init();
			let loadAudioResults = await Promise.all([p1, p2]);
			console.log("loadAudioResults", loadAudioResults);
			projectorSpeed.setSoundReady();
			// Check audio context
			toneAudio.checkAudioContext();
			// Init waveform
			await waveform.init();
			loadingAudio = false;
			console.log("loadingAudio", loadingAudio);
			// Update audio UI
			updateAudioUI();
			// Update export panel
			exportPanel.updateSwitches();
			// Turn audio off
			// Note: if audio remains true, sound will not play until film loops or user toggles audio off and on
			states.audio = false;
			console.log("states.audio", states.audio);
			states.audio ? projectorSpeed.playSound() : projectorSpeed.stopSound();
			controls.updateUI();
			// Reset datGUI
			resetDatGUI();
		} catch(err){
			console.log(err);
			audioError(errorAudioMsg);
		}
	}

	function updateAudioUI(){
		if (loadingAudio) return fullscreenSpinner.show();
		const msgUse = states.mediaData.audio ? replaceAudioMsg : attachAudioMsg;
		setMessage({$elm: $uploadAudioMsg, msg: msgUse});
		states.mediaData.audio ? enableButton({$elm: $deleteAudio}) : disableButton({$elm: $deleteAudio});
		// For short media, disable audio
		states.mediaHasAudio = states.mediaData.lastFrame <= options.shortFilmFrameThreshold && options.disableAudioForShortFilm ? false : states.mediaData.audio;
		console.log("states.mediaHasAudio", states.mediaHasAudio);
		controls.updateToggles();
		// Update mobile remote switches
		socketEmit.send('updateSwitches', states);
		fullscreenSpinner.hide();
		// Empty input
		$uploadAudioInput.val(null);
	}

	function resetDatGUI(){
		// Set state
		states.info = false;
		console.log("states.info", states.info);
		// Update stats
		stats.updateUI();
		// Destroy dat.GUI
		datGUI.destroy();
		// Setup dat.GUI
		images.setupDatGUI();
		// Init dat.GUI
		datGUI.init({uploadAudio: true});
		// Show dat.GUI
		datGUI.show();
	}

	function audioError(msg){
		setMessage({$elm: $uploadAudioMsg, msg: msg});
		fullscreenSpinner.hide();
		loadingAudio = false;
		console.log("loadingAudio", loadingAudio);
	}

	// Delete
	/*-------------------------------*/

	async function showAlert({msg, deleteAudioPrompt = false} = {}){
		// Open alert
		const deleteConfirmed = await alert.open({
			msg: msg,
			hideActionUndone: false,
			action: "delete"
		});
		console.log("deleteConfirmed", deleteConfirmed);
		if (deleteConfirmed) deleteAudioPrompt ? deleteAudio() : deletePost();
	}

	async function deletePost(){
		if (!states.userOwnsPost) return;
		const response = await postRequests.deletePost(states.mediaData.mediaId);
		console.log("response", response);
		if (!response){
			console.log("Error: Delete post failed");
			// Close alert
			alert.close();
			return;
		}
		// Remove media
		if (options.removeMedia) await mediaRequests.removeMedia(states.mediaData.mediaId);
		// Set local state
		postDeleted = true;
		console.log("postDeleted", postDeleted);
		// Go to my work
		goToLinkWithoutFade('/my-work');
	}

	async function deleteAudio(){
		// Note: deleting audio after initializing export will create exported video with silent audio
		if (states.fantascope || !states.userOwnsPost || states.createMode) return;
		// Note: audio file is not deleted from server
		const objData = {audio: false}
		// Update post
		const post = await postRequests.updatePost(states.mediaData.mediaId, objData);
		console.log("post", post);
		if (!post){
			console.log("Error: Delete audio failed");
			// Close alert
			alert.close();
			return;
		}
		// Close alert
		alert.close();
		// Update media
		states.mediaData = post;
		// Unload audio
		howlerAudio.unload();
		toneAudio.unload();
		// Destroy waveform
		waveform.close();
		waveform.destroy();
		// Update audio UI
		updateAudioUI();
		// Update export panel
		exportPanel.updateSwitches();
		// Turn audio off
		states.mediaHasAudio = false;
		console.log("states.mediaHasAudio", states.mediaHasAudio);
		states.audio = false;
		console.log("states.audio", states.audio);
		states.audio ? projectorSpeed.playSound() : projectorSpeed.stopSound();
		controls.updateUI();
		// Reset datGUI
		resetDatGUI();
	}

	// UI
	/*-------------------------------*/

	async function expand({closeAll} = {}){
		// Return conditions
		if (closeAll) return $items.not($info).removeClass('active');
		// Prevent close on extra
		if (preventCloseOnExtraClick) if ($(event.target).closest('.extra').length != 0) return;
		if ($(this).attr('data-expand') == "false"){
			if (singleOpen) $items.not($info).removeClass('active');
			return;
		}
		if ($(this).attr('data-expanding') == "true" || animating) return;
		if ($(this).closest('.tooltip').hasClass('disable')) return;
		if ($(this).hasClass('disableUI')) return;
		// Set local state
		animating = true;
		console.log("animating", animating);
		$(this).attr('data-expanding', true);
		const isOpen = $(this).hasClass('active');
		if (singleOpen) $items.not($info).removeClass('active');
		if (isOpen){
			$(this).removeClass('active');
			await delay(300);
			$(this).attr('data-expanding', false);
			animating = false;
			console.log("animating", animating);
			return;
		}
		$(this).addClass('active')
		await delay(300);
		$(this).attr('data-expanding', false);
		// Set local state
		animating = false;
		console.log("animating", animating);
	}

	function updateRemixButtonUI({enable}){
		if (!states.auth) enable = false;
		let msg = states.auth ? remixAuthMsg : remixNotAuthMsg;
		setMessage({$elm: $remixtMsg, msg: msg});
		enable ? enableButton({$elm: $remix, UI: true}) : disableButton({$elm: $remix, UI: true});
	}

	function updateReportButtonUI(){
		if (states.userOwnsPost) return $report.closest(".tooltip").hide();
		const enable = states.auth;
		let msg = enable ? reportAuthMsg : reportNotAuthMsg;
		setMessage({$elm: $reportMsg, msg: msg});
		enable ? enableButton({$elm: $report, UI: true}) : disableButton({$elm: $report, UI: true});
	}

	function updateResetUrlKeyframesButtonUI({enable}){
		enable ? enableButton({$elm: $resetUrlKeyframes}) : disableButton({$elm: $resetUrlKeyframes});
	}

	function updatePhoneConnectionUI(){
		if (states.numberOfDesktopSocketsInRoom > 1){
			$phoneConnection.addClass('alert');
			setMessage({$elm: $phoneConnectionMsg, msg: multipleDesktopBrowsersMsg});
			return;
		}
		$phoneConnection.removeClass('alert');
		states.remotePaired ? $phoneConnection.addClass('on') : $phoneConnection.removeClass('on');
		const phoneConnectionMsg = states.remotePaired ? phoneConnectMsg : phoneDisconnectMsg;
		setMessage({$elm: $phoneConnectionMsg, msg: phoneConnectionMsg});
		centerModal.getModalOpen() ? $phoneConnection.addClass('active') : $phoneConnection.removeClass('active');
	}

	function updateHelpButton(){
		states.playing ? disableButton({$elm: $help, UI: true}) : enableButton({$elm: $help, UI: true});
		states.tour ? $help.addClass('active') : $help.removeClass('active');
		if (highlightHelpButtonOnStart) !helpButtonClicked ? $help.addClass('highlight') : $help.removeClass('highlight');
	}

	function updateProjectInfoButton({active}){
		active ? $projectInfo.addClass('active') : $projectInfo.removeClass('active');
	}

	function updateInfoButton(){
		infoActive ? $info.addClass('active') : $info.removeClass('active');
	}

	function updateLikeButton({init}){
		if (init){
			if (states.createMode) return $like.closest(".tooltip").hide();
			const enable = states.auth;
			let msg = enable ? likeAuthMsg : likeNotAuthMsg;
			setMessage({$elm: $likeMsg, msg: msg});
			enable ? enableButton({$elm: $like, UI: true}) : disableButton({$elm: $like, UI: true});
		}
		if (!states.auth) return
		states.userLikesPost ? $like.addClass('select') : $like.removeClass('select');
	}

	function updateEnable(){
		if (states.createMode && !states.initialPhoto){
			$projectSettings.closest(".tooltip").addClass('disable');
			$reset.closest(".tooltip").addClass('disable');
			$info.closest(".tooltip").addClass('disable');
			$help.closest(".tooltip").addClass('disable');
			$remix.closest(".tooltip").addClass('disable');
			return;
		} 
		if (states.createMode && states.initialPhoto){
			$projectSettings.closest(".tooltip").removeClass('disable');
			$reset.closest(".tooltip").removeClass('disable');		
			$info.closest(".tooltip").removeClass('disable');
			$help.closest(".tooltip").removeClass('disable');
			$remix.closest(".tooltip").removeClass('disable');
		}
	}

	async function updatePosition(){
		if (states.playing){
			$tooltips.addClass('slide')
			await delay(300);
			$tooltips.addClass('hide')
		} else {
			$tooltips.removeClass('hide')
			$tooltips.removeClass('slide')
		}
	}

	function enableButton({$elm, UI}){
		UI ? $elm.closest('div').removeClass('disableUI') : $elm.closest('div').removeClass('disable');
	}

	function disableButton({$elm, UI}){
		UI ? $elm.closest('div').addClass('disableUI') : $elm.closest('div').addClass('disable');
	}

	function show(){
		$el.addClass('show');
		$el.css('visibility', 'visible');
	}

	function setMessage({$elm, msg}){
		$elm.html(msg);
	}

	// Link
	/*-------------------------------*/

	function goToLinkWithoutFade(link){
		window.location.href = link;
	}

	// Get
	/*-------------------------------*/

	function getInfoActive(){
		return infoActive;
	}

	function getPostDeleted(){
		return postDeleted;
	}

	return {
		init: init,
		expand: expand,
		updateRemixButtonUI: updateRemixButtonUI,
		updateResetUrlKeyframesButtonUI: updateResetUrlKeyframesButtonUI,
		updatePhoneConnectionUI: updatePhoneConnectionUI,
		updateHelpButton: updateHelpButton,
		updateProjectInfoButton: updateProjectInfoButton,
		updateEnable: updateEnable,
		getInfoActive: getInfoActive,
		getPostDeleted: getPostDeleted,
		updatePosition: updatePosition
	};

})(); //sidePanel

export {sidePanel};