
import {states} from '../states.js';
import {images} from './images.js';
import {keyframes} from './keyframes.js';
import {sidePanel} from "./sidePanel.js";
import {remixPanel} from "./remixPanel.js";
import {topPanel} from "./topPanel.js";

const paramOptions = (function(){

	//state
	let timeoutUpdateCurrentParamsUI;

	//cache DOM
	const $el = $("#param-options");
	const $wrapCurrentParams = $el.find('.wrap-current-params');
	const $wrapCurrentPhases = $el.find('.wrap-current-phases');

	//bind events
	$wrapCurrentParams.on('click', selectCurrentParams);
	$wrapCurrentPhases.on('click', selectCurrentPhases);

	// Input
	/*-------------------------------*/

	async function selectCurrentParams(){
		setActive($wrapCurrentParams)
		const roundedPlaybackParams = await images.getRoundedPlaybackParams();
		const defaultSettings = {
			order: 0,
			duration: 1,
			rampUp: 1,
			autoFrame: 0
		}
		states.keyframesToSave = [{...roundedPlaybackParams, ...defaultSettings}]
    console.log("states.keyframesToSave", states.keyframesToSave)
	}

	function selectCurrentPhases(){
		setActive($wrapCurrentPhases)
		states.keyframesToSave = keyframes.getKeyframeList()
    console.log("states.keyframesToSave", states.keyframesToSave)
	}

	// UI
	/*-------------------------------*/

	async function updateUI(){    
    const roundedPlaybackParams = await images.getRoundedPlaybackParams();
		Object.keys(roundedPlaybackParams).length > 0 ? $wrapCurrentParams.removeClass('disable') : $wrapCurrentParams.addClass('disable')
		const keyframeList = keyframes.getKeyframeList()
		keyframeList.length > 0 ? $wrapCurrentPhases.removeClass('disable') : $wrapCurrentPhases.addClass('disable')
		if ($wrapCurrentParams.hasClass('disable') && $wrapCurrentPhases.hasClass('disable')){
			remixPanel.updateButton({enable:false})
			removeActive()
		} else {
			remixPanel.updateButton({enable:true})      
		}
		if ($wrapCurrentParams.hasClass('disable') && !$wrapCurrentPhases.hasClass('disable')) selectCurrentPhases()
		if (!$wrapCurrentParams.hasClass('disable') && $wrapCurrentPhases.hasClass('disable')) selectCurrentParams()
	}

	function updateCurrentParamsUI(){
    clearTimeout(timeoutUpdateCurrentParamsUI);
    timeoutUpdateCurrentParamsUI = setTimeout(async() => {
			const roundedPlaybackParams = await images.getRoundedPlaybackParams();
			$('#current-params').empty()
			Object.entries(roundedPlaybackParams).forEach(([key, value]) => {
				const str = `<span><span class="capitalize">${key}</span>: ${value}</span>`
				$('#current-params').append(str)
			});
			updateUI()
			const keyframeList = keyframes.getKeyframeList()
			if (Object.keys(roundedPlaybackParams).length < 1 && keyframeList.length < 1){
        sidePanel.updateRemixButtonUI({enable: false})
        topPanel.updateExportButtonUI({enable: false})
      } else {
        sidePanel.updateRemixButtonUI({enable: true})
        topPanel.updateExportButtonUI({enable: true})
      }
		}, 100);
	}

	function setActive(elm){
		removeActive()
		elm.addClass('active')
	}

	function removeActive(){
		$wrapCurrentParams.removeClass('active')
		$wrapCurrentPhases.removeClass('active')
	}

	return {
		updateUI: updateUI,
		updateCurrentParamsUI: updateCurrentParamsUI,
    selectCurrentParams: selectCurrentParams,
    selectCurrentPhases: selectCurrentPhases
	}

})();

export {paramOptions};