import WaveSurfer from './plugins/wavesurfer.min.js';
import {S3_PATH, S3_PATH_UPLOAD} from '../config.js';
import {states} from '../states.js';
import {options} from '../options.js';
import {images} from './images.js';
import {controls} from './controls.js';
import {projectorSpeed} from './images_projectorSpeed.js';
import {delay} from '../shared/util.js';

// Waveform
const waveform = (function(){

	//states
	let waveformOpen = false;
	let waveformLoaded = false;
	let eventsBound = false;

	//data
	let wavesurfer;
	let waveLength;
	let audioLength; //seconds
	let resizeTimeout;

	//option
	// Note: height of waveform must match margin-top value in css and subtracted value of images width
	const waveformHeight = 128;
	const startOpen = false;

	//cache DOM
	const $el = $('#waveform');
	let $wave;

	function cacheDom(){
		$wave = $el.find('wave');
	}

	//bind events
	// Note: keyboard command for toggle
	function bindEvents(){
		if (eventsBound) return;
		eventsBound = true;
		$(window).on('resize', windowResize);
	}

	// Init
	/*-------------------------------*/

	function init(){
		return new Promise(async resolve => {
			try {
				// Calc audio length
				audioLength = states.mediaData.lastFrame / states.mediaData.fps;
				console.log("audioLength", audioLength);
				// Calc wave length
				waveLength = await calcWaveLength();
				console.log("waveLength", waveLength);
				// Init wavesurfer
				initWavesurfer();
				// Cache DOM
				cacheDom();
				// Load waveform data
				await loadWaveform();
				// Bind wavesurfer events
				await bindWavesurferEvents();
				// Set local state
				waveformLoaded = true;
				console.log("waveformLoaded", waveformLoaded);
				// Bind events
				bindEvents();
				// Open
				if (startOpen) open();
				// Continue
				resolve();
			} catch(err) {
				console.error(err);
			}
		});
	}

	function initWavesurfer(){
		wavesurfer = WaveSurfer.create({
			container: '#waveform',
			waveColor: 'white',
			progressColor: 'white',
			cursorWidth: 0,
			splitChannels: false,
			backend: 'MediaElement',
			maxCanvasWidth: 4000,
			pixelRatio: 1,
			minPxPerSec: waveLength,
			fillParent: false,
			responsive: false,
			scrollParent: false,
			height: waveformHeight
		});
	}

	// Load external peak data
	// Source: https://wavesurfer-js.org/faq/
	const loadWaveform = () => {
		return new Promise((resolve, reject) => {
			// Set media path
			const mediaPath = states.mediaData.exploreGallery
			? `${S3_PATH}/media/gallery/${states.mediaData.mediaId}/${options.audioFolderName}/`
			: `${S3_PATH_UPLOAD}/${states.mediaData.mediaId}/${options.audioFolderName}/`;
			fetch(mediaPath+'waveform.json', {cache: "no-cache"})
			.then(response => {
				if (!response.ok) throw new Error("HTTP error ", response.status);
				return response.json();
			})
			.then(peaks => {
				console.log('Loaded peaks with sample_rate: ', peaks.sample_rate);
				// load peaks into wavesurfer.js
				// Note: load with null audio for smaller network resource footprint
				// Note: null audio generated on server has slightly different duration than media audio, which is throwing off image to waveform sync
				wavesurfer.load(mediaPath+'audio.mp3', peaks.data);
				resolve();
			})
			.catch((e) => {
				//console.error('error', e);
				reject(e);
			});
		});
	}

	// Methods
	/*-------------------------------*/

	function destroy(){
		if (!wavesurfer) return;
		wavesurfer.destroy();
		waveformLoaded = false;
		console.log("waveformLoaded", waveformLoaded);
	}

	// Events
	/*-------------------------------*/

	function bindWavesurferEvents(){
		return new Promise((resolve, reject) => {
			wavesurfer.on('ready', function() {
				console.log("Wavesurfer ready");
				// Override style from wavesurfer plugin
				$wave.css({
					"position": "fixed",
					"background-color": "black"
				});
				resolve();
			});

			wavesurfer.on('loading', function(percent) {
				console.log("Wavesurfer audio loading", percent);
			});

			wavesurfer.on('destroy',function(){
				console.log("Wavesurfer destroyed");
			});

			wavesurfer.on('error', function(e) {
				//console.warn(e);
				reject(e);
			});
		});
	}

	// UI
	/*-------------------------------*/

	function calcWaveLength(){
		return new Promise(resolve => {
			const imagesHeight = images.getImagesHeight();
			//console.log("imagesHeight", imagesHeight);
			resolve(imagesHeight / audioLength);
		});
	}

	function windowResize(){
		// Clear timeout
		clearTimeout(resizeTimeout);
		// Set timeout
		resizeTimeout = setTimeout(renderWave, 500);
	}

	async function renderWave(){
		if (!waveformLoaded) return;
		waveLength = await calcWaveLength();
		// Redraw waveform to match height of images
		wavesurfer.params.minPxPerSec = waveLength;
		wavesurfer.drawer.clearWave();
		//wavesurfer.empty();
		//wavesurfer.destroy();
		wavesurfer.drawBuffer();
	}

	async function open(){
		if (!waveformLoaded) return;
		$el.addClass("waveform-active");
		images.$el.addClass("waveform-active");
		waveformOpen = true;
		console.log("waveformOpen", waveformOpen);
		// Set state
		states.waveform = true;
		console.log("states.waveform", states.waveform);
		// Update controls
		controls.updateUI();
		await delay(300);
		// Set sound position
		projectorSpeed.setSoundPosition();
	}

	async function close(){
		if (!waveformLoaded) return;
		$el.removeClass("waveform-active");
		images.$el.removeClass("waveform-active");
		waveformOpen = false;
		console.log("waveformOpen", waveformOpen);
		// Set state
		states.waveform = false;
		console.log("states.waveform", states.waveform);
		// Update controls
		controls.updateUI();
		await delay(300);
		// Set sound position
		projectorSpeed.setSoundPosition();
	}

	function toggle(){
		if (!waveformLoaded) return;
		waveformOpen ? close() : open();
	}

	return {
		init: init,
		toggle: toggle,
		open: open,
		close: close,
		destroy: destroy
	};

})(); //waveform

export {waveform};