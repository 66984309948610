import {socket} from './socketListen.js';
import {options} from '../options.js';
import {delay} from '../shared/util.js';

// Socket emit
const socketEmit = (function(){

	//states
	let flashing = false;

	// Send
	/*-------------------------------*/

	const send = (msg, data) => {
		data ? socket.emit(msg, data) : socket.emit(msg);
	}

	const sendFlash = async () => {
		if (flashing || !options.flashRemoteOnParamLimit) return;
		flashing = true;
		//console.log("flashing", flashing);
		socket.emit('flash');
		await delay(500);
		flashing = false;
		//console.log("flashing", flashing);
	}

	return {
		send: send,
		sendFlash: sendFlash
	};

})(); //socketEmit

export{socketEmit};