import axios from "axios";
import {states} from '../states.js';
import {delay} from '../shared/util.js';
import {images} from './images.js';
import {keyframes} from './keyframes.js';
import {paramOptions} from "./paramOptions.js";
import { topModal } from "../shared/topModal.js";
import { modals } from "./modals.js";
import {opaqueBackground} from '../shared/opaqueBackground.js';

const remixPanel = (function(){

	//state
	let remixSaved = false;

	//cache DOM
	const $el = $("#remix-panel");
	const $close = $el.find('.close');
	const $button = $el.find('button');	
	const $remixNote = $el.find('#remix-note');
	const $options = $el.find('.options');
	const $note = $el.find('.note');
	const $message = $el.find('.message');
	// Note: cached from outside module
	const $paramOptions = $('#param-options');
	const $insertParamOptions = $el.find('.insert-param-options');
	
	//bind events
	$close.on('click', async ()=>{
		hide()
		await delay(300)
		reset()
	});
	$button.on('click', saveRemix);

	// REST
	/*-------------------------------*/

	async function saveRemix(){
		if (!states.auth) return
		const data = {
			postId: states.mediaData._id,
			postTitle: states.mediaData.title,
			remixKeyframes: states.keyframesToSave,
			remixNote: $remixNote[0].value
		}
		const response = await axios.post('remixes', data, {validateStatus: () => true});
		console.log("response.data", response.data)
		if (response.status != 200 && response.status != 201) return console.error("Error creating remix")
		$options.addClass('invisible')
		$note.addClass('invisible')
		$message.removeClass('hidden')
		updateButton({enable:false})
		remixSaved = true
		console.log("remixSaved", remixSaved)
	}

	// UI
	/*-------------------------------*/

	async function show(){
		if (states.remixPanelOpen) return
		await modals.closePanel()
		// Move param options to panel
		$paramOptions.insertAfter($insertParamOptions);
		$paramOptions.removeClass('hidden')
		const roundedPlaybackParams = await images.getRoundedPlaybackParams();
		const keyframeList = keyframes.getKeyframeList()
		if (Object.keys(roundedPlaybackParams).length == 0 && keyframeList.length == 0) return
		await keyframes.updateCopyUI()
		paramOptions.updateCurrentParamsUI()
		if (keyframeList.length > 0) paramOptions.selectCurrentPhases()
		$el.show();
		$el.addClass('show');
		opaqueBackground.fadeIn({duration: 500});
		states.remixPanelOpen = true;
		console.log("states.remixPanelOpen", states.remixPanelOpen);
		topModal.checkOpenPanels()
	}

	async function hide(){
		$el.removeClass('show');
		opaqueBackground.fadeOut({duration: 500});
		await delay(500);
		$el.hide();
		states.remixPanelOpen = false;
		console.log("states.remixPanelOpen", states.remixPanelOpen);
	}

	function updateButton({enable}){
		enable ? $button.removeClass('disable') : $button.addClass('disable')
	}

	function reset(){
		$options.removeClass('invisible')
		$note.removeClass('invisible')
		$message.addClass('hidden')
		updateButton({enable:true})
		$remixNote[0].value = ''
		remixSaved = false
		console.log("remixSaved", remixSaved)
	}

	return {
		show: show,
		hide: hide,
		updateButton: updateButton
	}

})();

export {remixPanel};