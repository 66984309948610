import {callFetch} from './fetchAPI';

// Queue requests
const queueRequests = (function(){

	function addExtractJob(obj) {
		return callFetch({
			url: '/extract-job',
			method: 'POST',
			data: obj,
			status: 200
		})
	}

	function addEncodeJob(obj) {
		return callFetch({
			url: '/encode-job',
			method: 'POST',
			data: obj,
			status: 200
		})
	}

	function addResizeJob(obj) {
		return callFetch({
			url: '/resize-job',
			method: 'POST',
			data: obj,
			status: 200
		})
	}

	return {
		addExtractJob: addExtractJob,
		addEncodeJob: addEncodeJob,
		addResizeJob: addResizeJob
	};

})(); //queueRequests

export {queueRequests};