import {S3_PATH_UPLOAD} from '../config.js';
import {options} from '../options.js';
import {mediaRequests} from './mediaRequests.js';
import {queueRequests} from './queueRequests.js';

function extractHighResImagesAfterPostCreated({mediaId}){
	return new Promise(async (resolve, reject) => {
		try{
			const videoName = await mediaRequests.getPrivateVideo(mediaId);
			console.log("videoName", videoName);
			if (!videoName) throw("Error getting video name");
			const videoExt = videoName.msg.split(`${options.originalUploadedVideoName}.`)[1];
			console.log("videoExt", videoExt);
			if (!videoExt) throw("Error getting video extension");
			// Define media data
			const mediaData = {
				url: `${S3_PATH_UPLOAD}/${mediaId}/${options.videoFolderName}/${options.originalUploadedVideoName}.${videoExt}`,
				mediaId: mediaId
			}
			// Probe video
			const response = await mediaRequests.probeVideo(mediaData);
			console.log("response", response);
			if (!response) throw ("Error probing media");
			if (!response.hasVideo) throw ("Video not found");
			// Merge data
			const mediaDataMerged = {...mediaData, ...response};
			//console.log("mediaDataMerged", mediaDataMerged);
			// Define high resolution extraction
			mediaDataMerged.highResExtractNoAudio = true;
			// Add folder name for job data
			mediaDataMerged.imageFolderName = options.imagesHighResFolderName;
			// Add extract job to queue
			await addExtractJobToQueue(mediaDataMerged);
			console.log("High resolution images extract job added to queue");
			resolve();
		}
		catch(err){
			console.error(err)
			reject(err);
		}
	});
}

// Add job
/*-------------------------------*/

async function addExtractJobToQueue(mediaData){
	return new Promise(async (resolve, reject) => {
		// Add extract job to queue
		const response = await queueRequests.addExtractJob(mediaData);
		console.log("response", response);
		if (!response) return reject("Error adding extract job to queue.")
		resolve();
	});
}

export {extractHighResImagesAfterPostCreated}