import * as Tone from 'tone';
import {S3_PATH, S3_PATH_UPLOAD} from '../config.js';
import {states} from '../states.js';
import {options} from '../options.js';

const toneAudio = (function(){

	//states
	let loaded = false;

	//data
	let mediaAudio;
	let synth;

	//options
	const audioFileName = "audio";

	async function init(){
		return new Promise((resolve, reject) => {
			if (!states.mediaHasAudio) return resolve("Media does not have audio");
			// Set media path
			const mediaPath = states.mediaData.exploreGallery
			? `${S3_PATH}/media/gallery/${states.mediaData.mediaId}/${options.audioFolderName}/`
			: `${S3_PATH_UPLOAD}/${states.mediaData.mediaId}/${options.audioFolderName}/`;
			// Media audio
			mediaAudio = new Tone.Player({
				url: `${mediaPath}${audioFileName}.mp3`,
				onload: () => {
					loaded = true;
					console.log("loaded", loaded);
					resolve("Tone audio loaded");
				},
				onerror: () => reject("Tone audio load error")
			}).toDestination();
		});
	}

	function initSynth(){
		// Create a synth
		synth = new Tone.Synth().toDestination();
	}

	function play(offset){
		if (Tone.context.state != 'running') return;
		//console.log("mediaAudio.state", mediaAudio.state);
		if (mediaAudio.state == "started") return;
		mediaAudio.start(null, offset);
	}

	function stop(){
		//console.log("mediaAudio.state", mediaAudio.state);
		mediaAudio.stop();
	}

	function rate(data){
		mediaAudio.playbackRate = data;
	}

	function seek(offset){
		mediaAudio.seek(offset);
	}

	function reverse(data){
		mediaAudio.reverse = data;
	}

	function unload(){
		if (!mediaAudio) return;
		mediaAudio.dispose();
	}

	function playNote(){
		synth.triggerAttackRelease("C4", .1);
	}

	async function checkAudioContext(){
		if (!loaded) return;
		//console.log("Tone.context.state", Tone.context.state);
		if (Tone.context.state == 'running') return;
		await Tone.start();
		console.log('Tone audio ready');
	}

	return {
		init: init,
		play: play,
		stop: stop,
		rate: rate,
		seek: seek,
		reverse: reverse,
		unload: unload,
		checkAudioContext: checkAudioContext
	}

})();

export {toneAudio};