import {options} from './options.js';

// Remote options
const remoteOptions = [
	{
		id:"speed",
		title:"Speed",
		type:"&#x00025;",
		active:false,
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"frameRate",
		title:"Frame rate",
		type:" fps",
		active:false,
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"framing",
		title:"Framing",
		type: "",
		active:false,
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"focus",
		title:"Focus",
		type: "",
		active:false,
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"brightness",
		title:"Brightness",
		type:"&#x00025;",
		active:false,
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"saturate",
		title:"Saturate",
		type:"&#x00025;",
		active:false,
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"contrast",
		title:"Contrast",
		type:"&#x00025;",
		active:false,
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"hue",
		title:"Hue",
		type:"&#176;",
		active:false,
		minValue: null,
		maxValue: null,
		value: null
	}
];

// Setup projector visual info
const setupProjectorVisualInfo = [
	{
		id:"scrollPercent",
		title:"Scroll %",
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"currentFrame",
		title:"Current frame",
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"actualFrameRate",
		title:"FPS / Speed",
		minValue: null,
		maxValue: null,
		value: null
	}
]

// Setup wheel visual info
const setupWheelVisualInfo = [
	{
		id:"rotationAngle",
		title:"Rotation angle",
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"currentAngle",
		title:"Current angle",
		minValue: null,
		maxValue: null,
		value: null
	}
]

// Setup audio info
const setupAudioInfo = [
	{
		id:"audioPercent",
		title:"Audio %",
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"audioPosition",
		title:"Audio position",
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"audioRate",
		title:"Audio rate",
		minValue: null,
		maxValue: null,
		value: null
	},
	{
		id:"audioDrift",
		title:"Audio drift",
		minValue: null,
		maxValue: null,
		value: null
	}
]

// Setup media info
const setupMediaInfo = [
	{
		id:"title",
		title:"Title",
		value: null
	},
	{
		id:"year",
		title:"Year",
		value: null
	},
	{
		id:"frameRate",
		title:"Frame rate",
		value: null
	},
	{
		id:"totalFrames",
		title:"Total frames",
		value: null
	},
	{
		id:"imageCycles",
		title:"Image cycles",
		value: null
	},
	{
		id:"audio",
		title:"Audio",
		value: null
	}
]

// dat.GUI params
const playbackParams = {};
const visualInfoParams = {};
const audioInfoParams = {};
const mediaInfoParams = {};

function initArray({array, id, minValue, maxValue, value} = {}){
	// Find index of object in array by id
	const foundIndex = array.findIndex(x => x.id == id);
	// Update object values
	array[foundIndex].minValue = minValue;
	array[foundIndex].maxValue = maxValue;
	array[foundIndex].value = value;
}

function initMediaSetupArray({id, value}){
	// Find index of object in media setup array by id
	const foundIndex = setupMediaInfo.findIndex(x => x.id == id);
	// Update object value
	setupMediaInfo[foundIndex].value = value ?? false;
}

// Default create post
const defaultCreatePost = {
	mediaId: "",
	title: "",
	mediaAuthor: "",
	year: "",
	description: "",
	widthOriginal: null,
	heightOriginal: null,
	fps: options.createFrameRate,
	firstFrame: 1,
	lastFrame: 1,
	audio: false,
	privateLink: false,
	publicGallery: false,
	license: null,
	viewOnly: false,
	publicGalleryApprove: false,
	publicGalleryReject: false,
	thumb: 1,
	bgcolor: null,
	processed: true,
	failed: false,
	mediaUpload: false
}

// Default upload post
const defaultUploadPost = {
	mediaId: "",
	title: "",
	mediaAuthor: "",
	year: "",
	description: "",
	widthOriginal: null,
	widthResize: null,
	widthResizeHighRes: null,
	heightOriginal: null,
	heightResize: null,
	heightResizeHighRes: null,
	fps: null,
	firstFrame: 1,
	lastFrame: null,
	audio: false,
	privateLink: false,
	publicGallery: false,
	license: null,
	viewOnly: false,
	publicGalleryApprove: false,
	publicGalleryReject: false,
	thumb: null,
	bgcolor: null,
	processed: false,
	processedHighRes: false,
	processingHighRes: false,
	failed: false,
	failedHighRes: false,
	mediaUpload: true
}

const tags = [
	{
		id: 'nature',
		title: 'Nature'
	},
	{
		id: 'documentary',
		title: 'Documentary'
	},
	{
		id: 'experimental',
		title: 'Experimental'
	},
	{
		id: 'animation',
		title: 'Animation'
	},
	{
		id: 'narrative',
		title: 'Narrative'
	},
	{
		id: 'music',
		title: 'Music'
	},
	{
		id: 'comedy',
		title: 'Comedy'
	},
	{
		id: 'educational',
		title: 'Educational'
	},
	{
		id: 'archival',
		title: 'Archival'
	},
	{
		id: 'horror',
		title: 'Horror'
	},
	{
		id: 'celluloid',
		title: 'Celluloid'
	},
	{
		id: 'optical-printer',
		title: 'Optical Printer'
	},
	{
		id: 'photogram',
		title: 'Photogram'
	},
	{
		id: 'computer-generated',
		title: 'Computer Generated'
	},
	{
		id: 'artificial-intelligence',
		title: 'Artificial Intelligence'
	}
]

export {
	remoteOptions,
	initArray,
	initMediaSetupArray,
	setupProjectorVisualInfo,
	setupWheelVisualInfo,
	setupAudioInfo,
	setupMediaInfo,
	playbackParams,
	visualInfoParams,
	audioInfoParams,
	mediaInfoParams,
	defaultCreatePost,
	defaultUploadPost,
	tags
};