import axios from "axios";
import {S3_PATH, S3_PATH_UPLOAD} from '../config.js';
import {
	playbackParams,
	mediaInfoParams,
	visualInfoParams
} from '../database.js';
import {states} from '../states.js';
import {options} from '../options.js';
import {postRequests} from '../shared/postRequests.js';
import {projectorSpeed} from './images_projectorSpeed.js';
import {centerModal} from './centerModal.js';
import {socketEmit} from './socketEmit.js';
import {keyframes} from './keyframes.js';
import {controls} from './controls.js';
import {checkImage} from '../shared/loadImages.js';
import {sidePanel} from './sidePanel.js';
import {editFormCustom} from './editFormCustom.js';
// Note: importing from edit route
import {form} from '../edit/form.js';

const createFilm = (function(){

	//data
	let mediaPath;
	let timestamp;
	let counter = 0;

	//options
	const imageScroll = false;
	const imageScrollSpeed = 500;
	const initialDuplicateImages = 4;
	const sortWindow = 50;
	const shutterImageFileName = "black";
	const shutterImageMediaPath = `${S3_PATH}/media/shutter/`;

	//cache DOM
	const $el = $('#images');
	const el = $el[0];
	let $firstImage;
	let $lastImage;

	function cacheElms(){
		$firstImage = $el.find('#img1');
		$lastImage = $el.find('.lastImage');
	}

	//bind events
	// Attempt to save post when site exists
	// Note: Post updates when either the mobile remote or desktop looses connection
	//window.addEventListener("beforeunload", updatePost);

	// Init
	/*-------------------------------*/

	async function init(){
		return new Promise(async resolve => {
			projectorSpeed.setThresholdsForAutomatedPlaybackOrCreateMode();
			timestamp = Math.floor(Date.now());
			states.mediaData.mediaId = timestamp;
			states.mediaId = timestamp;
			mediaPath = `${S3_PATH_UPLOAD}/${timestamp}/${options.imagesFolderName}/`;
			// Create initial image tags with no source for caching
			for(let i = 1; i <= initialDuplicateImages; i++){
				const imgSettings = {
					id: i,
					last: false,
				}
				if (i == initialDuplicateImages) imgSettings.last = true;
				await createInitialImages(imgSettings);
			}
			projectorSpeed.cacheElms();
			cacheElms();
			resolve();
		});
	}

	// Create
	/*-------------------------------*/

	function createInitialImages({id, last} = {}){
		return new Promise(resolve => {
			const newImage = new Image();
			last ? newImage.className = "lastImage" : newImage.id = "img"+id;
			if (!last) $(newImage).attr("data-value", id);
			newImage.setAttribute("onerror", `this.onerror=null;this.src='${shutterImageMediaPath}${shutterImageFileName}.jpg'`);
			el.appendChild(newImage);
			$(newImage).css("display", "none");
			resolve();
		})
	}

	async function initCreateImage({imageNumber} = {}){
		if (counter == 0 && !states.initialPhoto){
			states.initialPhoto = true;
			console.log("states.initialPhoto", states.initialPhoto);
			centerModal.updateUI();
			try{
				// Create post
				const post = await createPost(states.mediaData);
				states.createPostCreated = true;
				console.log("states.createPostCreated", states.createPostCreated);
				socketEmit.send("desktopCreatePostCreated");
				// Create count record for create post
				await axios.post(`/counts/create-mode-post/${post._id}`)
				// Set state
				states.mediaData = post;
				// Init form from edit route
				form.init();
				// Init custom edit form
				editFormCustom.init();
				// Update buttons
				sidePanel.updateEnable();
				keyframes.updateEnable();
				controls.updateEnable();
			} catch(err){
				console.error(err)
				return;
			}
		}
		counter++;
		const url = `${mediaPath}${imageNumber}.jpg`;
		// Handles incoming images as follows:
		// Note: 3 initial image tags created on init. 3 images is the minimum needed for projector playback.
		// First image => set duplicate source on first, second, and third image, with third image designated as the last image via class
		// Second image => replace source once on second image
		// 2+ image => set source, load, append above last image, then sort the previous X number of images
		console.log("imageNumber", imageNumber);
		if (imageNumber == 1){
			for(let i = 1; i < initialDuplicateImages; i++){
				setImageSource($(`#img${i}`), url);
				$(`#img${i}`).css("display", "block");
			}
			setImageSource($lastImage, url);
			$lastImage.css("display", "block");
			visualInfoParams.currentFrame = 1;
			controls.setCurrentFrame(1);
			updateState();
			return;
		}
		if (imageNumber > 1 && imageNumber < initialDuplicateImages){
			setImageSource($(`#img${imageNumber}`), url);
			$(`#img${imageNumber}`).css("display", "block");
			if (playbackParams.speed == 0) scrollToImage($(`#img${imageNumber}`));
			updateState();
			return;
		}
		const imgSettings = {
			id: imageNumber,
			url: url
		}
		const imageCreated = await createImage(imgSettings);
		sortImages();
		updateState(); // Note: Update state even if image fails to load. It is most likely saved in S3 and should therefore be counted.
		if (playbackParams.speed != 0 || !imageCreated) return;
		scrollToImage($(`#img${imgSettings.id}`));
	}

	async function createImage({id, url} = {}){
		// Note: Create and append the image element, then wait for it to load before resolving promise. Show shutter image on error.
		const newImage = new Image();
		newImage.src = url;
		newImage.id = `img${id}`;
		$(newImage).attr("data-value", id);
		newImage.setAttribute("onerror", `this.onerror=null;this.src='${shutterImageMediaPath}${shutterImageFileName}.jpg'`);
		$lastImage.before($(newImage));
		try {
			const loadedImage = await checkImage(newImage);
			//console.log('Image found and loaded', loadedImage);
			return true;
		} catch(err){
			console.log(err);
			newImage.className = 'failed';			
			return false;
		}
	}

	// Source: https://stackoverflow.com/questions/60989601/sorting-array-of-images-by-value-in-ascending-order
	function sortImages () {
		const container = document.getElementById('images');
		const images = container.getElementsByTagName('img');
		// Make array with all images
		const imageArray = [].slice.call(images);
		//console.log("imageArray", imageArray);
		// Make array with last X number of images
		const imageArraySliced = counter < sortWindow ? imageArray : imageArray.slice(-sortWindow);
		//console.log("imageArraySliced", imageArraySliced);
		// Sort sliced array
		const imageArraySlicedSorted = imageArraySliced.sort((a, b) => {
			const aToSort = parseInt(a.getAttribute('data-value'))
			const bToSort = parseInt(b.getAttribute('data-value'))
			return aToSort - bToSort;
		});
		//console.log(imageArraySlicedSorted);
		// Update DOM
		for(let i = 0; i < imageArraySliced.length; i++) {
			imageArraySliced[i].parentNode.removeChild(imageArraySliced[i]);
			container.append(imageArraySlicedSorted[i]);
		}
	}

	function updateState(){
		states.lastFrame = counter;
		projectorSpeed.setLastframe();
		mediaInfoParams.totalFrames = counter;
		controls.setTotalFrames(counter);
	}

	function setImageSource(elm, src){
		elm.attr("src",`${src}`);
	}

	function scrollToImage(elm){
		imageScroll ? $.scrollTo(elm, 0, imageScrollSpeed) : $.scrollTo(elm, 0);
	}

	// Post requests
	/*-------------------------------*/

	function createPost(obj){
		return new Promise(async (resolve, reject) => {
			// Create post
			const post = await postRequests.createPost(obj);
			if (!post){
				console.log("Post not created")
				reject();
				return;
			}
			console.log("Create post", post);
			resolve(post);
		});
	}

	async function updatePost(){
		if (counter == 0) return;
		const postObj = {
			lastFrame: counter
		}
		// Update post
		const post = await postRequests.updatePost(timestamp, postObj);
		if (!post) return console.log("Post not updated");
		console.log("Update post", post);
	}

	// Misc
	/*-------------------------------*/

	function overrideDatGUI(){
		// Begin frame count at 0
		mediaInfoParams.totalFrames = 0;
		visualInfoParams.currentFrame = 0;
	}

	return {
		init: init,
		initCreateImage: initCreateImage,
		updatePost: updatePost,
		overrideDatGUI: overrideDatGUI
	}

})();

export {createFilm};