const watermark = (function(){

	//options
	const msgText = "Preview";

	//cache DOM
	const $el = $("#watermark");
	const $msg = $el.find('h1');

	function init(){
		setMsg({msg: msgText});
		show();
	}

	function show(){
		$el.show();
	}

	function hide(){
		$el.hide();
	}

	function setMsg({msg} = {}){
		$msg.html(msg)
	}

	return {
		init: init
	}

})();

export {watermark};