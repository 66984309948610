import {states} from '../states.js';
import {loader} from './loader.js';

const back = (function(){

	//options
	const fadeDuration = 700;

	//cache DOM
	const $el = $("#back");

	//bind events
	// Note: visibility controlled by hoverOverlay
	function bindEvents(){
		$el.on('click', onClick);
	}

	async function init(data){
		bindEvents();
	}

	async function fadeIn(){
		$el.fadeIn(fadeDuration);
	}

	async function fadeOut(){
		$el.fadeOut(fadeDuration);
	}

	function onClick(){
		if (!states.fantascope) loader.cancelUpload();
		goBack();
	}

	function goBack(){
		const link = !states.auth || !states.userOwnsPost ? '/explore' : '/my-work'
		console.log("link", link);
		window.location.href = link;
	}

	return {
		init: init,
		fadeIn: fadeIn,
		fadeOut: fadeOut,
		$el: $el,
		goBack: goBack
	}

})();

export {back};