import axios from "axios";
import {defaultCreatePost} from '../database.js';
import {options} from '../options.js';
import {states} from '../states.js';
import {images} from './images.js';
import {loader} from './loader.js';
import {centerModal} from './centerModal.js';
import {menu} from '../shared/menu.js';
import {menuButton} from '../shared/menuButton.js';
import {postRequests} from '../shared/postRequests.js';
import {centerMessage} from '../shared/centerMessage.js';
import {overlayPreventClick} from '../shared/overlayPreventClick.js';
import {delay} from '../shared/util.js';
import {back} from './back.js';
import {titleCard} from './titleCard.js';
import {qrCodeModal} from './qrCodeModal.js';

// Init media
const initMedia = (function(){

	//data
	let userId;
	// Note: default lastFrame is 3 for film posts, which is the minimum needed for projector playback
	const defaultCreateFilmPost = {...defaultCreatePost, lastFrame: 3}
	const defaultCreateFantascopePost = {...defaultCreatePost}

	//states
	let mediaReady = false;

	async function init(){
		// Get post ID from meta tag
		// Note: sent from server and rendered via EJS template
		const postId = $('meta[name="active-post"]').attr('content');
		// If create mode, wait for user media type selection before initializing images
		if (postId == 'create'){
			states.createMode = true;
			console.log("states.createMode", states.createMode);
			states.userOwnsPost = true;
			console.log("states.userOwnsPost", states.userOwnsPost);
			// If max storage reached, show error
			await menu.updateStorageUI()
			const maxStorage = states.proUser ? options.maxStoragePro : options.maxStorage
			if (states.currentStorage > maxStorage) return error('Error: Maximum storage reached');
			// Get user post count from beginning of month
			const counts = await axios.get('/counts/month/user');
			if (counts.status != 200) return error('Server error');
			console.log("counts", counts.data);
			const postsLengthThisMonth = counts.data.postsLength
			// Set max posts based on user role
			const maxPosts = states.proUser ? options.maxPostsPro : options.maxPosts;
			// If max posts has been reached, show error
			if (postsLengthThisMonth >= maxPosts) return error('Error: contact support for assistance');
			// Init QR code for standalone mobile camera link
			qrCodeModal.initQrCodeCameraLink();
			// Init center modal
			await centerModal.init();
			// Set default media data
			states.mediaData = states.fantascope ? defaultCreateFantascopePost : defaultCreateFilmPost;
			// Note: resolution set by user when selecting center modal options
			states.mediaData.widthOriginal = states.width;
			states.mediaData.heightOriginal = states.height;
			initImages();
			return;
		}
		// Disable menu button
		if (!states.createMode) menuButton.updateEnable({enable: false});
		// Get post for export
		if (states.exportMode){
			const post = await postRequests.getPostForExport(postId)
			console.log("post", post);
			if (!post){
				// Define message
				const msg = "Film not found";
				error(msg);
				return;
			}
			// Success getting post
			// Set media
			states.mediaData = post;
			// Media not processed
			if (!states.mediaData.processed){
				// Define message
				const msg = states.mediaData.failed ? "Film failed to process" : "Film is being processed";
				error(msg);
				return;
			}
			// Media processed
			setStates();
			initImages();
			// Update document title
			if (states.mediaData.title) document.title = states.mediaData.title;
			return;
		}
		// Get post
		const postResponseData = await postRequests.getPost(postId);
		console.log("postResponseData", postResponseData);
		// Error getting post
		if (!postResponseData.success){
			console.log("postResponseData.status", postResponseData.status);
			// Define message
			const msg = postResponseData.status == 401 ? "Private film" : "Film not found";
			error(msg);
			return;
		}
		// Success getting post
		// Set media
		states.mediaData = postResponseData.data;
		// Media not processed
		if (!states.mediaData.processed){
			// Define message
			const msg = states.mediaData.failed ? "Film failed to process" : "Film is being processed";
			error(msg);
			return;
		}
		// Get user info
		if (states.auth){
			if (!states.user){
				// Define message
				const msg = "User not found";
				error(msg);
				return;
			}
			userId = states.user.user_id
			console.log("userId", userId);
		}
		// Set states
		setStates();
		// Check view only
		if (states.mediaData.viewOnly && !states.screenMode && !states.userOwnsPost){
			// Define message
			const msg = "Film is view only"
			error(msg);
			return;
		}
		// Media processed
		initImages();
		// Update document title
		if (states.mediaData.title) document.title = states.mediaData.title;
	}

	function setStates(){
		// Check author
		if (states.auth && userId == states.mediaData.author) states.userOwnsPost = true;
		console.log("states.userOwnsPost", states.userOwnsPost);
		// Check for fantascope
		if (states.mediaData.lastFrame == 1){
			states.fantascope = true;
			console.log("states.fantascope", states.fantascope);
			// Set image generation cycles to 1
			options.imageGenerationCylces = 1;
		}
		// Set default fantascope fps if null
		if (states.fantascope && !states.mediaData.fps) states.mediaData.fps = options.defaultFantascopeFrameRate;
		// For short media, set image generation cycles based on shortFilmTotalFramesTarget option
		if (states.mediaData.lastFrame <= options.shortFilmFrameThreshold && options.generateImageCyclesForShortFilm && !states.fantascope){
			options.imageGenerationCylces = Math.round(options.shortFilmTotalFramesTarget / states.mediaData.lastFrame);
			console.log("options.imageGenerationCylces", options.imageGenerationCylces);
		}
		// For short media, disable audio
		states.mediaHasAudio = states.mediaData.lastFrame <= options.shortFilmFrameThreshold && options.disableAudioForShortFilm ? false : states.mediaData.audio;
		console.log("states.mediaHasAudio", states.mediaHasAudio);
	}

	async function initImages(){
		// Set local state
		mediaReady = true;
		console.log("mediaReady", mediaReady)
		if (states.exportMode){
			// Init title card
			titleCard.init();
			images.init();
			return;
		}
		// Bypass loader for fantascopes, create mode and screen mode
		if (!states.fantascope && !states.createMode && !states.screenMode) await loader.fadeIn();
		// Create title card from post info in screen mode
		if (states.screenMode) {
			// Check if post has keyframes. If not, play media at fixed speed.
			const postHasKeyframes = states.mediaData.keyframes.length > 0
			console.log("postHasKeyframes", postHasKeyframes)
			// If film is longitudinal, play slowly in reverse
			if (!states.fantascope && states.mediaData.longitudinal && states.screenMode && !states.exportMode) {
				states.mediaData.keyframes.push({
					order: 0,
					rampUp: 3,
					speed: -0.01,
					frameRate: 30
				})
			}
			// If media has no phases and is not longitudinal, play at full speed
			if (!postHasKeyframes && !states.mediaData.longitudinal){
				states.mediaData.keyframes.push({
					order: 0,
					speed: 100
				})
			}
			loader.hide();
			centerModal.hide();
			// Init title card
			titleCard.init();
			// Show title card
			titleCard.fadeIn();
		}
		images.init();
	}

	function error(msg){
		// Hide loader
		loader.hide();
		// Hide center modal
		centerModal.hide();
		// Destroy menu
		menu.destroy();
		// Init back button
		if (!states.screenMode) back.init();
		// Fade in back button
		if (!states.screenMode) back.fadeIn();
		// Set center message
		centerMessage.setMsg(msg)
		// Show center message
		centerMessage.show();
		// Fade out overlay
		overlayPreventClick.fadeOut();
	}

	function isMediaReady(){
		return mediaReady;
	}

	return {
		init: init,
		isMediaReady: isMediaReady
	};

})(); //initMedia

export {initMedia};