// Opaque background
const opaqueBackground = (function(){

	//options
	const fadeDuration = 800;

	//cache DOM
	const $el = $('#opaque-background');

	function fadeIn({duration = fadeDuration} = {}){
		return new Promise(resolve => $el.fadeIn(duration, resolve));
	}

	function fadeOut({duration = fadeDuration} = {}){
		return new Promise(resolve => $el.fadeOut(duration, resolve));
	}

	function show(){
		$el.show();
	}

	function hide(){
		$el.hide();
	}

	return {
		fadeIn: fadeIn,
		fadeOut: fadeOut,
		show: show,
		hide: hide
	};

})(); //opaqueBackground

export {opaqueBackground};