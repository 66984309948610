import axios from "axios";
const copy = require('clipboard-copy');
const queryString = require('query-string');
import {checkEqual} from 'check-equal';
import {rootURL} from '../config.js';
import {
	remoteOptions,
	initMediaSetupArray,
	playbackParams,
	visualInfoParams
} from '../database.js';
import {states} from '../states.js';
import {options} from '../options.js';
import {map, clamp, difference} from '../shared/math.js';
import {delay} from '../shared/util.js';
import {deletePropsWithEmptyValues} from '../shared/objectMutations.js';
import {menuButton} from '../shared/menuButton.js';
import {panelMessage} from '../shared/panelMessage.js';
import {flickerMessage} from '../shared/flickerMessage.js';
import {audioContextMessage} from '../shared/audioContextMessage.js';
import {overlayPreventClick} from '../shared/overlayPreventClick.js';
import {centerModal} from './centerModal.js';
import {waveform} from './waveform.js';
import {datGUI} from './datGUI.js';
import {stats} from './stats.js';
import {back} from './back.js';
import {hoverOverlay} from './hoverOverlay.js';
import {controls} from './controls.js';
import {qrCodeModal} from './qrCodeModal.js';
import {loader} from './loader.js';
import {socketEmit} from './socketEmit.js';
import {keyframes} from './keyframes.js';
// Child modules
import {generate} from './images_generate.js';
import {effects} from './images_effects.js';
import {projectorSpeed} from './images_projectorSpeed.js';
import {projectorFps} from './images_projectorFps.js';
import {projectorFraming} from './images_projectorFraming.js';
import {wheelSpeed} from './images_wheelSpeed.js';
import {wheelFps} from './images_wheelFps.js';
import {wheelFraming} from './images_wheelFraming.js';
import {createFilm} from './images_createFilm.js';
import {createWheel} from './images_createWheel.js';
import {titleCard} from './titleCard.js';
import {postMessage} from './postMessage.js';
import {howlerAudio} from './howlerAudio.js';
import {sidePanel} from './sidePanel.js';
import {editFormCustom} from './editFormCustom.js';
import {shepherd} from './shepherd.js';
import {exportPanel} from './exportPanel.js';
import {sharePanel} from './sharePanel.js';
import {paramOptions} from './paramOptions.js';
import {watermark} from './watermark.js';
import {projectInfoTitleCard} from './projectInfoTitleCard.js';
import {dragElement} from '../shared/dragElement.js';
// Note: importing from edit route
import {form} from '../edit/form.js';

// Images
const images = (function(){

	// States
	let imagesLoaded = false;
	let checkAudioContext = false;
	let autoScrollComplete = false

	// Options
	const fadeDuration = 1000;
	const smallDataIncrementAmount = 1/200;
	const freezeAutoParamsOnRemixDataInput = true;
	const scrollToLastImageIfFilmStartsInReverse = false;

	// Param timer
	let resetParamTimer;

	// Opacity
		// Options
		const opacityRange = 1;
		const opacityChangeAmount = 1;
		// Affects amount of mobile remote rotation
		const minDialValueForOpacity = -100;
		const maxDialValueForOpacity = 100;
		// Opacity init
		let opacityUse = 0;
		// Define range of opacity based on opacityRange
		const minOpacity = -opacityRange;
		const maxOpacity = opacityRange;
		// Map opacity limits to dial limits for initial opacity value
		let opacityInit = map(opacityUse, minOpacity, maxOpacity, minDialValueForOpacity, maxDialValueForOpacity);
		console.log("opacityInit", opacityInit);

	// Automate params
		// States
		let reset = true;
		let paramsSelected = false;
		let autoScrolling = false;
		let hasUrlParams = false;
		let keyframeStatusChecked = false;

		// Options
		const dataAmount = 1/2;
		let resetParamsSpeed = 5;
		let autoParamsSpeed = 30/2;
		let autoParamsSpeedSlow = 60;
		let autoParamsSpeedFast = 5;

		// Data
		let paramsRanges = [];
		const singleParamsBeingReset = [];
		let urlParamsArray = [];
		let currentParams = {};
		const resetParams = {
			speed: 0,
			frameRate: null,
			framing: 0,
			focus: 0,
			brightness: 100,
			saturate: 100,
			contrast: 100,
			hue: 0
		}

	// Cache DOM
	const $el = $('#images');

	// Bind events
	document.addEventListener("visibilitychange", function() {
		// Set state
		states.documentHidden = document.hidden ? true : false;
		console.log("states.documentHidden", states.documentHidden);
		// Toggle playback on browser visibility
		togglePlaybackOnBrowserVisibility();
	});

	// Init
	/*-------------------------------*/

	async function init(){
		console.log("Init images");
		// Init child modules
		if (states.fantascope){
			wheelSpeed.init();
			wheelFps.init();
		} else {
			projectorSpeed.init();
			projectorFps.init();
			projectorFraming.init();
		}
		// Init center modal
		if (!states.createMode) centerModal.init();
		// Init local options
		// Slow down param speed when exporting audio to match max-speed of visual screen capture export via Timecut
		if (states.exportAudioMode){
			// Note: Not sure why export frame rate affects audio sync. Param speeds below were selected from trial and error.
			// 30 fps export
			if (states.exportSettings.fps == 30){
				resetParamsSpeed = 32;
				autoParamsSpeed = 32;
			}
			// 60 fps export
			if (states.exportSettings.fps == 60){
				resetParamsSpeed = 30/2;
				autoParamsSpeed = 30/2;
			}
		}
		// Init image effects
		effects.init();
		// Calc params ranges
		calcParamsRanges();
		// Check for URL params
		// Note: above needs to initialize before url params values can be constrained
		checkUrlParams();
		// Init keyframes
		keyframes.init();
		// Init back button
		if (!states.createMode) back.init();
		// Init hover overlay
		if (!states.screenMode) hoverOverlay.init();
		// Init controls
		controls.init();
		// Set total frames
		const initialTotalFrames = states.createMode && !states.fantascope ? 0 : states.mediaData.lastFrame;
		controls.setTotalFrames(initialTotalFrames);
		// Set initial current frame
		const initialCurrentFrame = states.createMode && !states.fantascope ? 0 : 1;
		controls.setCurrentFrame(initialCurrentFrame);
		// Init side panel
		sidePanel.init();
		// Init panel message
		panelMessage.init();
		// Init flicker message
		flickerMessage.init();
		// Init export panel
		exportPanel.init();
		// Init share panel
		sharePanel.init();
		// Init project info title card
		projectInfoTitleCard.init();
		// Init edit form
		if (states.userOwnsPost && !states.createMode){
			// Init form from edit route
			form.init();
			// Init custom edit form
			editFormCustom.init();
		}
		// Init create or generate images
		if (!states.createMode) await generate.init();
		if (states.createMode) states.fantascope ? await createWheel.init() : await createFilm.init();
		// Setup dat.GUI
		setupDatGUI();
		// Init Shepherd tour
		shepherd.init();
		// If fatnascope, init draggable image
		if (states.fantascope) dragElement($el[0]);
		// Init playback
		await initPlayback();
		// Set local state
		imagesLoaded = true;
		console.log("imagesLoaded", imagesLoaded);
		// If film is longitudinal, select random initial frame
		if (!states.fantascope && states.mediaData.longitudinal && states.screenMode && !states.exportMode) {
			projectorSpeed.scrollToRandomFrame();
		}
		// Create view record
		if (states.user?.user_id != states.mediaData.author && !states.exportMode && !states.createMode){
			const response = await axios.post(`/views/${states.mediaData._id}`, {screen: states.screenMode}, {validateStatus: () => true});
			// console.log("response.data", response.data)
			// if (response.status != 201) return console.error("Error creating view")
		}
		// If film projector starts in reverse in screen mode, scroll to last image
		// Note: this enables smooth playback when inital speed param is negative and resolves an export image stutter when ramp up is false
		// Note: Disabling to fix export and screen playback issues when speed is slow
		if (scrollToLastImageIfFilmStartsInReverse && states.screenMode && !states.fantascope){
			const activePlaybackParams = states.exportMode ? urlParamsArray : states.mediaData.keyframes;
			if (activePlaybackParams[0] && activePlaybackParams[0].speed < 0) projectorSpeed.scrollToLastImage();
		}
		// Delay to ensure white frames are captured during export. White frames mark images loading duration.
		if (states.exportMode) await delay(1000);
		// Send message to parent window object
		postMessage.send({msg: 'Images loaded', data: {
			mediaHasAudio: states.mediaHasAudio,
			viewOnly: states.mediaData.viewOnly
		}});
		// Start playback if screen mode and motor are true but not export mode
		if (states.screenMode && states.screenModeSettings.motor && !states.exportMode){
			await delay(1500);
			keyframes.togglePlayAll();
		}
		if (states.exportMode){
			await delay(options.blackDurationAfterLoadingForExport);
			if (states.exportSettings.titleCard){
				await titleCard.fadeIn();
				await delay(3000);
				await titleCard.fadeOut();
				await delay(1000);
			}
			// Init watermark
			if (states.exportSettings.watermark) watermark.init();
			states.exportSettings.fadeIn ? overlayPreventClick.fadeOut({duration: fadeDuration}) : overlayPreventClick.hide();
			if (states.exportSettings.rampUp) await delay(options.initialPauseDurationForExport);
			keyframes.togglePlayAll();
			// Delay by export duration
			await delay(states.exportSettings.duration*1000);
			if (states.exportSettings.rampDown) await resetParamsGradually();
			await delay(1000);
			if (states.exportSettings.fadeOut) await overlayPreventClick.fadeIn({duration: fadeDuration});
			await delay(500);
			// Stop frame capture from Timecut
			// Note: Timecut listens for this function to stop capture, which is defined when Timecut is initialized. It will throw an error on client browser.
			stopCapture();
		}
	}

	function setupDatGUI(){
		// Setup dat.GUI array with initial values
		initMediaSetupArray({id: "title", value: states.mediaData.title});
		initMediaSetupArray({id: "year", value: states.mediaData.year});
		initMediaSetupArray({id: "frameRate", value: states.mediaData.fps});
		initMediaSetupArray({id: "totalFrames", value: states.mediaData.lastFrame});
		initMediaSetupArray({id: "imageCycles", value: options.imageGenerationCylces});
		initMediaSetupArray({id: "audio", value: states.mediaData.audio});
	}

	// UI
	/*-------------------------------*/
	function fadeIn(){
		$el.fadeIn(fadeDuration);
	}

	function fadeOut(){
		$el.fadeOut(fadeDuration);
	}

	function show(){
		$el.show();
	}

	function hide(){
		$el.hide();
	}

	function updateCursor(){
		states.enableImageMove && states.fantascope ? $el.addClass('draggable') : $el.removeClass('draggable');
	}

	// Playback
	/*-------------------------------*/

	function initPlayback(){
		return new Promise(async resolve =>{
			// Cache effects DOM
			effects.cacheDom();
			// Wait for QR Code
			while (true) {
				if (qrCodeModal.getQrCodeMade() || states.screenMode) break;
				console.log("Wait for QR Code");
				await delay(50);
			}
			// Init projector or wheel, hide loader, and show images
			if (states.fantascope){
				wheelSpeed.initWheel();
				wheelFraming.init();
				if (!states.exportMode){
					// Hide loader
					if (!states.createMode || !states.screenMode) loader.hide();
					// Hide title card
					if (states.screenMode){
						await delay(3000);
						await titleCard.fadeOut();
						await delay(1000);
					}
				}
				// Show images
				show();
			} else {
				if (states.mediaHasAudio){
					if (states.screenMode && states.screenModeSettings.waveform){
						// Wait for waveform to load and open
						await waveform.init();
						waveform.open();
					} else {
						// TO DO: init at sime time with promises all
						// Init waveform before fully loaded to speed up page load time
						waveform.init();
					}
				}
				await projectorSpeed.initProjector();
				if (!states.exportMode){
					// Hide loader
					if (!states.createMode || !states.screenMode) await loader.fadeOut();
					// Hide title card
					if (states.screenMode){
						await delay(2000);
						await titleCard.fadeOut();
						await delay(1000);
					}
				}
				// Show images
				show();
			}
			// Trigger mobile remote connection
			socketEmit.send('desktopImagesLoaded');
			// Note: overlayPreventClick modal is hidden after title card for export mode
			if (states.exportMode) centerModal.close();
			// Change center modal background opacity if create mode
			if (states.createMode) centerModal.backgroundOpaque({active: false});
			if (!states.createMode && !states.exportMode){
				// Show or close center modal based on presence of keyframes or global option
				if ((states.mediaData.keyframes && states.mediaData.keyframes.length > 0) || hasUrlParams || options.hideCenterModalOnLoad) {
					centerModal.close();
					overlayPreventClick.fadeOut({duration: fadeDuration});
				} else {
					centerModal.toggle();
					overlayPreventClick.hide();
				}
			}
			// Enable menu button
			if (!states.createMode) menuButton.updateEnable({enable: true});
			// Init dat.GUI
			datGUI.init();
			// Override dat.GUI info in create film mode
			if (states.createMode && !states.fantascope) createFilm.overrideDatGUI();
			// Show dat.GUI
			datGUI.show();
			// Set reset params frame rate
			resetParams.frameRate = states.mediaData.fps;
			// Update param info
			controls.updateParamInfo();
			// Resolve
			resolve();
		});
	}

	function togglePlayback(){
		states.fantascope ? wheelSpeed.toggleWheel() : projectorSpeed.toggleProjector();
	}

	function togglePlaybackOnBrowserVisibility(){
		states.fantascope ? wheelSpeed.toggleWheelOnBrowserVisibility() : projectorSpeed.toggleProjectorOnBrowserVisibility();
	}

	function startPlayback(){
		states.fantascope ? wheelSpeed.startWheel() : projectorSpeed.startProjector();
	}

	function stopPlayback(){
		states.fantascope ? wheelSpeed.stopWheel() : projectorSpeed.stopProjector();
	}

	function setMaxPlaybackSpeed(){
		if (!states.fantascope) projectorSpeed.setMaxProjectorSpeed();
	}

	// Reset images
	/*-------------------------------*/

	function resetImages(){
		// Stop timer
		generate.stopTimer();
		// Remove images
		$el.find('img').remove();
		// Reset state
		states.fantascope = false;
		console.log("states.fantascope", states.fantascope);
		states.mediaHasAudio = false;
		console.log("states.mediaHasAudio", states.mediaHasAudio);
		states.soundReady = false;
		console.log("states.soundReady", states.soundReady);
		states.enableParametersChange = true;
		console.log("states.enableParametersChange", states.enableParametersChange);
	}

	function resetImagePosition(){
		$el.css({
			'top': '0',
			'left': '0'
		})
	}

	// Reset frame rate
	/*-------------------------------*/

	async function frameRateReset(){
		if (!states.fantascope) return;
		// Reset params
		await resetParamsInstantly();
		// Reset arrays
		paramsRanges = [];
		urlParamsArray = [];
		// Re-init wheel speed and fps
		wheelSpeed.init();
		wheelFps.init();
		// Calc params ranges
		calcParamsRanges();
		// Check for URL params
		// Note: above needs to initialize before url params values can be constrained
		checkUrlParams();
		// Set state
		states.info = false;
		console.log("states.info", states.info);
		// Update stats
		stats.updateUI();
		// Destroy dat.GUI
		datGUI.destroy();
		// Setup dat.GUI
		setupDatGUI();
		// Init dat.GUI
		datGUI.init();
		// Show dat.GUI
		datGUI.show();
		// Set reset params frame rate
		resetParams.frameRate = states.mediaData.fps;
		// Update keyframes reset params
		keyframes.setResetParams(resetParams);
		// Remove saved and current keyframes
		keyframes.undo({removeAll: true});
		// Update param info
		controls.updateParamInfo();
	}

	// Check URL params
	/*-------------------------------*/

	// Source: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
	async function checkUrlParams(){
		// In export mode, use URL playback params which are passed from parent to child window
		// Note: option to remove export mode condition to pass in URL playback params in screen mode
		if (states.exportMode && states.screenModeSettings.playbackParams && states.screenModeSettings.playbackParams.length > 0){
			hasUrlParams = true;
			console.log("hasUrlParams", hasUrlParams);
			await convertSearchParamStringsToObjectsAndConstrain({
				inputArray: states.screenModeSettings.playbackParams,
				outputArray: urlParamsArray
			});
			return;
		}
		if (!window.location.search) return;
		// If create mode, remove params from URL and return
		if (states.createMode){
			const baseUrl = window.location.href.split("?")[0];
			window.history.replaceState({}, document.title, baseUrl);
			return;
		}
		const urlParams = queryString.parse(location.search, {arrayFormat: 'bracket'});
		//console.log("urlParams", urlParams);
		//console.log("urlParams.p", urlParams.p);
		if (!urlParams) return;
		hasUrlParams = true;
		console.log("hasUrlParams", hasUrlParams);
		// Set playback params array with decoded query string array or create an array with a single query string
		const arrayUse = urlParams.p || new Array(urlParams);
		console.log("arrayUse", arrayUse);
		await convertSearchParamStringsToObjectsAndConstrain({
			inputArray: arrayUse,
			outputArray: urlParamsArray
		});
		console.log("urlParamsArray", urlParamsArray);
	}

	const convertSearchParamStringsToObjectsAndConstrain = ({inputArray, outputArray} = {}) => {
		return new Promise(resolve => {
			inputArray.forEach((searchParamString, index) => {
				const searchParams = new URLSearchParams(searchParamString);
				const paramsObj = Object.fromEntries(searchParams);
				// Get object with long param names
				let paramsNamesLong = changeToLongParamNames(paramsObj);
				// Delete empty props
				paramsNamesLong = deletePropsWithEmptyValues(paramsNamesLong);
				// Constrain params
				const paramsConstrained = constrainParams(paramsNamesLong);
				// Push to array
				outputArray.push(paramsConstrained);
			})
			resolve();
		});
	}

	function calcParamsRanges(){
		// Speed
		const rotations = states.fantascope ? wheelSpeed.getMaxWheelSpeedRotations() : projectorSpeed.getMaxProjectorSpeed();
		const speedObj = {
			name: 'speed',
			min: -100*rotations,
			max: 100*rotations,
			rangeBalanced: true
		}
		paramsRanges.push(speedObj);
		// Frame rate
		const minFPS = states.fantascope ? wheelFps.getMinFPS() : projectorFps.getMinFPS();
		const maxFPS = states.fantascope ? wheelFps.getMaxFPS() : projectorFps.getMaxFPS();
		const frameRateObj = {
			name: 'frameRate',
			min: minFPS,
			max: maxFPS,
			rangeBalanced: false
		}
		paramsRanges.push(frameRateObj);
		// Effects
		const filters = effects.getFilters();	
		filters.forEach(filterObj => {
			const obj = {
				name: filterObj.parameterName,
				min: filterObj.min,
				max: filterObj.max,
				rangeBalanced: filterObj.rangeBalanced
			}
			paramsRanges.push(obj);
		})
		console.log("paramsRanges", paramsRanges);
	}

	function constrainParams(obj){
		// Constrain params based on speed, frame rate, and effects limits
		const objConstrain = {}
		// Get effects filters
		const filters = effects.getFilters();
		//console.log("filters", filters);
		Object.keys(obj).forEach((key, index) => {
			// Pass in order, rampUp, and duration
			if (key == 'order' || key == 'rampUp' || key == 'duration' || key == 'autoFrame') return objConstrain[key] = obj[key];
			// Find current object param in params ranges array
			const paramRange = paramsRanges.find(obj => obj.name === key);
			console.log("paramRange", paramRange);
			// Limit speed
			if (paramRange.name == "speed"){
				objConstrain[key] = obj[key] < 0
				? Math.max(obj[key], paramRange.min)
				: Math.min(obj[key], paramRange.max)
				// Constrain speed
				if (obj[key] < 0 && obj[key] > -0.01) objConstrain[key] = -0.01
				if (obj[key] > 0 && obj[key] < 0.01) objConstrain[key] = 0.01
				objConstrain[key] = parseFloat(objConstrain[key].toFixed(3))
			}
			// Limit frame rate
			if (paramRange.name == "frameRate"){
				objConstrain[key] = obj[key] <= 0
				? Math.max(obj[key], paramRange.min)
				: Math.min(obj[key], paramRange.max)
			}
			// Limit effects
			filters.forEach(filterObj => {
				if (paramRange.name == filterObj.parameterName){
					objConstrain[key] = obj[key] < 0
					? Math.max(obj[key], paramRange.min)
					: Math.min(obj[key], paramRange.max)
				}
			})
		});
		console.log("objConstrain", objConstrain);
		return objConstrain;
	}
	
	function changeToLongParamNames(obj){
		const objLongNames = {
			speed: obj.sp || obj.speed,
			frameRate: obj.fr || obj.frameRate,
			focus: obj.f || obj.focus,
			brightness: obj.b || obj.brightness,
			saturate: obj.s || obj.saturate,
			contrast: obj.c || obj.contrast,
			hue: obj.h || obj.hue,
			order: obj.o || obj.order,
			rampUp: obj.ru || obj.rampUp,
			duration: obj.d || obj.duration,
			autoFrame: obj.af || obj.autoFrame
		}
		return objLongNames;
	}

	// Automate playback params
	/*-------------------------------*/

	function initAutoParams({rampUp = 1, duration = 1, autoFrame = 0, params, speed = autoParamsSpeed, automateInstantly = false, resetSingle = false, hardReset = false} = {}){
		return new Promise(async resolve => {
			paramsSelected = resetSingle ? false : true;
			console.log("paramsSelected", paramsSelected);
			// Note: need to pass while loops a global object so that object can switch while parameters are changing
			currentParams = params;
			let counterParamsSet = 0;
			let paramsChanging = true;
			console.log("paramsChanging", paramsChanging);
			if (!resetSingle) await checkScrollPosition(currentParams);
			if (resetSingle) resetKeyframes({fromResetSingle: true});
			// Set param speed based on rampUp value from slider input
			if (rampUp == 0) speed = autoParamsSpeedSlow
			if (rampUp == 2) speed = autoParamsSpeedFast
			if (rampUp == 3) automateInstantly = true;
			const currentProjectorSpeed = states.fantascope ? false : projectorSpeed.getPercentProjectorSpeed()
			Object.keys(currentParams).forEach(async (key, index) => {
				// Skip null data
				if (currentParams[key] == null) return counterParamsSet++;
				// Skip framing data
				if (index == 2) return counterParamsSet++;
				// Skip speed if current projector speed equals speed param
				if (currentProjectorSpeed && index == 0 && currentProjectorSpeed.toFixed(5) == parseFloat(currentParams['speed']).toFixed(5)) return counterParamsSet++;
				await automateDial({
					i: index,
					data: dataAmount,
					paramSpeed: speed,
					automateInstantly: automateInstantly,
					resetSingle: resetSingle,
					hardReset: hardReset
				});
				// Reset film frame
				if (index == 0 && !states.fantascope){
					// Reset frame if phase is set to auto frame
					if (autoFrame == 1 && !states.menuOpen) projectorSpeed.resetFrame();
					// Reset frame if film speed is 100% and in screen mode. If in export mode, reset based on export settings in addition to conditions.
					if (index == 0 && (currentParams.speed == 100 || currentParams.speed == -100) && !projectorSpeed.isFramed()){
						if (states.playing && states.screenMode && !states.exportMode) projectorSpeed.resetFrame();
						if (states.playing && states.exportMode && states.exportSettings.autoFrame) projectorSpeed.resetFrame();
					}
				}
				counterParamsSet++;
				console.log("counterParamsSet", counterParamsSet);
				if (counterParamsSet == Object.keys(currentParams).length){
					// Duration value set from slider input
					if (duration <= 1){
						await delay(duration*1000)
					} else {
						// Note: using while loop instead of delay in order to break out of duration pause when toggling play button
						let i = 0;
						while (states.playing && i <= duration){
							await delay(100)
							i = i+0.1
						}
					}
					paramsChanging = false;
					console.log("paramsChanging", paramsChanging);
					if (!states.fantascope) projectorSpeed.resetThreshold();
					resolve();
				}
			});
		});
	}

	function checkScrollPosition(obj){
		return new Promise(async resolve => {
			// If media is a film with speed, lower thresholds in projector speed for playback and auto scroll fowards or backwards
			if (obj.speed == 0 || states.fantascope) return resolve();
			if (!states.fantascope) projectorSpeed.setThresholdsForAutomatedPlaybackOrCreateMode({autoPlayback: true});
			autoScrolling = true;
			console.log("autoScrolling", autoScrolling);
			await autoScroll(obj);
			autoScrolling = false;
			console.log("autoScrolling", autoScrolling);
			resolve();
		});
	}

	function autoScroll(obj){
		return new Promise(async resolve => {
			if (autoScrollComplete) return resolve();
			const threshold = 0.05;
			if (obj.speed > 0){
				// Forward direction detected
				const target = 100 - threshold;
				// Auto scroll backwards
				while (visualInfoParams.scrollPercent > target) {
					if (!states.fantascope) projectorSpeed.changeSpeed(-dataAmount);
					await delay(autoParamsSpeed);
				}
			} else {
				// Reverse direction detected
				const target = threshold;
				// Auto scroll forwards
				while (visualInfoParams.scrollPercent < target) {
					if (!states.fantascope) projectorSpeed.changeSpeed(dataAmount);
					await delay(autoParamsSpeed);
				}
			}
			autoScrollComplete = true
			console.log("autoScrollComplete", autoScrollComplete)
			console.log("Auto scroll complete");
			resolve();
		});
	}

	async function automateDial({i, data, paramSpeed, automateInstantly, resetSingle, hardReset} = {}) {
		return new Promise(async resolve => {
			// Needed to fix speed bug when clicking single phase
			if (i == 0 && !states.playing) changeParams({i: i, data: 0, automate: true, resetSingle: resetSingle, hardReset: hardReset});
			while (Object.values(playbackParams)[i] > Object.values(currentParams)[i]) {	
				const paramDifference = difference(Object.values(playbackParams)[i], Object.values(currentParams)[i]);
				//console.log("paramDifference", paramDifference);
				//if (checkBreaks(paramDifference)) break;
				decreaseDataIncrements(paramDifference);
				changeParams({i: i, data: -data, automate: true, resetSingle: resetSingle, hardReset: hardReset});
				if (!automateInstantly) await delay(paramSpeed);
			}
			console.log("Negative params set");
			while (Object.values(playbackParams)[i] < Object.values(currentParams)[i]) {
				const paramDifference = difference(Object.values(playbackParams)[i], Object.values(currentParams)[i]);
				//console.log("paramDifference", paramDifference);
				//if (checkBreaks(paramDifference)) break;
				decreaseDataIncrements(paramDifference);
				changeParams({i: i, data: data, automate: true, resetSingle: resetSingle, hardReset: hardReset});
				if (!automateInstantly) await delay(paramSpeed);
			}
			console.log("Positive params set");
			resolve();

			function checkBreaks(paramDifference){
				// Note: these break points are not needed when data amount increments are small enough to satisify conditions of while loops
				// Note: effective break point thresholds depend on data amount increments
				// Break early if not speed to avoid while loops getting stuck
				const breakPoint = .01;
				if (i != 0 && paramDifference <= breakPoint) return true;
				// Break early for effects when reseting single param
				if (i > 2 && resetSingle && paramDifference <= 1) return true;
				// Break early for frame rate when reseting single param
				if (i == 1 && resetSingle && paramDifference <= 0.1) return true;
				return false;
			}

			function decreaseDataIncrements(paramDifference){
				// Note: Speed and brightness are most sensitive params, speed because of visual stabilizing thresholds and brightness because of inversion threshold.
				if (paramDifference <= 0.1){
					//console.log("paramDifference", paramDifference);
					// Automate params as fast as possible by removing delay intervals to avoid slow down since data increments are decreased drastically
					if (!automateInstantly){
						automateInstantly = true;
						console.log("automateInstantly", automateInstantly);
					}
					// Note: decrease data increments for greater accuracy
					data = 1/1000					
				}
				// Note: Condition must catch equal or smaller param difference amounts to be effective. Given current settings, brightness param increments are 1. Param amount increments vary based on value range of param, since the use value is mapped to min and max values. They also vary based on the specific param's amount value defined as local options within components, which is multiplied to incoming data.
				else if (paramDifference <= 1){
					//console.log("paramDifference", paramDifference);
					data = 1/10
				}
			}
		});
	}	

	// Dial
	/*-------------------------------*/

	function rotateDial(data){
		// Set state
		if (!states.initialParamChange && states.optionSelected && states.enableParametersChange){
			states.initialParamChange = true
			console.log("states.initialParamChange", states.initialParamChange)
			// Update center controls tooltip
			controls.updateTooltip();
		}
		// Zoom image
		if (states.enableImageZoom) return controls.zoomImage({fromScroll: true, data: data});
		//console.log("data", data);
		if (!states.enableParametersChange || !states.soundReady) return;
		// Fade out center modal on initial phone rotation if initial selection is true
		// Prevent parameter change during initial phone rotation
		if ((!states.createMode || (states.createMode && states.initialPhoto)) && !states.initialRotation && states.initialSelection && centerModal.getModalOpen()) return fadeCenterModal({data: data});
		// Set initial rotation to true if user changes params while center modal is closed
		if (states.initialSelection && !states.initialRotation && !centerModal.getModalOpen()) return fadeCenterModal({data: data, bypass: true});
		// Prevent parameter change in create mode based on user actions
		if (states.createMode && (!states.initialPhoto || !states.initialSelection || !states.initialRotation)) return;
		// Change playback parameters
		for (let i = 0; i < remoteOptions.length; i++){
			if (remoteOptions[i].active == false) continue;
			changeParams({i: i, data: data});
			continue;
			if (!states.multipleParams) return;
		}
		// Show message if Audio Context is not running
		if ((states.audio || states.soundFX) && states.initialRotation && states.initialSelection){
			if (checkAudioContext) return;
			checkAudioContext = true;
			if (!howlerAudio.audioContextRunning()) audioContextMessage.show();
		}
	}

	function fadeCenterModal({data, bypass = false} = {}){
		if (states.initialRotation) return;
		// Set opacity
		opacityInit += data*opacityChangeAmount;
		// Clamp opacity
		opacityInit = clamp(opacityInit, minDialValueForOpacity, maxDialValueForOpacity);
		// Map dial limits to opacity limits
		opacityUse = map(opacityInit, minDialValueForOpacity, maxDialValueForOpacity, minOpacity, maxOpacity);
		// Absolute value
		const opacityUseAbs = Math.abs(opacityUse);
		// Change center modal opacity
		if (!bypass) centerModal.opacity(1-opacityUseAbs);
		if (opacityUseAbs != 1 && !bypass) return;
		// Set state
		states.initialRotation = true;
		console.log("states.initialRotation", states.initialRotation);
		centerModal.close();
	}

	async function changeParams({i, data, automate = false, resetSingle = false, hardReset = false} = {}){
		if (states.smallDataIncrements && i != 2) data *= smallDataIncrementAmount;
		//console.log("data", data);
		// Update current params in param options
		paramOptions.updateCurrentParamsUI()
		// Update phase after params have fully changed
		if ((!automate && i != 2) || (automate && resetSingle && i != 2) || (automate && hardReset && i != 2)) {
			clearTimeout(resetParamTimer);
			resetParamTimer = setTimeout(keyframes.updateCurrent, 300);
		}
		// Note: the order of params must match the order of remote options
		if (i == 0) return states.fantascope ? wheelSpeed.changeSpeed(data) : projectorSpeed.changeSpeed(data);
		if (i == 1) return states.fantascope ? wheelFps.changeFps(data) : projectorFps.changeFps(data);
		if (i == 2) return states.fantascope ? wheelFraming.changeFraming(data) : projectorFraming.changeFraming(data);
		// When automating parameters, disable looping for hue
		const disableLoop = i == 7 && automate ? true : false;
		effects.changeEffect({i: i-3, data: data, disableLoop: disableLoop});
	}

	// Reset params
	/*-------------------------------*/

	async function resetParamsGradually(){
		return new Promise(async resolve => {
			reset = true;
			console.log("reset", reset);
			keyframes.reset();
			// Update playback controls
			controls.updatePlaybackControls();
			await initAutoParams({params: resetParams, speed: resetParamsSpeed});
			resolve();
		});
	}

	async function resetParamsInstantly(){
		reset = true;
		console.log("reset", reset);
		// Set state
		states.enableParametersChange = false;
		console.log("states.enableParametersChange", states.enableParametersChange)
		// Update playback controls
		controls.updatePlaybackControls();
		await initAutoParams({params: resetParams, automateInstantly: true, hardReset: true});
		hardReset();
		// Set state
		states.enableParametersChange = true;
		console.log("states.enableParametersChange", states.enableParametersChange)
	}

	function hardReset(){
		// Re-init image effects
		effects.init();
		// Hard reset playback params to round out info data. Needed when remote is connected and changing params since sensor data comes in as a float.
		for (let key in playbackParams) {
			// Skip framing
			if (key === "framing") continue;
			playbackParams[key] = resetParams[key];
		}
		// Reset speed and framing
		if (states.fantascope){
			wheelSpeed.reset();
			wheelFraming.reset();
		} else {
			projectorSpeed.resetPosition();
			projectorSpeed.resetSpeed();
		}
		autoScrollComplete = false
		console.log("autoScrollComplete", autoScrollComplete)
	}

	async function resetParam(i){
		if (!i) i = 0;
		// Hard reset framing
		if (i == 2) return states.fantascope ? wheelFraming.reset() : projectorSpeed.resetFrame();
		// Return if targeted param is already reset
		let isReset = false;
		Object.keys(playbackParams).forEach((key, index) => {
			if (i == index && playbackParams[key] == resetParams[key]) isReset = true;
		});
		if (isReset) return;
		// Return if targeted param is already being reset
		for (const item of singleParamsBeingReset) {
			if (i == item) return;
		}
		// Keep track of params that are resetting by adding to array
		singleParamsBeingReset.push(i);
		console.log("singleParamsBeingReset", singleParamsBeingReset);
		// Automate targeted param to reset state by cloning playback params and setting targeted param and those being reset to their reset values within the cloned params. This will freeze current params in place as the targeted param resets.
		const singleParamReset = {...playbackParams};
		// Set targeted param and those being reset to their reset values
		Object.keys(singleParamReset).forEach((key, index) => {
			singleParamsBeingReset.forEach(item => {
				if (item == index) singleParamReset[key] = resetParams[key];
			})
		});
		console.log("singleParamReset", singleParamReset);
		// Init automate params
		await initAutoParams({params: singleParamReset, automateInstantly: false, resetSingle: true});
		// Remove param from array after it has been reset
		const paramIndex = singleParamsBeingReset.indexOf(i);
		singleParamsBeingReset.splice(paramIndex, 1);
		console.log("singleParamsBeingReset", singleParamsBeingReset);
		// Skip hard resets if a set of params is selected before single param reset finishes
		if (paramsSelected) return;
		// Hard reset frame rate to round out info data in playback params. This does not reset the actual frame rate, just the display on dat.GUI.
		Object.keys(playbackParams).forEach((key, index) => {
			if (i != 1) return;
			if (index == 1) playbackParams[key] = resetParams[key];
		});
		// Hard reset filter
		Object.keys(playbackParams).forEach((key, index) => {
			if (i < 3) return;
			if (i == index) effects.initFilterValues({filterToReset: key})
		});
	}

	function resetKeyframeStatusChecked(){
		keyframeStatusChecked = false;
		console.log("keyframeStatusChecked", keyframeStatusChecked);
	}

	function resetKeyframes({fromResetSingle = false} = {}){
		if (!fromResetSingle) keyframes.reset();
		reset = true;
		console.log("reset", reset);
		// Set current params to playback params
		if (freezeAutoParamsOnRemixDataInput && !fromResetSingle) currentParams = playbackParams;
		keyframeStatusChecked = true;
		console.log("keyframeStatusChecked", keyframeStatusChecked);
	}

	// Copy link
	/*-------------------------------*/

	function copyLink({returnFullURL, returnBaseURL}){
		return new Promise(async (resolve,reject) => {
			// Get saved and current keyframes
			const currentKeyframes = keyframes.getKeyframeList();
			// Check if current keyframes exist
			const currentKeyframesExist = currentKeyframes.length > 0;
			console.log("currentKeyframesExist", currentKeyframesExist);
			// If current keyframes exist, append current keyframes to URL. If not, append the media ID only.
			let stringifiedParams;
			if (currentKeyframesExist) {
				// Generate param string array of current keyframes and append to copied URL
				const paramStringArray = await keyframes.getParamStringArray({keyframes:currentKeyframes});
				console.log("paramStringArray", paramStringArray);
				// Return if no keyframes
				if (paramStringArray.length < 1) return reject("No keyframes found");
				stringifiedParams = queryString.stringify({p: paramStringArray}, {arrayFormat: 'bracket'});
				console.log("stringifiedParams", stringifiedParams);
			}
			// If user owns post, update the post with current keyframes
			//if (states.userOwnsPost) keyframes.updatePost();
			// Construct base URL from root URL and post route
			const baseURL = new URL(`/${states.mediaData.mediaId}`, rootURL);
			// Construct URL string
			const urlString = !currentKeyframesExist ? `${baseURL}` : `${baseURL}?${stringifiedParams}`;
			//console.log("urlString", urlString);
			// Return links
			if (returnBaseURL) return resolve(`${baseURL}`);
			if (returnFullURL) return resolve(urlString);
			// Check if copy to clipboard was successful (clipboard-copy returns a promise)
			try {
				await copy(urlString);
				console.log("Copied successfuly", urlString);
				resolve(urlString);
			} catch (err){
				//console.log(err);
				reject(err);
			}
		});
	}

	// Get
	/*-------------------------------*/

	function getRoundedPlaybackParams(){
		return new Promise(async (resolve) => {
			const obj = {};
			for (let key in playbackParams) {
				// Skip framing
				if (key === "framing") continue;
				// Round speed to nearest thousandth and everything else to nearest hundredth
				const roundParamValue = key === "speed" ? 10000 : 100;
				let paramRound = Math.round(roundParamValue*playbackParams[key])/roundParamValue;
				// Edge case: Force brightness threshold if target value is on edge of 0 for inversion. Works in conjunction with changeEffect function in images_effects component.
				if (key === "brightness" && playbackParams[key] > -0.01 && playbackParams[key] < 0) paramRound = -0.01;
				if (key === "brightness" && playbackParams[key] < 0.01 && playbackParams[key] > 0) paramRound = 0;
				// If frame rate, round value from reset params to check if change was made
				if (key == "frameRate" && paramRound != Math.round(roundParamValue*resetParams[key])/roundParamValue) obj[key] = paramRound;
				if (key != "frameRate" && paramRound != resetParams[key]) obj[key] = paramRound;
			}
			resolve(obj);
		});
	}

	function getReset(){
		return reset;
	}

	function getImagesLoaded(){
		return imagesLoaded;
	}

	function getHasUrlParams(){
		return hasUrlParams;
	}

	function getUrlParamsArray(){
		return urlParamsArray;
	}

	function getParamsRanges(){
		return paramsRanges;
	}

	function getResetParams(){
		return resetParams;
	}

	function getImagesHeight(){
		return $el.height();
	}

	// Set
	/*-------------------------------*/

	function setReset(data){
		reset = data;
	}

	return {
		$el: $el,
		init: init,
		rotateDial: rotateDial,
		startPlayback: startPlayback,
		stopPlayback: stopPlayback,
		togglePlayback: togglePlayback,
		setMaxPlaybackSpeed: setMaxPlaybackSpeed,
		resetImages: resetImages,
		resetImagePosition: resetImagePosition,
		getImagesHeight: getImagesHeight,
		resetParamsInstantly: resetParamsInstantly,
		copyLink: copyLink,
		getReset: getReset,
		initAutoParams: initAutoParams,
		resetParam: resetParam,
		resetParamsGradually: resetParamsGradually,
		setReset: setReset,
		resetKeyframeStatusChecked: resetKeyframeStatusChecked,
		getImagesLoaded: getImagesLoaded,
		getHasUrlParams: getHasUrlParams,
		getUrlParamsArray: getUrlParamsArray,
		getRoundedPlaybackParams: getRoundedPlaybackParams,
		getParamsRanges: getParamsRanges,
		getResetParams: getResetParams,
		frameRateReset: frameRateReset,
		setupDatGUI: setupDatGUI,
		updateCursor: updateCursor
	};

})(); //images

export {images};