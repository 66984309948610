const QRCode = require("@keeex/qrcodejs-kx");
import {rootURL} from '../config.js';
import {states} from '../states.js';
import {options} from '../options.js';

// QR code modal
const qrCodeModal = (function(){

	//states
	let qrCodeMade = false;

	//data
	let qrCodeModalGenerate;
	const qrCodeBaseURL = `${rootURL}/mobile`;
	const authQrCodeBaseURL = `${rootURL}/mobile-auth`;
	const socketCodeSearchParamKey = "sc";
	const qrCodeCameraLinkURL = `${rootURL}/camera`;

	//options
	const qrCodeOptions = {
		width: 250,
		height: 250,
		colorDark : "#000000",
		colorLight : "#ffffff",
		correctLevel : QRCode.CorrectLevel.H
	}

	//cache DOM
	const $el = $('#qrcode');
	const el = $el[0];
	// Note: cached outside component
	const $qrCodecameraLink = $('#qrcode-camera-link');
	const qrCodecameraLink = $qrCodecameraLink[0]

	// Note: called in create mode during initMedia
	function initQrCodeCameraLink(){
		// Init QR code generator
		const qrCodeCameraLinkModalGenerate = new QRCode(qrCodecameraLink, qrCodeOptions);
		// Make QR Code
		qrCodeCameraLinkModalGenerate.makeCode(qrCodeCameraLinkURL);
	}

	// Note: called after connectToRoom socket event
	function initQrCode(data){
		if (qrCodeMade) return;
		// Init QR code generator
		qrCodeModalGenerate = new QRCode(el, qrCodeOptions);
		// Make QR Code
		makeQrCode(data);
		// Set local state
		qrCodeMade = true;
		console.log("qrCodeMade", qrCodeMade);
	}

	function makeQrCode(data) {
		// If user is authenticated, use mobile remote auth URL
		if (states.auth) return qrCodeModalGenerate.makeCode(authQrCodeBaseURL);
		if (options.newSocketRoomOnRemoteDisconnect){
			// Find and hide QR code image
			$el.find('img').hide();
			// Clear QR code
			clearQrCode();
		}
		// Make QR code
		const searchParams = new URLSearchParams();
		searchParams.append(socketCodeSearchParamKey, data);
		const paramsString = searchParams.toString();
		const urlString = `${qrCodeBaseURL}?${paramsString}`;
		qrCodeModalGenerate.makeCode(urlString);
	}

	function clearQrCode() {
		// Clear QR code
		qrCodeModalGenerate.clear();
	}

	function getQrCodeMade(){
		return qrCodeMade;
	}

	return {
		initQrCode: initQrCode,
		initQrCodeCameraLink: initQrCodeCameraLink,
		getQrCodeMade: getQrCodeMade
	};

})(); //qrCodeModal

export {qrCodeModal};