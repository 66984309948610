import {callFetch} from './fetchAPI';

// Post requests
const postRequests = (function(){

	// Get
	/*-------------------------------*/

	// Get post by ID based on public link property set by user
	async function getPost(id) {
		// URL
		const fetchURL = `/posts/${id}`;
		try{
			const response = await fetch(fetchURL);
			if (response.status == 200){
				return {success: true, data: await response.json()};
			} else {
				return {success: false, status: response.status};
			}
		}catch(err){
			console.error(err);
			return {success: false, status: null};
		}
	}

	// Get private user post
	function getPrivatePost(id) {
		return callFetch({
			url: `/private-posts/${id}`,
			status: 200
		})
	}

	// Get post for export
	function getPostForExport(id) {
		return callFetch({
			url: `/export-posts/${id}`,
			status: 200
		})
	}

	// Get user posts
	function getUserPosts({page}) {
		return callFetch({
			url: `/posts/user/${page}`,
			status: 200
		})
	}

	// Get posts by category
	function getPostsByCategory(category) {
		return callFetch({
			url: `/posts/category/${category}`,
			status: 200
		})
	}

	// Get public gallery approve posts
	function getPublicGalleryApprovePosts() {
		return callFetch({
			url: `/posts/public-gallery-approve`,
			status: 200
		})
	}

	// Create
	/*-------------------------------*/

	// Create post
	function createPost(obj) {
		return callFetch({
			url: '/posts',
			method: 'POST',
			data: obj,
			status: 201
		})
	}

	// Update
	/*-------------------------------*/

	// Update post
	function updatePost(id, obj) {
		return callFetch({
			url: `/posts/${id}`,
			method: 'PATCH',
			data: obj,
			status: 200
		})
	}

	// Delete
	/*-------------------------------*/

	// Delete post
	function deletePost(id) {
		return callFetch({
			url: `/posts/${id}`,
			method: 'DELETE',
			status: 200
		})
	}

	// Admin
	/*-------------------------------*/

	// Admin get public gallery posts
	function adminGetPublicGalleryPosts() {
		return callFetch({
			url: `/posts/admin/public-gallery`,
			status: 200
		})
	}

	// Admin update post
	function adminUpdatePost(id, obj) {
		return callFetch({
			url: `/posts/admin/${id}`,
			method: 'PATCH',
			data: obj,
			status: 200
		})
	}

	return {
		getPost: getPost,
		getPrivatePost: getPrivatePost,
		getPostForExport: getPostForExport,
		getUserPosts: getUserPosts,
		getPostsByCategory: getPostsByCategory,
		getPublicGalleryApprovePosts: getPublicGalleryApprovePosts,
		createPost: createPost,
		updatePost: updatePost,
		deletePost: deletePost,
		adminGetPublicGalleryPosts: adminGetPublicGalleryPosts,
		adminUpdatePost: adminUpdatePost
	};

})(); //postRequests

export {postRequests};