import * as dat from 'dat.gui';
import {
	remoteOptions,
	playbackParams,
	setupProjectorVisualInfo,
	setupWheelVisualInfo,
	setupAudioInfo,
	setupMediaInfo,
	visualInfoParams,
	audioInfoParams,
	mediaInfoParams
} from '../database.js';
import {states} from '../states.js';
import {delay} from '../shared/util.js';

// datGUI
const datGUI = (function(){

	//states
	let datGuiOpen = false;
	let datGuiLoaded = false;

	//data
	let gui;

	//options
	const startOpen = false;
	const startFoldersOpen = true;
	const showCloseButton = false;

	//cache DOM
	const $el = $('#dat-gui');
	const el = $el[0];
	let $closeButton;
	let $ul;

	function cacheDom(){
		$closeButton = $el.find('div.close-button');
		$ul = $el.find('ul').first();
	}

	//bind events
	function bindEvents(){
		$closeButton.on('click', toggle);
		// Hide close button
		if (!showCloseButton) $closeButton.css("display", "none");
	}
	
	function init({uploadAudio} = {}){
		// Init dat.GUI
		gui = new dat.GUI({
			width: 250,
			closed: true,
			autoPlace: false,
			closeOnTop: false,
			hideable: false
		});
		// Append to DOM element for custom positioning
		el.appendChild(gui.domElement);
		// Add folder / nested GUI
		const f1 = gui.addFolder('Playback parameters');
		const f2 = gui.addFolder('Visual info');
		const f3 = states.mediaHasAudio ? gui.addFolder('Audio info') : "";
		const f4 = gui.addFolder('Media info');
		// Populate folder 1
		remoteOptions.forEach((obj, index) => {
			// Add element to object
			if (!uploadAudio) playbackParams[obj.id] = obj.value;
			// Append symbol type to parameter title except frame rate
			const title = obj.title == "Frame rate" ? `${obj.title}` : `${obj.title} ${obj.type}`;
			// Add controller to folder
			const controller = f1.add(playbackParams, obj.id, obj.minValue, obj.maxValue).step(0.01).listen().name(title);
			// Disable input
			controller.domElement.style.pointerEvents = "none";
		});
		// Populate folder 2
		const setupArrayUse = states.fantascope ? setupWheelVisualInfo : setupProjectorVisualInfo;
		setupArrayUse.forEach((obj, index) => {
			// Add element to object
			visualInfoParams[obj.id] = obj.value;
			// Add controller to folder
			// If in create mode, remove range from current frame controller because the max value keeps changing as new images are created
			const controller = states.createMode && obj.id == "currentFrame"
			? f2.add(visualInfoParams, obj.id).step(0.1).listen().name(obj.title)
			: f2.add(visualInfoParams, obj.id, obj.minValue, obj.maxValue).step(0.1).listen().name(obj.title);
			// Disable input
			controller.domElement.style.pointerEvents = "none";
		});
		// Populate folder 3
		if (states.mediaHasAudio){
			setupAudioInfo.forEach((obj, index) => {
				// Add element to object
				audioInfoParams[obj.id] = obj.value;
				// Add controller to folder
				const controller = f3.add(audioInfoParams, obj.id, obj.minValue, obj.maxValue).step(0.1).listen().name(obj.title);
				// Disable input
				controller.domElement.style.pointerEvents = "none";
			});
		}
		// Populate folder 4
		setupMediaInfo.forEach((obj, index) => {
			// Add element to object
			mediaInfoParams[obj.id] = obj.value;
			// Filter media info for fantascope
			const wheelFilter = [
				"title",
				"year",
				"frameRate"
			]
			const addController = states.fantascope ? wheelFilter.includes(obj.id) : true;
			if (addController){
				// Add controller to folder
				const controller = f4.add(mediaInfoParams, obj.id).listen().name(obj.title);
				// Disable input
				controller.domElement.style.pointerEvents = "none";
				// Target container element for radio checkbox and disable input
				$(controller.domElement).closest('.cr.boolean').css( "pointerEvents", "none" );
			}
		});
		// Open folder
		if (startFoldersOpen){
			f1.open();
			f2.open();
			if (states.mediaHasAudio) f3.open();
			f4.open();
		}
		// Hide GUI
		hide();
		// Cache DOM
		cacheDom();
		// Bind events
		bindEvents();
		// Set local state
		datGuiLoaded = true;
		console.log("datGuiLoaded", datGuiLoaded);
		// Open or close GUI
		!startOpen ? close() : open();
	}

	function checkClosed(){
		// Note: temp way to check if dat.GUI panel is open or closed, better solution is to listen for panel open and close events
		const isClosed = $ul.hasClass("closed");
		console.log("isClosed", isClosed);
		return isClosed;
	}

	async function open(){
		if (!datGuiLoaded) return;
		gui.open();
		// Set local state
		datGuiOpen = true;
		console.log("datGuiOpen", datGuiOpen);
		// Add class
		$el.addClass('open');
	}

	async function close(){
		if (!datGuiLoaded) return;
		// Remove class
		$el.removeClass('open');
		// Set local state
		datGuiOpen = false;
		console.log("datGuiOpen", datGuiOpen);
		await delay(300);
		gui.close();
	}

 	async function toggle(){
 		if (!datGuiLoaded) return;
 		const isClosed = checkClosed();
		console.log("isClosed", isClosed);
		!isClosed ? open() : close();
	}

	function show(){
		gui.show();
	}

	function hide(){
		gui.hide();
	}

	function destroy(){
		gui.destroy();
		$(".dg.main").remove();
		// Set local state
		datGuiLoaded = false;
		console.log("datGuiLoaded", datGuiLoaded);
	}

	return {
		init: init,
		show: show,
		hide: hide,
		open: open,
		close: close,
		destroy: destroy
	};

})(); //datGUI

export {datGUI};