import {S3_PATH, S3_PATH_UPLOAD} from '../config.js';
import {states} from '../states.js';
import {options} from '../options.js';
import {loader} from './loader.js';

// Projector images generate
const generate = (function(){

	//data
	let mediaPath,
		firstFrame,
		lastFrame,
		mediaFPS,
		progressRoundPrevious,
		generateImagesTimer,
		promiseResolve;

	//options
	const shutterImageFileName = "black";
	const shutterImageMediaPath = `${S3_PATH}/media/shutter/`;

	//cache DOM
	const $el = $('#images');
	const el = $('#images')[0];

	function init(){
		return new Promise(resolve => {
			// Switch image path for uploaded films with high resolution images
			const imagePathUse = states.mediaData.mediaUpload && !states.fantascope && states.exportMode && states.exportSettings.highRes ? options.imagesHighResFolderName : options.imagesFolderName;
			// Set media path
			mediaPath = states.mediaData.exploreGallery
			? `${S3_PATH}/media/gallery/${states.mediaData.mediaId}/${options.imagesFolderName}/`
			: `${S3_PATH_UPLOAD}/${states.mediaData.mediaId}/${imagePathUse}/`;
			firstFrame = states.mediaData.firstFrame; // Must be 1 for audio to sync
			lastFrame = states.mediaData.lastFrame;
			mediaFPS = states.mediaData.fps;
			// Generate images
			generateImages();
			// Define external promise
			promiseResolve = resolve;
		});
	}

	// Source: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
	function createImage(data, counter) {
		const newImage = new Image();
		// In export audio mode, load null image placeholder for audio stream recording
		newImage.src = states.exportAudioMode ? `${shutterImageMediaPath}${shutterImageFileName}.jpg` : `${mediaPath}${data}.jpg`;
		newImage.id = `img${counter}`;
		if (counter == lastFrame*options.imageGenerationCylces) newImage.className = "lastImage";
		newImage.setAttribute("onerror", `this.onerror=null;this.src='${shutterImageMediaPath}${shutterImageFileName}.jpg'`);
		el.appendChild(newImage);
	}

	// Create image elements from shutterImageMediaPath
	function createShutterImage() {
		const newImage = new Image();
		newImage.src = `${shutterImageMediaPath}${shutterImageFileName}.jpg`;
		el.appendChild(newImage);
	}

	// Option 1
	function generateImages() {
		let counter = firstFrame;
		let i = firstFrame;
		generateImagesTimer = setInterval(next, 5);
		loader.enableCancelButton();
		async function next() {
			if (counter > lastFrame*options.imageGenerationCylces) {
				// Stop timer
				clearInterval(generateImagesTimer);
				// Wait for last frame in DOM
				await waitUntilExists(".lastImage");
				// Resolve promise
				promiseResolve();
			} else if (i > lastFrame) {
				i = firstFrame;
			} else {
				// Create image
				createImage(i, counter);
				// Create shutter image
				if (options.shutterImage && !states.fantascope) createShutterImage();
				// Calculate progress
				calcProgress(counter);
				// Advance frame
				i++;
				// Advance counter
				counter++;
				//console.log("counter", counter);
			}
		}
	}

	// Option 2
	// Note: does not work with cancel button
	async function generateImagesForLoop(){
		let counter = firstFrame;
		// Generate images based on options.imageGenerationCylces
		for (let cycles_i = 0; cycles_i < options.imageGenerationCylces; cycles_i++) {
			for (let i = firstFrame; i <= lastFrame; i++) {
				// Create image
				createImage(i, counter);
				// Create shutter image
				if (options.shutterImage && !states.fantascope) createShutterImage();
				// Calculate progress
				calcProgress(counter);
				// Advance counter
				counter++;
				//console.log("counter", counter);
			}
		}
		// Wait for last frame in DOM
		await waitUntilExists(".lastImage");
		// Resolve promise
		promiseResolve();
	}

	function calcProgress(data){
		const progress = (data / (lastFrame*options.imageGenerationCylces)) * 100;
		const progressRound = Math.round(progress);
		if (progressRound == progressRoundPrevious) return;
		progressRoundPrevious = progressRound;
		// Update loader message
		loader.updateUI(progressRound);
	}

	// Source: https://stackoverflow.com/questions/16149431/make-function-wait-until-element-exists/16149679
	function waitUntilExists(elm) {
		return new Promise(async resolve => {
			while(!document.querySelector(elm)) {
				await new Promise(r => setTimeout(r, 500));
			}
			resolve();
		})
	}

	function stopTimer(){
		clearInterval(generateImagesTimer);
	}

	return {
		init: init,
		stopTimer: stopTimer
	};

})(); //generate

export {generate};