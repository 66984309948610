async function callFetch(obj){
	const {url, method = 'GET', data, status} = obj;
	// URL
	const fetchURL = url;
	// Fetch function parameters
	const fetchData = {
		method: method,
		headers: {
			'Content-Type': 'application/json',
		}
	}
	// Add body to params
	if (data) fetchData.body = JSON.stringify(data);
	try{
		const response = await fetch(fetchURL, fetchData);
		if (response.status == status){
			return await response.json();
		} else {
			return false;
		}
	}catch(err){
		console.error(err);
		return false;
	}
}

export {callFetch}