import axios from "axios";
import {states} from '../states.js';
import {options} from '../options.js';
import {delay, getPercentage, megabytesToGigabytes} from './util.js';
import {menuButton} from './menuButton.js';

const menu = (function(){

	//states
	let animating = false;
	let postPage = false;
	let leavePageConfimed = false;

	//data
	const pageId = states.pageId

	//options
	const contactFormLink = "https://forms.gle/tZ9tirSjfbw5G31W7";
	const guideLink = "https://docs.spoolyard.com";
	const leavePageConfirmMessage = "Leave site? Changes you made may not be saved.";

	//cache DOM
	const $el = $("#menu");
	const $title = $el.find('#title h1');
	const $links = $el.find('ul li');
	const $userStoragePercentage = $el.find('#user-storage-percentage');
	const $userStorageMessage = $el.find('#user-storage-message');
	const $userStorageBar = $el.find('#user-storage-bar');
	const $getMoreStorageButton = $el.find('#get-more-storage-button');
	// Note: cached outside component
	// Note: Elements with transform act as a containing block for fixed position descendants. On post page, transform is applied directly to fixed elements. On non-post pages, transform is applied to a non-fixed container.
	// Source: https://stackoverflow.com/questions/2637058/positions-fixed-doesnt-work-when-using-webkit-transform
	const $menuOverlay = $("#menu-overlay");
	const $postAppContent = $("#keyframes, #side-panel, #top-panel, #controls, #images-container, #waveform, #messages, #background-color, #menu-overlay, #opaque-background, #menu-button, #center-modal, .top-modal");
	const $nonPostAppContent = $("#non-post-app-content");
	const $centerIframe = $('#center-iframe');
	const $imagesContainer = $('#images-container');
	const $images = $('#images');

	//bind events
	$links.on('click', goToLink);
	$title.on('click', () => goToLink({title: true}));
	$menuOverlay.on('click', hide);

	function init(){
		// Set local state
		postPage = pageId == "post" || pageId == "create" ? true : false;
		console.log("postPage", postPage);
		// Change layer order or hide menu button
		states.screenMode ? menuButton.hide() : menuButton.above();
		// Update user storage UI
		if (states.auth) updateStorageUI()
	}

	async function show(){
		if (animating) return;
		animating = true;
		console.log("animating", animating);
		states.menuOpen = true;
		console.log("states.menuOpen", states.menuOpen);
		menuButton.updateUI();
		$el.addClass('show');
		$el.removeClass('behind');
		$menuOverlay.addClass('show');
		postPage ? $postAppContent.addClass('menu-slide') : $nonPostAppContent.addClass('menu-slide');
		// If image has been moved to the left, offset with image container so it won't obstruct menu
		if (postPage && states.fantascope){
			const imagesRect = $images[0].getBoundingClientRect();
			if (imagesRect.left < 0) $imagesContainer.css('left', `${Math.abs(imagesRect.left)}px`);
		}
		if (pageId == "exports") $centerIframe.addClass('menu-slide');
		await delay(300);
		animating = false;
		console.log("animating", animating);
	}

	async function hide({fromLink} = {}){
		if (animating) return;
		animating = true;
		console.log("animating", animating);
		$el.addClass('behind');
		fromLink ? $menuOverlay.addClass('show-full') : $menuOverlay.removeClass('show');
		postPage ? $postAppContent.removeClass('menu-slide') : $nonPostAppContent.removeClass('menu-slide');
		// Reset image container position
		if (postPage && states.fantascope) $imagesContainer.css('left', '0px');
		if (pageId == "exports") $centerIframe.removeClass('menu-slide');
		states.menuOpen = false;
		console.log("states.menuOpen", states.menuOpen);
		menuButton.updateUI();
		await delay(300);
		$el.removeClass('show');
		animating = false;
		console.log("animating", animating);
	}

	function toggle(){
		if (animating) return;
		states.menuOpen = !states.menuOpen;
		console.log("states.menuOpen", states.menuOpen);
		menuButton.updateUI();
		states.menuOpen ? show() : hide();
	}

	async function goToLink({title} = {}){
		if (title && !animating) return window.location.href = `/explore`;
		if ($(this).hasClass("active") && !$(this).hasClass("enable-click")) return;
		// If post page, confirm user decision to leave page if project is not saved and user changed keyframes
		if (postPage && states.userOwnsPost && !states.saved && states.userChangedKeyframes){
			const response = confirm(leavePageConfirmMessage);
			if (!response) return;
			leavePageConfimed = true;
			console.log("leavePageConfimed", leavePageConfimed);
		}
		let link = $(this).attr('data-link');
		if (link == "contact") return goToLinkNewTab(contactFormLink);
		if (link == "guide") return goToLinkNewTab(guideLink);
		// Event called from cookie consent module
		if (link == "cookies") return;
		hide({fromLink: true});
		await delay(500);
		window.location.href = `/${link}`;
	}

	function goToLinkNewTab(link){
		// Source: https://stackoverflow.com/questions/5141910/javascript-location-href-to-open-in-new-window-tab/5141926
		let a = document.createElement('a');
		a.target = '_blank';
		a.href = link;
		a.click();
	}

	function destroy({menuOnly} = {}){
		$el.hide();
		if (!menuOnly) $nonPostAppContent.hide();
		menuButton.hide();
	}

	function getLeavePageConfimed(){
		return leavePageConfimed;
	}

	async function updateStorageUI(){
		const response = await axios.get(`/user-storage`)
		//console.log("response.data", response.data.total)
		const currentStorage = response.data.total
		states.currentStorage = currentStorage
		console.log("states.currentStorage", states.currentStorage)
		const maxStorage = states.proUser ? options.maxStoragePro : options.maxStorage
		const percentage = Math.ceil(getPercentage(currentStorage, maxStorage))
		$userStoragePercentage.html(`Storage (${percentage}% full)`)
		let storageBarPercentageDisplay = Math.min(percentage, 100);
		// Set min storage bar display to 6 percent if not 0 to fix rendering bug
		if (storageBarPercentageDisplay > 0 && storageBarPercentageDisplay < 6) storageBarPercentageDisplay = 6
		$userStorageBar.css('width', `${storageBarPercentageDisplay}%`)
		if (currentStorage > maxStorage) $userStorageBar.addClass('!bg-errorYellow')
		const currentStorageDisplayValue = currentStorage >= 1000 ? megabytesToGigabytes(currentStorage).toFixed(2) : Math.ceil(currentStorage)
		const currentStorageDisplayUnit = currentStorage >= 1000 ? 'GB' : 'MB'
		const maxStorageDisplayValue = maxStorage >= 1000 ? megabytesToGigabytes(maxStorage).toFixed(0) : maxStorage
		const maxStorageDisplayUnit = maxStorage >= 1000 ? 'GB' : 'MB'
		$userStorageMessage.html(`${currentStorageDisplayValue} ${currentStorageDisplayUnit} of ${maxStorageDisplayValue} ${maxStorageDisplayUnit} used`)
		if (states.proUser) $getMoreStorageButton.hide()
	}

	return{
		init: init,
		toggle: toggle,
		destroy: destroy,
		getLeavePageConfimed: getLeavePageConfimed,
		updateStorageUI: updateStorageUI
	}

})();

export {menu};