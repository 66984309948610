// Background color
const backgroundColor = (function(){

	//cache DOM
	const $el = $('#background-color');

	function show(){
		$el.show();
	}

	function changeColor(color){
		$el.css("background-color", color);
	}

	function applyFilter(filters){
		$el.css("filter",
			`
			${filters[0].name}(${Math.abs(filters[0].use)}${filters[0].type})
			${filters[1].name}(${Math.abs(filters[1].use)}${filters[1].type})
			${filters[2].name}(${Math.abs(filters[2].use)}${filters[2].type})
			${filters[3].name}(${Math.abs(filters[3].use)}${filters[3].type})
			${filters[4].name}(${filters[4].use}${filters[4].type})
			`
		);
	}

	return {
		show: show,
		changeColor: changeColor,
		applyFilter: applyFilter
	};

})(); //backgroundColor

export {backgroundColor};