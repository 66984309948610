// Upload requests
const uploadRequests = (function(){

	/*
	Function to carry out the actual PUT request to S3 using the signed request from the app.
	*/
	async function uploadFile({file, signedRequest, url, fileName, mediaId, targetFolder} = {}){
		// URL
		const fetchURL = signedRequest;
		// Data
		const data = file;
		// Fetch function parameters
		let fetchData = {
			method: 'PUT',
			body: data
		}
		try{
			let response = await fetch(fetchURL, fetchData);
			if (response.status == 200){
				return {url: url, mediaId: mediaId, fileName: fileName, targetFolder: targetFolder};
			} else {
				console.log('Could not upload file');
				return false;
			}
		}catch(err){
			console.error(err);
			return false;
		}
	}

	/*
	Function to get the temporary signed request from the app.
	If request successful, continue to upload the file using this signed
	request.
	*/
	async function getSignedRequest({file, api, targetFolder} = {}){
		// URL
		const fetchURL = `/${api}?file-name=${file.name}&file-type=${file.type}&media-id=${file.mediaId}&target-folder=${targetFolder}`;
		try{
			let response = await fetch(fetchURL);
			if (response.status == 200){
				return await response.json();
			} else {
				console.log('Could not get signed URL');
				return false;
			}
		}catch(err){
			console.error(err);
			return false;
		}
	}

	/*
	Function called when file input updated. If there is a file selected, then
	start upload procedure by asking for a signed request from the app.
	*/
	async function initUpload({file, api, targetFolder} = {}){
		if (file == null) return false;
		const response = await getSignedRequest({file: file, api: api, targetFolder: targetFolder});
		if (!response) return false;
		const fileUpload = await uploadFile({
			file: file,
			signedRequest: response.signedRequest,
			url: response.url,
			fileName: response.fileName,
			mediaId: response.mediaId,
			targetFolder: response.targetFolder
		});
		return fileUpload;
	}

	return {
		initUpload: initUpload
	};

})(); //uploadRequests

export {uploadRequests};