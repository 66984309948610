import {
	remoteOptions,
	initArray,
	playbackParams,
	setupProjectorVisualInfo,
	setupAudioInfo,
	visualInfoParams,
	audioInfoParams
} from '../database.js';
import {states} from '../states.js';
import {options} from '../options.js';
import {map, fpsToMilliseconds, getRandomInteger} from '../shared/math.js';
import {delay} from '../shared/util.js';
import {backgroundColor} from './backgroundColor.js';
import {socketEmit} from './socketEmit.js';
import {howlerAudio} from './howlerAudio.js';
import {toneAudio} from './toneAudio.js';
import {controls} from './controls.js';
// Child modules
import {rpPlaybackProjector} from './images_projectorSpeed_recordPlayerPlayback.js';
import {projectorFraming} from './images_projectorFraming.js';

// Projector speed
const projectorSpeed = (function(){

	//states
	let projectorOn = false;
	let projectorReverse = false;
	let projectorHasPlayed = false;
	let inThreshold = false;
	let topOfPage = true;
	let bottomOfPage = false;
	let wasPlaying = false;
	let enableReversePlayback = true;
	let enableForwardPlayback = true;
	let enableReverseLoop = true;
	let enableForwardLoop = true;
	let autoFraming = false;
	let screenModeInitialPlayback = false;

	//data

		// Init
		let scrollSpeed = 0,
			scrollPercent = 0,
			projectorSpeed = 0,
			percentProjectorSpeed = 0;

		// Timeouts
		let scrollPageDownTimeout,
			scrollPageUpTimeout,
			resizeTimeout;

		// Triggers
		let triggerScrollPageDown = true,
			triggerScrollPageUp = true;

		// For background color
		let mediaBackgroundColor;	

		// For FPS
		let fpsUse,
			fpsActual;

		// For sound
		let mediaFPS,
			firstFrame,
			lastFrame,
			currentVisibleImagePrevious,
			soundRateUse,
			soundRateMapped,
			soundLength,
			soundLengthBasedOnMediaFPS,
			soundPositionUse = 0,
			soundPercent = 0,
			soundDrift = 0;

		// For window resize
		let currentVisibleImage,
			currentVisibleImageBeforeResize;

		// For image offset
		let lastImageOffsetUse = 0;
		let firstImageOffsetUse = 0;

	//options

		// Threshold
		let thresholdBetweenDirectionSwitch = true;
		let threshold = thresholdBetweenDirectionSwitch ? 5 : 0;

		// Scroll speed
		let minScrollSpeed = threshold; // Defines threshold trigger range
		let maxScrollSpeed = 500; // Defines the range of minProjectorSpeed to maxProjectorSpeed, inscrease to have a longer ramp

		// Scroll speed sensitivity
		const speedAmountLower = 2; // Multiplied by incoming data to determine speed amount beneath sensitivity threshold
		const speedAmountHigher = 8; // Multiplied by incoming data to determine speed amount above sensitivity threshold
		const speedSensitivityThreshold = 10 // Determines when higher speed sensitivity kicks in based on projector speed percentage

		// Exponential scroll speed
		let exponentialSpeed = options.exponentialProjectorSpeed; // Determines if speed parameters increase exponentially
		const exponentialSpeedThreshold = 10; // Determines when exponential speed kicks in based on projector speed percentage
		const exponentialSpeedAmount = 10; // Determines rate of increase

		// Export scroll speed
		const exportSpeed = 5; // Multiplied by incoming data to determine export speed

		// Projector speed
		let minProjectorSpeed = .001; // Determines scroll amount per movement, or projector event, from scrollPageDown or scrollPageUp
		let maxProjectorSpeed = 1; // Must be 1 for true frame rate at max speed
		const setMaxProjectorSpeedThreshold = .1; // Affects threshold to set max projector speed when phone is lifted

		// Looping images
		const playSoundDelay = 300; // ms
		
		// Sound
		const testSound = false;

	//cache DOM
	const $el = $('#images');
	const el = $('#images')[0];
	let $firstImage;
	let $lastImage;

	function cacheElms(){
		$firstImage = $el.find('#img1');
		$lastImage = $el.find('.lastImage');
	}

	//bind events
	function bindEvents(){
		console.log("Bind events");
		// Note: deviceOrientation input, keyboard commands
		$(window).on('scroll', scroll);
		$(window).on('resize', windowResize);
	}

	// Init
	/*-------------------------------*/

	function init(){
		// Define variables based on media data
		mediaFPS = states.mediaData.fps;
		firstFrame = states.mediaData.firstFrame;
		lastFrame = states.mediaData.lastFrame;
		mediaBackgroundColor = states.mediaData.bgcolor || options.defaultBackgroundColor;
		//console.log("mediaBackgroundColor", mediaBackgroundColor);
		// Get record player playback settings
		if (states.recordPlayerMode) rpPlaybackProjector.init();
		// Setup dat.GUI
		setupDatGUI();
	}

	function setupDatGUI(){
		// Setup dat.GUI array with initial values
		initArray({
			array: remoteOptions,
			id: "speed",
			minValue: -100,
			maxValue: 100,
			value: projectorSpeed
		});
		initArray({
			array: setupProjectorVisualInfo,
			id: "scrollPercent",
			minValue: 0,
			maxValue: 100,
			value: scrollPercent
		});
		initArray({
			array: setupProjectorVisualInfo,
			id: "currentFrame",
			minValue: firstFrame,
			maxValue: lastFrame*options.imageGenerationCylces,
			value: firstFrame
		});
		initArray({
			array: setupAudioInfo,
			id: "audioPercent",
			minValue: 0,
			maxValue: 100,
			value: soundPercent
		});
		initArray({
			array: setupAudioInfo,
			id: "audioDrift",
			minValue: -10,
			maxValue: 10,
			value: soundDrift
		});
	}

	function initProjector(){
		return new Promise(async (resolve, reject) => {
			// Cache elements
			cacheElms();
			// Init sound
			if (states.mediaHasAudio || options.projectorSoundEffects){
				const p1 = howlerAudio.init();
				const p2 = toneAudio.init();
				try {
					let loadAudioResults = await Promise.all([p1, p2]);
					console.log("loadAudioResults", loadAudioResults);
				} catch(e){
					console.error(e);
				}
				setSoundReady();
			}
			if (!states.mediaHasAudio){
				// If media does not have audio, set soundReady to true
				states.soundReady = true;
				console.log("states.soundReady", states.soundReady);
			}
			// Set background color
			backgroundColor.changeColor(mediaBackgroundColor);
			backgroundColor.show();
			// Bind events
			bindEvents();
			// Resolve
			resolve();
		});
	}

	function setRpPlaybackSettings(data){
		thresholdBetweenDirectionSwitch = data.thresholdBetweenDirectionSwitch;
		threshold = data.threshold;
		minScrollSpeed = data.minScrollSpeed;
		maxScrollSpeed = data.maxScrollSpeed;
		minProjectorSpeed = data.minProjectorSpeed;
		maxProjectorSpeed = data.maxProjectorSpeed;
	}

	function setThresholdsForAutomatedPlaybackOrCreateMode({autoPlayback = false} = {}){
		// Lower threshold between direction switch for automated playback
		if (autoPlayback){
			threshold = 2;
			//console.log("threshold", threshold);
			minScrollSpeed = threshold;
		}
	}

	function resetThreshold(){
		threshold = thresholdBetweenDirectionSwitch ? 5 : 0;
		//console.log("threshold", threshold);
		minScrollSpeed = threshold;
	}

	function setLastframe(){
		lastFrame = states.lastFrame;
	}

	// Projector playback
	/*-------------------------------*/

	// Set fps
	function setFps(data){
		fpsUse = data;
		// Update datGUI params
		if (!states.mediaHasAudio) getFpsActual();
	}

	// Set scroll speed
	function setScrollSpeed(data){
		scrollSpeed = data;
	}

	// Scroll down window height divided by projectorSpeed at interval of fpsUse milliseconds
	function scrollPageDown() {
		window.scrollBy(0, window.innerHeight*projectorSpeed);
		scrollPageDownTimeout = setTimeout(scrollPageDown, fpsToMilliseconds(fpsUse));
	}
	
	// Scroll up window height divided by projectorSpeed at interval of fpsUse milliseconds
	function scrollPageUp() {
		window.scrollBy(0, -window.innerHeight*projectorSpeed);
		scrollPageUpTimeout = setTimeout(scrollPageUp, fpsToMilliseconds(fpsUse));
	}

	function changeSpeed(data){
		// Exit if near top or bottom of page
		if (data < 0 && !enableReversePlayback) return socketEmit.sendFlash();
		if (data > 0 && !enableForwardPlayback) return socketEmit.sendFlash();
		// Set scrollSpeed
		if (states.recordPlayerMode) scrollSpeed = data;
		// Note: scrollSpeed increment must be consistent during export for audio sync
		else if (states.exportMode) scrollSpeed += data*exportSpeed;
		else {
			// Set scroll speed sensitivity
			if (exponentialSpeed){
				scrollSpeed += Math.abs(percentProjectorSpeed) < exponentialSpeedThreshold
				? data
				: data*Math.abs(percentProjectorSpeed/exponentialSpeedAmount);
			} else {
				scrollSpeed += Math.abs(percentProjectorSpeed) < speedSensitivityThreshold
				? data*speedAmountLower
				: data*speedAmountHigher;
			}
		}
		//console.log("scrollSpeed", scrollSpeed);
		// Lock record player midpoint speed if within range
		if (states.recordPlayerMode){
			rpPlaybackProjector.checkRpMidpointSpeed(scrollSpeed);
			//rpPlaybackProjector.detectPitchThreshold(scrollSpeed);
		}
		// Threshold
		if (scrollSpeed > -threshold && scrollSpeed < threshold){
			if (!thresholdBetweenDirectionSwitch) return;
			// Gradual or linear acceleration of projector speed
			states.recordPlayerMode ? window.scrollBy(0, -scrollSpeed) : window.scrollBy(0, -data);
			// Reset triggers
			triggerScrollPageUp = true;
			triggerScrollPageDown = true;
			//console.log("triggerScrollPageUp", triggerScrollPageUp);
			//console.log("triggerScrollPageDown", triggerScrollPageDown);
			// Stop projector
			stopProjector();
			// Set state
			inThreshold = true;
			//console.log("inThreshold", inThreshold);
			// Update datGUI params
			playbackParams.speed = 0;
			// Update param info
			controls.updateParamInfo();
			return;
		}
		// Forward
		if (scrollSpeed > threshold){
			// If at bottom of page and forward loop is false, reset scrollSpeed
			if (bottomOfPage && !enableForwardLoop) return resetScrollSpeed();
			// Limit scrollSpeed
			if (scrollSpeed > maxScrollSpeed) {
				scrollSpeed = maxScrollSpeed;
				socketEmit.sendFlash();
			}
			// Map scrollSpeed to projector speed
			projectorSpeed = map(scrollSpeed, minScrollSpeed, maxScrollSpeed, minProjectorSpeed, maxProjectorSpeed);
			//console.log("projectorSpeed", projectorSpeed);
			// Map projectorSpeed to percent
			percentProjectorSpeed = map(projectorSpeed, minProjectorSpeed, maxProjectorSpeed, 0, 100);
			// Constrain speed param
			constrainPercentProjectorSpeed()
			playbackParams.speed = percentProjectorSpeed
			// Update param info
			controls.updateParamInfo();
			// Turn on projector
			if (triggerScrollPageDown){
				triggerScrollPageDown = false;
				console.log("triggerScrollPageDown", triggerScrollPageDown);
				scrollPageDown();
				if (!thresholdBetweenDirectionSwitch && projectorHasPlayed){
					clearTimeout(scrollPageUpTimeout);
					triggerScrollPageUp = true;
					console.log("triggerScrollPageUp", triggerScrollPageUp);
					// Stop sound
					stopSound();
				}
				// Set state
				projectorOn = true;
				console.log("projectorOn", projectorOn);
				projectorReverse = false;
				console.log("projectorReverse", projectorReverse);
				inThreshold = false;
				console.log("inThreshold", inThreshold);
				projectorHasPlayed = true;
				console.log("projectorHasPlayed", projectorHasPlayed);
				// Play sound
				playSound();
				// Play sound effects
				playSoundEffects();
			}
			// Set sound rate
			setSoundRate();
			// Update datGUI params
			if (!states.mediaHasAudio) getFpsActual();
			return;
		}
		// Reverse
		if (scrollSpeed < -threshold){
			// If at top of page and reverse loop is false, reset scrollSpeed
			if (topOfPage && !enableReverseLoop) return resetScrollSpeed();
			// Limit scrollSpeed
			if (scrollSpeed < -maxScrollSpeed) {
				scrollSpeed = -maxScrollSpeed;
				socketEmit.sendFlash();
			}
			// Map scrollSpeed to projector speed
			projectorSpeed = map(scrollSpeed, -minScrollSpeed, -maxScrollSpeed, minProjectorSpeed, maxProjectorSpeed);
			//console.log("projectorSpeed", projectorSpeed);
			// Map projectorSpeed to percent
			percentProjectorSpeed = map(projectorSpeed, minProjectorSpeed, maxProjectorSpeed, 0, -100);
			// Constrain speed param
			constrainPercentProjectorSpeed()
			playbackParams.speed = percentProjectorSpeed
			// Update param info
			controls.updateParamInfo();
			// Turn on projector
			if (triggerScrollPageUp){
				triggerScrollPageUp = false;
				console.log("triggerScrollPageUp", triggerScrollPageUp);
				scrollPageUp();
				if (!thresholdBetweenDirectionSwitch && projectorHasPlayed){
					clearTimeout(scrollPageDownTimeout);
					triggerScrollPageDown = true;
					console.log("triggerScrollPageDown", triggerScrollPageDown);
					// Stop sound
					stopSound();
				}
				// Set state
				projectorOn = true;
				console.log("projectorOn", projectorOn);
				projectorReverse = true;
				console.log("projectorReverse", projectorReverse);
				inThreshold = false;
				console.log("inThreshold", inThreshold);
				projectorHasPlayed = true;
				console.log("projectorHasPlayed", projectorHasPlayed);
				// Play sound
				playSound();
				// Play sound effects
				playSoundEffects();
			}
			// Set sound rate
			setSoundRate();
			// Update datGUI params
			if (!states.mediaHasAudio) getFpsActual();
		}
	}

	async function scroll(){
		// Source: https://stackoverflow.com/questions/2387136/cross-browser-method-to-determine-vertical-scroll-percentage-in-javascript
		const scrollTop = $(window).scrollTop(),
			docHeight = $(document).height(),
			windowHeight = $(window).height();
		// Clac scroll percent
		scrollPercent = (scrollTop / (docHeight - windowHeight)) * 100;
		//console.log('scrollPercent', scrollPercent);
		// Update datGUI params
		visualInfoParams.scrollPercent = scrollPercent;
		// Calc last image offset from top of viewport
		const lastImageOffset = $lastImage.offset().top - scrollTop;
		if (lastImageOffset > 0) lastImageOffsetUse = lastImageOffset || 0;
		//console.log("lastImageOffsetUse", lastImageOffsetUse);
		// Calc first image offset from top of viewport		
		const firstImageOffset = $firstImage.offset().top - scrollTop;
		if (firstImageOffset < 0) firstImageOffsetUse = firstImageOffset || 0;
		//console.log("firstImageOffsetUse", firstImageOffsetUse);
		// Source: https://stackoverflow.com/questions/3898130/check-if-a-user-has-scrolled-to-the-bottom/3898152
		// Detect top and bottom of page
		if (scrollTop == 0){
			if (topOfPage) return scrollComplete();
			topOfPage = true;
			console.log("topOfPage", topOfPage);
			if (options.loopImages && states.soundReady && projectorOn){
				if (!projectorReverse || !enableReverseLoop) return;
				// Loop scroll position
				//window.scrollTo(0,windowHeight);
				// Note: scroll to last image instead of docHeight because waveform length extends past last image
				$.scrollTo(".lastImage", -windowHeight-firstImageOffsetUse);
				// Play sound
				await delay(playSoundDelay)
				playSound();
			} else {
				stopProjector();
				resetScrollSpeed();
			}
		}
		// Fix black flash when images loop forward in projector if waveform is present on post page
		//else if(scrollTop + windowHeight == docHeight) {
		else if(scrollTop + windowHeight > docHeight-windowHeight) {
			if (bottomOfPage) return scrollComplete();
			bottomOfPage = true;
			console.log("bottomOfPage", bottomOfPage);
			// Stop sound needed to play audio when looping forward with slow speed
			stopSound()
			if (options.loopImages && states.soundReady && projectorOn){
				if (projectorReverse || !enableForwardLoop) return;
				// Loop scroll position
				//window.scrollTo(0,0);
				$.scrollTo("#img1", windowHeight-lastImageOffsetUse);
				// Play sound
				await delay(playSoundDelay)
				playSound();
			} else {
				stopProjector();
				resetScrollSpeed();
			}
		}
		else if (topOfPage) {
			topOfPage = false;
			console.log("topOfPage", topOfPage);
		}
		else if (bottomOfPage){
			bottomOfPage = false;
			console.log("bottomOfPage", bottomOfPage);
		}
		scrollComplete();
	}

	function scrollComplete(){
		getCurrentVisibleImage();
		getSoundPercent();
	}

	function constrainPercentProjectorSpeed(){
		if (percentProjectorSpeed < 0 && percentProjectorSpeed > -0.01) percentProjectorSpeed = -0.01
		if (percentProjectorSpeed > 0 && percentProjectorSpeed < 0.01) percentProjectorSpeed = 0.01
		percentProjectorSpeed = parseFloat(percentProjectorSpeed.toFixed(3))
	}

	function getCurrentVisibleImage({bypassSoundFX} = {}){
		// Map scrollPercent to number of frames
		// If in create mode, add an extra image since first and last image are duplicates
		const extraImage = states.createMode ? 1 : 0;
		currentVisibleImage = Math.round(map(scrollPercent, 0, 100, 1, lastFrame*options.imageGenerationCylces+extraImage));
		if (currentVisibleImage == currentVisibleImagePrevious) return;
		//console.log("currentVisibleImage", currentVisibleImage);
		// Note: fires twice on loop due to rapid image scroll
		//console.log("currentVisibleImage", currentVisibleImage);
		// Update datGUI params
		visualInfoParams.currentFrame = currentVisibleImage;
		// Update frame counter
		let remainder = currentVisibleImage % lastFrame;
		if (remainder == 0) remainder = lastFrame;
		controls.setCurrentFrame(remainder);
		// Define previous visible image
		currentVisibleImagePrevious = currentVisibleImage;
		// Project sound effects click
		if (!bypassSoundFX) playSoundEffectsClick();
	}

	function windowResize(){
		// Clear timeout
		clearTimeout(resizeTimeout);
		// Set timeout
		resizeTimeout = setTimeout(resumePlayback, 500);
		if (!states.soundReady || !projectorHasPlayed) return;
		// Save visible image before resize
		currentVisibleImageBeforeResize = currentVisibleImage;
		console.log("currentVisibleImageBeforeResize", currentVisibleImageBeforeResize);
		if (!projectorOn) return;
		// Set local state
		wasPlaying = true;
		console.log("wasPlaying", wasPlaying);
		// Disable params
		states.enableParametersChange = false;
		console.log("states.enableParametersChange", states.enableParametersChange);
	}

	function resumePlayback(){
		// After resizing browser window, resume frame position
		if (!states.soundReady || !projectorHasPlayed || !projectorOn || !wasPlaying) return;
		// Scroll to visible image before resize
		if (currentVisibleImageBeforeResize) $.scrollTo("#img"+currentVisibleImageBeforeResize);
		// Set local state
		wasPlaying = false;
		console.log("wasPlaying", wasPlaying);
		// Enable params
		states.enableParametersChange = true;
		console.log("states.enableParametersChange", states.enableParametersChange);
		if (states.fantascope || !states.mediaHasAudio) return;
		// Set sound position
		setSoundPosition();
	}

	function stopProjector(){
		if (inThreshold || !projectorHasPlayed || !projectorOn) return;
		console.log("Stop projector");
		clearTimeout(scrollPageUpTimeout);
		clearTimeout(scrollPageDownTimeout);
		projectorSpeed = 0
		percentProjectorSpeed = 0
		projectorOn = false;
		console.log("projectorOn", projectorOn);
		// Stop sound
		stopSound();
		// Stop sound effects
		stopSoundEffects();
	}

	function startProjector(){
		// If remote disconnects after projector is paused manually, enable parameters change when remote reconnects
		states.enableParametersChange = true;
		console.log("states.enableParametersChange", states.enableParametersChange);
		if (inThreshold || !projectorHasPlayed || projectorOn || topOfPage || bottomOfPage) return;
		projectorReverse ? scrollPageUp() : scrollPageDown();
		projectorOn = true;
		console.log("projectorOn", projectorOn);
		// Play sound
		playSound();
		// Play sound effects
		playSoundEffects();
	}

	function toggleProjector(){
		projectorOn ? stopProjector() : startProjector();
		states.enableParametersChange = projectorOn ? false : true;
	}

	function toggleProjectorOnBrowserVisibility(){
		if (!states.remotePaired) return;
		if (states.documentHidden && projectorOn) stopProjector();
		if (!states.documentHidden) startProjector();
		states.enableParametersChange = states.documentHidden ? false : true;
		console.log("states.enableParametersChange", states.enableParametersChange);
	}

	function setMaxProjectorSpeed(){
		// When remote is lifted, set max projectorSpeed if within range. Meant to prevent accidental device orientation data input.
		// Note: first effect in remoteOptions must be speed
		if (
			projectorSpeed > maxProjectorSpeed-setMaxProjectorSpeedThreshold &&
			remoteOptions[0].active == true &&
			!states.recordPlayerMode &&
			projectorHasPlayed &&
			states.enableParametersChange &&
			!topOfPage &&
			!bottomOfPage
		){
			projectorSpeed = maxProjectorSpeed;
			console.log("projectorSpeed", projectorSpeed);
			// Play sound
			playSound();
			console.log("Reset projector speed");
		}
	}

	function resetScrollSpeed(){
		scrollSpeed = 0;
		console.log("scrollSpeed", scrollSpeed);
	}

	// Sound
	/*-------------------------------*/

	function setSoundReady(){
		if (!states.mediaHasAudio) return;
		states.soundReady = true;
		console.log("states.soundReady", states.soundReady);
		getSoundLength();
		// Setup dat.GUI array with initial values
		initArray({
			array: setupAudioInfo,
			id: "audioPosition",
			minValue: 0,
			maxValue: soundLength,
			value: 0
		});
		if (testSound) testingSound();
	}

	function getSoundLength(){
		if (!states.mediaHasAudio) return;
		soundLength = howlerAudio.getLength();
		console.log("soundLength", soundLength);
		soundLengthBasedOnMediaFPS = lastFrame / mediaFPS;
		console.log("soundLengthBasedOnMediaFPS", soundLengthBasedOnMediaFPS);
	}

	function getSoundPercent(){
		if (!states.mediaHasAudio) return;
		// Get sound position
		const currentTime = projectorReverse
		? soundLength - howlerAudio.getCurrentTime()
		: howlerAudio.getCurrentTime();
		//console.log("currentTime", currentTime);
		// Calc sound percent
		soundPercent = currentTime/soundLength * 100;
		//console.log("soundPercent", soundPercent);
		// Update datGUI params
		audioInfoParams.audioPercent = soundPercent;
		audioInfoParams.audioPosition = currentTime;
		// Get sound drift
		getSoundDrift();
	}

	// Note: sound drift occurring when scroll speed is moving at a different rate than audio speed
	// Possibly caused by browser processing time of image filters, high frame rate playback, css transition animation of wavelength panel opening, and manually adjusting the frameline. Media with lower frame rates cause less sound drift.
	function getSoundDrift(){
		if (!states.mediaHasAudio) return;
		// Calc sound drift
		soundDrift = soundPercent - scrollPercent;
		//console.log("soundDrift", soundDrift);
		// Update datGUI params
		audioInfoParams.audioDrift = soundDrift;
		// Attempts to correct sound drift
		//fixSoundDrift();
	}

	/*function fixSoundDrift(){
		// Approach 1: reset position continuously (effective but produces crackle)
		//setSoundPosition();
		// Approach 2: reset position when soundDrift threshold is reached
		//const soundDriftResetThreshold = 1;
		//if (soundDrift > soundDriftResetThreshold || soundDrift < -soundDriftResetThreshold){
		//	setSoundPosition();
		//}
		// Approach 3: slow or speed up sound rate based on soundDrift
		// Note: see soundDriftFixAttemptApproach3
		// Approach 4: set sound rate with browser render FPS
		// Note: see images_projectorFps
	}*/

	/*function soundDriftFixAttemptApproach3(){
		// Attempt to correct sound drift by adjusting sound rate with dynamic offset
		// Calc offset
		const offset = projectorReverse
		? soundRateUse - Math.abs(soundDrift*0.1)
		: soundRateUse + Math.abs(soundDrift*0.1);
		// Calc offset difference
		const offsetDifference = soundRateUse - offset;
		// If offsetDifference surpasses threshold of 0.03, adjust sound rate with offset
		const offsetUse = Math.abs(offsetDifference) > 0.03 ? offset : soundRateUse;
		howlerAudio.rate(offsetUse);
	}*/

	function setSoundPosition({playOffset} = {}){
		if (!states.mediaHasAudio) return;
		// Set sound position based on scroll percentage
		// Map scroll percentage to sound length
		const soundPosition = map(scrollPercent, 0, 100, 0, soundLengthBasedOnMediaFPS);
		//console.log("soundPosition", soundPosition);
		soundPositionUse = projectorReverse
		? Math.abs(soundLength-soundPosition)
		: soundPosition;
		//console.log("soundPositionUse", soundPositionUse);
		if (playOffset) return soundPositionUse;
		// Seek
		howlerAudio.seek(soundPositionUse);
		if (projectorReverse) toneAudio.seek(soundPositionUse);
	}

	function setSoundRate(){
		if (!states.mediaHasAudio) return;
		// Note: dividing by 0 returns infinity
		soundRateUse = projectorReverse
		? -soundRateMapped*projectorSpeed
		: soundRateMapped*projectorSpeed
		//console.log("soundRateUse", soundRateUse);
		// Note: Howler.js used for sound effects and forward media playback. Tone.js used for reverse media playback. Howler.js is unable to play audio in reverse. Tone.js is unable to easily return current sound position, which is needed to calculate audio drift.
		if (projectorReverse) toneAudio.reverse(true);
		projectorReverse ? howlerAudio.mute(true) : howlerAudio.mute(false);
		// If not infinity, set sound rate
		if (isFinite(soundRateUse)){
			howlerAudio.rate(soundRateUse)
			if (projectorReverse) toneAudio.rate(Math.abs(soundRateUse))
			//soundDriftFixAttemptApproach3();
		}
		//console.log("soundRateUse", soundRateUse);
		// Update datGUI params
		getFpsActual();
		audioInfoParams.audioRate = soundRateUse;
	}

	function getFpsActual(){
		fpsActual = fpsUse*projectorSpeed;
		//console.log("fpsActual", fpsActual);
		// Update datGUI params
		visualInfoParams.actualFrameRate = fpsActual;
	}

	function setSoundRateMapped(data){
		soundRateMapped = data;
	}

	function playSound(){
		if (!states.mediaHasAudio || !states.audio || !projectorOn) return;
		// Set position
		const position = setSoundPosition({playOffset: true});
		// Set sound rate
		// Note: Must set sound rate before playing initally when projector is in reverse to avoid audio stutter. However, the rate must be set after playing when looping in reverse to avoid audio stoppage.
		if (states.screenMode && !screenModeInitialPlayback && projectorReverse){
			screenModeInitialPlayback = true;
			console.log("screenModeInitialPlayback", screenModeInitialPlayback);
			// Set sound rate
			setSoundRate();
			// Play sound
			howlerAudio.play(position);
			if (projectorReverse) toneAudio.play(position);
			return;
		}
		// Play sound
		howlerAudio.play(position);
		if (projectorReverse) toneAudio.play(position);
		// Set sound rate
		setSoundRate();
	}

	function stopSound(){
		if (!states.mediaHasAudio) return;
		howlerAudio.stop();
		toneAudio.stop();
	}

	function playSoundEffects(){
		if (!options.projectorSoundEffects || !states.soundFX || !projectorOn) return;
		howlerAudio.playMotor();
	}

	function stopSoundEffects(){
		if (!options.projectorSoundEffects) return;
		howlerAudio.stopMotor();
	}

	function playSoundEffectsClick(){
		if (!options.projectorSoundEffects || !states.soundFX) return;
		howlerAudio.playClick();
	}

	function testingSound(){
		if (!states.mediaHasAudio) return;
		// Testing sound rate
		setInterval(() => {
			console.log("currentTime", howlerAudio.getCurrentTime());
			console.log("soundRateMapped", soundRateMapped);
			console.log("soundRateUse", soundRateUse);
			console.log("fpsUse", fpsUse);
			console.log("fpsActual", fpsActual);
		}, 500);
	}

	function resetSpeed(){
		// Reset initial params
		scrollSpeed = 0;
		projectorSpeed = 0;
		// Reset datGUI params
		getFpsActual();
		// Hard reset audio info params
		for (let key in audioInfoParams) {
			audioInfoParams[key] = 0;
		}
	}

	function resetPosition(){
		// Reset initial params
		scrollPercent = 0;
		// Scroll to top
		window.scrollTo(0, 0);
	}

	function resetFrame({speed = 0} = {}){
		// Note: If focus is applied, this method does not frame properly
		getCurrentVisibleImage({bypassSoundFX: true});
		console.log("currentVisibleImage", currentVisibleImage)
		$(`#img${currentVisibleImage}`)[0].scrollIntoView();
	}

	// Note: not working consistently
	async function resetFrameGradually(){
		if (autoFraming) return;
		autoFraming = true;
		console.log("autoFraming", autoFraming);
		while (true){
			// Hard reset frame if projector speed is between 0 and full speed
			if (Math.abs(percentProjectorSpeed) != 100 && Math.round(Math.abs(percentProjectorSpeed)) != 0){
				resetFrame();
				break;
			}
			// Prevent reset if images are about to loop
			if (Math.abs(scrollPercent) > 99.99){
				await delay(1);
				continue;
			}
			// Check if framed and return window height remainder
			const remainder = isFramed({getRemainder: true});
			console.log("remainder", remainder);
			if (remainder == 0) break;
			// Note: Small float needed to avoid missing 0 continuously
			// Note: frameAmount data is multiplied by 5 in projector framing compenent
			const frameAmount = remainder > 0 ? .1 : -.1;
			console.log("frameAmount", frameAmount)
			projectorFraming.changeFraming(frameAmount);
			await delay(1);
		}
		autoFraming = false;
		console.log("autoFraming", autoFraming);
	}

	function isFramed({getRemainder} = {}){
		const scrollTop = $(window).scrollTop();
		const $currentImage = $(`#img${currentVisibleImage}`);
		if (getRemainder && !$currentImage.offset()) return 0;
		if (!$currentImage.offset()) return false;
		const currentImageOffset = $currentImage.offset().top - scrollTop;
		const remainder = currentImageOffset % $currentImage.height();
		if (getRemainder) return remainder;
		return remainder == 0 ? true : false;
	}

	function scrollToRandomFrame(){
		const randomFrame = getRandomInteger(states.mediaData.firstFrame, states.mediaData.lastFrame);
		$(`#img${randomFrame}`)[0].scrollIntoView();
	}

	function scrollToLastImage(){
		$lastImage[0].scrollIntoView();
	}

	function getMaxProjectorSpeed(){
		return maxProjectorSpeed;
	}

	function getPercentProjectorSpeed(){
		return percentProjectorSpeed;
	}

	return {
		init: init,
		initProjector: initProjector,
		setRpPlaybackSettings: setRpPlaybackSettings,
		setFps: setFps,
		setScrollSpeed: setScrollSpeed,
		setSoundRateMapped: setSoundRateMapped,
		setSoundRate: setSoundRate,
		changeSpeed: changeSpeed,
		toggleProjector: toggleProjector,
		toggleProjectorOnBrowserVisibility: toggleProjectorOnBrowserVisibility,
		startProjector: startProjector,
		stopProjector: stopProjector,
		setMaxProjectorSpeed: setMaxProjectorSpeed,
		playSound: playSound,
		stopSound: stopSound,
		playSoundEffects: playSoundEffects,
		stopSoundEffects: stopSoundEffects,
		setThresholdsForAutomatedPlaybackOrCreateMode: setThresholdsForAutomatedPlaybackOrCreateMode,
		cacheElms: cacheElms,
		setLastframe: setLastframe,
		resetSpeed: resetSpeed,
		resetPosition: resetPosition,
		resetFrame: resetFrame,
		resetThreshold: resetThreshold,
		getMaxProjectorSpeed: getMaxProjectorSpeed,
		isFramed: isFramed,
		setSoundPosition: setSoundPosition,
		scrollToLastImage: scrollToLastImage,
		scrollToRandomFrame: scrollToRandomFrame,
		setSoundReady: setSoundReady,
		getPercentProjectorSpeed: getPercentProjectorSpeed
	};

})(); //projectorSpeed

export {projectorSpeed};