import {validDesktopBrowser} from '../config.js';
import {browserErrorMessage} from '../shared/browserErrorMessage.js';
import {app} from './app.js';
import {body} from '../shared/body.js';
import {menu} from '../shared/menu.js';
import {footer} from '../shared/footer.js';
import {keyboardCommands} from './keyboardCommands.js';
import {centerModal} from './centerModal.js';
import {loadPage} from '../shared/loadPage.js';
import {topModal} from '../shared/topModal.js';

if (validDesktopBrowser) app.init();

(async () => {
	await loadPage.init({showLoader: false});
	if (!validDesktopBrowser){
		menu.destroy();
		footer.hide();
		browserErrorMessage.init();
		centerModal.hide();
		return;
	}
	body.resetScrollPosition();
})();