const copy = require('clipboard-copy');
import {states} from '../states.js';
import {delay} from '../shared/util.js';
import {images} from './images.js';
import {topModal} from "../shared/topModal.js";
import {modals} from './modals.js';

const sharePanel = (function(){

	//options
	const includeKeyframesOnStart = true;
	const defaultButtonMsg = "Copy link";
	const copyLinkSuccessMsg = "Link copied to clipboard";
	const copyLinkErrorMsg = "Error copying link";

	//cache DOM
	const $el = $("#share-panel");
	const $close = $el.find('.close');
	const $copyShareLinkButton = $el.find("#copy-share-link-button");
	const copyShareLinkButton = $copyShareLinkButton[0];
	const $shareLinkField = $el.find('#share-link-field');
	const shareLinkField = $shareLinkField[0];
	const $includeKeyframesToggle = $el.find('#checkboxIncludeKeyframes');
	const includeKeyframesToggle = $includeKeyframesToggle[0];

	//bind events
	$close.on('click', hide);
	$copyShareLinkButton.on('click', copyLink);
	includeKeyframesToggle.addEventListener('change', function() {
		updateLink();
	});

	// Init
	/*-------------------------------*/

	function init(){
		copyShareLinkButton.innerHTML = defaultButtonMsg;
		if (includeKeyframesOnStart) includeKeyframesToggle.checked = true;
	}

	// Link
	/*-------------------------------*/

	async function updateLink(){
		const shareLink = includeKeyframesToggle.checked ? await images.copyLink({returnFullURL: true}) : await images.copyLink({returnBaseURL: true});
		shareLinkField.value = shareLink;
	}

	async function copyLink(){
		try {
			const link = shareLinkField.value;
			await copy(link);
			console.log("Copied successfuly", link);
			updateButton({success: true});
		} catch (err){
			console.log(err);
			updateButton({success: false});
		}
	}

	// UI
	/*-------------------------------*/

	async function show(){
		if (states.sharePanelOpen) return
		await modals.closePanel()
		states.sharePanelOpen = true;
		console.log("states.sharePanelOpen", states.sharePanelOpen);
		topModal.checkOpenPanels()
		$el.show();
		$el.addClass('show');
	}

	async function hide(){
		$el.removeClass('show');
		await delay(500);
		$el.hide();
		states.sharePanelOpen = false;
		console.log("states.sharePanelOpen", states.sharePanelOpen);
	}

	async function updateButton(success){
		const msg = success ? copyLinkSuccessMsg : copyLinkErrorMsg;
		copyShareLinkButton.innerHTML = msg;
		await delay(2000);
		copyShareLinkButton.innerHTML = defaultButtonMsg;
	}

	return {
		init: init,
		show: show,
		hide: hide,
		updateLink: updateLink
	}

})();

export {sharePanel};