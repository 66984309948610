import {
	remoteOptions,
	initArray,
	playbackParams
} from '../database.js';
import {states} from '../states.js';
import {options} from '../options.js';
import {map} from '../shared/math.js';
import {backgroundColor} from './backgroundColor.js';
import {socketEmit} from './socketEmit.js';
import {controls} from './controls.js';

// Projector images effects
const effects = (function(){

	//states
	let loaded = false;

	//data
	const minDial = 0;
	const maxDial = 200;
	const filters = [
		{
			name: "blur",
			parameterName: "focus",
			type: "px",
			neutral: 0,
			range: 30,
			rangeBalanced: true,
			amount: 1
		},
		{
			name: "brightness",
			parameterName: "brightness",
			type: "%",
			neutral: 100,
			range: 400,
			rangeBalanced: true,
			amount: .5
		},
		{
			name: "saturate",
			parameterName: "saturate",
			type: "%",
			neutral: 100,
			range: 400,
			rangeBalanced: false,
			amount: 1
		},
		{
			name: "contrast",
			parameterName: "contrast",
			type: "%",
			neutral: 100,
			range: 400,
			rangeBalanced: false,
			amount: 1
		},
		{
			name: "hue-rotate",
			parameterName: "hue",
			type: "deg",
			neutral: 0,
			range: 360,
			rangeBalanced: false,
			loop: true,
			amount: 1
		}
	];
	const invert = {
		name: "invert",
		type: "%",
		min: 0,
		max: 100,
		use: 0,
		state: false
	}
	const scale = {
		name: "scale",
		min: .9,
		max: 1.1,
		use: 1
	}

	//cache DOM
	const $el = $('#images');
	let $imgs;

	function cacheDom(){
		$imgs = $el.find('img');
	}

	function init(){
		filters.forEach((obj, index) => {
			// Calc initial filter values and add to object properties
			initFilterValues({obj: obj});
			// Setup dat.GUI array with initial values if not loaded
			if (loaded) return;
			initArray({
				array: remoteOptions,
				id: obj.parameterName,
				minValue: obj.min,
				maxValue: obj.max,
				value: obj.use
			});
		});
		// Set local state
		loaded = true;
		console.log("loaded", loaded);
	}

	function initFilterValues({obj, filterToReset = false} = {}){
		if (filterToReset) obj = filters.find(filter => filter.parameterName == filterToReset);
		// Define use value as neutral filter value
		obj.use = obj.neutral;
		// If CSS filter range has negative values, define balanced filter range
		if (obj.rangeBalanced){
			obj.min = -obj.range;
			obj.max = obj.range;
		} else {
			// Otherwise, start filter range at 0
			obj.min = 0;
			obj.max = obj.range;
		}
		// Map filter limits to dial limits for initial value
		obj.init = map(obj.use, obj.min, obj.max, minDial, maxDial)
		console.log("Filter", obj);
		// Apply filter if loaded
		if (loaded) applyFilter();
	}

	function toggleInvert(i){
		if (filters[i].use < 0 && !invert.state){
			invert.state = true;
			//console.log("invert.state", invert.state);
			invert.use = invert.max;
			return;
		}
		if (filters[i].use >= 0 && invert.state){
			invert.state = false;
			//console.log("invert.state", invert.state);
			invert.use = invert.min;
		}
	}

	function changeEffect({i, data, disableLoop} = {}){
		// Set filter
		filters[i].init += data*filters[i].amount;
		// Constrain filter
		if (filters[i].loop && !disableLoop){
			// Loop filter
			if (filters[i].init > maxDial) filters[i].init = minDial;
			if (filters[i].init < minDial) filters[i].init = maxDial;
		} else {
			// Limit filter
			if (filters[i].init > maxDial) {
				filters[i].init = maxDial;
				socketEmit.sendFlash();
			}
			if (filters[i].init < minDial) {
				filters[i].init = minDial;
				socketEmit.sendFlash();
			}
		}
		//console.log("filters[i].init", filters[i].init);
		// Map dial limits to filter limits
		filters[i].use = map(filters[i].init, minDial, maxDial, filters[i].min, filters[i].max);
		//console.log(filters[i].parameterName, filters[i].use);
		// Set invert when brightness value is negative
		if (filters[i].name == "brightness") toggleInvert(i);
		// Edge case: Force brightness threshold if target value is on edge of 0 for inversion. Works in conjunction with getRoundedPlaybackParams function in images component.
		if (filters[i].name === "brightness" && filters[i].use > -0.01 && filters[i].use < 0) filters[i].use = -0.01;
		if (filters[i].name === "brightness" && filters[i].use < 0.01 && filters[i].use > 0) filters[i].use = 0;
		// Map scale range to blur value
		if (filters[i].name == "blur"){
			scale.use = map(filters[0].use, filters[0].min, filters[0].max, scale.min, scale.max);
			//console.log(scale.name, scale.use);
		}
		// Apply filter
		applyFilter();
	}

	function applyFilter(){
		// Apply filters to images
		$el.css("filter",
			`
			${filters[0].name}(${Math.abs(filters[0].use)}${filters[0].type})
			${filters[1].name}(${Math.abs(filters[1].use)}${filters[1].type})
			${filters[2].name}(${Math.abs(filters[2].use)}${filters[2].type})
			${filters[3].name}(${Math.abs(filters[3].use)}${filters[3].type})
			${filters[4].name}(${Math.abs(filters[4].use)}${filters[4].type})
			${invert.name}(${invert.use}${invert.type})
			`
		);
		// Apply scale to images
		// Note: Blur filter and transform scale is resource intensive. Consider disabling for film images.
		zoom();
		// Apply filters to background color except focus
		if (states.fantascope || options.applyEffectsToProjectorBackgroundColor){
			backgroundColor.applyFilter(
				[
					{
						name:filters[1].name,
						use:filters[1].use,
						type:filters[1].type
					},
					{
						name:filters[2].name,
						use:filters[2].use,
						type:filters[2].type
					},
					{
						name:filters[3].name,
						use:filters[3].use,
						type:filters[3].type
					},
					{
						name:filters[4].name,
						use:filters[4].use,
						type:filters[4].type
					},
					{
						name:invert.name,
						use:invert.use,
						type:invert.type
					}
				]
			);
		}
		// Update datGUI params
		playbackParams.focus = filters[0].use;
		playbackParams.brightness = filters[1].use;
		playbackParams.saturate = filters[2].use;
		playbackParams.contrast = filters[3].use;
		playbackParams.hue = filters[4].use;
		// Update param info
		controls.updateParamInfo();
	}

	function zoom(){
		// Note: For fantascopes, apply effect to parent container to avoid transform rotate conflict with child image. For projector, apply effect to child images because scale resizes height of parent container.
		const elm = states.fantascope ? $el : $imgs;
		if (!states.fantascope) return elm.css("transform", `${scale.name}(${scale.use})`);
		// For fantascopes, calculate scale based on current image zoom value set by controls
		const imageZoomValueForScale = controls.getImageZoomValue()/100;
		elm.css("transform", `${scale.name}(${imageZoomValueForScale/scale.use})`);
	}

	function getFilters(){
		return filters;
	}

	return {
		init: init,
		initFilterValues: initFilterValues,
		cacheDom: cacheDom,
		changeEffect,
		getFilters: getFilters,
		zoom: zoom
	};

})(); //effects

export {effects};