import {getUserInfo} from '../states.js';
import {options} from '../options.js';
import {loadImagesFromS3} from '../shared/loadImages';
import {menu} from '../shared/menu.js';
import {delay} from '../shared/util.js';
import {alert} from '../shared/alert.js';
import {audioContextMessage} from '../shared/audioContextMessage.js';
import {fullscreenButton} from '../shared/fullscreenButton.js';
import {loader} from './loader.js';
import {keyboardCommands} from './keyboardCommands.js';
import {stats} from './stats.js';
import {initMedia} from './initMedia.js';
import {socketEmit} from './socketEmit.js';
import {menuOptions} from './menuOptions.js';
import {mouseScroll} from './mouseScroll.js';
import {postMessage} from './postMessage.js';
import {topPanel} from './topPanel.js';

// App
const app = (function(){

	//cache DOM
	const $el = $('#app');
	const $images = $el.find('img');

	async function init(){
		try{
			await getUserInfo()
			// If site is loaded in an iframe, initiate post messaging with parent window object and wait for response
			// Note: another option is to set screen mode to true if iframe is detected
			const isIframe = window.self !== window.top;
			console.log("isIframe", isIframe);
			if (isIframe){
				postMessage.init();
				while (!postMessage.getParentMessageRecieved()) await delay(50);
			}
			// Init
			loadImagesFromS3($images);
			menu.init();
			loader.init();
			keyboardCommands.init();
			stats.init();
			audioContextMessage.init();
			// Note: initMedia.init => initMedia.initImages => images.init => images_generate.init => images.initPlayback
			initMedia.init();
			fullscreenButton.init();
			menuOptions.init();
			mouseScroll.init();
			topPanel.init();
			alert.init();
		} catch(err){
			console.error(err);
		}
	}

	return {
		init: init
	};

})(); //app

export {app};