import axios from "axios";
import {states} from '../states.js';
import {delay} from './util.js';
import {deletePropsWithEmptyValues} from './objectMutations.js';
import {topModal} from './topModal.js';

let modalsProxy;
// Define a function to conditionally import a module
(async function() {
  if (states.pageId != 'post') return
	// Dynamically import the module when the condition is met
	const {modals} = await import('../post/modals.js');
	modalsProxy = modals
})()

const reportPanel = (function(){

	//options
	const successMsg = "Report sent successfully"

	//cache DOM
	const $el = $("#report-panel");
	const $form = $el.find('form');
	const $radioInput = $form.find('.radios input');
	const $response = $el.find('.response');
	const $responseMessage = $el.find('.response .message');
	const $submitButton = $el.find('.submit-button');
	const $cancelButton = $el.find('.cancel-button');
	const $close = $el.find('.close');

	//bind events
	$form.on('submit', preventDefault);
	$radioInput.on('input', onInput);
	$submitButton.on('click', submit);
	$cancelButton.on('click', reset);
	$close.on('click', reset);

	// Form
	/*-------------------------------*/

	async function submit(){
		if (!validateForm()) return console.log("Form validation fail");
		console.log("Form validation pass");
		const formData = new FormData($form[0]);
		let formDataObj = Object.fromEntries(formData);
		formDataObj = deletePropsWithEmptyValues(formDataObj);
		formDataObj.post = states.mediaData._id
		formDataObj.reportId = Math.floor(Date.now());
		const response = await axios.post('/reports', formDataObj, {validateStatus: () => true});
		console.log("response.data", response.data)
		if (response.status != 201) return showResponse({msg: Object.values(response.data)[0], error: true});
		showResponse({msg: successMsg, error: false});
		disableForm();
	}

	function onInput(){
		if ($response.is(":visible")) hideResponse();
		enableButtons();
	}
	
	async function reset(){
		hide()
		await delay(500);
		$form.trigger("reset")
		enableForm()
		disableButtons()
		hideResponse()
	}
	
	function validateForm(){
		return $form[0].checkValidity();
	}

	function preventDefault(e){
		// Prevent form submitting
		e.preventDefault();
	}

	// Response
	/*-------------------------------*/

	function showResponse({msg, error = false}){
		// Construct HTML markup
		const str = `<p>${msg}</p>`
		// Write to DOM
		$responseMessage.html(str);
		// Change color based on message type
		error ? $response.addClass('error') : $response.removeClass('error');
		// Show
		$response.show()
	}

	function hideResponse(){
		$response.hide()
	}

	// UI
	/*-------------------------------*/

	function enableButtons(){
		$submitButton[0].disabled = false;
		$cancelButton[0].disabled = false;
	}

	function disableButtons(){
		$submitButton[0].disabled = true;
		$cancelButton[0].disabled = true;
	}

	function disableForm(){
		$form.addClass('disable')
	}

	function enableForm(){
		$form.removeClass('disable')
	}

	async function show(){
		if (states.reportPanelOpen) return
		if (states.pageId == 'post') await modalsProxy.closePanel()
		states.reportPanelOpen = true;
		console.log("states.reportPanelOpen", states.reportPanelOpen);		
		topModal.checkOpenPanels()
		$el.show();
		$el.addClass('show');
	}

	async function hide(){
		$el.removeClass('show');
		await delay(500);
		$el.hide();
		states.reportPanelOpen = false;
		console.log("states.reportPanelOpen", states.reportPanelOpen);
	}

	return {
		show: show,
		hide: hide
	}

})();

export {reportPanel};