import {states} from '../states.js';
import {menu} from './menu.js';

const menuButton = (function(){

	//options
	const fadeDuration = 600;

	//cache DOM
	const $el = $("#menu-button");
	const $hamburger = $el.find('.hamburger');

	//bind events
	$el.on('click', () => menu.toggle());

	function updateUI(){
		states.menuOpen ? $el.addClass('active menu-slide') : $el.removeClass('active menu-slide');
		states.menuOpen ? $hamburger.addClass('animate') : $hamburger.removeClass('animate');
	}

	function updateEnable({enable} = {}){
		enable ? $el.removeClass('disable') : $el.addClass('disable');
	}

	function fadeOut(){
		$el.fadeOut(fadeDuration);
	}

	function hide(){
		$el.hide();
	}

	function above(){
		$el.addClass('above');
	}

	return{
		updateUI: updateUI,
		updateEnable: updateEnable,
		hide: hide,
		above: above
	}

})();

export {menuButton};