import {
	remoteOptions,
	initArray,
	playbackParams,
	setupWheelVisualInfo,
	visualInfoParams
} from '../database.js';
import {states} from '../states.js';
import {options} from '../options.js';
import {map, fpsToMilliseconds} from '../shared/math.js';
import {wheelFraming} from './images_wheelFraming.js';
import {backgroundColor} from './backgroundColor.js';
import {socketEmit} from './socketEmit.js';
import {controls} from './controls.js';
// Child modules
import {rpPlaybackWheel} from './images_wheelSpeed_recordPlayerPlayback.js';

// Wheel speed
const wheelSpeed = (function(){

	//states
	let wheelOn = false;
	let wheelReverse = false;
	let wheelHasPlayed = false;
	let inThreshold = false;

	//data

		// Init
		let scrollSpeed = 0,
			wheelSpeed = 0,
			angleUse = 0;

		// Timeouts
		let spinClockwiseTimeOut,
			spinCounterclockwiseTimeOut;

		// Triggers
		let triggerSpinClockwise = true,
			triggerSpinCounterclockwise = true;

		// Background color
		let mediaBackgroundColor;

		// For FPS
		let fpsUse,
			mediaFPS;

	//options

		// Threshold
		let thresholdBetweenDirectionSwitch = true;
		const threshold = thresholdBetweenDirectionSwitch ? 20 : 0;

		// Sroll speed
		let minScrollSpeed = threshold; // Defines threshold trigger range
		let maxScrollSpeed = 500; // Defines the range of minWheelSpeed to maxWheelSpeed, inscrease to have a longer ramp
		const amount = 3;

		// Wheel speed
		const minWheelSpeed = 0;
		let maxWheelSpeed; // Defined in init based on maxWheelSpeedRotations
		const maxWheelSpeedRotations = 2;

	//cache DOM
	const $el = $('#images');
	let $img;

	function cacheElms(){
		$img = $el.find('img');
	}

	// Init
	/*-------------------------------*/

	function init(){
		// Define variables based on media data
		mediaFPS = states.mediaData.fps;
		maxWheelSpeed = (360/mediaFPS)*maxWheelSpeedRotations;
		mediaBackgroundColor = states.mediaData.bgcolor || options.defaultBackgroundColor;
		//console.log("mediaBackgroundColor", mediaBackgroundColor);
		// Get record player playback settings
		if (states.recordPlayerMode) rpPlaybackWheel.init();
		// Setup dat.GUI
		setupDatGUI();
	}

	function setupDatGUI(){
		// Setup dat.GUI array with initial values
		initArray({
			array: remoteOptions,
			id: "speed",
			minValue: -100*maxWheelSpeedRotations,
			maxValue: 100*maxWheelSpeedRotations,
			value: wheelSpeed
		});
		initArray({
			array: setupWheelVisualInfo,
			id: "rotationAngle",
			minValue: -maxWheelSpeed,
			maxValue: maxWheelSpeed,
			value: wheelSpeed
		});
		initArray({
			array: setupWheelVisualInfo,
			id: "currentAngle",
			minValue: 0,
			maxValue: 360,
			value: angleUse
		});
	}

	function initWheel(){
		//Re-cache DOM
		cacheElms();
		// If media does not have audio, set soundReady to true
		!states.mediaHasAudio ? states.soundReady = true : console.log("Error: Media should not have audio");
		console.log("states.soundReady", states.soundReady);
		// Set background color
		backgroundColor.changeColor(mediaBackgroundColor);
		backgroundColor.show();
	}

	function setRpPlaybackSettings(data){
		thresholdBetweenDirectionSwitch = data.thresholdBetweenDirectionSwitch;
		threshold = data.threshold;
		minScrollSpeed = data.minScrollSpeed;
		maxScrollSpeed = data.maxScrollSpeed;
	}

	// Wheel playback
	/*-------------------------------*/

	function stopWheel(){
		if (inThreshold || !wheelOn || !wheelHasPlayed) return;
		console.log("Stop wheel");
		clearTimeout(spinClockwiseTimeOut);
		clearTimeout(spinCounterclockwiseTimeOut);
		wheelOn = false;
		console.log("wheelOn", wheelOn);
	}

	function startWheel(){
		// If remote disconnects after wheel is paused manually, enable parameters change when remote reconnects
		states.enableParametersChange = true;
		console.log("states.enableParametersChange", states.enableParametersChange);
		if (inThreshold || wheelOn || !wheelHasPlayed) return;
		wheelReverse ? spinClockwise() : spinCounterclockwise();
		wheelOn = true;
		console.log("wheelOn", wheelOn);
	}

	function toggleWheel(){
		wheelOn ? stopWheel() : startWheel();
		states.enableParametersChange = wheelOn ? false : true;
	}

	function toggleWheelOnBrowserVisibility(){
		if (!states.remotePaired) return;
		if (states.documentHidden && wheelOn) stopWheel();
		if (!states.documentHidden) startWheel();
		states.enableParametersChange = states.documentHidden ? false : true;
		console.log("states.enableParametersChange", states.enableParametersChange);
	}

	function setFps(data){
		fpsUse = data;
	}

	function setScrollSpeed(data){
		scrollSpeed = data;
	}

	function setAngleUse(data){
		angleUse = data;
	}

	// Note: For fantascopes, full/normal projector speed equals 360 (degrees) divided by number of wedges, which should be thought of as frames. When projector speed is at full/normal rate, it rotates the exact angle of a single wedge/frame, therefore, the number of projector movements or ticks (which has been called fpsUse) will equal frames per second. If projector is not operating at full/normal speed, a single projector movement/tick will rotate the image more or less than the angle of a single wedge/frame, and should therefore be thought of as a movement/tick per second. On a properly functioning analog projector, a projector movement/tick equals a frame advancing, so this effect is not commonly seen. Example of describing playback rate: 13 (number of wedges) copies (instances of image) rotated at multiples of 27.69 (360 / number of wedges) degrees.

	// Note: A fantascope may have several wedge/frame counts embedded in a single image. For example, Stampfer's stroboscopic disc No. 10 has 10 sprocket wedges, 11 human figures, and a 6 sided star. Each one of these elements will stabalize visually based on the full/normal projector speed rate relative to the element's number of wedges/frames. When projector speed is 32.727 (360 divided by 11), the humans will stabilize. At a rate of 36 (360 divided by 10), the sprockets will stabilize. At a rate of 60, (360 divided by 6), the star will stabilize.

	// Rotate image by angleUse degrees at interval of fpsUse milliseconds
	// Note: Perception of forward motion occurs when image is rotating counterclockwise
	function spinCounterclockwise() {
		angleUse -= wheelSpeed;
		rotate($img, angleUse);
		wheelFraming.setAngleUse(angleUse);
		spinCounterclockwiseTimeOut = setTimeout(spinCounterclockwise, fpsToMilliseconds(fpsUse));
	}

	function spinClockwise() {
		angleUse += wheelSpeed;
		rotate($img, angleUse);
		wheelFraming.setAngleUse(angleUse);
		spinClockwiseTimeOut = setTimeout(spinClockwise, fpsToMilliseconds(fpsUse));
	}

	function rotate($elm, degree) {
		$elm.css('transform', `rotate(${degree}deg)`);
		// Update datGUI params
		const remainder = degree % 360;
		const remainderUse = remainder < 0 ? 360 - Math.abs(remainder) : remainder;
		visualInfoParams.currentAngle = remainderUse;
	}

	function changeSpeed(data){
		// Set scrollSpeed
		scrollSpeed = states.recordPlayerMode ? data : scrollSpeed + data*amount;
		//console.log("scrollSpeed", scrollSpeed);
		// Lock record player midpoint speed if within range
		if (states.recordPlayerMode){
			rpPlaybackWheel.checkRpMidpointSpeed(scrollSpeed);
			//rpPlaybackWheel.detectPitchThreshold(scrollSpeed);
		}
		// Threshold
		if (scrollSpeed > -threshold && scrollSpeed < threshold){
			if (!thresholdBetweenDirectionSwitch) return;
			// Gradual or linear acceleration of wheel speed
			angleUse += states.recordPlayerMode ? scrollSpeed/50 : data/50;
			//console.log("angleUse", angleUse);
			rotate($img, angleUse);
			// Reset triggers
			triggerSpinCounterclockwise = true;
			triggerSpinClockwise = true;
			//console.log("triggerSpinCounterclockwise", triggerSpinCounterclockwise);
			//console.log("triggerSpinClockwise", triggerSpinClockwise);
			// Stop wheel
			stopWheel();
			// Set state
			inThreshold = true;
			console.log("inThreshold", inThreshold);
			// Update datGUI params
			playbackParams.speed = 0;
			// Update param info
			controls.updateParamInfo();
			return;
		}
		// Forward
		if (scrollSpeed > threshold){
			// Limit scrollSpeed
			if (scrollSpeed > maxScrollSpeed) {
				scrollSpeed = maxScrollSpeed;
				socketEmit.sendFlash();
			}
			// Map scrollSpeed to wheel speed
			wheelSpeed = map(scrollSpeed, minScrollSpeed, maxScrollSpeed, minWheelSpeed, maxWheelSpeed);
			//console.log("wheelSpeed", wheelSpeed);
			// Update datGUI params
			visualInfoParams.rotationAngle = wheelSpeed;
			// Update current degree
			controls.setCurrentDegree(wheelSpeed);
			// Map wheelSpeed to percent
			const percentWheelSpeed = map(wheelSpeed, minWheelSpeed, maxWheelSpeed, 0, 100*maxWheelSpeedRotations);
			//console.log("percentWheelSpeed", percentWheelSpeed);
			// Update datGUI params
			playbackParams.speed = percentWheelSpeed;
			// Update param info
			controls.updateParamInfo();
			// Turn on wheel
			if (!triggerSpinCounterclockwise) return;
			triggerSpinCounterclockwise = false;
			console.log("triggerSpinCounterclockwise", triggerSpinCounterclockwise);
			spinCounterclockwise();
			if (!thresholdBetweenDirectionSwitch && wheelHasPlayed){
				clearTimeout(spinClockwiseTimeOut);
				triggerSpinClockwise = true;
				console.log("triggerSpinClockwise", triggerSpinClockwise);
			}
			// Set state
			wheelOn = true;
			console.log("wheelOn", wheelOn);
			wheelReverse = false;
			console.log("wheelReverse", wheelReverse);
			inThreshold = false;
			console.log("inThreshold", inThreshold);
			wheelHasPlayed = true;
			console.log("wheelHasPlayed", wheelHasPlayed);
			return;
		}
		// Reverse
		if (scrollSpeed < -threshold){
			// Limit scrollSpeed
			if (scrollSpeed < -maxScrollSpeed) {
				scrollSpeed = -maxScrollSpeed;
				socketEmit.sendFlash();
			}
			// Map scrollSpeed to wheel speed
			wheelSpeed = map(scrollSpeed, -minScrollSpeed, -maxScrollSpeed, minWheelSpeed, maxWheelSpeed);
			//console.log("wheelSpeed", wheelSpeed);
			// Update datGUI params
			visualInfoParams.rotationAngle = -wheelSpeed;
			// Update current degree
			controls.setCurrentDegree(-wheelSpeed);
			// Map wheelSpeed to percent
			const percentWheelSpeed = map(wheelSpeed, minWheelSpeed, maxWheelSpeed, 0, -100*maxWheelSpeedRotations);
			//console.log("percentWheelSpeed", percentWheelSpeed);
			// Update datGUI params
			playbackParams.speed = percentWheelSpeed;
			// Update param info
			controls.updateParamInfo();
			// Turn on wheel
			if (!triggerSpinClockwise) return;
			triggerSpinClockwise = false;
			console.log("triggerSpinClockwise", triggerSpinClockwise);
			spinClockwise();
			if (!thresholdBetweenDirectionSwitch && wheelHasPlayed){
				clearTimeout(spinCounterclockwiseTimeOut);
				triggerSpinCounterclockwise = true;
				console.log("triggerSpinCounterclockwise", triggerSpinCounterclockwise);
			}
			// Set state
			wheelOn = true;
			console.log("wheelOn", wheelOn);
			wheelReverse = true;
			console.log("wheelReverse", wheelReverse);
			inThreshold = false;
			console.log("inThreshold", inThreshold);
			wheelHasPlayed = true;
			console.log("wheelHasPlayed", wheelHasPlayed);
		}
	}

	function reset(){
		// Reset initial params
		scrollSpeed = 0;
		wheelSpeed = 0;
		// Update datGUI params
		visualInfoParams.rotationAngle = wheelSpeed;
		// Update current degree
		controls.setCurrentDegree(wheelSpeed);
	}

	function getMaxWheelSpeedRotations(){
		return maxWheelSpeedRotations;
	}

	return {
		init: init,
		initWheel: initWheel,
		cacheElms: cacheElms,
		changeSpeed: changeSpeed,
		setRpPlaybackSettings: setRpPlaybackSettings,
		setFps: setFps,
		setScrollSpeed: setScrollSpeed,
		setAngleUse: setAngleUse,
		toggleWheel: toggleWheel,
		toggleWheelOnBrowserVisibility: toggleWheelOnBrowserVisibility,
		startWheel: startWheel,
		stopWheel: stopWheel,
		reset: reset,
		getMaxWheelSpeedRotations: getMaxWheelSpeedRotations
	}

})(); //wheelSpeed

export {wheelSpeed};