import {states} from '../states.js';
import {shepherd} from './shepherd.js';
import {sidePanel} from './sidePanel.js';

const hoverOverlay = (function(){

	//states
	let visible = false;

	//data
	let timer;

	//options
	const hideCursor = true;
	const delay = 2000;

	//cache DOM
	const $elms = $('#controls, #back, #keyframes, #side-panel, #top-panel, #panel-message, #flicker-message, #menu-button');
	const $images = $('#images');

	//bind events
	function bindEvents(){
		$(document).on('mousemove', show);
		$elms.on('click', show);
	}

	function init(){
		bindEvents();
	}

	function show(){
		if (visible) return resetTimer();
		visible = true;
		//console.log("visible", visible);
		$elms.addClass('show');
		// Show cursor
		if (hideCursor){
			$images.removeClass('hide-cursor');
			$('html').css('cursor', 'default');
		}
		resetTimer();
	}

	function hide(){
		if (states.menuOpen || states.tour) return;
		visible = false;
		//console.log("visible", visible);
		$elms.removeClass('show');
		sidePanel.expand({closeAll: true});
		// Stop tour
		if (shepherd.isActive()) shepherd.cancel();
		// Hide cursor
		if (hideCursor){
			$images.addClass('hide-cursor');
			$('html').css('cursor', 'none');
		}
	}

	function resetTimer(){
		// Clear and reset timer
		clearTimeout(timer);
		if (states.hidePanels) timer = setTimeout(hide, delay);
	}

	return {
		init: init,
		show: show,
		hide: hide,
		resetTimer: resetTimer
	}

})();

export {hoverOverlay};