import {states} from '../states.js';
import {options} from '../options.js';
import {menu} from '../shared/menu.js';
import {panelMessage} from '../shared/panelMessage.js';
import {fullscreenButton} from '../shared/fullscreenButton.js';
import {images} from './images.js';
import {waveform} from './waveform.js';
import {datGUI} from './datGUI.js';
import {stats} from './stats.js';
import {projectorFraming} from './images_projectorFraming.js';
import {wheelFraming} from './images_wheelFraming.js';
import {projectorSpeed} from './images_projectorSpeed.js';
import {controls} from './controls.js';
import {keyframes} from './keyframes.js';
import {menuOptions} from './menuOptions.js';
import {howlerAudio} from './howlerAudio.js';
import {shepherd} from './shepherd.js';
import {hoverOverlay} from './hoverOverlay.js';
import {sidePanel} from './sidePanel.js';

// Keyboard commands
const keyboardCommands = (function(){

	//data
	let keyboardTrigger = false;

	//options
	const framingAmount = 2;

	//bind events
	function bindEvents(){
		$(document).keydown(input);
		$(document).keyup(reset);
	}

	function init(){
		bindEvents();
	}

	async function input(e){
		// Source: https://stackoverflow.com/questions/1402698/binding-arrow-keys-in-js-jquery
		if(!options.keyboardInput) return;
		if (!images.getImagesLoaded()) return;
		if (states.postEditFormOpen || states.postExportPanelOpen || states.reportPanelOpen || states.remixPanelOpen) return;
		// Check audio context
		howlerAudio.checkAudioContext();
		if (states.screenMode) return;
		// Fire while key is pressed down
		if (e.which == 38 || e.which == 37) {
			//console.log("up or left arrow pressed");
			if (!createModeReady()) return;
			if (shepherd.isActive()) return;
			states.fantascope ? wheelFraming.changeFraming(framingAmount) : projectorFraming.changeFraming(framingAmount);
		}
		if (e.which == 40 || e.which == 39) {
			//console.log("down or right arrow pressed");
			if (!createModeReady()) return;
			if (shepherd.isActive()) return;
			states.fantascope ? wheelFraming.changeFraming(-framingAmount) : projectorFraming.changeFraming(-framingAmount);
		}
		// If not z for toggling panel visibility or b for toggling menu or f from fullscreen, check create mode status
		if ((e.which != 90 && e.which != 66 && e.which != 70) && !createModeReady()) return;
		// Fire once
		if (keyboardTrigger) return;
		keyboardTrigger = true;
		switch(e.which) {
			case 16:
				//console.log("Shift key pressed");
				states.smallDataIncrements = true;
				console.log("states.smallDataIncrements", states.smallDataIncrements);
				menuOptions.updateSmallDataUI();
				break;
			case 32:
				//console.log("Spacebar key pressed");
				if (states.tour || states.menuOpen) return;
				keyframes.togglePlayAll();
				break;
			case 77:
				//console.log("m pressed");
				if (states.fantascope || !states.mediaHasAudio) return;
				states.audio = !states.audio;
				console.log("states.audio", states.audio);
				states.audio ? projectorSpeed.playSound() : projectorSpeed.stopSound();
				controls.updateUI();
				break;
			case 88:
				//console.log("x pressed");
				if (states.fantascope || !options.projectorSoundEffects) return;
				states.soundFX = !states.soundFX;
				console.log("states.soundFX", states.soundFX);
				states.soundFX ? projectorSpeed.playSoundEffects() : projectorSpeed.stopSoundEffects();
				controls.updateUI();
				break;
			case 87:
				//console.log("w pressed");
				if (states.fantascope || !states.mediaHasAudio) return;
				states.waveform ? waveform.close() : waveform.open();
				break;
			case 73:
				//console.log("i pressed");
				states.info = !states.info;
				console.log("states.info", states.info);
				stats.updateUI();
				if (options.showDatGUI) states.info ? datGUI.open() : datGUI.close();
				break;
			case 80:
				//console.log("p pressed");
				keyframes.addCurrent();
				break;
			case 70:
				//console.log("f pressed");
				fullscreenButton.toggle();
				// Note: keyup event not firing after fullscreen
				reset()
				break;
			case 90:
				//console.log("z pressed");
				states.enableImageZoom = true;
				console.log("states.enableImageZoom", states.enableImageZoom);
				break;
			case 72:
				//console.log("h pressed");
				if (states.tour) return;
				states.hidePanels = !states.hidePanels;
				console.log("states.hidePanels", states.hidePanels);
				panelMessage.updateUI();
				!states.hidePanels ? hoverOverlay.show() : hoverOverlay.hide();
				sidePanel.expand({closeAll: true});
				break;
			case 81:
				//console.log("q pressed");
				menu.toggle();
				hoverOverlay.show();
				break;
			case 76:
				//console.log("l pressed");
				states.fantascope ? wheelFraming.reset() : projectorSpeed.resetFrame();
				break;	
			// Exit this handler for other keys
			default: return; 
		}
		// Prevent the default action
		e.preventDefault(); 
	}

	function reset(){
		keyboardTrigger = false;
		if (!createModeReady()) return;
		states.smallDataIncrements = false;
		console.log("states.smallDataIncrements", states.smallDataIncrements);
		menuOptions.updateSmallDataUI();
		states.enableImageZoom = false;
		console.log("states.enableImageZoom", states.enableImageZoom);
	}

	function createModeReady(){
		return states.createMode && !states.initialPhoto ? false : true;
	}

	return {
		init: init
	};

})(); //keyboardCommands

export {keyboardCommands};