import axios from "axios";
const queryString = require('query-string');
import {states} from '../states.js';
import {options} from '../options.js';
import {delay} from '../shared/util.js';
import {convertObjToOriginal} from '../shared/objectMutations.js';
import {postRequests} from '../shared/postRequests.js';
import {queueRequests} from '../shared/queueRequests.js';
import {encodeRequests} from '../shared/encodeRequests.js';
import {extractHighResImagesAfterPostCreated} from '../shared/extractImages.js';
import {keyframes} from './keyframes.js';
import {createFilm} from './images_createFilm.js';
import {images} from './images.js';
import {paramOptions} from "./paramOptions.js";
import {topModal} from "../shared/topModal.js";
import {modals} from "./modals.js";
import {opaqueBackground} from '../shared/opaqueBackground.js';

const exportPanel = (function(){

	//states
	let exportDataSent = false;
	let extractingHighResImages = false;
	let maxExportsReached = false;
	let maxStorageReached = false;
	let userNotFound = false;
	let highResCheckboxDisabled = false;
	let highResCreatePost = false;
	let paramOptionsSelection = false;

	//options
	const defaultDuration = 10;
	const minDuration = 10;
	const maxDuration = 60;
	const maxHighResFantascopeExportWidth = 1920;
	const maxLowResCreatePostExportWidth = 640;
	const fallbackExportWidth = 640;
	const fallbackExportHeight = 480;
	const centerMessageSuccessText = "Print in progress...<br>Go to the Prints gallery to view exported video";
	const centerMessageErrorText = "Error during print:<br>";
	const extractingHighResText = "Extracting high resolution images...";
	const extractingHighResFailedText = "Extracting high resolution images failed";
	const timerPollDatabase = 10000;

	//data
	let pollTimer;
	let widthLowRes;
	let heightLowRes;
	let widthHighRes;
	let heightHighRes;
	let aspectRatio;
	let userName;
	const switchesData = [
		{
			id:"exportHighRes",
			name:"highRes",
			text: ""
		},
		{
			id:"exportTitleCard",
			name:"titleCard",
			text: "Show title card"
		},
		{
			id:"exportAudio",
			name:"audio",
			text: "Audio",
			audioOnly: true
		},
		{
			id:"exportSoundFX",
			name:"soundFX",
			text: "Sound effects",
			filmOnly: true
		},
		{
			id:"exportFadeIn",
			name:"fadeIn",
			text: "Fade in"
		},
		{
			id:"exportFadeOut",
			name:"fadeOut",
			text: "Fade out"
		},
		{
			id:"exportRampUp",
			name:"rampUp",
			text: "Ramp up"
		},
		{
			id:"exportRampDown",
			name:"rampDown",
			text: "Ramp down"
		},
		{
			id:"exportAutoFrame",
			name:"autoFrame",
			text: "Auto frameline",
			filmOnly: true
		}
	]

	//cache DOM
	const $el = $("#export-panel");
	const $form = $el.find('form');
	const $switches = $el.find(".switches");
	const $close = $el.find('.close');
	const $exportDuration = $el.find('#export-duration');
	const $exportDurationValue = $el.find('#export-duration-value');
	const $exportButton = $el.find("#export-button");
	const $titlePreview = $el.find("#title-preview");
	const $authorPreview = $el.find("#author-preview");
	const $remixAuthor = $el.find("#remix-author");
	const $yearPreview = $el.find("#year-preview");
	const $exportFields = $el.find("#export-fields");
	const $exportOptions = $el.find("#export-options");
	const $centerMessage = $el.find(".center-message");
	const $centerMessageText = $centerMessage.find("p");
	const $spinner = $centerMessage.find(".spinner");
	const $icons = $centerMessage.find(".icon");
	const $successIcon = $centerMessage.find(".success");
	const $errorIcon = $centerMessage.find(".error");
	const $remainingExports = $el.find("#remaining-exports");
	let checkboxes;
	let $highResCheckbox;
	let $resolutionValue;
	// Note: cached from outside module
	const $paramOptions = $('#param-options');
	const $insertParamOptions = $el.find('.insert-param-options');
	const $wrapParamOptions = $el.find('#wrap-param-options');
	const $nextExportSetting = $el.find('#next-export-setting');

	function cacheDOM(){
		checkboxes = $form.find("input[type=checkbox]");
		$highResCheckbox = $el.find("#exportHighRes");
		$resolutionValue = $el.find("#resolution-value");
	}

	//bind events
	$close.on('click', hide);
	$exportButton.on('click', exportMedia);
	$exportDuration[0].addEventListener('input', function() {
		setMessage({$elm: $exportDurationValue, msg: this.value});
	});
	$nextExportSetting.on('click', ()=>{
		paramOptionsSelection = false
		updateView()
	});

	function bindEvents(){
		$highResCheckbox.off().change(function() {
			this.checked ? updateHighResCheckboxText({highRes: true}) : updateHighResCheckboxText({highRes: false});
		});
	}

	$form.on('submit', preventDefault);

	// Init
	/*-------------------------------*/

	async function init(){
		if (states.auth){
			if (!states.user){
				userNotFound = true;
				console.log("userNotFound", userNotFound);
				error("User not found");
				return;
			}
			// Set user name
			userName = states.user.given_name && states.user.family_name ?  `${states.user.given_name} ${states.user.family_name}` : null;
			// Get user encodes count from beginning of month
			const counts = await axios.get('/counts/month/user');
			if (counts.status != 200){
				error("Upload server error");
				return;
			};
			console.log("counts.data", counts.data);
			const encodesLengthThisMonth = counts.data.encodesLength
			// Set max exports based on user subscription
			const maxExports = states.proUser ? options.maxExportsPro : options.maxExports;
			// If max exports has been reached, show error
			if (encodesLengthThisMonth >= maxExports){
				maxExportsReached = true;
				console.log("maxExportsReached", maxExportsReached);
				error(`Maximum number of monthly exports reached`);
				return;
			}
		}
		// Set initial duration values
		$exportDuration[0].value = defaultDuration;
		$exportDuration[0].min = minDuration;
		$exportDuration[0].max = maxDuration;
		// Set aspect ratio
		aspectRatio = (states.mediaData.widthOriginal / states.mediaData.heightOriginal)
		|| (states.mediaData.widthResize / states.mediaData.heightResize)
		|| (fallbackExportWidth / fallbackExportHeight);
		console.log("aspectRatio", aspectRatio);
		// Set resolution values for create posts
		// Note: no images are resized for create posts
		if (!states.mediaData.mediaUpload){
			if (states.mediaData.widthOriginal > maxLowResCreatePostExportWidth) highResCreatePost = true;
			console.log("highResCreatePost", highResCreatePost);
			// Set low resolution values
			widthLowRes = highResCreatePost ? maxLowResCreatePostExportWidth : states.mediaData.widthOriginal || fallbackExportWidth;
			heightLowRes = widthLowRes / aspectRatio;
			// Set high resolution values
			widthHighRes = states.mediaData.widthOriginal || fallbackExportWidth;
			heightHighRes = widthHighRes / aspectRatio;
		}
		// Set resolution values for uploaded media posts
		// Note: Images are resized on server. Width and height resize vales are defined in post.
		else {
			// Set low resolution values
			widthLowRes = !states.fantascope ? states.mediaData.widthResize || fallbackExportWidth : fallbackExportWidth;
			heightLowRes = !states.fantascope ? states.mediaData.heightResize || widthLowRes / aspectRatio : widthLowRes / aspectRatio;
			// Set high resolution values
			const fantascopeHighResResizeWidthExceedsThreshold = states.fantascope && states.mediaData.widthResizeHighRes > maxHighResFantascopeExportWidth ? true : false;
			if (states.fantascope && fantascopeHighResResizeWidthExceedsThreshold){
				widthHighRes = maxHighResFantascopeExportWidth;
				heightHighRes = widthHighRes / aspectRatio;
			} else {
				widthHighRes = states.mediaData.widthResizeHighRes || fallbackExportWidth;
				heightHighRes = states.mediaData.heightResizeHighRes || widthHighRes / aspectRatio;
			}
		}
		// Round resolution values
		widthLowRes = Math.round(widthLowRes)
		heightLowRes = Math.round(heightLowRes)
		widthHighRes = Math.round(widthHighRes)
		heightHighRes = Math.round(heightHighRes)
		// Restrict export width for print preview if user is not subscribed
		if (!states.proUser){
			widthLowRes = 300
			heightLowRes = Math.round(widthLowRes / aspectRatio)
		}
		// Set high resolution checkbox text
		switchesData.find(elm => elm.name === "highRes").text = `<span id="resolution-value">${widthLowRes}x${heightLowRes}</span> px`
		// Update form
		updateForm();
		// Create switches
		createSwitches();
		// Reset form
		resetForm();
		// Init high resolution image extraction
		initHighResImageExtraction();
	}

	// Create
	/*-------------------------------*/

	function createSwitches(){
		switchesData.forEach(element => {
			console.log("element", element)
			if (!states.mediaHasAudio && element.audioOnly) return;
			if (states.fantascope && element.filmOnly) return;			
			createElement(element)
		});
		// Cache DOM
		cacheDOM();
		// Bind events
		bindEvents();
	}

	function updateSwitches(){
		// Remove switches
		$switches.empty();
		// Create switches
		createSwitches();
		// Reset form
		resetForm();
	}

	function createElement(element){
		const str = `<div class="container">
						<label class="switch" for="${element.id}">
							<input type="checkbox" id="${element.id}" name="${element.name}"/>
							<div class="slider round"></div>
						</label>
						<p class="fixed-width">${element.text}</p>
					</div>`
		// Append to DOM
		$switches.append(str);
	}

	// Image extraction
	/*-------------------------------*/

	async function initHighResImageExtraction(){
		try {
			if (!states.proUser || (!states.userOwnsPost && !states.mediaData.exploreGallery)) throw("High resolution export not allowed");
			// Return without error if create mode or create post with high res media
			if ((states.createMode || !states.mediaData.mediaUpload) && highResCreatePost) return;
			// Disable high res checkbox if create mode or create post without high res media
			if ((states.createMode || !states.mediaData.mediaUpload) && !highResCreatePost) throw("Media is not high resolution");
			if (!states.fantascope && states.mediaData.failedHighRes) throw("High resolution images failed to process");
			if (!states.fantascope && !states.mediaData.processedHighRes){
				// Check if media resolution is higher than low resolution default
				if (!states.mediaData.widthOriginal || widthLowRes >= states.mediaData.widthOriginal) throw("Media is not high resolution");
				extractingHighResImages = true;
				console.log("extractingHighResImages", extractingHighResImages);
				// Update center message
				updateCenterMessasge({spinner: true, msg: extractingHighResText});
				// Poll database on interval
				pollTimer = setInterval(pollDB, timerPollDatabase);
				// Note: check if server is processing high resolution images to prevent redundant jobs being added to queue
				if (states.mediaData.processingHighRes) return;
				// Add job to queue
				await extractHighResImagesAfterPostCreated({
					mediaId: `${states.mediaData.mediaId}`
				});
				// Update post
				// Note: since extracting high resolution images can be called multiple times if the user refreshes post page, indication in the post is needed to prevent redundant jobs being added to queue
				const dataObj = {processingHighRes: true}
				const post = await postRequests.updatePost(states.mediaData.mediaId, dataObj);
				console.log("post", post);
				if (!post) console.error("Post not found");
				return;
			}
			if (states.fantascope && !states.mediaData.widthResizeHighRes) throw("No high resolution image found");
			if (widthLowRes >= widthHighRes) throw("No high resolution image found");
		} catch(err){
			console.log(err);
			console.log("Stop polling database");
			clearInterval(pollTimer);
			extractingHighResImages = false;
			console.log("extractingHighResImages", extractingHighResImages);
			disableHighResCheckbox();
			// Reset center message
			resetCenterMessage();
		}
	}

	async function pollDB(){
		if (states.mediaData.processedHighRes || states.mediaData.failedHighRes){
			console.log("Stop polling database");
			clearInterval(pollTimer);
			extractingHighResImages = false;
			console.log("extractingHighResImages", extractingHighResImages);
			return;
		}
		// Poll database for updated post if high res images are not processed and not failed
		console.log("Poll database");
		// Get private user post
		const post = await postRequests.getPrivatePost(states.mediaData.mediaId);
		console.log("post", post);
		if (!post) {
			console.error("Error getting post");
			console.log("Stop polling database");
			clearInterval(pollTimer);
			extractingHighResImages = false;
			console.log("extractingHighResImages", extractingHighResImages);
			disableHighResCheckbox();
			return;
		}
		// Set state
		states.mediaData = post;
		console.log("states.mediaData", states.mediaData);
		// High res images processed
		if (states.mediaData.processedHighRes){
			extractingHighResImages = false;
			console.log("extractingHighResImages", extractingHighResImages);
			// Update resolution values
			widthHighRes = states.mediaData.widthResizeHighRes || fallbackExportWidth;
			heightHighRes = states.mediaData.heightResizeHighRes || widthHighRes / aspectRatio;
			// Round resolution values
			widthHighRes = Math.round(widthHighRes)
			heightHighRes = Math.round(heightHighRes)
			// Update high res checkbox UI
			if (widthLowRes >= widthHighRes) disableHighResCheckbox();
			// Reset center message
			resetCenterMessage();
			return;
		}
		// High res images failed to process
		if (states.mediaData.failedHighRes) {
			extractingHighResImages = false;
			console.log("extractingHighResImages", extractingHighResImages);
			disableHighResCheckbox();
			error(extractingHighResFailedText);
		}
	}

	// Form
	/*-------------------------------*/

	function updateForm(){
		$titlePreview[0].value = states.mediaData.title || 'Untitled';
		$authorPreview[0].value = states.mediaData.mediaAuthor;
		// If user name is an email address, do not input value
		if (userName && userName.length > 0 && !userName.includes('@')) $remixAuthor[0].value = userName;
		$yearPreview[0].value = states.mediaData.year;
	}

	function resetForm(){
		// Set all checkboxes to true except soundFX
		checkboxes.each(function(index, element){
			//console.log(index, element)
			const name = $(element).attr('name');
			// Note: return true is equivalent to continue
			if (name == 'highRes') return true;
			if (name == 'soundFX') return true;
			element.checked = true;
		});
		if (highResCheckboxDisabled) disableHighResCheckbox();
		// Set duration to default value
		$exportDuration[0].value = defaultDuration;
		setMessage({$elm: $exportDurationValue, msg: defaultDuration});
	}

	function resetCenterMessage(){
		if (maxExportsReached || maxStorageReached || userNotFound || extractingHighResImages) return;
		// Show fields and options
		$exportFields.removeClass('hide');
		$exportOptions.removeClass('hide');
		$wrapParamOptions.removeClass('hide');
		// Enable export buton
		updateExportButton({enable: true});
		// Hide center message
		$centerMessage.addClass('hide');
		$spinner.hide();
		$icons.hide();
		// Empty text
		$centerMessageText.html("");
	}

	function updateCenterMessasge({error = false, spinner = false, msg} = {}){
		// Hide fields and options
		$exportFields.addClass('hide');
		$exportOptions.addClass('hide');
		$wrapParamOptions.addClass('hide');
		// Disable export buton
		updateExportButton({enable: false});
		// Show icon or spinner
		$spinner.hide();
		$icons.hide();
		if (spinner) $spinner.show();
		else error ? $errorIcon.show() : $successIcon.show();
		// Update text
		$centerMessageText.html(msg);
		// Show center message
		$centerMessage.removeClass('hide');
	}

	function disableHighResCheckbox(){
		$highResCheckbox.closest('label').addClass('disable');
		highResCheckboxDisabled = true;
		console.log("highResCheckboxDisabled", highResCheckboxDisabled);
	}

	function updateHighResCheckboxText({highRes}){
		const textUse = highRes
		? `${widthHighRes}x${heightHighRes}`
		: `${widthLowRes}x${heightLowRes}`
		setMessage({$elm: $resolutionValue, msg: textUse});
	}

	function validateForm(){
		return $form[0].checkValidity();
	}

	function preventDefault(e){
		// Prevent form submitting
		e.preventDefault();
	}

	// Export
	/*-------------------------------*/

	async function exportMedia(){
		// Only allow exports for user owned projects or explore gallery media
		if (!states.mediaData.exploreGallery && !states.userOwnsPost) return;
		if (maxExportsReached || maxStorageReached || userNotFound) return;
		try {
			if (!states.auth) throw("Must log in to export");
			// Validate form
			if (!validateForm()) throw("Form validation fail");
			console.log("Form validation pass");
			// If in create mode, update post to record last frame
			if (states.createMode) createFilm.updatePost();
			// Get form data
			const formData = new FormData($form[0]);
			// Convert checkbox checked state to value and append to form data
			// Source: https://stackoverflow.com/questions/33487360/formdata-and-checkboxes
			$.each(checkboxes, function(key, val) {
				formData.append($(val).attr('name'), $(this).is(':checked'))
			});
			// Convert formData to object
			const formDataObj = Object.fromEntries(formData);
			console.log("formDataObj", formDataObj);
			// Convert object values to original type
			convertObjToOriginal(formDataObj);
			console.log("formDataObj", formDataObj);
			// Check permissions
			if (formDataObj.highRes == true && (!states.proUser || (!states.userOwnsPost && !states.mediaData.exploreGallery) || (!states.mediaData.mediaUpload && !highResCreatePost))) throw("High resolution export not allowed");
			// Get current keyframes
			const currentKeyframes = states.keyframesToSave
			console.log("currentKeyframes", currentKeyframes)			
			// Check if current keyframes exist
			const currentKeyframesExist = currentKeyframes.length > 0;
			console.log("currentKeyframesExist", currentKeyframesExist);
			if (!currentKeyframesExist) throw("Current keyframes do not exist")
			// Get param string array of current keyframes
			const paramStringArray = await keyframes.getParamStringArray({keyframes:currentKeyframes});
			console.log("paramStringArray", paramStringArray);
			// Check if keyframes string exists
			if (paramStringArray.length < 1) throw("No keyframes found");
			// Set export settings
			// Export settings for client browser
			// Note: these are converted to URL params and passed to client browser during export via screen route media player
			// Note: width, height, fps, watermark and audio are only relavent for the encode settings on server, not for client browser rendering
			// If fps in playback params exceeds 30, set encode fps on server to 60. Otherwise, set it to 30.
			let playbackParamsFpsExceeds30 = false;
			for(const keyframe of currentKeyframes){
				//console.log("keyframe", keyframe);
				if (keyframe.frameRate > 30){
					playbackParamsFpsExceeds30 = true;
					break;
				}
			}
			console.log("playbackParamsFpsExceeds30", playbackParamsFpsExceeds30);
			const serverSettings = {
				width: !formDataObj.highRes ? widthLowRes : widthHighRes,
				height: !formDataObj.highRes ? heightLowRes : heightHighRes,
				fps: playbackParamsFpsExceeds30 ? 60 : 30,
				watermark: !states.proUser
			}
			const settingsObj = {...formDataObj, ...serverSettings};
			console.log("settingsObj", settingsObj);
			// Set encode settings
			const {_id, __v, createdAt, updatedAt, ...encodeData} = states.mediaData;
			// Generate timestamp for encode id
			const timestamp = `${Math.floor(Date.now())}`;
			encodeData.encodeId = timestamp;
			encodeData.keyframes = currentKeyframes;
			encodeData.failed = false;
			encodeData.processed = false;
			encodeData.encodeSettings = settingsObj;
			encodeData.post = states.mediaData._id;
			// Create encode
			await createEncode(encodeData);
			// Convert search params to object
			const searchParams = new URLSearchParams(settingsObj);
			const paramsString = searchParams.toString();
			// Create array with params string
			const settingsStringArray = new Array(paramsString);
			console.log("settingsStringArray", settingsStringArray);
			// Convert array to query string
			const stringifiedArrays = queryString.stringify({
				p: paramStringArray,
				s: settingsStringArray
			}, {arrayFormat: 'bracket'});
			console.log("stringifiedArrays", stringifiedArrays);
			// Encode settings for server
			// Note: Ensure duration is longer than maximum export duration option since Timecut stops based on client function. The actual exported media duration is set by user in the export panel and passed to client browser via URL params.
			// Reference: https://github.com/tungs/timecut#cli-options-stop-function-name
			const encodeSettings = {
				encodeId: timestamp,
				mediaId: states.mediaData.mediaId,
				urlParams: stringifiedArrays,
				duration: 180,
				width: settingsObj.width,
				height: settingsObj.height,
				fps: settingsObj.fps,
				audio: settingsObj.audio,
				soundFX: settingsObj.soundFX,
				imagesFolderName: options.imagesFolderName,
				thumbsFolderName: options.thumbsFolderName,
				watermark: settingsObj.watermark
			}
			// Add encode job to queue
			await addEncodeJobToQueue(encodeSettings);
			// Update center message
			updateCenterMessasge({msg: centerMessageSuccessText});
			// Set local state
			exportDataSent = true;
			console.log("exportDataSent", exportDataSent);
		} catch(err){
			console.log(err);
			error(`${centerMessageErrorText}${err}`);
		}
	}

	// Error
	/*-------------------------------*/

	function error(msg){
		// Update center message
		updateCenterMessasge({error: true, msg: msg});
	}

	// Create encode
	/*-------------------------------*/
	
	function createEncode(encodeData){
		return new Promise(async (resolve, reject) => {
			// Create encode
			const encode = await encodeRequests.createEncode(encodeData);
			console.log("encode", encode);
			if (!encode) return reject("Error creating encode.");
			resolve();
		});
	}

	// Add job
	/*-------------------------------*/

	async function addEncodeJobToQueue(encodeData){
		return new Promise(async (resolve, reject) => {
			// Add encode job to queue
			const response = await queueRequests.addEncodeJob(encodeData);
			console.log("response", response);
			if (!response) return reject("Error adding encode job to queue.")
			resolve();
		});
	}

	// UI
	/*-------------------------------*/

	async function show(){
		if (states.postExportPanelOpen) return
		await modals.closePanel()
		// Move param options to panel
		$paramOptions.insertAfter($insertParamOptions);
		$paramOptions.removeClass('hidden')
		const roundedPlaybackParams = await images.getRoundedPlaybackParams();
		const keyframeList = keyframes.getKeyframeList()
		if (Object.keys(roundedPlaybackParams).length == 0 && keyframeList.length == 0) return
		await keyframes.updateCopyUI()
		paramOptions.updateCurrentParamsUI()
		if (keyframeList.length > 0) paramOptions.selectCurrentPhases()
		// Update view
		paramOptionsSelection = true
		updateView()
		// Get user encodes count from beginning of month
		const counts = await axios.get('/counts/month/user');
		if (counts.status != 200) error("Upload server error");
		console.log("counts", counts.data);
		const encodesLengthThisMonth = counts.data.encodesLength
		// Set remaining exports in UI
		if (states.proUser) $remainingExports.text(options.maxExportsPro-encodesLengthThisMonth)
		// Set max exports based on user subscription
		const maxExports = states.proUser ? options.maxExportsPro : options.maxExports;
		// If max exports has been reached, show error
		if (encodesLengthThisMonth >= maxExports){
			maxExportsReached = true;
			console.log("maxExportsReached", maxExportsReached);
			error(`Maximum number of monthly exports reached`);
		}
		// If max storage reached, show error
		const maxStorage = states.proUser ? options.maxStoragePro : options.maxStorage
		if (states.currentStorage > maxStorage){
			maxStorageReached = true;
			console.log("maxStorageReached", maxStorageReached);
			error(`Maximum storage reached`);
		}
		// Set state
		states.postExportPanelOpen = true;
		console.log("states.postExportPanelOpen", states.postExportPanelOpen);
		topModal.checkOpenPanels()
		$el.show();
		$el.addClass('show');
		opaqueBackground.fadeIn({duration: 500});
	}

	async function hide(){
		$el.removeClass('show');
		opaqueBackground.fadeOut({duration: 500});
		await delay(500);
		$el.hide();
		// Set local state
		exportDataSent = false;
		console.log("exportDataSent", exportDataSent);
		states.postExportPanelOpen = false;
		console.log("states.postExportPanelOpen", states.postExportPanelOpen);
		// Reset center message
		resetCenterMessage();
	}

	async function updateView(){
		if (paramOptionsSelection){
			$form.hide()
			$wrapParamOptions.show()
		} else {
			$wrapParamOptions.fadeOut(400, () => {
				$form.fadeIn(400)
				topModal.checkOpenPanels()
			})
		}
	}

	function setMessage({$elm, msg}){
		$elm.html(msg);
	}

	function updateExportButton({enable}){
		if (maxExportsReached || maxStorageReached || userNotFound || exportDataSent || extractingHighResImages) enable = false;
		enable ? $exportButton.attr('disabled', false) : $exportButton.attr('disabled', true);
	}

	return {
		init: init,
		show: show,
		hide: hide,
		updateForm: updateForm,
		updateExportButton: updateExportButton,
		updateSwitches: updateSwitches
	}

})();

export {exportPanel};