import axios from "axios";
import {S3_PATH_UPLOAD} from '../config.js';
import {states} from '../states.js';
import {options} from '../options.js';
import {postRequests} from '../shared/postRequests.js';
import {centerModal} from './centerModal.js';
import {socketEmit} from './socketEmit.js';
import {keyframes} from './keyframes.js';
import {controls} from './controls.js';
import {wheelSpeed} from './images_wheelSpeed.js';
import {wheelFraming} from './images_wheelFraming.js';
import {checkImage} from '../shared/loadImages.js';
import {editFormCustom} from './editFormCustom.js';
import {sidePanel} from './sidePanel.js';
// Note: importing from edit route
import {form} from '../edit/form.js';

const createWheel = (function(){

	//data
	let mediaPath;
	let timestamp;
	let imgId;
	let imgUrl;
	let counter = 0;

	//options
	const fadeOnNewImage = false;
	const fadeDuration = 200;

	//cache DOM
	const $el = $('#images');
	const el = $el[0];
	let $img;
	let img;

	function cacheElms(){
		$img = $el.find('img');
		img = $img[0];
	}

	// Init
	/*-------------------------------*/

	async function init(){
		return new Promise(async resolve => {
			timestamp = Math.floor(Date.now());
			states.mediaData.mediaId = timestamp;
			states.mediaId = timestamp;
			mediaPath = `${S3_PATH_UPLOAD}/${timestamp}/${options.imagesFolderName}/`;
			resolve();
		});
	}

	// Create
	/*-------------------------------*/
	async function initCreateImage(){
		if (!states.initialPhoto){
			states.initialPhoto = true;
			console.log("states.initialPhoto", states.initialPhoto);
			counter++;
			imgId = counter;
			imgUrl = `${mediaPath}${counter}.jpg`;
			updateState();
			centerModal.updateUI();
			try{
				// Create post
				const post = await createPost(states.mediaData);
				states.createPostCreated = true;
				console.log("states.createPostCreated", states.createPostCreated);
				socketEmit.send("desktopCreatePostCreated");
				// Create count record for create post
				await axios.post(`/counts/create-mode-post/${post._id}`)
				// Set state
				states.mediaData = post;
				// Init form from edit route
				form.init();
				// Init custom edit form
				editFormCustom.init();
				// Update enable
				sidePanel.updateEnable();
				keyframes.updateEnable();
				controls.updateEnable();
			} catch(err){
				console.error(err)
				return;
			}
			await createImage({id: imgId, url: imgUrl});
			cacheElms();
			wheelSpeed.cacheElms();
			wheelFraming.cacheElms();
			return;
		}		
		if (!states.createPostCreated) return;
		if (fadeOnNewImage) await fadeOut();
		const imageReloaded = await reloadImg({imgToReload: img, url: imgUrl});
		if (!imageReloaded) return console.log("Error reloading image");
		if (fadeOnNewImage) fadeIn();
	}

	async function createImage({id, url} = {}){
		const newImage = new Image();
		newImage.src = url;
		newImage.id = `img${id}`;
		newImage.className = "lastImage";
		el.appendChild(newImage);
		try {
			await checkImage(newImage);
			return true;
		} catch(err){
			console.log(err);
			return false;
		}
	}

	// Source: https://stackoverflow.com/questions/1077041/refresh-image-with-a-new-one-at-the-same-url
	const reloadImg = ({imgToReload, url} = {}) => {
		return new Promise(async resolve => {
			try{
				if (!imgToReload) return resolve(false);
				await fetch(url, { cache: 'reload', mode: 'cors' });
				imgToReload.src = url;
				resolve(true);
			} catch(err){
				console.error(err)
				resolve(false);
			}
		});
	}

	function updateState(){
		states.lastFrame = counter;
	}

	// UI
	/*-------------------------------*/

	function fadeIn(){
		return new Promise(resolve => {
			$el.fadeIn(fadeDuration, resolve);
		});
	}

	function fadeOut(){
		return new Promise(resolve => {
			$el.fadeOut(fadeDuration, resolve);
		});
	}

	// Post requests
	/*-------------------------------*/

	function createPost(obj){
		return new Promise(async (resolve, reject) => {
			// Create post
			const post = await postRequests.createPost(obj);
			if (!post){
				console.log("Post not created")
				reject();
				return;
			}
			console.log("Create post", post);
			resolve(post);
		});
	}

	return {
		init: init,
		initCreateImage: initCreateImage
	}

})();

export {createWheel};