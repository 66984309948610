import {states} from '../states.js';
import {images} from './images.js';
import {back} from './back.js';

// Loader
const loader = (function(){

	//state
	let loaded = false;
	let loading = false;

	//data
	let progressUse = 0;

	//options
	const fadeDuration = 1000;
	const showCancelButton = true;

	//cache DOM
	const $el = $('#loader');
	const $progress = $el.find('#loader-message p span');
	const $cancelButton = $el.find('#cancel-button');

	//bind events
	$cancelButton.on('click', function(){
		if (states.screenMode) return;
		cancelUpload();
		back.goBack();
	});

	function init(){
		if (loaded) return;
		loaded = true;
		console.log("loaded", loaded);
		if (!showCancelButton || states.screenMode) hideCancelButton();
		makeVisible();
	}

	function updateUI(data){
		progressUse = Math.ceil(data);
		//console.log("progressUse", progressUse);
		$progress.text(progressUse);
	}

	function makeVisible(){
		$el.css("visibility", "visible");
	}

	/*Note: Fading style same as center-modal. Opacity used for smooth fading*/
	function fadeIn(){
		return new Promise(resolve => {
			reset();
			loading = true;
			console.log("loading", loading);
			show();
			$el.fadeTo(fadeDuration, 1, resolve);
		});
	}

	function fadeOut(){
		return new Promise(resolve => {
			$el.fadeTo(fadeDuration, 0, () => {
				hide();
				loading = false;
				console.log("loading", loading);
				resolve();
			});
		});
	}

	function show(){
		$el.show();
	}

	function hide(){
		$el.hide();
	}

	function reset(){
		progressUse = 0;
		updateUI(progressUse);
		enableCancelButton();
	}

	function enableCancelButton(){
		$cancelButton.removeClass("avoid-clicks");
	}

	function disableCancelButton(){
		$cancelButton.addClass("avoid-clicks");
	}

	function hideCancelButton(){
		$cancelButton.hide();
	}

	function cancelUpload(){
		disableCancelButton();
		images.resetImages();
	}

	return {
		init: init,
		fadeIn: fadeIn,
		fadeOut: fadeOut,
		show: show,
		hide: hide,
		updateUI: updateUI,
		disableCancelButton: disableCancelButton,
		enableCancelButton: enableCancelButton,
		cancelUpload: cancelUpload
	};

})(); //loader

export {loader};