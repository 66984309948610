import {
	remoteOptions,
	initArray,
	playbackParams
} from '../database.js';
import {states} from '../states.js';
import {map} from '../shared/math.js';
import {wheelSpeed} from './images_wheelSpeed.js';
import {socketEmit} from './socketEmit.js';
import {controls} from './controls.js';

// Wheel fps
const wheelFps = (function(){

	//data
	let fpsInit,
		fpsUse,
		minFPS,
		maxFPS;

	//options
	// Affects amount of mobile remote rotation
	const minDialValueForFPS = 0;
	const maxDialValueForFPS = 200;

	function init(){
		// Define variables based on media data
		fpsUse = states.mediaData.fps; // Initial frame rate of source media
		// Define range of fps
		minFPS = 1;
		maxFPS = fpsUse*2;
		// Map fps limits to dial limits for initial fps value
		fpsInit = map(fpsUse, minFPS, maxFPS, minDialValueForFPS, maxDialValueForFPS);
		console.log("fpsInit", fpsInit);
		// Set default fps
		wheelSpeed.setFps(fpsUse);
		// Setup dat.GUI
		setupDatGUI();
	}

	function setupDatGUI(){
		// Setup dat.GUI array with initial values
		initArray({
			array: remoteOptions,
			id: "frameRate",
			minValue: minFPS,
			maxValue: maxFPS,
			value: fpsUse
		});
	}

	function changeFps(data){
		// Set fps
		fpsInit = fpsInit + data;
		// Limit fps
		if (fpsInit > maxDialValueForFPS) {
			fpsInit = maxDialValueForFPS;
			socketEmit.sendFlash();
		}
		if (fpsInit < minDialValueForFPS) {
			fpsInit = minDialValueForFPS;
			socketEmit.sendFlash();
		}
		//console.log("fpsInit", fpsInit);
		// Map dial limits to fps limits
		fpsUse = map(fpsInit, minDialValueForFPS, maxDialValueForFPS, minFPS, maxFPS);
		//console.log("fpsUse", fpsUse);
		// Set fps
		wheelSpeed.setFps(fpsUse);
		// Update datGUI params
		playbackParams.frameRate = fpsUse;
		// Update param info
		controls.updateParamInfo();
	}

	function getMinFPS(){
		return minFPS;
	}

	function getMaxFPS(){
		return maxFPS;
	}

	return {
		init: init,
		changeFps: changeFps,
		getMinFPS: getMinFPS,
		getMaxFPS: getMaxFPS
	};

})(); //wheelFps

export {wheelFps};