import {states} from '../states.js';
import {projectorSpeed} from './images_projectorSpeed.js';

// Record player playback for projector
// Note: sound playback does not function properly when record player switches directions
const rpPlaybackProjector = (function(){

	//data

		// Midpoint speed
		let rpMidpointSpeed,
			rpMidpointSpeedThresholdMin,
			rpMidpointSpeedThresholdMax,
			lockRpMidpointSpeed = false;

		// Playback settings for projector speed
		let thresholdBetweenDirectionSwitch,
			threshold,
			minScrollSpeed,
			maxScrollSpeed,
			minProjectorSpeed,
			maxProjectorSpeed;

	//options

		// Select playback profile
		if (states.recordPlayerMode){
			//rpPlaybackSettings1();
			//rpPlaybackSettings2();
			//rpPlaybackSettings3();
			rpPlaybackSettings4();
		}

		// Control speed of virtual projector by changing record player speed between 33, 45, and 78 RPM's
		// Note: change live moving average window size to 20 on node server
		// Note: common RPM's are 33, 45, and 78
		function rpPlaybackSettings1(){
			// Record player RPM
			const minRecordPlayerRPM = 33;
			const maxRecordPlayerRPM = 78;
			// Threshold
			thresholdBetweenDirectionSwitch = true; // Must be set to true for projector to stop when record player stops
			threshold = Math.floor(minRecordPlayerRPM / 10)-1; // Round down to nearest 10 and substract 1
			// Sroll speed
			minScrollSpeed = threshold;
			maxScrollSpeed = Math.floor(maxRecordPlayerRPM / 10); // Round down to nearest 10
			// Projector speed
			minProjectorSpeed = 1.2;
			maxProjectorSpeed = 1;
		}

		// Control speed of virtual projector through pitch control slider. Turntable settings: RPM = 45, Pitch range +-20%. Testing with AT-LP120-USB.
		// Note: change live moving average window size to 100 on node server
		// Note: common RPM's are 33, 45, and 78
		function rpPlaybackSettings2(){
			// Record player RPM
			const recordPlayerSpeed = 45;
			const pitchRange = .20;
			const minRecordPlayerRPM = recordPlayerSpeed - (recordPlayerSpeed * pitchRange);
			const maxRecordPlayerRPM = recordPlayerSpeed + (recordPlayerSpeed * pitchRange) - 1;
			// Threshold
			thresholdBetweenDirectionSwitch = true; // Must be set to true for projector to stop when record player stops
			threshold = Math.floor(minRecordPlayerRPM / 10); // Round down to nearest 10
			// Sroll speed
			minScrollSpeed = minRecordPlayerRPM / 10;
			maxScrollSpeed = maxRecordPlayerRPM / 10;
			// Projector speed
			// Note: Source frame rate in middle of pitch slider
			minProjectorSpeed = .5;
			maxProjectorSpeed = 1.5;
			// Midpoint speed
			lockRpMidpointSpeed = true;
			rpMidpointSpeed = (minRecordPlayerRPM + maxRecordPlayerRPM) / 20;
			rpMidpointSpeedThresholdMin = .05;
			rpMidpointSpeedThresholdMax = .2;
		}

		// Control speed of virtual projector through pitch control slider. Turntable settings: RPM = 45, Pitch range +-20%. Testing with AT-LP120-USB.
		// Note: smoother but less responsive than option 2
		// Note: change live moving average window size to 200 on node server
		// Note: common RPM's are 33, 45, and 78
		function rpPlaybackSettings3(){
			// Record player RPM
			const recordPlayerSpeed = 45;
			const pitchRange = .20;
			const minRecordPlayerRPM = recordPlayerSpeed - (recordPlayerSpeed * pitchRange);
			const maxRecordPlayerRPM = recordPlayerSpeed + (recordPlayerSpeed * pitchRange);
			// Threshold
			thresholdBetweenDirectionSwitch = true; // Must be set to true for projector to stop when record player stops
			threshold = Math.floor(minRecordPlayerRPM / 10); // Round down to nearest 10
			// Sroll speed
			minScrollSpeed = minRecordPlayerRPM / 10;
			maxScrollSpeed = maxRecordPlayerRPM / 10;
			// Projector speed
			// Note: Source frame rate in middle of pitch slider
			minProjectorSpeed = .5;
			maxProjectorSpeed = 1.5;
			// Midpoint speed
			lockRpMidpointSpeed = true; // Leave false for balancing frameline. Add .25 to recordPlayerSpeed. Increase live moving average window size.
			rpMidpointSpeed = (minRecordPlayerRPM + maxRecordPlayerRPM) / 20;
			rpMidpointSpeedThresholdMin = .04;
			rpMidpointSpeedThresholdMax = .04;
		}

		// Control speed of virtual projector through pitch control slider. Turntable settings: RPM = 45, Pitch range +-20%. Testing with AT-LP120-USB.
		// Note: change live moving average window size to 100 on node server
		// Note: common RPM's are 33, 45, and 78
		function rpPlaybackSettings4(){
			// Record player RPM
			const recordPlayerSpeed = 45;
			const pitchRange = .20;
			const minRecordPlayerRPM = recordPlayerSpeed - (recordPlayerSpeed * pitchRange);
			const maxRecordPlayerRPM = recordPlayerSpeed + (recordPlayerSpeed * pitchRange) - 1;
			// Threshold
			thresholdBetweenDirectionSwitch = true; // Must be set to true for projector to stop when record player stops
			threshold = Math.floor(minRecordPlayerRPM / 10); // Round down to nearest 10
			// Sroll speed
			minScrollSpeed = minRecordPlayerRPM / 10;
			maxScrollSpeed = maxRecordPlayerRPM / 10;
			// Projector speed
			// Note: Source frame rate at end of pitch slider
			minProjectorSpeed = 1/30;
			maxProjectorSpeed = 1;
		}

		// To do: Forward source frame rate at end of pitch slider. Reverse source frame rate at beginning of pitch slider. Projector stops at middle of pitch slider.

	// Init
	/*-------------------------------*/

	function init(){
		const rpPlaybackSettingsInit = {
			thresholdBetweenDirectionSwitch: thresholdBetweenDirectionSwitch,
			threshold: threshold,
			minScrollSpeed: minScrollSpeed,
			maxScrollSpeed: maxScrollSpeed,
			minProjectorSpeed: minProjectorSpeed,
			maxProjectorSpeed: maxProjectorSpeed
		}
		if (states.recordPlayerMode) projectorSpeed.setRpPlaybackSettings(rpPlaybackSettingsInit);
	}

	// Playback
	/*-------------------------------*/

	// Lock record player midpoint speed if within range
	function checkRpMidpointSpeed(scrollSpeed){
		if (!states.recordPlayerMode || !lockRpMidpointSpeed) return;
		if (scrollSpeed > rpMidpointSpeed-rpMidpointSpeedThresholdMin && scrollSpeed < rpMidpointSpeed+rpMidpointSpeedThresholdMax) {
			scrollSpeed = rpMidpointSpeed;
		} else if (scrollSpeed < -(rpMidpointSpeed-rpMidpointSpeedThresholdMin) && scrollSpeed > -(rpMidpointSpeed+rpMidpointSpeedThresholdMax)) {
			scrollSpeed = -rpMidpointSpeed;
		}
		projectorSpeed.setScrollSpeed(scrollSpeed);
	}

	// Detect pitch threshold
	function detectPitchThreshold(scrollSpeed){
		if (!states.recordPlayerMode) return;
		if (scrollSpeed > rpMidpointSpeed){
			console.log("Pitch faster");
			// Note: can change range of projector speed based on pitch threshold, for example:
			//minProjectorSpeed = 1.5;
			//maxProjectorSpeed = 0.5;
		} else if (scrollSpeed < rpMidpointSpeed){
			console.log("Pitch slower");
			// Note: can change range of projector speed based on pitch threshold, for example:
			//minProjectorSpeed = 1.99;
			//maxProjectorSpeed = 0.01;
		} else {
			console.log("Pitch normal");
		}
	}

	return {
		init: init,
		checkRpMidpointSpeed: checkRpMidpointSpeed,
		detectPitchThreshold: detectPitchThreshold
	};

})(); //rpPlaybackProjector

export {rpPlaybackProjector};