function deletePropsWithEmptyValues(obj) {
	for (const prop in obj) {
		if (obj[prop] === null || obj[prop] === undefined || obj[prop] === "") {
			delete obj[prop];
		}
	}
	return obj;
}

function setObjectPropsFalse(obj){
	Object.keys(obj).forEach(key => {
		obj[key] = false;
	})
}

const renameProp = (oldProp, newProp, obj) => {
	obj[newProp] = obj[oldProp]
	delete obj[oldProp]
}

// Source: https://stackoverflow.com/questions/5072136/javascript-filter-for-objects
function filterObject(obj, keep) {
	Object.keys(obj).forEach(key => {
		if (!keep.has(key)) delete obj[key]
	})
}

// Source: https://www.tutorialspoint.com/convert-the-number-or-boolean-type-json-object-from-string-type-to-its-original-in-javascript
// Source: https://codippa.com/how-to-convert-string-to-boolean-javascript
const convertObjToOriginal = (obj) => {
	const keys = Object.keys(obj);
	for(let i = 0; i < keys.length; i++){
		const key = keys[i];
		const num = parseInt(obj[key], 10);
		const val = obj[key];
		if(num && String(num) === val) obj[key] = num;
		else if(val === 'true' || val === 'false') obj[key] = (val == 'true');
	};
};

function convertObjValuesToFloat(obj) {
	for (const key in obj) {
		obj[key] = parseFloat(obj[key]);
	}
}

export {
	deletePropsWithEmptyValues,
	setObjectPropsFalse,
	filterObject,
	convertObjToOriginal,
	convertObjValuesToFloat
}