import {
	remoteOptions,
	initArray,
	playbackParams
} from '../database.js';
import {states} from '../states.js';
import {controls} from './controls.js';

// Projector framing
const projectorFraming = (function(){

	//data
	let timer;

	//options
	const framingAmount = 5;
	const smallFramingAmount = 1;

	function init(){
		// Setup dat.GUI
		setupDatGUI();
	}

	function setupDatGUI(){
		// Setup dat.GUI array with initial values
		initArray({
			array: remoteOptions,
			id: "framing",
			minValue: -100,
			maxValue: 100,
			value: 0
		});
	}

	function changeFraming(data){
		// Reset after period of inactivity
		// Clear and set timer
		clearTimeout(timer);
		timer = setTimeout(resetFraming, 200);
		let framingAmountUse = states.smallDataIncrements ? data*smallFramingAmount : data*framingAmount;
		// Scroll amount must be 1 or greater
		if (framingAmountUse > 0 && framingAmountUse < 1) framingAmountUse = 1;
		if (framingAmountUse < 0 && framingAmountUse > -1) framingAmountUse = -1;
		//console.log("framingAmountUse", framingAmountUse);
		// Scroll window
		window.scrollBy(0, framingAmountUse);
		// Update datGUI params
		playbackParams.framing = framingAmountUse;
		// Update param info
		controls.updateParamInfo();
	}

	function resetFraming(){
		playbackParams.framing = 0;
		// Update param info
		controls.updateParamInfo();
	}

	return {
		init: init,
		changeFraming: changeFraming
	};

})(); //projectorFraming

export {projectorFraming};