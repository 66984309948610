import {callFetch} from './fetchAPI';

// Encode requests
const encodeRequests = (function(){

	// Get
	/*-------------------------------*/

	// Get user encodes
	function getUserEncodes({page}) {
		return callFetch({
			url: `/encodes/user/${page}`,
			status: 200
		})
	}

	// Get private user encode
	function getPrivateEncode(id) {
		return callFetch({
			url: `/private-encode/${id}`,
			status: 200
		})
	}

	// Create
	/*-------------------------------*/

	// Create encode
	function createEncode(obj) {
		return callFetch({
			url: '/encodes',
			method: 'POST',
			data: obj,
			status: 201
		})
	}

	// Update
	/*-------------------------------*/

	// Update encode
	function updateEncode(id, obj) {
		return callFetch({
			url: `/encodes/${id}`,
			method: 'PATCH',
			data: obj,
			status: 200
		})
	}

	// Delete
	/*-------------------------------*/

	// Delete encode
	function deleteEncode(id) {
		return callFetch({
			url: `/encodes/${id}`,
			method: 'DELETE',
			status: 200
		})
	}

	return {
		getUserEncodes: getUserEncodes,
		getPrivateEncode: getPrivateEncode,
		createEncode: createEncode,
		updateEncode: updateEncode,
		deleteEncode: deleteEncode
	};

})(); //encodeRequests

export {encodeRequests};