const copy = require('clipboard-copy');
import {
	rootURL,
	S3_PATH,
	S3_PATH_UPLOAD
} from '../config.js';
import {states} from '../states.js';
import {options} from '../options.js';
import {tags} from '../database.js';
import {getRandomInteger, isBetween} from '../shared/math.js';
import {postRequests} from '../shared/postRequests.js';
import {alert} from '../shared/alert.js';
import {footer} from '../shared/footer.js';
import {centerMessage} from '../shared/centerMessage.js';
import {mediaRequests} from '../shared/mediaRequests.js';
import {audioUpload} from '../shared/audioUpload.js';
import {fullscreenSpinner} from '../shared/fullscreenSpinner.js';
import {menu} from '../shared/menu.js';

// Form
const form = (function(){

	//states
	let eventsBound = false;
	let loadingAudio = false;

	//data
	const pageId = states.pageId
	let postId;
	let media;
	let mediaPath;
	let currentPreviewImage;
	let randomFrame;
	let previousFrameRate;
	let pollTimer;
	let wedgeCounterTimer;
	const brokenImagePath = '/media-keep/thumbnails/broken.jpg';
	
	//options
	const fadeDuration = 1000;
	const timerPollDatabase = 10000;
	const changeWedgeCountWhileMouseDown = false;
	const fantascopeWedgeMin = 3;
	const fantascopeWedgeMax = 30;
	const defaultShareMsg = "Share link";
	const copyLinkSuccessMsg = "Link copied";
	const copyLinkErrorMsg = "Error";
	const alertDeletePostMsg = 'Are you sure you want to delete this project?';
	const alertDeleteAudioMsg = 'Are you sure you want to delete audio?';
	const wedgeCountWarningMsg = "Updating this will delete all existing phases";

	// Cache DOM
	/*-------------------------------*/
	const $el = $('#form');
	const $form = $el.find('form');
	const $response = $el.find('#response');
	const $responseMessage = $el.find('#response .message');
	const $preview = $el.find('#preview');
	const $options = $el.find('#options');
	const $refresh = $el.find('#refresh');
	const $remix = $el.find('#remix');
	const $screen = $el.find('#screen');
	const $share = $el.find('#share');
	const $shareMsg = $el.find("#share p");
	const $previewImg = $preview.find('img');
	const $uploadAudio = $el.find('#upload-audio');
	const $uploadAudioMsg = $el.find('#upload-audio p');
	const $uploadAudioInput = $el.find('#upload-audio-input');
	const $deleteAudio = $el.find('#delete-audio');
	const $deleteAudioMsg = $el.find('#delete-audio p');
	const $input = $el.find('input, textarea').not($uploadAudioInput);
	const titleInput = $el.find('#title')[0];
	const authorInput = $el.find('#author')[0];
	const yearInput = $el.find('#year')[0];
	const descriptionInput = $el.find('#description')[0];
	const orderInput = $el.find('#order')[0];
	const $orderField = $el.find('#order-field');
	const $radiosMainLabel = $el.find('#edit-radios .main-label');
	const $radioFields = $el.find('#edit-radios .field');
	const $radioInputs = $el.find('#edit-radios input');
	const accountAccessInput = $el.find('#account-access')[0];
	const privateLinkInput = $el.find('#private-link')[0];
	const $publicGalleryInput = $el.find('#public-gallery');
	const publicGalleryInput = $publicGalleryInput[0];
	const publicGalleryViewOnlyInput = $el.find('#public-gallery-view-only')[0];
	const $updateButton = $el.find('#update');
	const $cancelButton = $el.find('#cancel');
	const $deleteButton = $el.find('p#delete');
	const $spinner = $el.find('.spinner');
	const $mediaFailError = $el.find('#mediaFailError');
	const $fantascopeFormItems = $el.find('#fantascope-form-items');
	const $colorPicker = $el.find('#color-picker');
	const $colorPickerInput = $el.find('#color-input');
	const colorPickerInput = $colorPickerInput[0];
	const $wedgeCounter = $el.find('#wedge-counter');
	const $wedgePlus = $el.find('#wedge-counter #plus');
	const $wedgePlusWrap = $el.find('#wedge-counter #plusWrap');
	const $wedgeMinus = $el.find('#wedge-counter #minus');
	const $wedgeMinusWrap = $el.find('#wedge-counter #minusWrap');
	const $wedgeCount = $el.find('#wedge-counter #count');
	const $wedgeCountMsg = $el.find('#wedge-counter .message');
	const $remixLicenseMessage = $el.find('#remix-license-message');
	const $tagsField = $el.find('#tags-field');
	const $tagsContainer = $el.find('#tags');
	let $tags;

	// Bind events
	/*-------------------------------*/
	$refresh.find('.row').on('click', getRandomFrame);
	$remix.find('.row').on('click', () => goToLink(`/${media.mediaId}`));
	$screen.find('.row').on('click', () => goToLink(`/screen/${media.mediaId}`));
	$share.find('.row').on('click', copyLink);
	$share.find('.row').on('mouseleave', () => setMessage({$elm: $shareMsg, msg: defaultShareMsg}));
	$cancelButton.on('click', reset);
	$deleteButton.on('click', () => showAlert({msg: alertDeletePostMsg}));
	$form.on('submit', preventDefault);
	$uploadAudio.find('.row').on('click', () => {
		$uploadAudioInput.trigger("click");
	});
	$deleteAudio.find('.row').on('click', () => showAlert({msg: alertDeleteAudioMsg, deleteAudioPrompt: true}));
	$radioInputs.on('change', updateRemixLicenseMessage);

	// Change counter on click
	if (!changeWedgeCountWhileMouseDown){
		$wedgePlus.on('click', function(){
			tickWedgeCount({increase: true, thisElm: this})
		});
		$wedgeMinus.on('click', function(){
			tickWedgeCount({increase: false, thisElm: this})
		});
	}

	// Change counter while holding mouse down
	if (changeWedgeCountWhileMouseDown){
		$wedgePlus.on('mousedown', function(){
			//console.log("Pressing");
			tickWedgeCount({increase: true, thisElm: this})
			wedgeCounterTimer = setInterval(() =>{
				tickWedgeCount({increase: true, thisElm: this})
			}, 150);
		});
		$wedgeMinus.on('mousedown', function(){
			//console.log("Pressing");
			tickWedgeCount({increase: false, thisElm: this})
			wedgeCounterTimer = setInterval(() =>{
				tickWedgeCount({increase: false, thisElm: this})
			}, 150);
		});
		$wedgePlus.on('mouseleave', function(){
			//console.log("Mouse leave");
			clearInterval(wedgeCounterTimer);
		});
		$wedgeMinus.on('mouseleave', function(){
			//console.log("Mouse leave");
			clearInterval(wedgeCounterTimer);
		});
		$(window).on('mouseup', function(){
			//console.log("Not pressing");
			clearInterval(wedgeCounterTimer);
		});
	}

	function bindEvents(){
		$input.on('input', onInput);
		$uploadAudioInput.on('input', initAudioUpload);
	}

	// Unbind and bind events
	// Note: this is called everytime form is initialized
	function bindPageSpecificEvents(){
		if (pageId == 'edit') $updateButton.off().on('click', updatePost);
	}

	// Init
	/*-------------------------------*/

	async function init({bypassGetPost} = {}){
		if (!bypassGetPost){
			// Get post ID from meta tag
			// Note: sent from server and rendered via EJS template
			postId = states.createMode && states.createPostCreated ? states.mediaData.mediaId : $('meta[name="active-post"]').attr('content');
			// Get private user post
			const post = await postRequests.getPrivatePost(postId);
			console.log("post", post);
			if (!post){
				$el.hide();
				// Set center message
				centerMessage.setMsg("Film not found")
				// Show center message
				centerMessage.show();
				return;
			}
			// Bind page specific events
			bindPageSpecificEvents();
			// Hide tags if not edit page because of UI constraints on post page
			if (pageId != 'edit') $tagsField.hide();
			// Set media
			media = post;
			console.log("media", media);
		}
		// Set state
		if (media.lastFrame == 1){
			states.fantascope = true;
			console.log("states.fantascope", states.fantascope);
		}
		// If fantacope, hide preview image refresh and show form items
		if (states.fantascope){
			$refresh.hide();
			$fantascopeFormItems.show();
			// Update wedge count message
			setMessage({$elm: $wedgeCountMsg, msg: wedgeCountWarningMsg});
		}
		// Hide order field if post is not from explore gallery
		if (!media.exploreGallery) $orderField.hide();
		// Update document title
		if (media.title) document.title = `${pageId == 'edit' ? 'Edit: ' : ''}${media.title}`;
		// Init form
		initForm(media);
		// Delay input event to prevent initial event being called when site is loaded from browser history
		if (!eventsBound){
			eventsBound = true;
			bindEvents();
		}
		// Set preview
		setPreview();
		// Update audio UI
		if (!states.fantascope && media.lastFrame > options.shortFilmFrameThreshold){
			$uploadAudio.show();
			updateAudioUI();
		}
		if (media.processed || media.failed) return;
		// Poll database on interval
		pollTimer = setInterval(pollDB, timerPollDatabase);
	}

	function initForm(media){
		initTags(media)
		const {title, mediaAuthor, year, description, privateLink, publicGallery, viewOnly, order, bgcolor} = media;
		titleInput.value = title;
		authorInput.value = mediaAuthor;
		yearInput.value = year;
		descriptionInput.value = description;
		if (!privateLink && !publicGallery) accountAccessInput.checked = true;
		if (privateLink && !publicGallery) privateLinkInput.checked = true;
		if (!privateLink && publicGallery && !viewOnly) publicGalleryInput.checked = true;
		if (!privateLink && publicGallery && viewOnly) publicGalleryViewOnlyInput.checked = true;
		if (order) orderInput.value = order;
		updateRemixLicenseMessage();
		if (!states.fantascope) return;
		if (bgcolor) colorPickerInput.value = bgcolor;
		const wedgeCountValue = media.fps || '?';
		$wedgeCount.text(wedgeCountValue);
		updateWedgeCounterUI();
	}

	// Post
	/*-------------------------------*/

	async function pollDB(){
		if (media.processed || media.failed){
			console.log("Stop polling database");
			clearInterval(pollTimer);
			menu.updateStorageUI();
			return;
		}
		// Poll database for updated post if media is not processed and not failed
		console.log("Poll database");
		// Get private user post
		const post = await postRequests.getPrivatePost(postId);
		console.log("post", post);
		if (!post) return;
		// If media is processed or failed, set preview
		if (post.processed || post.failed){
			// Set media
			media = post;
			console.log("media", media);
			// Set preview
			setPreview();
			// Update audio UI
			if (!states.fantascope && media.lastFrame > options.shortFilmFrameThreshold){
				$uploadAudio.show();
				updateAudioUI();
			}
		}
	}

	async function updatePost(){
		if (!validateForm()) return console.log("Form validation fail");
		console.log("Form validation pass");
		// Set previous frame rate
		previousFrameRate = media.fps;
		// Set previous public gallery
		const previousPublicGallery = media.publicGallery;
		// Get form data
		const formData = new FormData($form[0]);
		// Convert formData to object
		let formDataObj = Object.fromEntries(formData);
		//console.log("formDataObj", formDataObj);
		// Get checked privacy value from radios and set privacy settings
		if (formDataObj.privacy == 'accountAccess'){
			formDataObj.privateLink = false;
			formDataObj.publicGallery = false;
			formDataObj.viewOnly = false;
		}
		if (formDataObj.privacy == 'privateLink'){
			formDataObj.privateLink = true;
			formDataObj.publicGallery = false;
			formDataObj.viewOnly = false;
		}
		if (formDataObj.privacy == 'publicGallery'){
			formDataObj.privateLink = false;
			formDataObj.publicGallery = true;
			formDataObj.viewOnly = false;
			// Add Creative Commons license if project is remixable
			formDataObj.license = "CC BY-NC 4.0";
		}
		if (formDataObj.privacy == 'publicGalleryViewOnly'){
			formDataObj.privateLink = false;
			formDataObj.publicGallery = true;
			formDataObj.viewOnly = true;
		}
		// Delete privacy prop
		delete formDataObj.privacy;
		// If film, delete background color prop
		if (!states.fantascope) delete formDataObj.bgcolor;
		// If randomFrame is defined, append to form data thumb
		if (randomFrame) formDataObj.thumb = randomFrame;
		// If fantascope, append wedge count data as fps
		if (states.fantascope){
			const wedgeCountExists = Number.isInteger(parseInt($wedgeCount.text()));
			const fpsValue = wedgeCountExists ? parseInt($wedgeCount.text()) : null;
			formDataObj.fps = fpsValue;
		}
		// Get selected tags
		const selectedTags = []
		$tagsContainer[0].querySelectorAll('span.select').forEach(tag =>{
			selectedTags.push(tag.getAttribute('data-value'))
		})
		formDataObj.tags = selectedTags;
		console.log("formDataObj", formDataObj);
		// Update post
		const post = await postRequests.updatePost(postId, formDataObj);
		console.log("post", post);
		if (!post) return showResponse({msg: "Error: Info not updated", error: true});
		const responseMessage = post.publicGallery && previousPublicGallery != post.publicGallery
		? "Your project is submitted! If approved, it will appear in the public gallery."
		: "Info updated"
		showResponse({msg: responseMessage});
		disableButtons();
		resetUI();
		// Set media
		media = post;
		console.log("media", media);
		// Set state
		states.mediaData = post;
		console.log("states.mediaData", states.mediaData);
		// Remove keyframes if fantascope and frame rate updated
		await removeKeyframes();
		// Init post
		init({bypassGetPost: true});
	}

	async function removeKeyframes(){
		if (!states.fantascope) return;
		if (previousFrameRate == states.mediaData.fps) return;
		const postObj = {
			keyframes: []
		}
		// Update post
		const post = await postRequests.updatePost(states.mediaData.mediaId, postObj);
		console.log("post", post);
		if (!post) return console.log("Error removing keyframes");
		// Set media
		media = post;
		console.log("media", media);
		// Set state
		states.mediaData = post;
		console.log("states.mediaData", states.mediaData);
		console.log("Keyframes removed");
	}

	async function deletePost(){
		const response = await postRequests.deletePost(postId);
		console.log("response", response);
		if (!response){
			showResponse({msg: "Error: Delete post failed", error: true});
			// Close alert
			alert.close();
			return;
		}
		// Remove media
		if (options.removeMedia) await mediaRequests.removeMedia(postId);
		// Go to my work
		goToLinkWithoutFade('/my-work');
	}

	// Custom form inputs
	/*-------------------------------*/

	function getRandomFrame(){
		while (true) {
			currentPreviewImage = randomFrame;
			randomFrame = getRandomInteger(media.firstFrame, media.lastFrame);
			//console.log("randomFrame", randomFrame);
			//console.log("currentPreviewImage", currentPreviewImage);
			if (randomFrame !== currentPreviewImage) {
				break;
			}
		}
		setPreviewImageSource(mediaPath+randomFrame);
		// Remove broken image class
		$previewImg.removeClass("broken");
		// Update UI to pending save state
		$preview.addClass("change");
		onInput();
	}

	function tickWedgeCount({increase, thisElm} = {}){
		if (increase && parseInt($wedgeCount.text()) >= fantascopeWedgeMax) return;
		const wedgeCountExists = Number.isInteger(parseInt($wedgeCount.text()));
		if (!increase && !wedgeCountExists) return;
		let tick = increase ? 1 : -1;
		const currentValue = parseInt($wedgeCount.text()) || fantascopeWedgeMin-1;
		const newValue = currentValue + tick;
		!isBetween(newValue, fantascopeWedgeMin-1, fantascopeWedgeMax+1)
		? $wedgeCount.text('?')
		: $wedgeCount.text(newValue);
		updateWedgeCounterUI();
		onInput(thisElm);
	}

	function initTags(media){
		$tagsContainer.empty()
		tags.forEach(tag => {
			const selected = media.tags.includes(tag.id)
			const tagElm = `<span data-value=${tag.id} class=${selected ? "select" : ""}>${tag.title}</span>`
			$tagsContainer.append(tagElm)
		})
		// Bind events
		$tags = $tagsContainer.find('span');
		$tags.on('click', updateTagsUI);
	}

	function updateTagsUI(){
		$(this).toggleClass("select")
		onInput($tagsContainer);
	}

	// Form misc
	/*-------------------------------*/

	function preventDefault(e){
		// Prevent form submitting
		e.preventDefault();
	}

	function reset(){
		hideResponse();
		disableButtons();
		resetUI();
		init();
	}

	function validateForm(){
		return $form[0].checkValidity();
	}

	async function showAlert({msg, deleteAudioPrompt = false} = {}){
		// Open alert
		const deleteConfirmed = await alert.open({
			msg: msg,
			hideActionUndone: false,
			action: "delete"
		});
		console.log("deleteConfirmed", deleteConfirmed);
		if (deleteConfirmed) deleteAudioPrompt ? deleteAudio() : deletePost();
	}

	// Link
	/*-------------------------------*/

	async function goToLink(link){
		footer.fadeOut();
		await fadeOut();
		window.location.href = link;
	}

	function goToLinkWithoutFade(link){
		window.location.href = link;
	}

	// Share link
	/*-------------------------------*/

	async function copyLink(){
		try {
			// Construct base URL from root URL and post ID
			const baseURL = new URL(`/screen/${media.mediaId}`, rootURL);
			// Construct URL string
			const urlString = `${baseURL}`;
			//console.log("baseURL", baseURL);
			const copyToClipboard = await copy(urlString);
			console.log("Copied successfuly", urlString);
			setMessage({$elm: $shareMsg, msg: copyLinkSuccessMsg});
		} catch (err){
			console.log(err);
			setMessage({$elm: $shareMsg, msg: copyLinkErrorMsg});
		}
	}

	// Response
	/*-------------------------------*/

	function showResponse({msg, error = false}){
		// Construct HTML markup
		const str = `<p>${msg}</p>`
		// Write to DOM
		$responseMessage.html(str);
		// Change color based on message type
		error ? $response.addClass('error') : $response.removeClass('error');
		// Show
		$response.css("display", "block");
	}

	function hideResponse(){
		// Hide
		$response.css("display", "none");
	}

	// UI
	/*-------------------------------*/

	function setPreview(){
		if (!media.processed){
			// Hide preview image
			$preview.hide();
			// Hide options
			$options.hide();
			// Show form
			if (!media.failed) return show();
			// If media failed, hide spinner and show error message
			// Hide spinner
			$spinner.hide();
			// Error message
			$mediaFailError.show();
			// Show element
			show();
			return;
		}	
		// Hide spinner
		$spinner.hide();
		// Show preview image
		$preview.show();
		// Show options
		$options.show();
		// Set media path
		const fantascopeUploadImage = media.mediaUpload && media.lastFrame == 1 ? true : false;
		const imageFolder = fantascopeUploadImage ? 'thumbs' : 'images';
		mediaPath = media.exploreGallery
		? `${S3_PATH}/media/thumbnails/${media.mediaId}`
		: `${S3_PATH_UPLOAD}/${media.mediaId}/${imageFolder}/`;
		// Set preview image
		if (media.exploreGallery){
			$refresh.hide();
			// Set preview image
			setPreviewImageSource(mediaPath);
		} else {
			currentPreviewImage = media.thumb;
			randomFrame = currentPreviewImage;
			// Set preview image
			setPreviewImageSource(mediaPath+currentPreviewImage);
		}
		// Wait for preview image to load and show component
		/*$previewImg[0].addEventListener('load', function() {
			// Show form
			show();
		});*/
		// Broken image handling
		if ($previewImg[0]){
			$previewImg[0].addEventListener('error', function() {
				this.src = `${brokenImagePath}`;
				this.className = 'broken';
			});
		}
		// Show form
		show();
	}

	function resetUI(){
		$('.field').removeClass("change");
		$radiosMainLabel.removeClass("change");
		$updateButton.removeClass("change");
		$preview.removeClass("change");
	}

	function onInput(elm){
		// Update UI to pending save state
		$updateButton.addClass("change");
		if ($response.is(":visible")) hideResponse();
		enableButtons();
		if (!elm) return;
		// Note: color picker passes an event
		const thisElm = elm.target ? elm.target : elm;
		// If radio element, remove class from field
		const isRadioElm = $(thisElm).attr("type") == "radio";
		//console.log("isRadioElm", isRadioElm);
		if (isRadioElm){
			$radioFields.removeClass("change");
			$radiosMainLabel.addClass("change");
		}
		$(thisElm).closest('.field').addClass("change");
	}

	function enableButtons(){
		$updateButton[0].disabled = false;
		$cancelButton[0].disabled = false;
	}

	function disableButtons(){
		$updateButton[0].disabled = true;
		$cancelButton[0].disabled = true;
	}

	function updateWedgeCounterUI(){
		// Enable or disable plus button
		parseInt($wedgeCount.text()) == fantascopeWedgeMax
		? $wedgePlusWrap.addClass('disable')
		: $wedgePlusWrap.removeClass('disable')
		// Enable or disable minus button
		const wedgeCountExists = Number.isInteger(parseInt($wedgeCount.text()));
		!wedgeCountExists
		? $wedgeMinusWrap.addClass('disable')
		: $wedgeMinusWrap.removeClass('disable')
	}

	function setPreviewImageSource(src){
		$previewImg.attr("src",`${src}.jpg`);
	}

	function updateRemixLicenseMessage(){
		publicGalleryInput.checked ? $remixLicenseMessage.show() : $remixLicenseMessage.hide();
	}

	function setMessage({$elm, msg}){
		$elm.html(msg);
	}

	function show(){
		$el.css("opacity", "1");
	}

	function fadeIn() {
		return new Promise(resolve => {
			$el.fadeIn(fadeDuration, resolve);
		})
	}

	function fadeOut() {
		return new Promise(resolve => {
			$el.fadeOut(fadeDuration, resolve);
		})
	}

	// Audio upload
	/*-------------------------------*/

	async function initAudioUpload(){
		if (!media) return;
		try {
			loadingAudio = true;
			console.log("loadingAudio", loadingAudio);
			updateAudioUI();
			const uploadData = await audioUpload.checkFile({
				uploadElm: this,
				media: media
			});
			const audioReplaced = await audioUpload.uploadFile({
				...uploadData,
				media: media
			});
			// Update media
			// Note: post audio status updated on server
			media = audioReplaced.post;
			loadingAudio = false;
			console.log("loadingAudio", loadingAudio);
			updateAudioUI();
		} catch(err){
			audioError(err);
		}
	}

	async function deleteAudio(){
		// Note: audio file is not deleted from server
		const objData = {audio: false}
		// Update post
		const post = await postRequests.updatePost(postId, objData);
		console.log("post", post);
		if (!post){
			setMessage({$elm: $deleteAudioMsg, msg: 'Error deleting audio'});
			// Close alert
			alert.close();
			return;
		}
		// Close alert
		alert.close();
		// Update media
		media = post;
		// Update audio UI
		updateAudioUI();
	}

	function updateAudioUI(){
		if (loadingAudio){
			setMessage({$elm: $uploadAudioMsg, msg: 'Loading...'});
			fullscreenSpinner.show();
			return;
		}
		const msgUse = media.audio ? 'Replace audio' : 'Attach audio';
		setMessage({$elm: $uploadAudioMsg, msg: `${msgUse} <span>(mp3, ${options.maxAudioUploadFileSize}MB max)</span>`});
		media.audio ? $deleteAudio.show() : $deleteAudio.hide();
		fullscreenSpinner.hide();
		// Empty input
		$uploadAudioInput.val(null);
	}

	function audioError(msg){
		setMessage({$elm: $uploadAudioMsg, msg: msg});
		fullscreenSpinner.hide();
		loadingAudio = false;
		console.log("loadingAudio", loadingAudio);
	}

	return {
		init: init,
		updatePost: updatePost
	};

})(); //form

export {form};