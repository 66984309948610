import {states} from '../states.js';

const topModal = (function(){

	//cache DOM
	const $reportPanel = $("#report-panel");
	const $form = $("#form");
	const $exportPanel = $("#export-panel");
	const $sharePanel = $("#share-panel");
	const $remixPanel = $("#remix-panel");

	//bind events
	$(window).on('resize', checkOpenPanels);

	function checkOpenPanels(){
		if (states.postEditFormOpen) stickToTop($form)
		if (states.postExportPanelOpen) stickToTop($exportPanel)
		if (states.sharePanelOpen) stickToTop($sharePanel)
		if (states.reportPanelOpen) stickToTop($reportPanel)
		if (states.remixPanelOpen) stickToTop($remixPanel)
	}

	function stickToTop($elm){
    $(window).height() <= $elm.height()
    ? $elm.addClass('stick-to-top')
    : $elm.removeClass('stick-to-top')
	}

	return {
		checkOpenPanels: checkOpenPanels
	}

})();

export {topModal};