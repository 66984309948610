import {remoteOptions} from '../database.js';
import {states} from '../states.js';
import {centerModal} from './centerModal.js';
import {socketEmit} from './socketEmit.js';
import {menuOptions} from './menuOptions.js';
import {controls} from './controls.js';

// Update option
const updateOption = (function(){

	function setAllObjectPropertiesFalse(array, prop){
		array.forEach(obj => obj[prop] = false);
	}

	function selectOption(index){
		if (states.createMode && !states.initialPhoto) return;
		// Set state and update center modal on initial remote selection
		if (!states.initialSelection){
			states.initialSelection = true;
			console.log("states.initialSelection", states.initialSelection);
			if (states.remotePaired && (!states.createMode || (states.createMode && states.initialPhoto))) centerModal.updateUI();
			// Send initialSelection signal to mobile remote
			socketEmit.send('initialSelection', states);
		}
		if (!index) index = 0;
		// Toggle remote options active property
		if (remoteOptions[index].active && states.multipleParams) remoteOptions[index].active = false;
		else if (remoteOptions[index].active && !states.multipleParams) setAllObjectPropertiesFalse(remoteOptions, "active");
		else if (!remoteOptions[index].active && states.multipleParams) remoteOptions[index].active = true;
		else if (!remoteOptions[index].active && !states.multipleParams){
			setAllObjectPropertiesFalse(remoteOptions, "active");
			remoteOptions[index].active = true;
		}
		//console.log("remoteOptions", remoteOptions);
		// Update optionSelected state based on active remote options
		updateOptionSelectedState();
		// Update menu options
		menuOptions.updateUI(index);
		// Update center controls tooltip
		controls.updateTooltip();
	}

	function updateOptionSelectedState(){
		for (let i = 0; i < remoteOptions.length; i++){
			if (remoteOptions[i].active){
				// Set state
				states.optionSelected = true;
				console.log("states.optionSelected", states.optionSelected);
				return;					
			}
		}
		// Set state
		states.optionSelected = false;
		console.log("states.optionSelected", states.optionSelected);
	}

	function reset(){
		setAllObjectPropertiesFalse(remoteOptions, "active");
		updateOptionSelectedState();
		menuOptions.reset();
	}

	return {
		selectOption: selectOption,
		reset: reset
	};

})(); //updateOption

export {updateOption};