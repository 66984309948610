const centerMessage = (function(){

	//options
	const fadeDuration = 700;

	//cache DOM
	const $el = $("#center-message");
	const $p = $el.find("p");

	function setMsg(msg){
		$p.html(msg)
	}

	function show(){
		$el.show();
	}

	function hide(){
		$el.hide();
	}

	function fadeIn(){
		$el.fadeIn(fadeDuration);
	}

	function fadeOut(){
		$el.fadeOut(fadeDuration);
	}

	return{
		setMsg: setMsg,
		show: show,
		hide: hide,
		fadeIn: fadeIn,
		fadeOut: fadeOut
	}

})();

export {centerMessage};