import {states} from '../states.js';
import {wheelSpeed} from './images_wheelSpeed.js';

// Record player playback for wheel
const rpPlaybackWheel = (function(){

	//data

		// Midpoint speed
		let rpMidpointSpeed,
			rpMidpointSpeedThresholdMin,
			rpMidpointSpeedThresholdMax,
			lockRpMidpointSpeed = false;

		// Playback settings for wheel speed
		let thresholdBetweenDirectionSwitch,
			threshold,
			minScrollSpeed,
			maxScrollSpeed;

	//options

		// Select playback profile
		if (states.recordPlayerMode) rpPlaybackSettings1();

		// Control speed of virtual wheel through pitch control slider. Turntable settings: RPM = 45, Pitch range +-20%. Testing with AT-LP120-USB.
		// Note: change live moving average window size to 100 on node server
		// Note: common RPM's are 33, 45, and 78
		function rpPlaybackSettings1(){
			// Record player RPM
			const recordPlayerSpeed = 45;
			const pitchRange = .20;
			const minRecordPlayerRPM = recordPlayerSpeed - (recordPlayerSpeed * pitchRange);
			const maxRecordPlayerRPM = recordPlayerSpeed + (recordPlayerSpeed * pitchRange) - 1;
			// Threshold
			thresholdBetweenDirectionSwitch = true; // Must be set to true for wheel to stop when record player stops
			threshold = Math.floor(minRecordPlayerRPM / 10); // Round down to nearest 10
			// Sroll speed
			minScrollSpeed = minRecordPlayerRPM / 10;
			maxScrollSpeed = maxRecordPlayerRPM / 10;
			// Midpoint speed
			lockRpMidpointSpeed = true;
			rpMidpointSpeed = (minRecordPlayerRPM + maxRecordPlayerRPM) / 20;
			rpMidpointSpeedThresholdMin = .05;
			rpMidpointSpeedThresholdMax = .1;
		}

	// Init
	/*-------------------------------*/

	function init(){
		const rpPlaybackSettingsInit = {
			thresholdBetweenDirectionSwitch: thresholdBetweenDirectionSwitch,
			threshold: threshold,
			minScrollSpeed: minScrollSpeed,
			maxScrollSpeed: maxScrollSpeed
		}
		if (states.recordPlayerMode) wheelSpeed.setRpPlaybackSettings(rpPlaybackSettingsInit);
	}

	// Playback
	/*-------------------------------*/

	// Lock record player midpoint speed if within range
	function checkRpMidpointSpeed(scrollSpeed){
		if (!states.recordPlayerMode || !lockRpMidpointSpeed) return;
		if (scrollSpeed > rpMidpointSpeed-rpMidpointSpeedThresholdMin && scrollSpeed < rpMidpointSpeed+rpMidpointSpeedThresholdMax) {
			scrollSpeed = rpMidpointSpeed;
		} else if (scrollSpeed < -(rpMidpointSpeed-rpMidpointSpeedThresholdMin) && scrollSpeed > -(rpMidpointSpeed+rpMidpointSpeedThresholdMax)) {
			scrollSpeed = -rpMidpointSpeed;
		}
		wheelSpeed.setScrollSpeed(scrollSpeed);
	}

	// Detect pitch threshold
	function detectPitchThreshold(scrollSpeed){
		if (!states.recordPlayerMode) return;
		if (scrollSpeed > rpMidpointSpeed){
			console.log("Pitch faster");
		} else if (scrollSpeed < rpMidpointSpeed){
			console.log("Pitch slower");
		} else {
			console.log("Pitch normal");
		}
	}

	return {
		init: init,
		checkRpMidpointSpeed: checkRpMidpointSpeed,
		detectPitchThreshold: detectPitchThreshold
	};

})(); //rpPlaybackWheel

export {rpPlaybackWheel};