import {states} from '../states.js';
import {delay} from '../shared/util.js';
import {remixPanel} from './remixPanel.js';
import {exportPanel} from './exportPanel.js';
import {sharePanel} from './sharePanel.js';
import {reportPanel} from '../shared/reportPanel.js';
import {editFormCustom} from './editFormCustom.js';

const modals = (function(){

	async function closePanel(){
      const addDelay = !states.sharePanelOpen || !states.reportPanelOpen || !states.postEditFormOpen
      if (states.postEditFormOpen) await editFormCustom.hide()
      if (states.remixPanelOpen) await remixPanel.hide()
      if (states.postExportPanelOpen) await exportPanel.hide()
      if (states.sharePanelOpen) await sharePanel.hide()
      if (states.reportPanelOpen) await reportPanel.hide()
      if (addDelay) await delay(200)		
	}

  return {
    closePanel: closePanel
  }

})();

export {modals};