import {playbackParams} from '../database.js';
import {states} from '../states.js';
import {options} from '../options.js';
import {delay} from '../shared/util.js';
import {images} from './images.js';
import {waveform} from './waveform.js';
import {projectorFraming} from './images_projectorFraming.js';
import {wheelFraming} from './images_wheelFraming.js';
import {projectorSpeed} from './images_projectorSpeed.js';
import {effects} from './images_effects.js';
import {hoverOverlay} from './hoverOverlay.js';
import {keyframes} from './keyframes.js';
import {socketEmit} from './socketEmit.js';
import {updateOption} from './updateOption.js';
import {sidePanel} from './sidePanel.js';
import {shepherd} from './shepherd.js';

const controls = (function(){

	//states
	let liveMode = false;
	let quickOnboardingComplete = false;

	//data
	let scrollInterval;
	let imageZoomValue;

	//options
	const framingAmount = 2;
	const showShuffleButton = true;
	const enableImageMoveOnLoad = true;
	const defaultImageZoomValue = 100;
	const minImageZoomValue = 15;
	const maxImageZoomValue = 350;
	const imageZoomSliderSteps = 1;

	//cache DOM
	const $el = $('#controls');
	const $centerControls = $el.find('#center-controls');
	const $leftControls = $el.find('#left-controls')
	const $playbackWrap = $el.find('#playback');
	const $shuffleWrap = $el.find('#shuffle');
	const $shuffleImg = $el.find('#shuffle span');
	const $up = $el.find('#up span');
	const $down = $el.find('#down span');
	const $resetFrame = $el.find('#reset-frame span');
	const $frameCounter = $el.find('#frame-counter');
	const $currentFrame = $el.find('#current-frame');
	const $totalFrames = $el.find('#total-frames');
	const $degreeCounter = $el.find('#degree-counter');
	const $currentDegree = $el.find('#current-degree');
	const $audioToggle = $el.find('#checkboxAudio');
	const audioToggle = $audioToggle[0];
	const $soundFxToggle = $el.find('#checkboxSoundFX');
	const soundFxToggle = $soundFxToggle[0];
	const $waveformToggle = $el.find('#checkboxWaveform');
	const waveformToggle = $waveformToggle[0];
	const $audioPanel = $el.find('#audio-panel');
	const $playControls = $el.find('#play-controls');
	const $liveControls = $el.find('#live-controls');
	const $frameLineOptions = $el.find('#frameline-options');
	const $paramInfo = $el.find('#param-info');
	const $resetParams = $el.find('#reset-params .circle');
	const $speedInfo = $el.find('#speed-info');
	const $frameRateInfo = $el.find('#frame-rate-info');
	const $framingInfo = $el.find('#framing-info');
	const $focusInfo = $el.find('#focus-info');
	const $brightnessInfo = $el.find('#brightness-info');
	const $saturateInfo = $el.find('#saturate-info');
	const $contrastInfo = $el.find('#contrast-info');
	const $hueInfo = $el.find('#hue-info');
	const $imageControls = $el.find('#image-controls');
	const $imageMoveToggle = $el.find('#checkboxImageMove');
	const imageMoveToggle = $imageMoveToggle[0];
	const $imageZoomSlider = $el.find('#image-zoom-slider');
	const $resetImageControls = $el.find('#reset-image-controls .circle');
	const $centerControlsTooltipText = $centerControls.find('.tooltip-text');
	// Cached from outside component
	const $sidePanel = $('#side-panel')

	// Param info data
	const paramInfoData = [
		{
			$elm: $speedInfo,
			id: 'speed',
			roundTo: 100
		},
		{
			$elm: $frameRateInfo,
			id: 'frameRate',
			roundTo: 100
		},
		{
			$elm: $framingInfo,
			id: 'framing',
			roundTo: 1
		},
		{
			$elm: $focusInfo,
			id: 'focus',
			roundTo: 100
		},
		{
			$elm: $brightnessInfo,
			id: 'brightness',
			roundTo: 100
		},
		{
			$elm: $saturateInfo,
			id: 'saturate',
			roundTo: 100
		},
		{
			$elm: $contrastInfo,
			id: 'contrast',
			roundTo: 100
		},
		{
			$elm: $hueInfo,
			id: 'hue',
			roundTo: 100
		}
	]

	//bind events
	$playbackWrap.on('click', () => keyframes.togglePlayAll());
	$shuffleImg.on('click', () => keyframes.toggleShuffle());
	$resetParams.on('click', () => images.resetParamsInstantly());
	$resetImageControls.on('click', () => resetImagePosition());
	$resetFrame.on('click', () => states.fantascope ? wheelFraming.reset() : projectorSpeed.resetFrame());
	$up.on('click', scrollUp);
	$down.on('click', scrollDown);
	$up.on('mousedown', () => {
		scrollInterval = setInterval(scrollUp, 30);
	}).on('mouseup mouseleave', () => {
		clearInterval(scrollInterval);
	});
	$down.on('mousedown', () => {
		scrollInterval = setInterval(scrollDown, 30);
	}).on('mouseup mouseleave', () => {
		clearInterval(scrollInterval);
	});
	audioToggle.addEventListener('change', function() {
		if (states.fantascope) return;
		states.audio = this.checked ? true : false;
		states.audio ? projectorSpeed.playSound() : projectorSpeed.stopSound();
		socketEmit.send('updateSwitches', states);
	});
	soundFxToggle.addEventListener('change', function() {
		if (states.fantascope) return;
		states.soundFX = this.checked ? true : false;
		states.soundFX ? projectorSpeed.playSoundEffects() : projectorSpeed.stopSoundEffects();
		socketEmit.send('updateSwitches', states);
	});
	waveformToggle.addEventListener('change', function() {
		if (states.fantascope) return;
		this.checked ? waveform.open() : waveform.close();
		socketEmit.send('updateSwitches', states);
	});
	imageMoveToggle.addEventListener('change', function() {
		if (!states.fantascope) return;
		states.enableImageMove = this.checked ? true : false;
		images.updateCursor();
	});
	$imageZoomSlider[0].addEventListener('input', function() {
		imageZoomValue = parseInt(this.value);
		zoomImage({fromScroll: false});
	});
	$(window).on('resize', nudgeLeftControls);

	// Init
	/*-------------------------------*/

	async function init(){
		// Update toggles
		updateToggles();
		// If fantascope or disabled in options, hide soundFX toggle
		if (states.fantascope || !options.projectorSoundEffects) $soundFxToggle.closest(".switch-container").hide();
		// If fantascope, hide elements
		if (states.fantascope){
			$audioPanel.hide()
			$frameCounter.hide();
			$frameLineOptions.hide();
		}
		// If film, hide elements
		if (!states.fantascope){
			$degreeCounter.hide();
			$imageControls.hide();
		}
		// Show or hide shuffle button
		if (!showShuffleButton) $shuffleWrap.hide();
		// Hide play controls
		if (states.fantascope && !showShuffleButton) $playControls.hide();
		// Set audio state based on option or screen mode settings
		if (states.mediaHasAudio){
			if ((!states.screenMode && options.initAudioOnLoad) || (states.screenMode && states.screenModeSettings.audio)){
				states.audio = true;
				console.log("states.audio", states.audio);
			}
		}
		// Set soundFX state based on screen mode settings
		if (!states.fantascope && states.screenMode && states.screenModeSettings.soundFX){
			states.soundFX = true;
			console.log("states.soundFX", states.soundFX);
		}
		// Setup for fantascope
		if (states.fantascope){
			// Set initial degree
			setCurrentDegree(0);
			// Set initial image zoom value
			imageZoomValue = defaultImageZoomValue;
			// Set initial image zoom slider values
			$imageZoomSlider[0].value = defaultImageZoomValue;
			$imageZoomSlider[0].min = minImageZoomValue;
			$imageZoomSlider[0].max = maxImageZoomValue;
			// Set image zoom slider steps
			$imageZoomSlider[0].step = imageZoomSliderSteps;
			// Enable image move on load
			if (enableImageMoveOnLoad){
				states.enableImageMove = true;
				console.log("states.enableImageMove", states.enableImageMove);
				images.updateCursor();
			}
		}
		// Update UI
		updateUI();
		// Update playback UI
		await updatePlaybackControls({init: true});
		$centerControls.addClass('slide')
		// Update info active
		updateInfoActive();
		// Update enable UI
		updateEnable();
		// Show
		if (!states.screenMode) show();
	}

	// Frame line
	/*-------------------------------*/

	function scrollUp(){
		states.fantascope ? wheelFraming.changeFraming(framingAmount) : projectorFraming.changeFraming(framingAmount);
		// Clear hover overlay timer
		hoverOverlay.resetTimer();
	}

	function scrollDown(){
		states.fantascope ? wheelFraming.changeFraming(-framingAmount) : projectorFraming.changeFraming(-framingAmount);
		// Clear hover overlay clearTimer
		hoverOverlay.resetTimer();
	}

	// Frame coutner
	/*-------------------------------*/

	function setTotalFrames(data){
		$totalFrames.html(data);
	}

	function setCurrentFrame(data){
		$currentFrame.html(data);
	}

	// Degree coutner
	/*-------------------------------*/

	function setCurrentDegree(data){
		const roundValue = 10;
		let dataRound = Math.round(roundValue*data)/roundValue;
		$currentDegree.html(dataRound);
	}

	// Multiple params
	/*-------------------------------*/

	function toggleMultipleParams(){
		states.multipleParams = !states.multipleParams;
		console.log("states.multipleParams", states.multipleParams);
		if (states.remotePaired) socketEmit.send('updateTargetState', {targetState: 'multipleParams', value: states.multipleParams});
		if (!states.multipleParams) updateOption.reset();
		updateUI();
	}

	// Info
	/*-------------------------------*/

	function updateParamInfo(){
		paramInfoData.forEach( ({$elm, id, roundTo}) => {
			const msg = id == 'framing'
			? playbackParams[id] == 0 ? '0' : (playbackParams[id] > 0 ? '+' : '-')
			: `${Math.round(roundTo*playbackParams[id])/roundTo}`
			setMessage({
				$elm: $elm,
				msg: msg
			});
		});
	}

	function updateInfoActive(){
		sidePanel.getInfoActive() ? $paramInfo.removeClass('hide') : $paramInfo.addClass('hide');
		sidePanel.getInfoActive() ? $frameCounter.removeClass('hide') : $frameCounter.addClass('hide');
		sidePanel.getInfoActive() ? $degreeCounter.removeClass('hide') : $degreeCounter.addClass('hide');
	}

	// Image controls
	/*-------------------------------*/
	// Note: draggable image initialized in images component
	
	function zoomImage({fromScroll, data}){
		if (fromScroll){
			imageZoomValue += data;
			$imageZoomSlider[0].value = imageZoomValue;
		}
		// Limit image zoom value
		if (imageZoomValue < minImageZoomValue) imageZoomValue = minImageZoomValue;
		if (imageZoomValue > maxImageZoomValue) imageZoomValue = maxImageZoomValue;
		effects.zoom();
	}

	function resetImagePosition(){
		imageZoomValue = defaultImageZoomValue;
		$imageZoomSlider[0].value = imageZoomValue;
		effects.zoom();
		images.resetImagePosition();
	}

	function getImageZoomValue(){
		return imageZoomValue;
	}

	// UI
	/*-------------------------------*/

	function updatePlaybackControls({init} = {}){
		return new Promise(async resolve => {
			states.playing ? $playbackWrap.addClass('playing') : $playbackWrap.removeClass('playing');
			if (states.playing && liveMode){
				liveMode = false;
				console.log("liveMode", liveMode);
				// Update keyframes playback mode UI
				keyframes.updatePlaybackModeUI();
				// Update side panel help button
				sidePanel.updateHelpButton();
				// Update side panel position
				sidePanel.updatePosition();
				// Stop tour
				if (shepherd.isActive()) shepherd.cancel();
				// Set state
				states.enableParametersChange = false;
				console.log("states.enableParametersChange", states.enableParametersChange);
				$centerControls.removeClass('show');
				await delay(400);
				$centerControls.removeClass('live-mode');
				$centerControls.addClass('show');
				if (!states.fantascope) $centerControls.addClass('film');
				if (!states.fantascope || showShuffleButton) $playControls.show();
				$liveControls.hide();
				// Update tooltip
				updateTooltip();
				return resolve();
			}
			if (!states.playing && !liveMode) {
				liveMode = true;
				console.log("liveMode", liveMode);
				// Update keyframes playback mode UI
				keyframes.updatePlaybackModeUI();
				// Update side panel help button
				sidePanel.updateHelpButton();
				// Update side panel position
				sidePanel.updatePosition();
				if (!init){
					$centerControls.removeClass('show');
					// Shorten delay if there is no play controls panel
					states.fantascope && !showShuffleButton ? await delay(200) : await delay(400);
				}
				if (!states.fantascope) $centerControls.removeClass('film');
				$centerControls.addClass('live-mode');
				$centerControls.addClass('show');
				if (!states.fantascope || showShuffleButton) $playControls.hide();
				$liveControls.show();
				// Update tooltip
				updateTooltip();
				// Set state
				states.enableParametersChange = true;
				console.log("states.enableParametersChange", states.enableParametersChange);
			}
			resolve();
		});
	}

	function updateToggles(){
		// Show or hide audio and waveform toggles based on media audio
		states.mediaHasAudio ? $audioToggle.closest(".switch-container").show() : $audioToggle.closest(".switch-container").hide();
		states.mediaHasAudio ? $waveformToggle.closest(".switch-container").show() : $waveformToggle.closest(".switch-container").hide();
	}

	async function updateTooltip(){
		if (quickOnboardingComplete) return;
		if (states.initialSelection && !states.initialParamChange && states.enableParametersChange) {
			$centerControlsTooltipText.fadeOut(300)
			await delay(300);
			const message = states.optionSelected ? "Scroll to adjust value" : "Select a parameter to begin"
			$centerControlsTooltipText.find('p').text(message)
			$centerControlsTooltipText.fadeIn(300)
		}
		if (states.initialSelection && states.initialParamChange && states.enableParametersChange) {
			$centerControlsTooltipText.fadeOut(300)
			quickOnboardingComplete = true;
			console.log("quickOnboardingComplete", quickOnboardingComplete)
		}
		if (!quickOnboardingComplete && !states.enableParametersChange) {
			states.playing ? $centerControlsTooltipText.hide() : $centerControlsTooltipText.show();
		}
	}

	async function updateUI(){
		// Switch toggles
		states.audio ? check(audioToggle) : uncheck(audioToggle);
		states.soundFX ? check(soundFxToggle) : uncheck(soundFxToggle);
		states.waveform ? check(waveformToggle) : uncheck(waveformToggle);
		states.enableImageMove ? check(imageMoveToggle) : uncheck(imageMoveToggle);
		// Sync mobile
		socketEmit.send('updateSwitches', states);
		// Shuffle
		const shuffleOn = keyframes.getShuffle();
		shuffleOn ? $shuffleWrap.addClass('active') : $shuffleWrap.removeClass('active');
	}

	function updateEnable(){
		if (!states.createMode) return;
		states.initialPhoto ? $el.removeClass('disable') : $el.addClass('disable');
	}

	function nudgeLeftControls(){
		$(window).height() < $sidePanel.height() + $leftControls.height() + 20
		? $leftControls.addClass('ml-[60px]')
		: $leftControls.removeClass('ml-[60px]')
	}

	function show(){
		$el.addClass('show');
	}

	function enablePlayback({enable} = {}){
		enable ? $playbackWrap.removeClass('disablePlayback') : $playbackWrap.addClass('disablePlayback');
	}

	function check(elm){
		elm.checked = true;
	}

	function uncheck(elm){
		elm.checked = false;
	}

	function setMessage({$elm, msg}){
		$elm.html(msg);
	}

	return {
		init: init,
		updateUI: updateUI,
		updateToggles: updateToggles,
		updatePlaybackControls: updatePlaybackControls,
		updateEnable: updateEnable,
		updateTooltip: updateTooltip,
		enablePlayback: enablePlayback,
		setTotalFrames: setTotalFrames,
		setCurrentFrame: setCurrentFrame,
		setCurrentDegree: setCurrentDegree,
		updateParamInfo: updateParamInfo,
		updateInfoActive: updateInfoActive,
		getImageZoomValue: getImageZoomValue,
		zoomImage: zoomImage,
		nudgeLeftControls: nudgeLeftControls
	}

})();

export {controls};