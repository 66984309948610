import {states} from '../states.js';
import {overlayPreventClick} from '../shared/overlayPreventClick.js';
import {checkImage} from '../shared/loadImages.js';
import {loader} from './loader.js';
import {sidePanel} from './sidePanel.js';

// Center modal
const centerModal = (function(){

	//states
	let createModeEventsBound = false;
	let createModeDeviceTypeSelection = false;
	let createModeStandaloneMobileCameraSelected = false;
	let createModeMediaTypeSelection = false;
	let createModeAspectRatioSelection = false;
	let createModeResolutionSelection = false;
	let createModeOptionsComplete = false;

	//options
	const qrcodeMessage = "Scan the QR code with your smartphone&rsquo;s camera or use a mouse / touchpad to begin"
	const qrcodeMessageCreateMode = "Scan the QR code with your smartphone&rsquo;s camera to begin"

	//data
	let promiseResolve;
	const views = [
		{
			id: "deviceTypeOptions",
			images: [
				{
					name: "desktop",
					size: 200,
					classes: ['link', 'device-type-option']
				}
			],
			message: "Click to create project using tethered desktop mode<br><br><span class='small'>(Once you're familiar with tethered desktop mode, try making media out in the world using the <span id='mobile' data-option='mobile' class='link device-type-option'>standalone mobile camera</span>)",
			active: false
		},
		{
			id: "mediaTypeOptions",
			images: [
				{
					name: "film",
					size: 200,
					classes: ['link', 'media-type-option']
				},
				{
					name: "fantascope",
					size: 200,
					classes: ['link', 'media-type-option']
				}
			],
			message: "Select a film or fantascope",
			active: false
		},
		{
			id: "aspectRatioOptions",
			images: [
				{
					name: "1-1",
					size: 180,
					classes: ['link', 'aspect-ratio-option']
				},
				{
					name: "4-3",
					size: 180,
					classes: ['link', 'aspect-ratio-option']
				},
				{
					name: "16-9",
					size: 180,
					classes: ['link', 'aspect-ratio-option']
				}
			],
			message: "Select aspect ratio",
			active: false
		},
		{
			id: "resolutionOptions",
			images: [
				{
					name: "640x640",
					size: 250,
					classes: ['link', 'hide', 'resolution-option', 'aspect-ratio-1-1']
				},
				{
					name: "640x480",
					size: 250,
					classes: ['link', 'hide', 'resolution-option', 'aspect-ratio-4-3']
				},
				{
					name: "640x360",
					size: 250,
					classes: ['link', 'hide', 'resolution-option', 'aspect-ratio-16-9']
				},
				{
					name: "1080x1080",
					size: 250,
					classes: ['link', 'hide', 'resolution-option', 'aspect-ratio-1-1', 'high-res']
				},
				{
					name: "1440x1080",
					size: 250,
					classes: ['link', 'hide', 'resolution-option', 'aspect-ratio-4-3', 'high-res']
				},
				{
					name: "1920x1080",
					size: 250,
					classes: ['link', 'hide', 'resolution-option', 'aspect-ratio-16-9', 'high-res']
				}
			],
			message: "Select resolution",
			active: false
		},
		{
			id: "qrCodeCameraLink",
			size: 200,
			message: "Scan the QR code with your smartphone&rsquo;s camera or go to Spoolyard.com/camera<br><br>Once you complete filming your project, it will appear in the My Work gallery",
			active: false
		},
		{
			id: "qrCode",
			size: 200,
			message: "",
			active: false
		},
		{
			id: "select",
			images: [
				{
					name: "select",
					size: 250
				}
			],
			message: "Select a parameter to control",
			active: false
		},
		{
			id: "phone",
			images: [
				{
					name: "rotate-phone",
					size: 250
				},
				{
					name: "pinch",
					size: 250
				}
			],
			message: "Set your phone on a flat, level surface and rotate it or use a pinch gesture to change parameter",
			active: false
		},
		{
			id: "camera",
			images: [
				{
					name: "camera",
					size: 200
				}
			],
			message: "Tap the camera icon to take photos",
			active: false
		}
	];

	//states
	let initialView = true;
	let initialFade = false;
	let modalOpen = false;

	//options
	const fadeDuration = 500;
	const msgLengthThreshold = 65;
	
	//cache DOM
	const $el = $('#center-modal');
	const $content = $el.find('.content');
	const $close = $el.find('#close-center-modal');
	const $icons = $el.find('#icons');
	const icons = $el.find('#icons')[0];
	const $qrCodeCameraLink = $el.find('#qrcode-camera-link');
	const $qrCode = $el.find('#qrcode');
	const $mouse = $el.find('#mouse');
	const $message = $el.find('#message p');
	const $resetOptions = $el.find('#reset-options');
	let $imgs;
	let $deviceTypeOptions;
	let $mediaTypeOptions;
	let $aspectRatioOptions;
	let $resolutionOptions;
	let $highResolutionOptions;
	let $resolutionOptionsAspectRatio11;
	let $resolutionOptionsAspectRatio43;
	let $resolutionOptionsAspectRatio169;

	//Re-cache DOM
	function cacheDom(){
		$imgs = $icons.find('img');
		if (!states.createMode) return;
		$deviceTypeOptions = $el.find('.device-type-option');
		$mediaTypeOptions = $el.find('.media-type-option');
		$aspectRatioOptions = $el.find('.aspect-ratio-option');
		$resolutionOptions = $el.find('.resolution-option');
		$highResolutionOptions = $el.find('.high-res');
		$resolutionOptionsAspectRatio11 = $el.find('.aspect-ratio-1-1');
		$resolutionOptionsAspectRatio43 = $el.find('.aspect-ratio-4-3');
		$resolutionOptionsAspectRatio169 = $el.find('.aspect-ratio-16-9');
	}

	function cacheBindDeviceTypeOptions(){
		$deviceTypeOptions = $el.find('.device-type-option');
		$deviceTypeOptions.off().on('click', function() {
			selectDeviceType({elm: this});
		});
	}

	//bind events
	$close.on('click', toggle);
	$mouse.on('click', toggle);

	function bindCreateModeEvents(){
		if (createModeEventsBound) return;
		createModeEventsBound = true;
		$resetOptions.on('click', () => {
			fadeOutNoOpacity({elm: $resetOptions});
			updateUI({resetCreateModeOptions: true});
		});
		$deviceTypeOptions.on('click', function() {
			selectDeviceType({elm: this});
		});
		$mediaTypeOptions.on('click', function() {
			selectMediaType({elm: this});
		});
		$aspectRatioOptions.on('click', function() {
			selectAspectRatio({elm: this});
		});
		$resolutionOptions.on('click', function() {
			selectResolution({elm: this});
		});
	}

	// Init
	/*-------------------------------*/

	function init(){
		return new Promise(async resolve => {
			promiseResolve = resolve;
			await populateIcons();
			// Update message
			views.find(obj => obj.id == 'qrCode').message = states.createMode ? qrcodeMessageCreateMode : qrcodeMessage;
			if (states.createMode){
				// Wait for image to load
				try {
					const loadedImage = await checkImage($deviceTypeOptions[0]);
					console.log('Image found and loaded', loadedImage);
				} catch(err){
					console.log(err);
					return;
				}
				// Hide loader
				loader.hide();
				// Change background opacity
				backgroundOpaque({active: true});
				// Add class
				$el.addClass("create-mode")
				// Update UI
				updateUI();
				toggle();
				overlayPreventClick.hide();
				return;
			}
			updateUI({qrCodeOverride: true});
			promiseResolve();
		});
	}

	// Create
	/*-------------------------------*/

	function populateIcons(){
		return new Promise(resolve => {
			views.forEach(obj => {
				// Skip QR code
				if (obj.id == 'qrCode' || obj.id == 'qrCodeCameraLink') return;
				// Skip create mode icons if not create mode
				if (!states.createMode && (obj.id == 'deviceTypeOptions' || obj.id == 'mediaTypeOptions' || obj.id == 'aspectRatio' || obj.id == 'resolutionOptions' || obj.id == 'camera')) return;
				const images = obj.images;
				images.forEach(imgsObj => {
					createImage({
						parent: icons,
						name: imgsObj.name,
						size: imgsObj.size,
						classes: imgsObj.classes
					});
				});
			});
			cacheDom();
			if (states.createMode) bindCreateModeEvents();
			$imgs.hide();
			resolve();
		});
	}

	function createImage({parent, name, size, classes}) {
		const newImage = new Image();
		newImage.src = `/media-keep/instructions/${name}.svg`;
		newImage.id = name;
		if (classes && classes.includes('link')) newImage.setAttribute("data-option", name);
		// Set image size
		$(newImage).css("width",`${size}px`);
		// Append to DOM
		parent.appendChild(newImage);
		// Add classes
		if (!classes) return;
		classes.forEach(className => {
			$(newImage).addClass(className);
		})
	}

	// Create mode options
	/*-------------------------------*/

	function selectDeviceType({elm} = {}){
		const deviceType = $(elm).attr('data-option');
		createModeStandaloneMobileCameraSelected = deviceType == "desktop" ? false : true;
		console.log("createModeStandaloneMobileCameraSelected", createModeStandaloneMobileCameraSelected);
		createModeDeviceTypeSelection = true;
		console.log("createModeDeviceTypeSelection", createModeDeviceTypeSelection);
		// Update UI
		updateUI();
	}

	function selectMediaType({elm} = {}){
		const mediaType = $(elm).attr('data-option');
		mediaType == "fantascope" ? states.fantascope = true : states.fantascope = false;
		console.log("states.fantascope", states.fantascope);
		createModeMediaTypeSelection = true;
		console.log("createModeMediaTypeSelection", createModeMediaTypeSelection);
		// Update UI
		updateUI();
	}

	function selectAspectRatio({elm} = {}){
		const aspectRatio = $(elm).attr('data-option');
		// Show resolution options that match aspect ratio
		if (aspectRatio == '1-1') $resolutionOptionsAspectRatio11.removeClass('hide');
		if (aspectRatio == '4-3') $resolutionOptionsAspectRatio43.removeClass('hide');
		if (aspectRatio == '16-9') $resolutionOptionsAspectRatio169.removeClass('hide');
		createModeAspectRatioSelection = true;
		console.log("createModeAspectRatioSelection", createModeAspectRatioSelection);
		updateUI();
	}

	function selectResolution({elm} = {}){
		const resolution = $(elm).attr('data-option');
		// Set width and height for create post
		states.width = Number(resolution.split('x')[0]);
		states.height = Number(resolution.split('x')[1]);
		createModeResolutionSelection = true;
		console.log("createModeResolutionSelection", createModeResolutionSelection);
		// Fade out reset options button
		fadeOutNoOpacity({elm: $resetOptions});
		createModeOptionsComplete = true;
		console.log("createModeOptionsComplete", createModeOptionsComplete);
		// Update UI
		updateUI({qrCodeOverride: true, fromResolutionSelection: true});
		// Resolve external promise from init
		promiseResolve();
	}

	// Array
	/*-------------------------------*/

	function setAllObjectPropertiesFalse(array, prop){
		array.forEach(obj => obj[prop] = false);
	}

	function toggleActiveView(view){
		setAllObjectPropertiesFalse(views, "active");
		const result = views.find(obj => obj.id === view);
		result.active = true;
	}

	// UI
	/*-------------------------------*/

	function fadeIn({elm = $el} = {}){
		return new Promise(resolve => {
			show({elm: elm});
			elm.fadeTo(fadeDuration, 1, () => {
				if (!initialFade) initialFade = true;
				console.log("initialFade", initialFade);
				resolve();
			});
		});
	}

	function fadeOut({elm = $el} = {}){
		return new Promise(resolve => {
			elm.fadeTo(fadeDuration, 0, () => {
				hide({elm: elm});
				resolve();
			});
		});
	}

	function fadeInNoOpacity({elm = $el} = {}){
		return new Promise(resolve => {
			elm.fadeIn(fadeDuration, () => {
				resolve();
			});
		});
	}

	function fadeOutNoOpacity({elm = $el} = {}){
		return new Promise(resolve => {
			elm.fadeOut(fadeDuration, () => {
				resolve();
			});
		});
	}

	function show({elm = $el} = {}){
		elm.show();
	}

	function hide({elm = $el} = {}){
		elm.hide();
	}

	function opacity(data){
		$el.css("opacity", data);
	}

	function backgroundOpaque({active}){
		active ? $el.addClass('create-mode-options') : $el.removeClass('create-mode-options');
	}

	// QR code visibility is being controlled seperately because it reappears after intro modal is closed
	async function showQrCode({fromResolutionSelection} = {}){
		toggleActiveView("qrCode");
		if (modalOpen && initialFade && !fromResolutionSelection) await fadeOut({elm: $content});
		$imgs.hide();
		modalOpen ? $qrCode.show() : $qrCode.fadeTo(fadeDuration, 1);
		if (!states.createMode) $mouse.show();
		// Get active view
		const activeView = views.find(obj => obj.active === true);
		// Set class based on message length
		const messageClass = activeView.message.length > msgLengthThreshold ? "centerLong" : "centerShort";
		$message.removeClass().addClass(messageClass);
		// Set message
		setMessage(activeView.message);
		if (modalOpen) fadeIn({elm: $content});
	}

	async function updateUI({qrCodeOverride, fromResolutionSelection, resetCreateModeOptions} = {}){
		// Fade content
		!initialFade && initialView ? initialView = false : await fadeOut({elm: $content});
		console.log("initialView", initialView);
		// Show close button if remote is paired
		if (createModeOptionsComplete && states.remotePaired) show({elm: $close});
		// Show QR code if override
		if (qrCodeOverride) return showQrCode({fromResolutionSelection: fromResolutionSelection});
		// Reset create mode options
		if (resetCreateModeOptions){
			createModeDeviceTypeSelection = false;
			createModeStandaloneMobileCameraSelected = false;
			createModeMediaTypeSelection = false;
			createModeAspectRatioSelection = false;
			createModeResolutionSelection = false;
			$resolutionOptions.addClass('hide');
		}
		// If phone disconnects and reconnects during instructions, set active view based on initialSelection value
		!states.createMode && !states.initialSelection ? toggleActiveView("select") : toggleActiveView("phone");
		// Set active view for create mode based on states
		if (states.createMode){
			if (!createModeDeviceTypeSelection) toggleActiveView("deviceTypeOptions")
			else if (createModeDeviceTypeSelection && createModeStandaloneMobileCameraSelected) toggleActiveView("qrCodeCameraLink")
			else if (!createModeMediaTypeSelection) toggleActiveView("mediaTypeOptions")
			else if (!createModeAspectRatioSelection) toggleActiveView("aspectRatioOptions")
			else if (!createModeResolutionSelection) toggleActiveView("resolutionOptions")
			else if (!states.initialPhoto) toggleActiveView("camera")
			else if (!states.initialSelection) toggleActiveView("select")
			else if (!states.initialRotation) toggleActiveView("phone")
		}
		$qrCode.hide();
		$qrCodeCameraLink.hide();
		$mouse.hide();
		// Get active view
		const activeView = views.find(obj => obj.active === true);
		// Set class based on message length
		const messageClass = activeView.message.length > msgLengthThreshold ? "centerLong" : "centerShort";
		$message.removeClass().addClass(messageClass);
		// Set message
		setMessage(activeView.message);
		// Cache and bind device type options for link within message
		cacheBindDeviceTypeOptions()
		// Show QR code for camera link or active icons
		if (activeView.id == 'qrCodeCameraLink'){
			$imgs.hide();
			$qrCodeCameraLink.show();
		} else {
			showActiveIcons(activeView.images);
		}
		// Show reset options button
		if (createModeDeviceTypeSelection && !createModeOptionsComplete) fadeInNoOpacity({elm: $resetOptions});
		// Fade in content
		if (modalOpen) fadeIn({elm: $content});
	}

	function close(){
		hide();
		$el.css("opacity", 0);
		$content.css("opacity", 0);
		modalOpen = false;
		console.log("modalOpen", modalOpen);
		sidePanel.updatePhoneConnectionUI();
	}

	function showActiveIcons(images){
		$imgs.hide();
		images.forEach(obj => {
			$(`img#${obj.name}`).show();
		});
	}

	function setMessage(msg){
		$message.html(msg);
	}

	// Toggle
	/*-------------------------------*/

	function toggleOpen(){
		modalOpen = true;
		console.log("modalOpen", modalOpen);
		sidePanel.updatePhoneConnectionUI();
		if (!initialFade){
			// Fade initial background color using css transition
			$el.addClass('init');
			// Show close button
			if (!states.createMode) show({elm: $close});
		}
		fadeIn();
		fadeIn({elm: $content});
	}

	async function toggleClose(){
		modalOpen = false;
		console.log("modalOpen", modalOpen);
		sidePanel.updatePhoneConnectionUI();
		await fadeOut();
	}

	function toggle(){
		modalOpen ? toggleClose() : toggleOpen();
	}

	// Return
	/*-------------------------------*/

	function getModalOpen(){
		return modalOpen;
	}

	return {
		init: init,
		hide: hide,
		close: close,
		getModalOpen: getModalOpen,
		updateUI: updateUI,
		opacity: opacity,
		showQrCode: showQrCode,
		populateIcons: populateIcons,
		toggle: toggle,
		toggleOpen: toggleOpen,
		toggleClose: toggleClose,
		backgroundOpaque: backgroundOpaque
	};

})(); //centerModal

export {centerModal};