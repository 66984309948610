// Source: https://stackoverflow.com/questions/6677035/jquery-scroll-to-element
// Note: jQuery Extension Function
/**
* Scrolls the container to the target position minus the offset
*
* @param target    - the destination to scroll to, can be a jQuery object
*                    jQuery selector, or numeric position
* @param offset    - the offset in pixels from the target position, e.g.
*                    pass -80 to scroll to 80 pixels above the target
* @param speed     - the scroll speed in milliseconds, or one of the
*                    strings "fast" or "slow". default: 0
* @param container - a jQuery object or selector for the container to
*                    be scrolled. default: "html, body"
*/
jQuery.scrollTo = function (target, offset, speed, container) {
	if (isNaN(target)) {
		if (!(target instanceof jQuery))
			target = $(target);

		target = parseInt(target.offset().top);
	}

	container = container || "html, body";
	if (!(container instanceof jQuery))
		container = $(container);

	speed = speed || 0;
	offset = offset || 0;

	container.animate({
		scrollTop: target + offset
	}, speed);
};