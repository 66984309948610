import {callFetch} from './fetchAPI';

// Media requests
const mediaRequests = (function(){

	async function removeMedia(mediaId) {
		const response = await callFetch({
			url: '/remove-media',
			method: 'POST',
			data: {id: mediaId},
			status: 200
		})
		if (!response) return console.log('Error removing media');
		console.log('Success:', response.msg);
		return response;
	}

	async function removeExport(encodeId) {
		const response = await callFetch({
			url: '/remove-export',
			method: 'POST',
			data: {id: encodeId},
			status: 200
		})
		if (!response) return console.log('Error removing export');
		console.log('Success:', response.msg);
		return response;
	}

	async function replaceAudio(obj) {
		const response = await callFetch({
			url: '/replace-audio',
			method: 'POST',
			data: obj,
			status: 200
		})
		if (!response) return console.log('Error replacing audio');
		console.log('Success:', response.msg);
		return response;
	}

	// Probe video
	function probeVideo(obj) {
		return callFetch({
			url: '/probe-video',
			method: 'POST',
			data: obj,
			status: 200
		})
	}

	// Probe image
	function probeImage(obj) {
		return callFetch({
			url: '/probe-image',
			method: 'POST',
			data: obj,
			status: 200
		})
	}

	// Get private video
	function getPrivateVideo(id) {
		return callFetch({
			url: `/private-video/${id}`,
			status: 200
		})
	}

	return {
		removeMedia: removeMedia,
		removeExport: removeExport,
		replaceAudio: replaceAudio,
		probeVideo: probeVideo,
		probeImage: probeImage,
		getPrivateVideo: getPrivateVideo
	};

})(); //mediaRequests

export {mediaRequests};