import {states} from '../states.js';

const titleCard = (function(){

	//state
	let loaded = false;

	//options
	const fadeDuration = 1000;

	//cache DOM
	const $el = $("#title-card");
	const $msg = $el.find(".message");

	function init(){
		if (loaded) return;
		loaded = true;
		console.log("loaded", loaded);
		if (!states.exportMode) $el.addClass('screen-page');
		const title = states.exportMode ? states.exportSettings.title.trim() || "Untitled" : states.mediaData.title.trim() || "Untitled";
		const year = states.exportMode ? states.exportSettings.year || "" : states.mediaData.year || "";
		const author = states.exportMode ? states.exportSettings.mediaAuthor.trim() || "" : states.mediaData.mediaAuthor.trim() || "";
		const remixAuthor = states.exportMode ? states.exportSettings.remixAuthor.trim() || "" : "";
		const remixAuthorExists = remixAuthor.length > 0;
		const str = 
			`
				<h1 class="title">${title}</h1>
				<p class="author">${author}</p>
				<p class="year">${year}</p>
				${remixAuthorExists ? `<p class="remix-author">Projected by ${remixAuthor}</p>` : ''}
			`;

		// Push titles up if remix author is displayed
		if (remixAuthorExists) $el.addClass('bottomOffset');
		setMsg(str);
		makeVisible();
	}

	function makeVisible(){
		$el.css("visibility", "visible");
	}

	function setMsg(msg){
		$msg.html(msg)
	}

	/*Note: Fading style same as center-modal. Opacity used for smooth fading*/
	function fadeIn(){
		return new Promise(resolve => {
			show();
			$el.fadeTo(fadeDuration, 1, resolve);
		});
	}

	function fadeOut(){
		return new Promise(resolve => {
			$el.fadeTo(fadeDuration, 0, () => {
				hide();
				resolve();
			});
		});
	}

	function show(){
		$el.show();
	}

	function hide(){
		$el.hide();
	}

	return{
		init: init,
		setMsg: setMsg,
		show: show,
		hide: hide,
		fadeIn: fadeIn,
		fadeOut: fadeOut
	}

})();

export {titleCard};